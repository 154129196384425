import { Injectable, TemplateRef } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ComponentType } from '@angular/cdk/portal';
import { MediaObserver } from '@angular/flex-layout';
import { AlertDialogData } from '../interfaces/alert-dialog-data.interface';
import { AlertWrapperComponent } from '../dialogs/alert-wrapper/alert-wrapper.component';
import { MaterialMediaQueries } from '../utils/angular-utils/enums/material-media-queries.enum';

@Injectable()
export class XpoDialogManagerService {
  private configDefaults: MatDialogConfig;

  constructor(private matDialog: MatDialog, private mediaQueryService: MediaObserver) {
    this.configDefaults = {
      width: '650px',
    };

    this.initializeWatchers();
  }

  public alert(alertOptions: AlertDialogData, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const defaultsWithData = { ...configOverrides, ...this.configDefaults };
    defaultsWithData.data = alertOptions;

    return this.matDialog.open(AlertWrapperComponent, defaultsWithData);
  }

  public open(dialogComponent: ComponentType<any> | TemplateRef<any>, configOverrides: MatDialogConfig = {}): MatDialogRef<any> {
    const dialogConfig = { ...configOverrides, ...this.configDefaults };

    return this.matDialog.open(dialogComponent, dialogConfig);
  }

  private initializeWatchers(): void {
    this.mediaQueryService.asObservable().subscribe(mediaChange => {
      this.configDefaults.width = this.mediaQueryService.isActive(MaterialMediaQueries.GT_MEDIUM) ? '650px' : '80%';
    });
  }
}
