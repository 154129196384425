import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(input: any, pattern: any, replacement: any): any {
    if (typeof input !== 'string' || !pattern) {
      return input;
    }

    return input.replace(new RegExp(pattern, 'g'), replacement);
  }
}
