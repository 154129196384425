<div class="notes">
  <div class="notes__content">
    <h3>Notes</h3>
    <div class="notes__note"
         *ngFor="let note of (notesArray$ | async)">
      {{note.comments}}
    </div>
    <div *ngIf="(notesArray$ | async)?.length === 0"
         class="notes__no-note">
      No notes present
    </div>
  </div>
</div>
