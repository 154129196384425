<div class="payform-header payform-header-scrolling" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center" class="payform-header-content">
    <div fxLayout="row" fxLayoutAlign="start center" class="payform-header-inner-content">
      <div fxLayout="column" class="payform-header-sic">
        <div class="payform-header-label">SIC</div>
        <div class="payform-header-data">{{ payform?.dmclSic }}</div>
      </div>
      <div fxLayout="column" class="payform-header-employee">
        <div class="payform-header-label">Employee</div>
        <div class="payform-header-data">{{ payform?.dsrEmployeeDetail?.fullName }}</div>
      </div>
      <div fxLayout="column" class="payform-header-shift" [ngClass]="{ 'payform-header-warning': checkPayformConflictWarning() }">
        <div class="payform-header-label">Shift Start</div>
        <div class="payform-header-data">{{ payform?.shiftStartDate | date: 'MM/dd/yyyy' }}</div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div *ngIf="payform?.timeCrdNbr1 || payform?.timeCrdNbr2" fxLayout="column" class="payform-header-tcnumber">
        <div class="payform-header-label">T.C. #</div>
        <div class="payform-header-data">{{ payform | tcNumber }}</div>
      </div>
      <div
        class="payform-header-paytime-and-miles"
        *ngIf="
          payform?.statusCd === PayformStatusCd.DELETED ||
          payform?.statusCd === PayformStatusCd.RETURNED ||
          payform?.statusCd === PayformStatusCd.APPROVED ||
          payform?.statusCd === PayformStatusCd.TRANSMITTED ||
          (payform?.statusCd === PayformStatusCd.NEW && (readonly$ | async)) ||
          (payform?.statusCd === PayformStatusCd.SUBMITTED && (readonly$ | async))
        "
      >
        <mat-divider [vertical]="true"></mat-divider>
        <div fxLayout="column" class="payform-header-paytime">
          <div class="payform-header-label">
            Time Claimed
            <div *ngIf="showSubjectTo$ | async" class="subject-to">(subject to pay rules)</div>
          </div>
          <div class="payform-header-data align-end">{{ paytime$ | async | minutesToHours }}&nbsp;</div>
        </div>
        <div fxLayout="column" class="payform-header-paymiles">
          <div class="payform-header-label">Pay Miles Claimed</div>
          <div class="payform-header-data align-end">{{ paymiles$ | async | number }}&nbsp;{{ (paymiles$ | async) > 0 ? 'miles' : '' }}</div>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxLayout="column" class="payform-header-status">
        <mat-chip-list>
          <mat-chip
            [ngClass]="{
              'payform-header__status-approved': payform?.statusCd === PayformStatusCd.APPROVED,
              'payform-header__status-deleted': payform?.statusCd === PayformStatusCd.DELETED,
              'payform-header__status-not-submitted': payform?.statusCd === PayformStatusCd.NEW,
              'payform-header__status-returned': payform?.statusCd === PayformStatusCd.RETURNED,
              'payform-header__status-submitted': payform?.statusCd === PayformStatusCd.SUBMITTED && (readonly$ | async),
              'payform-header__status-edit-mode': payform?.statusCd === PayformStatusCd.SUBMITTED && (readonly$ | async) === false,
              'payform-header__status-transmitted': payform?.statusCd === PayformStatusCd.TRANSMITTED
            }"
          >
            {{ payform | payformStatus | async }} {{ (readonly$ | async) ? '(View Only)' : '(Edit Mode)' }}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="payform-header-buttons">
      <button
        id="add-schedule-button"
        mat-button
        *ngIf="shouldDisplayAddScheduleButton"
        [disabled]="payform?.linehaulSchedule?.length >= constants.MaxSchedules"
        color="primary"
        (click)="addScheduleClicked()"
        class="payform-header-buttons-add"
      >
        + Schedule
      </button>
      <button id="edit-schedule-button" mat-button *ngIf="shouldDisplayEditButton" color="primary" (click)="editClicked()" class="payform-header-buttons-edit"><mat-icon>edit</mat-icon>Edit</button>
      <div fxLayout="column">
        <button
          id="expand-collapse-button"
          mat-button
          mat-icon-button
          tabindex="-1"
          aria-label="Expand or Collapse"
          (click)="expandCollapseClicked()"
          [matTooltip]="isExpanded ? 'Collapse' : 'Expand'"
          class="payform-header-buttons-collapse-expand"
        >
          <div *ngIf="isExpanded">
            <mat-icon>keyboard_arrow_up</mat-icon>
            <mat-icon>keyboard_arrow_up</mat-icon>
          </div>
          <div *ngIf="!isExpanded">
            <mat-icon>keyboard_arrow_down</mat-icon>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
