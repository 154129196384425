import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';
import { ScheduleTrailerFormFields } from '../enums';
import { EquipmentErrorTypes } from '../enums/equipment-error-types.enum';

export function loadReleaseValidatorFunction(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !control.parent) {
      return null;
    }

    const trlrEmptyInd = control.parent.get(ScheduleTrailerFormFields.TrlrEmtyInd);
    const trlrHazardousInd = control.parent.get(ScheduleTrailerFormFields.TrlrHazardousInd);
    const trlrNbr = control.parent.get(ScheduleTrailerFormFields.TrlrNbr);

    if (!trlrNbr || !trlrEmptyInd || !trlrHazardousInd) {
      return null;
    }

    if (trlrNbr.value && !control.value && !trlrEmptyInd.value) {
      const loadReleaseRequiredError = {
        type: EquipmentErrorTypes.LoadReleaseRequired,
        primarySubject: trlrNbr.value,
        displayError: true,
      };

      return { [EquipmentErrorTypes.LoadReleaseRequired]: loadReleaseRequiredError };
    }

    if (trlrNbr.value && control.value.length > 0 && control.value.length < 7) {
      const invalidLoadReleaseFormat = {
        type: EquipmentErrorTypes.InvalidLoadReleaseFormat,
        primarySubject: trlrNbr.value,
        displayError: true,
      };

      return { [EquipmentErrorTypes.InvalidLoadReleaseFormat]: invalidLoadReleaseFormat };
    }

    if (!trlrNbr.value && (trlrEmptyInd.value || trlrHazardousInd.value || control.value)) {
      const noTrailerProvidedError = {
        type: EquipmentErrorTypes.NoTrailerProvided,
      };

      return { [EquipmentErrorTypes.NoTrailerProvided]: noTrailerProvidedError };
    }

    return null;
  };
}

@Directive({
  selector: '[loadReleaseValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: LoadReleaseValidator, multi: true }],
})
export class LoadReleaseValidator implements Validator {
  constructor() {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return loadReleaseValidatorFunction()(control);
  }
}
