export enum WarningValidationType {
  ActivityDurationWarning = 'activityDurationWarning',
  ActivityDurationWarnings = 'activityDurationWarnings',
  ActivitySicWarning = 'activitySicWarning',
  ActivitySicWarnings = 'activitySicWarnings',
  CushionWarning = 'cushionWarning',
  DetourWarning = 'detourWarning',
  EquipmentMismatch = 'equipmentMismatch',
  EquipmentNotFound = 'equipmentNotFound',
  EslSoloWarning = 'eslSoloWarning',
  EslWarning = 'eslWarning',
  HazmatOverrideWarning = 'hazmatOverrideWarning',
  HazmatRouteWarning = 'hazmatRouteWarning',
  MaxDurationWarning = 'maxDurationWarning',
  OperationTypeWarning = 'operationTypeWarning',
  OriginDestWarning = 'originDestWarning',
  PayformConflictWarning = 'payformConflictWarning',
  RouteTypeWarning = 'routeTypeWarning',
  SameOriginDestWarning = 'sameOriginDestWarning',
  TotalMilesWarning = 'totalMilesWarning',
  EmptyValue = 'emptyValue',
  MultipleFuelingWarning = 'multipleFuelingWarning',
  OnlyOneScheduleWarning = 'onlyOneScheduleWarning',
}
