import { Pipe, PipeTransform } from '@angular/core';
import { DsrPayform } from '@xpo-ltl/sdk-linehaulpayform';

@Pipe({
  name: 'tcNumber',
})
export class TcNumberPipe implements PipeTransform {
  transform(value: DsrPayform, args?: any): any {
    let transformedValue = '';
    if (!!value) {
      if (!!value.timeCrdNbr1) {
        transformedValue = `${value.timeCrdNbr1}`;
      }
      if (!!value.timeCrdNbr2) {
        transformedValue = `${transformedValue}, ${value.timeCrdNbr2}`;
      }
    }
    return transformedValue;
  }
}
