import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { FormUtils } from '../classes';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType, DisplayOnlyFormFields } from '../enums';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { ScheduleTrailerFormFields } from '../enums/schedule-trailer-form-fields.enum';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function hazmatRouteValidatorFunction(errorWarningService: ErrorWarningService, schSequenceNbr: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || !control.value || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const tripInfo = control.get(LinehaulScheduleFormFields.TripInfoControl);
    const tripCode = LoDash.get(control.get(DisplayOnlyFormFields.ScheduleTripCode), 'value', undefined);

    if (!tripInfo || !tripCode) {
      FormUtils.unsetWarning(control, WarningValidationType.HazmatRouteWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
      return null;
    }

    const originCtrl = tripInfo.get(LinehaulScheduleFormFields.OriginSicCd);
    const destCtrl = tripInfo.get(LinehaulScheduleFormFields.DestinationSicCd);
    const equipmentCtrl = control.get(LinehaulScheduleFormFields.EquipmentControl);

    if (!originCtrl || !destCtrl || !originCtrl.value || !destCtrl.value || !equipmentCtrl) {
      FormUtils.unsetWarning(control, WarningValidationType.HazmatRouteWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
      return null;
    }

    const trailerArray = equipmentCtrl.get(LinehaulScheduleFormFields.ScheduleTrailer) ? equipmentCtrl.get(LinehaulScheduleFormFields.ScheduleTrailer).value : [];
    const containsHazmatTrailer = (trailerArray ?? []).some(trailer => !!LoDash.get(trailer, ScheduleTrailerFormFields.TrlrHazardousInd, false));

    if (tripCode === '31' && !containsHazmatTrailer) {
      const hazmatRouteWarning = {
        type: WarningValidationType.HazmatRouteWarning,
        primarySubject: `${originCtrl.value.toUpperCase()} -> ${destCtrl.value.toUpperCase()}`,
      };

      FormUtils.setWarning(control, WarningValidationType.HazmatRouteWarning, hazmatRouteWarning);
    } else {
      FormUtils.unsetWarning(control, WarningValidationType.HazmatRouteWarning);
    }

    errorWarningService.detailWarningsChanged(schSequenceNbr);
    return null;
  };
}

@Directive({
  selector: '[hazmatRouteValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: HazmatRouteValidator, multi: true }],
})
export class HazmatRouteValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}
  @Input()
  schSequenceNbr: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return hazmatRouteValidatorFunction(this.errorWarningService, this.schSequenceNbr)(control);
  }
}
