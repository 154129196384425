import { Subject } from 'rxjs';

export class Unsubscriber {
  private _done = new Subject();
  public get done() {
    return this._done;
  }

  complete() {
    this.done.next(true);
    this.done.complete();
  }
}
