import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Observable, of } from 'rxjs';
import { FormUtils, FormUtilsControlState } from '../../classes';
/**
 * @title Tree with nested nodes
 */
@Component({
  selector: 'app-form-state-tree',
  templateUrl: './form-state-tree.component.html',
  styleUrls: ['./form-state-tree.component.scss'],
})
export class FormStateTreeComponent implements AfterViewInit {
  @Input()
  form: AbstractControl;

  nestedTreeControl: NestedTreeControl<FormUtilsControlState>;
  nestedDataSource: MatTreeNestedDataSource<FormUtilsControlState>;

  constructor(private changeDetRef: ChangeDetectorRef) {
    this.nestedTreeControl = new NestedTreeControl<FormUtilsControlState>(this.getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
  }

  ngAfterViewInit() {
    // TODO - figure out how to auto-update the display when form changes
    // this.form.statusChanges.subscribe(_ => this.onUpdateFormState());
    // this.form.valueChanges.subscribe(_ => this.onUpdateFormState());
  }

  onUpdateFormState() {
    const state = FormUtils.getControlState(this.form, 'Payform', true);
    this.nestedDataSource.data = [state];
    this.nestedTreeControl.expandDescendants(state);
  }

  public hasNestedChild(_: number, nodeData: FormUtilsControlState) {
    return nodeData.children && nodeData.children.length > 0;
  }

  private getChildren(node: FormUtilsControlState): Observable<FormUtilsControlState[]> {
    return of(node.children);
  }

  getValue(value: any) {
    if (!value) {
      return '';
    } else if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return String(value);
    }
  }
}
