<!-- <app-form-state-tree [form]="form"></app-form-state-tree> -->

<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <div class="add-edit-activity">
    <div fxLayout="row">
      <h2 md-dialog-title>{{ title }}</h2>
    </div>
    <mat-dialog-content class="add-edit-activity-content" fxLayout="column" fxLayoutAlign="stretch start">
      <div class="add-edit-activity-content__activity">
        <mat-form-field class="add-edit-activity-content__activity-type">
          <mat-select id="activity-select" placeholder="Activity Type" [formControlName]="AddEditActivityFormFields.ActivityType" disableOptionCentering>
            <mat-option *ngFor="let nonDriveTimeType of nonDriveTimeTypes" [value]="nonDriveTimeType">
              {{ nonDriveTimeType?.actvtyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="add-edit-activity-content__activity-sic">
          <input id="sic-input" matInput type="text" autocomplete="off" placeholder="SIC" maxlength="3" minlength="3" [formControlName]="AddEditActivityFormFields.SIC" />
          <mat-error>
            <div *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.SIC, 'invalidLocation')">Invalid SIC</div>
            <div *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.SIC, 'minlength')">Invalid SIC</div>
          </mat-error>
          <div *ngIf="!FormUtils.hasErrors(form, AddEditActivityFormFields.SIC) && FormUtils.hasWarnings(form, AddEditActivityFormFields.SIC)" class="warning-field">
            <div *ngIf="FormUtils.hasWarning(form, AddEditActivityFormFields.SIC, WarningValidationType.EmptyValue)">SIC not entered</div>
          </div>
        </mat-form-field>

        <mat-form-field class="add-edit-activity-content__activity-start-date">
          <input
            id="start-date-input"
            matInput
            type="text"
            autocomplete="off"
            #datePicker
            [matDatepicker]="startDatePicker"
            id="create-payform-shift-start-input"
            [xpoAllowCharacters]="ValidationRegexPatterns.dateInputValidatorPattern | toRegex"
            xpoDate=""
            [min]="minStartDate"
            [max]="maxStartDate"
            placeholder="Start Date"
            [formControlName]="AddEditActivityFormFields.StartDate"
          />
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="add-edit-activity-content__time">
        <mat-form-field class="add-edit-activity-content__time-start-time">
          <input
            id="start-time-input"
            matInput
            [errorStateMatcher]="startTimeMatcher"
            type="text"
            autocomplete="off"
            [xpoAllowCharacters]="ValidationRegexPatterns.timeInputValidatorPattern | toRegex"
            maxlength="5"
            placeholder="Start Time"
            [formControlName]="AddEditActivityFormFields.StartTime"
            (blur)="handleTimeBlur(AddEditActivityFormFields.StartTime)"
          />
          <mat-hint>24 Hour HH:MM</mat-hint>
          <mat-error>
            <div *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.StartTime, 'required')">Required</div>
            <div *ngIf="form.hasError(ActivityFormErrors.startAfterEnd)">Start on or after End</div>
            <div *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.StartTime, TimeFormatErrors.invalidFormat)">Invalid Format</div>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="add-edit-activity-content__time-end-time" [ngClass]="{ 'warning-section': FormUtils.hasWarnings(form, AddEditActivityFormFields.EndTime) }">
          <input
            id="end-time-imput"
            matInput
            [errorStateMatcher]="startTimeMatcher"
            type="text"
            autocomplete="off"
            [xpoAllowCharacters]="ValidationRegexPatterns.timeInputValidatorPattern | toRegex"
            maxlength="5"
            placeholder="End Time"
            [formControlName]="AddEditActivityFormFields.EndTime"
            (blur)="handleTimeBlur(AddEditActivityFormFields.EndTime)"
          />
          <mat-hint>24 Hour HH:MM</mat-hint>
          <mat-error>
            <div *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.EndTime, 'required')">Required</div>
            <div *ngIf="form.hasError(ActivityFormErrors.startAfterEnd)">Start on or after End</div>
            <div *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.EndTime, TimeFormatErrors.invalidFormat)">Invalid Format</div>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="add-edit-activity-content__time-duration">
          <input
            id="duration-input"
            matInput
            type="text"
            autocomplete="off"
            maxlength="4"
            placeholder="{{ activityType }}"
            [formControlName]="AddEditActivityFormFields.Duration"
            [xpoAllowCharacters]="durationExpression"
            (blur)="handleDurationBlur()"
          />
          <mat-hint>Minutes</mat-hint>
          <div *ngIf="!form.get(AddEditActivityFormFields.Duration).errors" class="warning-field">
            <div *ngIf="FormUtils.hasWarning(form, null, ActivityFormErrors.minimumDuration)">{{ FormUtils.getWarning(form, null, ActivityFormErrors.minimumDuration) }}</div>
            <div *ngIf="FormUtils.hasWarning(form, null, ActivityFormErrors.maximumDuration)">{{ FormUtils.getWarning(form, null, ActivityFormErrors.maximumDuration) }}</div>
          </div>
        </mat-form-field>

        <mat-checkbox id="spans-two-days-checkbox" [formControlName]="AddEditActivityFormFields.SpansTwoDays" [color]="color">Activity ended next day</mat-checkbox>
      </div>

      <div *ngIf="hasEquipment" [formGroup]="equipGroup">
        <div class="add-edit-activity-content__trailers" fxLayout="row">
          <ng-container [ngTemplateOutlet]="trailerInput" [ngTemplateOutletContext]="{ trailer: 1, field: AddEditActivityFormFields.Trailer1 }"> </ng-container>

          <ng-container [ngTemplateOutlet]="trailerInput" [ngTemplateOutletContext]="{ trailer: 2, field: AddEditActivityFormFields.Trailer2 }"> </ng-container>

          <ng-container [ngTemplateOutlet]="trailerInput" [ngTemplateOutletContext]="{ trailer: 3, field: AddEditActivityFormFields.Trailer3 }"> </ng-container>
        </div>
        <div class="add-edit-activity-content__dollies" fxLayout="row">
          <ng-container [ngTemplateOutlet]="dollyInput" [ngTemplateOutletContext]="{ dolly: 1, field: AddEditActivityFormFields.Dolly1 }"> </ng-container>

          <ng-container [ngTemplateOutlet]="dollyInput" [ngTemplateOutletContext]="{ dolly: 2, field: AddEditActivityFormFields.Dolly2 }"> </ng-container>
        </div>
      </div>
      <div class="add-edit-activity-error-field" *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.Equipment, ActivityFormErrors.equipmentRequired)">
        At least One Trailer or Dolly is Required
      </div>
      <div class="add-edit-activity-content__comments">
        <mat-form-field>
          <textarea
            id="comments-input"
            matInput
            autocomplete="off"
            placeholder="Comments"
            [formControlName]="AddEditActivityFormFields.Comments"
            #comments
            maxlength="250"
            matTextareaAutosize
            matAutosizeMinRows="3"
            matAutosizeMaxRows="3"
          ></textarea>
          <mat-hint align="end">{{ comments?.value?.length }} / 250</mat-hint>
          <mat-error>
            <div *ngIf="FormUtils.hasError(form, AddEditActivityFormFields.Comments, 'required')">Required</div>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="add-edit-activity-actionbar">
      <button id="cancel-button" mat-button color="primary" (click)="cancelClicked()" tabindex="-1">Cancel</button>
      <button id="save-button" mat-button color="primary" (click)="saveClicked()" tabindex="-1">{{ saveLabel }}</button>
    </mat-dialog-actions>
  </div>
</form>

<ng-template #trailerInput let-trailer="trailer" let-field="field">
  <mat-form-field class="add-edit-activity-content__trailers-1" [ngClass]="{ 'warning-section': FormUtils.hasWarnings(equipGroup, field) }">
    <input
      matInput
      autocomplete="off"
      placeholder="Trailer {{ trailer }}"
      [xpoAllowCharacters]="ValidationRegexPatterns.trailerInputValidatorPattern | toRegex"
      maxlength="11"
      (blur)="equipFieldChange(field)"
      [errorStateMatcher]="equipmentRequiredMatcher"
      [value]="equipGroup.get(field).value | equipment"
      [formControl]="equipGroup.get(field)"
    />
    <mat-error>
      <div *ngIf="FormUtils.hasError(equipGroup, field, 'invalidFormat')">Invalid Format</div>
      <div *ngIf="FormUtils.hasError(equipGroup, field, 'equipmentRequired')">Trailer Required</div>
      <div *ngIf="FormUtils.hasError(equipGroup, field, 'duplicateEquipment')">Duplicate Equipment</div>
    </mat-error>
    <div *ngIf="!equipGroup.get(field).errors && FormUtils.hasWarnings(equipGroup, field)" class="warning-field">
      <div *ngIf="FormUtils.hasWarning(equipGroup, field, WarningValidationType.EquipmentNotFound)">Equipment Not Found</div>
      <div *ngIf="FormUtils.hasWarning(equipGroup, field, WarningValidationType.EquipmentMismatch)">Equipment Not Trailer</div>
    </div>
  </mat-form-field>
</ng-template>

<ng-template #dollyInput let-dolly="dolly" let-field="field">
  <mat-form-field class="add-edit-activity-content__dollies-1" [ngClass]="{ 'warning-section': FormUtils.hasWarnings(equipGroup, field) }">
    <input
      matInput
      autocomplete="off"
      placeholder="Dolly {{ dolly }}"
      [xpoAllowCharacters]="ValidationRegexPatterns.dollyInputValidatorPattern | toRegex"
      maxlength="8"
      (blur)="equipFieldChange(field)"
      [errorStateMatcher]="equipmentRequiredMatcher"
      [value]="equipGroup.get(field).value | equipment"
      [formControl]="equipGroup.get(field)"
    />
    <mat-error>
      <div *ngIf="FormUtils.hasError(equipGroup, field, 'invalidFormat')">Invalid Dolly</div>
      <div *ngIf="FormUtils.hasError(equipGroup, field, 'equipmentRequired')">Dolly Required</div>
      <div *ngIf="FormUtils.hasError(equipGroup, field, 'duplicateEquipment')">Duplicate Equipment</div>
    </mat-error>
    <div *ngIf="!equipGroup.get(field).errors && FormUtils.hasWarnings(equipGroup, field)" class="warning-field">
      <div *ngIf="FormUtils.hasWarning(equipGroup, field, WarningValidationType.EquipmentNotFound)">Equipment Not Found</div>
      <div *ngIf="FormUtils.hasWarning(equipGroup, field, WarningValidationType.EquipmentMismatch)">Equipment Not Dolly</div>
    </div>
  </mat-form-field>
</ng-template>
