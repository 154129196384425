import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertWrapperComponent } from './dialogs/alert-wrapper/alert-wrapper.component';
import { ConfirmCancelComponent } from './dialogs/confirm-cancel/confirm-cancel.component';
import { XpoDialogManagerService } from './services/xpo-dialog-manager.service';
import { MaterialModule } from './material.module';
import { AlertComponent } from './dialogs/alert/alert.component';
import { DialogTemplateInjectorDirective } from './dialogs/dialog-template-injector.directive';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [DialogTemplateInjectorDirective, AlertWrapperComponent, AlertComponent],
  providers: [XpoDialogManagerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppDialogsModule {}
