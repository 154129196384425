import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PayformNoteTypeCd } from '@xpo-ltl/sdk-common';
import { DsrPayform, Note } from '@xpo-ltl/sdk-linehaulpayform';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormStateDirective } from '../../../../../../classes/form-state.component';
import { LinehaulScheduleFormFields } from '../../../../../../enums/linehaul-schedule-form-fields.enum';
import { NotesFormBuilder } from './notes.form-builder';
import { PayPortalStateStore } from '../../../../../../../app/state';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent extends FormStateDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  typeSequenceNbr: string = undefined;
  @Input()
  noteType: PayformNoteTypeCd = PayformNoteTypeCd.PAYFORM;

  private notesArraySubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>(undefined);
  public notesArray$ = this.notesArraySubject.asObservable();

  constructor(protected formBuilder: UntypedFormBuilder, private state: PayPortalStateStore) {
    super(formBuilder);
  }

  ngOnInit() {
    this.linkFormToParent(LinehaulScheduleFormFields.Note, NotesFormBuilder.create);
  }

  ngAfterViewInit() {
    // Listen for State changes
    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((current: DsrPayform) => {
        this.notesArraySubject.next((current.note ?? []).filter((note: Note) => note.noteType === this.noteType && note.noteTypeSequenceNbr === this.typeSequenceNbr));
      });
  }
}
