import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppStateUtils, PayPortalStateStore } from 'app';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';
import { ActionCd, PayformActivityLocCd } from '@xpo-ltl/sdk-common';
import { DsrActivity, DsrPayform, NonDriveTimeType, Note, WorkStandard } from '@xpo-ltl/sdk-linehaulpayform';
import { Unsubscriber } from '../../utils/angular-utils/classes';
import { XpoAngularUtilsService } from '../../utils/angular-utils/services';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { ActivityUtils, FormUtils } from '../../classes';
import { AddEditActivityData } from '../../classes/add-edit-activity-data';
import { ErrorWarningService } from '../../components/payform/services';
import { WarningValidationType } from '../../enums';
import { AddEditActivityFormFields } from '../../enums/add-edit-activity-form-fields.enum';
import { EquipmentType } from '../../enums/equipment-type.enum';
import { EquipmentPipe } from '../../pipes/equipment.pipe';
import { PaidTimeOrDurationPipe } from '../../pipes/paid-time-or-duration.pipe';
import { AppConstantsService } from '../../services/app-constants.service';
import { AsyncValidatorCompletionService } from '../../services/async-validator-completion.service';
import { EquipmentDetailsCacheService } from '../../services/equipment-details-cache.service';
import { LocationDetailsCacheService } from '../../services/location-details-cache.service';
import { ServiceCenterCacheService } from '../../services/service-center-cache.service';
import { equipmentValidatorFunction, sicValidatorFunction, TimeFormatErrors, timeFormatValidatorFunction, warnEmptyValidatorFunction } from '../../validators';
import { LoDash } from '../../utils/angular-utils/lodash-utils';

/**
 * Form errors and warnings
 */
export enum ActivityFormErrors {
  startAfterEnd = 'startAfterEnd',
  minimumDuration = 'minimumDuration',
  maximumDuration = 'maximumDuration',
  equipmentRequired = 'equipmentRequired',
}

export class StartEndTimeMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched)) || form.hasError(ActivityFormErrors.startAfterEnd);
  }
}

export class EquipmentRequiredMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched)) || control.parent.hasError(ActivityFormErrors.equipmentRequired);
  }
}

@Component({
  selector: 'app-add-edit-activity',
  templateUrl: './add-edit-activity.component.html',
  styleUrls: ['./add-edit-activity.component.scss'],
})
export class AddEditActivityComponent implements OnInit, OnDestroy {
  public saveLabel;
  public title;

  public form: UntypedFormGroup;
  public equipGroup: UntypedFormGroup;
  public commentsControl: UntypedFormControl;

  public minStartDate: Date;
  public maxStartDate: Date;
  public selectedNonDriveTimeType: NonDriveTimeType = undefined;
  public nonDriveTimeTypes: NonDriveTimeType[];

  private activityTimezone: string;
  public activityType: string;

  public readonly startTimeMatcher = new StartEndTimeMatcher();
  public readonly equipmentRequiredMatcher = new EquipmentRequiredMatcher();

  public readonly AddEditActivityFormFields = AddEditActivityFormFields;
  public readonly EquipmentType = EquipmentType;
  public readonly WarningValidationType = WarningValidationType;
  public readonly ValidationRegexPatterns = ValidationRegexPatterns;
  public readonly FormUtils = FormUtils;
  public readonly TimeFormatErrors = TimeFormatErrors;
  public readonly ActivityFormErrors = ActivityFormErrors;
  public readonly durationExpression = new RegExp('^[0-9]$');
  public currentPayform: DsrPayform;
  color = 'accent';

  @ViewChild('picker')
  datePicker: MatDatepicker<Moment>;

  private unsubscriber = new Unsubscriber();

  @HostListener('document:keydown', ['$event'])
  onKeyDown(ev: KeyboardEvent) {
    const ariaLabel = document && document.activeElement ? document.activeElement.getAttribute('aria-label') : undefined;
    if (ariaLabel && ariaLabel.toUpperCase() === 'OPEN CALENDAR' && ev.key === 'Enter') {
      if (!this.datePicker.opened) {
        this.datePicker.open();
      }
    }
  }

  constructor(
    private matDialogRef: MatDialogRef<AddEditActivityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddEditActivityData,
    private formBuilder: UntypedFormBuilder,
    public constants: AppConstantsService,
    public utils: XpoAngularUtilsService,
    private locationDetailsCache: LocationDetailsCacheService,
    private serviceCenterCache: ServiceCenterCacheService,
    private equipmentDetailsCache: EquipmentDetailsCacheService,
    private asyncValidatorCompleted: AsyncValidatorCompletionService,
    private errorWarningService: ErrorWarningService,
    private state: PayPortalStateStore,
    private paidTimeOrDurationPipe: PaidTimeOrDurationPipe,
    private equipmentPipe: EquipmentPipe
  ) {}

  ngOnInit() {
    this.title = this.data.title;

    this.state
      .getCurrentPayformState()
      .pipe(take(1))
      .subscribe(currentPayform => {
        this.currentPayform = currentPayform;
      });

    if (!this.data.activity) {
      // create a new activity with start time set to payform shift start time
      const newActivity = new DsrActivity();
      newActivity.note = [];

      this.data.activity = newActivity;
      this.saveLabel = 'Add Activity';

      const count = LoDash.get(this.currentPayform, 'dsrActivity', []).filter(x => x.listActionCd !== ActionCd.DELETE && x.nonDriveTimeType.sendToPayrollInd).length;
      if (count >= this.constants.MaxActivities) {
        this.nonDriveTimeTypes = this.constants?.nonDriveTimeTypes ? this.constants.nonDriveTimeTypes.filter(t => !t.sendToPayrollInd) : [];
      } else {
        this.nonDriveTimeTypes = this.constants?.nonDriveTimeTypes;
      }
    } else {
      // editing an existing Activity
      this.saveLabel = 'Save Activity';

      this.nonDriveTimeTypes = this.constants?.nonDriveTimeTypes;
    }

    this.buildFormFromActivity(this.data.activity);
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  public isFormValid() {
    return this.form && this.form.valid;
  }

  get hasEquipment() {
    return ActivityUtils.requiresEquipment(this.selectedNonDriveTimeType);
  }

  private setDefaultSicForActivityType() {
    let defaultSic = '';

    if (this.selectedNonDriveTimeType) {
      let originSic = '';
      let destinationSic = '';

      const activityName = this.selectedNonDriveTimeType?.actvtyName ?? '';
      const schedule = this.currentPayform.linehaulSchedule ? this.currentPayform.linehaulSchedule.find(s => s.schSequenceNbr === this.data.schSequenceNbr) : null;

      if (schedule) {
        originSic = LoDash.get(schedule, 'originSicCd', '');
        destinationSic = LoDash.get(schedule, 'destinationSicCd', '');
      }

      if (this.selectedNonDriveTimeType.expectedLoc === PayformActivityLocCd.ORIGIN) {
        defaultSic = originSic;
      }
      if (this.selectedNonDriveTimeType.expectedLoc === PayformActivityLocCd.DEST) {
        defaultSic = destinationSic;
      }
    }

    FormUtils.setValues(this.form, {
      [AddEditActivityFormFields.SIC]: defaultSic,
    });
  }

  public getTimesFromForm(): { startDateTime: Date; endDateTime: Date; durationInMn: number } {
    // get the calendar date of the start of the activity
    const calendarDate = this.form.get(AddEditActivityFormFields.StartDate).value;
    const year = calendarDate.getUTCFullYear();
    const month = calendarDate.getUTCMonth();
    const date = calendarDate.getUTCDate();

    const startClockTime = moment(this.form.get(AddEditActivityFormFields.StartTime).value, 'HH:mm');
    const endClockTime = moment(this.form.get(AddEditActivityFormFields.EndTime).value, 'HH:mm');

    // create the start and end DateTimes in the Activity timezone
    const startDateTime = moment.tz(
      {
        year,
        month,
        date,
        hours: startClockTime.hour(),
        minutes: startClockTime.minute(),
      },
      this.activityTimezone
    );

    const endDateTime = moment.tz(
      {
        year,
        month,
        date,
        hours: endClockTime.hour(),
        minutes: endClockTime.minute(),
      },
      this.activityTimezone
    );
    if (this.form.get(AddEditActivityFormFields.SpansTwoDays).value) {
      endDateTime.add(1, 'days');
    }

    let duration = moment.duration(endDateTime.diff(startDateTime)).asMinutes();
    if (duration <= 0) {
      duration = undefined;
    }

    return {
      startDateTime: new Date(startDateTime.toDate()),
      endDateTime: new Date(endDateTime.toDate()),
      durationInMn: duration,
    };
  }

  private startEndTimeValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const spansTwoDays = LoDash.get(control.get(AddEditActivityFormFields.SpansTwoDays), 'value');
      if (!spansTwoDays) {
        const startTimeControl = control.get(AddEditActivityFormFields.StartTime);
        const endTimeControl = control.get(AddEditActivityFormFields.EndTime);
        if (startTimeControl && endTimeControl && startTimeControl.errors === null && endTimeControl.errors === null) {
          // no other errors, and not spanning two days, so make sure start time is before end time
          const startTimeValue = LoDash.replace(startTimeControl.value, ':', '');
          // eslint-disable-next-line radix
          const startTime = parseInt(startTimeValue) ?? -1;

          const endTimeValue = LoDash.replace(endTimeControl.value, ':', '');
          // eslint-disable-next-line radix
          const endTime = parseInt(endTimeValue) ?? -1;

          if (startTime >= 0 && endTime >= 0 && startTime >= endTime) {
            return { [ActivityFormErrors.startAfterEnd]: true };
          }
        }
      }
      return null;
    };
  }

  private durationValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      FormUtils.clearWarnings(control, [ActivityFormErrors.minimumDuration, ActivityFormErrors.maximumDuration]);

      const durationField = control.get(AddEditActivityFormFields.Duration);
      const value = LoDash.get(durationField, 'value');
      const workStandard: WorkStandard = this.getWorkStandard();

      if (value && workStandard) {
        // eslint-disable-next-line radix
        const duration = parseInt(value);
        // eslint-disable-next-line radix
        if (duration < parseInt(workStandard.minimumDuration)) {
          FormUtils.setWarning(control, ActivityFormErrors.minimumDuration, `Under ${workStandard.minimumDuration} min`);
          // eslint-disable-next-line radix
        } else if (duration > parseInt(workStandard.maxDuration)) {
          FormUtils.setWarning(control, ActivityFormErrors.maximumDuration, `Over ${workStandard.maxDuration} min`);
        }
      }

      // This valdator only sets warnings, never errors.
      return null;
    };
  }

  private equipmentRequiredValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // make sure at least one trailer or dolly is specified
      const equipment = [
        control.get(AddEditActivityFormFields.Trailer1).value,
        control.get(AddEditActivityFormFields.Trailer2).value,
        control.get(AddEditActivityFormFields.Trailer3).value,
        control.get(AddEditActivityFormFields.Dolly1).value,
        control.get(AddEditActivityFormFields.Dolly2).value,
      ];

      if (!(equipment ?? []).some(eq => !LoDash.isEmpty(eq))) {
        return { [ActivityFormErrors.equipmentRequired]: true };
      }

      return null;
    };
  }

  /**
   * Populate the Form with the data from the passed Activity.  If no
   * activity, the n
   */
  private buildFormFromActivity(activity: DsrActivity) {
    // set the activity type
    this.selectedNonDriveTimeType = activity && activity.nonDriveTimeType ? this.constants?.nonDriveTimeTypes.find(x => x.nonDrvTimeTypeId === activity.nonDriveTimeType.nonDrvTimeTypeId) : undefined;

    // find the note associated with this activity
    const notes = LoDash.get(activity.note[0], 'comments', '');

    const buildTrailer = (prop: string, isDolly: boolean = false): any[] => [
      '',
      [],
      equipmentValidatorFunction(this.equipmentDetailsCache, isDolly ? EquipmentType.Dolly : EquipmentType.Trailer, this.errorWarningService, this.asyncValidatorCompleted, activity.schSequenceNbr),
    ];

    // build the Form
    this.form = this.formBuilder.group(
      {
        [AddEditActivityFormFields.ActivityType]: [this.selectedNonDriveTimeType, Validators.required],
        [AddEditActivityFormFields.SIC]: [LoDash.get(activity, 'actvtySicCd', ''), [warnEmptyValidatorFunction()], [sicValidatorFunction(this.serviceCenterCache, this.asyncValidatorCompleted)]],
        [AddEditActivityFormFields.StartDate]: [this.minStartDate, Validators.required],
        [AddEditActivityFormFields.StartTime]: ['', [timeFormatValidatorFunction(), Validators.required]],
        [AddEditActivityFormFields.EndTime]: ['', [timeFormatValidatorFunction(), Validators.required]],
        [AddEditActivityFormFields.Duration]: ['', Validators.required],
        [AddEditActivityFormFields.SpansTwoDays]: false,
        [AddEditActivityFormFields.Equipment]: this.formBuilder.group(
          {
            [AddEditActivityFormFields.Trailer1]: buildTrailer('trlrNbr1'),
            [AddEditActivityFormFields.Trailer2]: buildTrailer('trlrNbr2'),
            [AddEditActivityFormFields.Trailer3]: buildTrailer('trlrNbr3'),
            [AddEditActivityFormFields.Dolly1]: buildTrailer('dollyNbr1', true),
            [AddEditActivityFormFields.Dolly2]: buildTrailer('dollyNbr2', true),
          },
          {
            validator: [this.equipmentRequiredValidator()],
          }
        ),
        [AddEditActivityFormFields.Comments]: [notes],
      },
      {
        validator: [this.startEndTimeValidator(), this.durationValidator()],
      }
    );

    this.commentsControl = this.form.get(AddEditActivityFormFields.Comments) as UntypedFormControl;
    this.equipGroup = this.form.get(AddEditActivityFormFields.Equipment) as UntypedFormGroup;

    this.updateFormEquipmentGroup(!activity.actvtySequenceNbr);
    this.updateEnableEquipmentGroup();
    this.updateRequiredComments();

    const isEditable = this.selectedNonDriveTimeType?.durationEditInd ?? false;
    const durationControl = () => this.form.get(AddEditActivityFormFields.Duration);
    if (isEditable) {
      durationControl().enable();
    } else {
      durationControl().disable();
    }

    // All times based on the Payform dmclSic, no matter if Activity has a different SicCd
    const sicCd = this.currentPayform.dmclSic;
    return this.locationDetailsCache
      .request({ sicCd })
      .pipe(take(1))
      .subscribe(result => {
        this.activityTimezone = result?.locReference?.timezoneName;
        this.updateFormTime(activity);
        this.watchValueChanges();
        FormUtils.markAsTouched(this.form);
        this.form.updateValueAndValidity();
      });
  }

  private updateRequiredComments() {
    if (this.selectedNonDriveTimeType && this.selectedNonDriveTimeType.requiredCommentInd) {
      this.commentsControl.setValidators([Validators.required]);
    } else {
      this.commentsControl.clearValidators();
    }
    this.commentsControl.updateValueAndValidity({ emitEvent: false });
  }

  private updateFormEquipmentGroup(isNewActivity: boolean) {
    // pre-populate the equipment from the Payform
    let schedule = AppStateUtils.getScheduleFromPayform(this.currentPayform, this.data.schSequenceNbr);

    if (!schedule) {
      // no schedule, so grab first one in Payform. If that fails, then no default equipment for you!
      schedule = AppStateUtils.getFirstScheduleInPayform(this.currentPayform);
    }

    const trailers = [this.data.activity.trlrNbr1, this.data.activity.trlrNbr2, this.data.activity.trlrNbr3];
    const trailerValue = trailerNbr =>
      !LoDash.isEmpty(trailers[trailerNbr - 1]) ? trailers[trailerNbr - 1] : isNewActivity ? LoDash.get(AppStateUtils.trailerFromSchedule(schedule, trailerNbr), 'trlrNbr', '') : '';

    const dollies = [this.data.activity.dollyNbr1, this.data.activity.dollyNbr2];

    const dollyValue = dollyNbr => (!LoDash.isEmpty(dollies[dollyNbr - 1]) ? dollies[dollyNbr - 1] : isNewActivity ? AppStateUtils.dollyFromSchedule(schedule, dollyNbr) ?? '' : '');

    FormUtils.setValues(this.equipGroup, {
      [AddEditActivityFormFields.Trailer1]: trailerValue(1),
      [AddEditActivityFormFields.Trailer2]: trailerValue(2),
      [AddEditActivityFormFields.Trailer3]: trailerValue(3),
      [AddEditActivityFormFields.Dolly1]: dollyValue(1),
      [AddEditActivityFormFields.Dolly2]: dollyValue(2),
    });
  }

  private updateFormTime(activity: DsrActivity) {
    // DsrActivity stores the start and end time in UTC

    // get the calendar date for the start of the activity, and add 1 day for the max start date
    const currentPayform = this.currentPayform;
    const startDate = moment(currentPayform.shiftStartDate, 'YYYY-MM-DD');

    this.minStartDate = new Date(startDate.toDate());
    this.maxStartDate = new Date(startDate.add(1, 'days').toDate());

    // get the start and end DateTime in Activity timezone
    const startDateTime = activity.startDateTimeUtc ? moment.tz(activity.startDateTimeUtc, this.activityTimezone) : undefined;
    const endDateTime = activity.endDateTimeUtc ? moment.tz(activity.endDateTimeUtc, this.activityTimezone) : undefined;

    const doesSpanTwoDays = startDateTime && endDateTime && startDateTime.day() !== endDateTime.day();

    // convert the DateTime HH:mm into clock HH:mm (ie, 6:45 EST => 06:45 <= 6:45 PDT)
    const formatTime = (t: Moment): string => (t ? t.format('HH:mm') : '');

    const startClockTime = formatTime(startDateTime);
    const endClockTime = formatTime(endDateTime);
    const duration = activity.durationInMn;

    const startCalendarDate = startDateTime ? new Date(startDateTime.year(), startDateTime.month(), startDateTime.date()) : this.minStartDate;

    FormUtils.setValues(this.form, {
      [AddEditActivityFormFields.StartDate]: startCalendarDate,
      [AddEditActivityFormFields.StartTime]: startClockTime,
      [AddEditActivityFormFields.EndTime]: endClockTime,
      [AddEditActivityFormFields.Duration]: duration,
      [AddEditActivityFormFields.SpansTwoDays]: doesSpanTwoDays,
    });
  }

  private updateDurationFromForm() {
    const times = this.getTimesFromForm();
    FormUtils.setValues(this.form, {
      [AddEditActivityFormFields.Duration]: (isNaN(times.durationInMn) ? undefined : times.durationInMn) ?? '',
    });
  }

  private updateEnableEquipmentGroup() {
    if (this.hasEquipment) {
      this.equipGroup.enable({ onlySelf: true });
    } else {
      this.equipGroup.disable({ onlySelf: true });
    }
  }

  public equipFieldChange(field: string) {
    const control = this.equipGroup.get(field);
    FormUtils.setValue(control, this.equipmentPipe.transform(control.value));
    control.updateValueAndValidity();
  }

  private watchValueChanges() {
    const durationControl = () => this.form.get(AddEditActivityFormFields.Duration);
    // Register to handle value changes in the form
    this.form
      .get(AddEditActivityFormFields.ActivityType)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe((newValue: NonDriveTimeType) => {
        this.selectedNonDriveTimeType = newValue;
        const isEditable = this.selectedNonDriveTimeType?.durationEditInd ?? false;
        if (isEditable) {
          durationControl().enable();
        } else {
          durationControl().disable();
        }
        this.updateDurationFromForm();
        this.activityType = this.paidTimeOrDurationPipe.transform(newValue);
        this.setDefaultSicForActivityType();
        this.updateRequiredComments();
        this.commentsControl.markAsTouched();
        this.updateEnableEquipmentGroup();
        this.form.updateValueAndValidity();
      });

    this.form
      .get(AddEditActivityFormFields.StartTime)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.updateDurationFromForm();
      });

    this.form
      .get(AddEditActivityFormFields.EndTime)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.updateDurationFromForm();
      });

    this.form
      .get(AddEditActivityFormFields.SpansTwoDays)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.updateDurationFromForm();
      });

    this.equipGroup
      .get(AddEditActivityFormFields.Trailer1)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.checkRequiredEquipment();
        this.checkDuplicateEquipment();
      });

    this.equipGroup
      .get(AddEditActivityFormFields.Trailer2)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.checkDuplicateEquipment();
      });

    this.equipGroup
      .get(AddEditActivityFormFields.Trailer3)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.checkDuplicateEquipment();
      });

    this.equipGroup
      .get(AddEditActivityFormFields.Dolly1)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.checkRequiredEquipment();
        this.checkDuplicateEquipment();
      });

    this.equipGroup
      .get(AddEditActivityFormFields.Dolly2)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged())
      .subscribe(newValue => {
        this.checkDuplicateEquipment();
      });
  }

  /**
   * Add/Update Activity based on the Form values
   */
  private fromForm(): DsrActivity {
    const activity = this.data.activity;
    if (!activity.nonDriveTimeType) {
      activity.nonDriveTimeType = new NonDriveTimeType();
    }
    activity.nonDriveTimeType.actvtyDescription = this.selectedNonDriveTimeType?.actvtyDescription;
    activity.nonDriveTimeType.actvtyName = this.selectedNonDriveTimeType?.actvtyName;
    activity.nonDriveTimeType.kindOfTimeCd = this.selectedNonDriveTimeType?.kindOfTimeCd;
    activity.nonDriveTimeType.nonDrvTimeTypeId = this.selectedNonDriveTimeType?.nonDrvTimeTypeId;
    activity.nonDriveTimeType.paidCd = this.selectedNonDriveTimeType?.paidCd;
    activity.nonDriveTimeType.requiredCommentInd = this.selectedNonDriveTimeType?.requiredCommentInd;
    activity.nonDriveTimeType.sendToPayrollInd = this.selectedNonDriveTimeType?.sendToPayrollInd;
    activity.nonDriveTimeType.workStandard = this.selectedNonDriveTimeType?.workStandard;

    if (this.hasEquipment) {
      activity.trlrNbr1 = this.equipGroup.get(AddEditActivityFormFields.Trailer1).value;
      activity.trlrNbr2 = this.equipGroup.get(AddEditActivityFormFields.Trailer2).value;
      activity.trlrNbr3 = this.equipGroup.get(AddEditActivityFormFields.Trailer3).value;
      activity.dollyNbr1 = this.equipGroup.get(AddEditActivityFormFields.Dolly1).value;
      activity.dollyNbr2 = this.equipGroup.get(AddEditActivityFormFields.Dolly2).value;
    } else {
      activity.trlrNbr1 = undefined;
      activity.trlrNbr2 = undefined;
      activity.trlrNbr3 = undefined;
      activity.dollyNbr1 = undefined;
      activity.dollyNbr2 = undefined;
    }

    let note: Note = LoDash.last(activity.note) as Note;
    if (!note) {
      // create a new note
      note = new Note();
      activity.note = [note];
    }
    note.comments = this.form.get(AddEditActivityFormFields.Comments).value;

    activity.actvtySicCd = this.form.get(AddEditActivityFormFields.SIC).value ?? ''.toUpperCase();

    const times = this.getTimesFromForm();
    activity.startDateTimeUtc = times.startDateTime;
    activity.endDateTimeUtc = times.endDateTime;
    activity.durationInMn = this.form.get(AddEditActivityFormFields.Duration).value;

    return activity;
  }

  /**
   * Convert time to be between 00:00 and 23:59.
   */
  handleTimeBlur(fieldName: AddEditActivityFormFields) {
    const control = this.form.get(fieldName);
    let val = control.value;
    if (val) {
      val = val.replace(/:/g, '');
      // eslint-disable-next-line radix
      const rawTime = parseInt(val);
      const hours = Math.trunc(rawTime / 100);
      const minutes = rawTime % 100;

      if (LoDash.inRange(hours, 24) && LoDash.inRange(minutes, 60)) {
        const time = `${LoDash.padStart('' + hours, 2, '0')}:${LoDash.padStart('' + minutes, 2, '0')}`;
        control.setValue(time, { emitEvent: true });
      }
    }
  }

  handleDurationBlur() {
    const control = this.form.get(AddEditActivityFormFields.Duration);
    const val = control.value;
    if (val) {
      // eslint-disable-next-line radix
      let duration = parseInt(val);
      if (!duration || duration < 0) {
        duration = undefined;
      }
      control.setValue(duration, { emitEvent: true });
    }
  }

  getWorkStandard(): WorkStandard {
    if (this.hasEquipment) {
      // return work standard for number of trailers we have
      // default to 2 trailers if there are no trailers defined
      const trailers =
        [AddEditActivityFormFields.Trailer1, AddEditActivityFormFields.Trailer2, AddEditActivityFormFields.Trailer3].reduce((total, field) => {
          const r = total + (this.equipGroup && !LoDash.isEmpty(LoDash.get(this.equipGroup.get(field), 'value')) ? 1 : 0);
          return r;
        }, 0) || 2;

      // eslint-disable-next-line radix
      const workStd = LoDash.get(this.selectedNonDriveTimeType, 'workStandard', []).find(x => parseInt(x.trlrQuantity) === trailers);
      return workStd;
    } else if (
      // no equipment, so return first WorkStandard
      this.selectedNonDriveTimeType &&
      this.selectedNonDriveTimeType.workStandard &&
      this.selectedNonDriveTimeType.workStandard.length > 0
    ) {
      const workStd = LoDash.get(this.selectedNonDriveTimeType, 'workStandard', [])[0];
      return workStd;
    }

    // No work standard
    return undefined;
  }

  checkRequiredEquipment() {
    if (!this.hasEquipment) {
      return;
    }
  }

  checkDuplicateEquipment() {
    const equipments = [
      this.equipGroup.get(AddEditActivityFormFields.Trailer1),
      this.equipGroup.get(AddEditActivityFormFields.Trailer2),
      this.equipGroup.get(AddEditActivityFormFields.Trailer3),
      this.equipGroup.get(AddEditActivityFormFields.Dolly1),
      this.equipGroup.get(AddEditActivityFormFields.Dolly2),
    ];

    const distinctEquipment = [];

    const duplicateEquipment = () => ({ duplicateEquipment: true });

    for (const equip of equipments) {
      if (equip.value && equip.value.length > 0) {
        if (distinctEquipment.findIndex(x => x.toUpperCase() === equip.value.toUpperCase()) >= 0) {
          equip.setErrors(duplicateEquipment());
          FormUtils.clearWarnings(equip);
        } else {
          distinctEquipment.push(equip.value);
          if (equip.hasError('duplicateEquipment')) {
            delete equip.errors['duplicateEquipment'];
          }
        }
      }
    }

    this.form.updateValueAndValidity();
  }

  cancelClicked() {
    FormUtils.hasError(this.form, AddEditActivityFormFields.Equipment, ActivityFormErrors.equipmentRequired);
    this.matDialogRef.close();
  }

  saveClicked() {
    const activity = this.fromForm();
    this.matDialogRef.close(activity);
  }
}
