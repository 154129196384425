import { ActionCd, PayformNoteTypeCd } from '@xpo-ltl/sdk-common';
import { DsrActivity, DsrPayform, LinehaulSchedule, Note, ScheduleTrailer } from '@xpo-ltl/sdk-linehaulpayform';
import { PayformsFilter } from '../classes';
import { PayformsFilterState } from './app.state';
import { PayformStatusCd } from '@xpo-ltl/sdk-common';
import * as moment from 'moment-timezone';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export class AppStateUtils {
  /**
   * Gets the LinehaulSchedule in the state given its schSequenceNbr
   */
  public static getScheduleIndexFromPayform(payform: DsrPayform, schSequenceNbr: string) {
    return payform.linehaulSchedule.findIndex(sch => +sch.schSequenceNbr === +schSequenceNbr);
  }

  /**
   * Gets the LinehaulSchedule in the state given its schSequenceNbr
   */
  public static getScheduleFromPayform(payform: DsrPayform, schSequenceNbr: string) {
    return payform.linehaulSchedule && payform.linehaulSchedule.length ? payform.linehaulSchedule.find(sch => +sch.schSequenceNbr === +schSequenceNbr) : null;
  }

  /**
   * Gets the first LinehaulSchedule that is not DELETED
   */
  public static getFirstScheduleInPayform(payform: DsrPayform) {
    return (payform.linehaulSchedule ?? []).find(sch => sch.listActionCd !== ActionCd.DELETE);
  }

  /**
   * Return true if any value set in patch is different from the same value in state
   */
  public static doesPatchChangeState(state, patch): boolean {
    for (const key of Object.keys(patch)) {
      if (LoDash.get(state, key) !== LoDash.get(patch, key)) {
        return true;
      }
    }
    // all values in the patch equal the corresponding value in the target
    return false;
  }

  /**
   * Gets the DsrActivity in the state given its actvtySequenceNbr
   */
  public static getActivityFromState(activities: DsrActivity[], actvtySequenceNbr: string): DsrActivity {
    return (activities ?? []).find(act => +act.actvtySequenceNbr === +actvtySequenceNbr);
  }

  /**
   * Gets the index of the DsrActivity in the state given its actvtySequenceNbr
   */
  public static getActivityIndexFromState(activities: DsrActivity[], actvtySequenceNbr: string): number {
    return activities.findIndex(act => +act.actvtySequenceNbr === +actvtySequenceNbr);
  }

  /**
   * Gets the Note associated with the given actvtySequenceNbr
   */
  public static getNoteForActivity(notes: Note[], actvtySequenceNbr: string): Note {
    return (notes ?? []).find((n: Note) => n.noteType === PayformNoteTypeCd.ACTIVITY && +n.noteTypeSequenceNbr === +actvtySequenceNbr) as Note;
  }

  /**
   * Return the ScheduleTrailer for the requested displaySequenceNbr. If it doesn't exist, return undefined.
   * @param displaySequenceNbr trailer display index (1,2,3)
   */
  public static trailerFromSchedule(schedule: LinehaulSchedule, displaySequenceNbr: number): ScheduleTrailer {
    return (schedule?.scheduleTrailer ?? []).find(trailer => trailer.listActionCd !== ActionCd.DELETE && trailer.displaySequenceNbr === displaySequenceNbr.toString());
  }

  public static dollyFromSchedule(schedule: LinehaulSchedule, displaySequenceNbr: number): string {
    switch (displaySequenceNbr) {
      case 1:
        return LoDash.get(schedule, 'dollyNbr1', '');
      case 2:
        return LoDash.get(schedule, 'dollyNbr2', '');
      default:
        return undefined;
    }
  }

  public static getDestination(schedule: LinehaulSchedule): string {
    if (schedule) {
      return schedule.viaSicCd ? schedule.viaSicCd : schedule.destinationSicCd;
    }
    return undefined;
  }

  public static setDestination(schedule: LinehaulSchedule, destSic: string) {
    if (schedule && destSic) {
      if (schedule.viaSicCd) {
        schedule.viaSicCd = destSic;
      } else {
        schedule.destinationSicCd = destSic;
      }
    }
  }

  public static filterInitialState(): PayformsFilterState {
    const filter = new PayformsFilter();
    filter.fromDate = moment().add(-14, 'days');
    filter.toDate = moment();
    filter.status = [PayformStatusCd.SUBMITTED];
    return filter;
  }

  public static findNoteInState(state: Note[], noteSequenceNbr: string): Note {
    return state.find(note => note.noteSequenceNbr === noteSequenceNbr);
  }

  public static findNoteIndexInState(state: Note[], noteSequenceNbr: string): number {
    return state.findIndex(note => note.noteSequenceNbr === noteSequenceNbr);
  }

  public static findActivityInState(state: DsrActivity[], actvtySequenceNbr: string): DsrActivity {
    return state.find(item => item.actvtySequenceNbr === actvtySequenceNbr);
  }

  public static deleteTrailerAt(state: ScheduleTrailer[], index: number): ScheduleTrailer[] {
    if (state[index].listActionCd !== ActionCd.ADD) {
      // existing trailer at the index, so mark it for delete.
      state[index].listActionCd = ActionCd.DELETE;
    } else {
      // new trailer at index, so just remove it from the list
      LoDash.pullAt(state, [index]);
    }
    return state;
  }

  public static findScheduleInState(state: LinehaulSchedule[], schSequenceNbr: string): LinehaulSchedule {
    return state.find((item: LinehaulSchedule) => item.schSequenceNbr === schSequenceNbr);
  }

  public static findScheduleIndexInState(state: LinehaulSchedule[], schSequenceNbr: string): number {
    return state.findIndex((item: LinehaulSchedule) => item.schSequenceNbr === schSequenceNbr);
  }
}
