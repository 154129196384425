export enum ErrorMessage {
  OneDollyRequiredWithTwoTrailers = 'Dolly 1 is required when 2 trailers are on a schedule.',
  TwoDollysRequiredWithThreeTrailers = '2 dollies need to be provided for 3 trailers on a schedule.',
  OneTractorTwoDollysNoTrailer = '2 dollies are entered but no trailers.',
  DuplicateDolly = 'Dolly number {0} occurs multiple times.',
  DuplicateDollys = 'Dolly numbers {0} occur multiple times.',
  DuplicateTrailer = 'Trailer number {0} occurs multiple times.',
  DuplicateTrailers = 'Trailer numbers {0} occur multiple times.',
  InvalidLoadReleaseFormat = 'Load Release Number for {0} is less than 7 digits',
  InvalidLoadReleaseFormats = 'Load Release Numbers for {0} are less than 7 digits',
  NoTrailerProvided = 'Trailer Number not provided but {0} selected.',
  LoadReleaseRequired = 'Load Release number missing for Trailer {0}.',
  LoadReleaseRequireds = 'Load Release numbers missing for Trailers {0}.',
  TotalMilesLessThanZero = 'Total Schedule Pay Miles is less than 0.',
  OperationTypeInvalid = 'Type of Operation is invalid for number of trailers.',
}
