import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { FormUtils } from '../classes';
import { WarningValidationType } from '../enums';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function warnEmptyValidatorFunction(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled) {
      return null;
    }

    FormUtils.unsetWarning(control, WarningValidationType.EmptyValue);

    if (LoDash.isEmpty(control.value)) {
      FormUtils.setWarning(control, WarningValidationType.EmptyValue, true);
    }

    return null;
  };
}

@Directive({
  selector: '[warnEmptyValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: WarnEmptyValidator,
      multi: true,
    },
  ],
})
export class WarnEmptyValidator implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return warnEmptyValidatorFunction()(control);
  }
}
