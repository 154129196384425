import { Pipe, PipeTransform } from '@angular/core';
import { GetLocReferenceDetailsBySicResp } from '@xpo-ltl/sdk-location';
import * as moment from 'moment-timezone';
import { map } from 'rxjs/operators';
import { LocationDetailsCacheService } from '../services';
import { LoDash } from '../utils/angular-utils/lodash-utils';

/**
 * Display Date based on the requested SIC
 */
@Pipe({
  name: 'sicDate',
})
export class SicDatePipe implements PipeTransform {
  constructor(private locationDetailsCache: LocationDetailsCacheService) {}

  transform(value: any, format?: string, tzSic?: string, locale?: string): any {
    if (!value) {
      return undefined;
    }

    format = format ?? 'dddd, MMMM Do YYYY, h:mm:ss a';

    if (LoDash.isEmpty(tzSic)) {
      tzSic = 'UPO';
    }

    // format the date based on the passed Sic timezone
    return this.locationDetailsCache.request({ sicCd: tzSic }).pipe(
      map((location: GetLocReferenceDetailsBySicResp) => {
        const timezone = location?.locReference?.timezoneName;
        const dt = moment.tz(value, timezone);
        const result = dt.format(format);
        return result;
      })
    );
  }
}
