import { Component, OnInit, Inject } from '@angular/core';
import { ErrorData } from '../../classes/error-data';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ErrorWarningTypes } from '../../enums/error-warning-types.enum';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public ErrorWarningTypes = ErrorWarningTypes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorData) {}

  ngOnInit() {}
}
