import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AppStateUtils, PayPortalStateStore, PayformState } from '../../../../../app/state';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ActionCd, PayformStatusCd, PayformPaidTypeCd } from '@xpo-ltl/sdk-common';
import { DsrPayform, LinehaulPayformApiService, LinehaulSchedule, ListLinehaulPayformsQuery } from '@xpo-ltl/sdk-linehaulpayform';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FormUtils } from '../../../../classes';
import {
  AccessLevel,
  ConfigManagerProperties,
  EmployeeDetailFormFields,
  PayformFormFields,
  SnackbarMessage,
  WarningValidationType,
  LinehaulScheduleFormFields,
  DisplayOnlyFormFields,
  ScheduleDetourFormFields,
  DsrActivityFormFields,
} from '../../../../enums';
import { AppConstantsService, DriverPayService, ScrollbarService } from '../../../../services';
import { PayFormService } from '../../services';
import { Unsubscriber } from '../../../../utils/angular-utils/classes';
import { watchAsyncFormStatus } from '../../../../utils/angular-utils/utils';
import { LoDash } from '../../../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-payform-header',
  templateUrl: './payform-header.component.html',
  styleUrls: ['./payform-header.component.scss'],
})
export class PayformHeaderComponent implements OnInit, OnDestroy {
  public readonly$: Observable<boolean>;
  payform: DsrPayform;

  @Input()
  parentForm: UntypedFormGroup;
  @Output()
  expandCollapseClick = new EventEmitter<boolean>();
  private unsubscriber = new Unsubscriber();

  public isExpanded = true;
  private isScrollingSubject = new BehaviorSubject<boolean>(false);
  public isScrolling$ = this.isScrollingSubject.asObservable();

  public PayformFormFields = PayformFormFields;
  public EmployeeDetailFormFields = EmployeeDetailFormFields;
  public PayformStatusCd = PayformStatusCd;
  public AccessLevel = AccessLevel;

  private paytimeSubject = new BehaviorSubject<number>(undefined);
  public paytime$ = this.paytimeSubject.asObservable();

  private paymilesSubject = new BehaviorSubject<number>(undefined);
  public paymiles$ = this.paymilesSubject.asObservable();

  private showSubjectToSubject = new BehaviorSubject<boolean>(false);
  public showSubjectTo$ = this.showSubjectToSubject.asObservable();

  public payformState: PayformState;
  public currentPayform: DsrPayform;
  private readonly: boolean;

  get shouldDisplayEditButton(): boolean {
    return this.readonly && this.payform?.statusCd === PayformStatusCd.SUBMITTED;
  }

  get shouldDisplayAddScheduleButton(): boolean {
    const currentPayform = this.currentPayform;
    return (!this.readonly && currentPayform.statusCd === PayformStatusCd.SUBMITTED) || (currentPayform?.statusCd === PayformStatusCd.NEW && currentPayform?.dsrPayformId < 0);
  }

  get payformDmclSic(): string {
    return this.currentPayform?.dmclSic;
  }

  constructor(
    public constants: AppConstantsService,
    private payformService: PayFormService,
    private linehaulService: LinehaulPayformApiService,
    private driverPayService: DriverPayService,
    private config: ConfigManagerService,
    private scrollbarService: ScrollbarService,
    private state: PayPortalStateStore,
    private xpoSnackBar: XpoSnackBar
  ) {}

  ngOnInit() {
    this.readonly$ = this.state.getPayformReadOnlySelector();
    this.readonly$.pipe(takeUntil(this.unsubscriber.done)).subscribe(readonly => {
      this.readonly = readonly;
      this.payformService.setFooter(this.readonly);
    });
    this.state
      .getPayformState()
      .pipe(take(1))
      .subscribe(state => {
        this.payform = state.current;
        this.currentPayform = state.current;
        this.state.setPayformReadOnlyAction(this.readonly);

        if (this.readonly) {
          this.state.setCurrentPayformAction(this.currentPayform);
        }
      });

    this.loadPayForms();
    this.scrollbarService.scrollbarPosition$.pipe(takeUntil(this.unsubscriber.done)).subscribe(position => {
      this.isScrollingSubject.next(position > 0);
    });

    this.parentForm.statusChanges.pipe(takeUntil(this.unsubscriber.done), watchAsyncFormStatus(this.parentForm), distinctUntilChanged()).subscribe(() => {
      setTimeout(() => {
        this.computePaytime();
        this.computePaymiles();
      }, 100);
    });

    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        this.payform = payform;
        setTimeout(() => {
          this.computePaytime();
          this.computePaymiles();
        }, 100);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  expandCollapseClicked() {
    this.isExpanded = !this.isExpanded;
    this.expandCollapseClick.emit(this.isExpanded);
  }

  editClicked() {
    this.state.setPayformReadOnlyAction(false);
  }

  addScheduleClicked() {
    this.state
      .getCurrentPayformState()
      .pipe(take(1))
      .subscribe(current => (this.currentPayform = current));

    const schedules =
      this.currentPayform.linehaulSchedule && this.currentPayform.linehaulSchedule.length ? this.currentPayform.linehaulSchedule.filter(sch => sch.listActionCd !== ActionCd.DELETE) : [];
    const lastSchedule = LoDash.last(schedules) as LinehaulSchedule;

    if (schedules && schedules.length >= this.constants.MaxSchedules) {
      this.driverPayService.showMaxSchedulesReachedDialog();
    } else {
      const schedule = new LinehaulSchedule();
      if (lastSchedule) {
        schedule.tractorNbr = lastSchedule.tractorNbr;
        if (lastSchedule.originSicCd && AppStateUtils.getDestination(lastSchedule)) {
          // set origin/destingation using the last schedule data
          schedule.originSicCd = AppStateUtils.getDestination(lastSchedule);
          schedule.destinationSicCd = lastSchedule.originSicCd;
        }
        schedule.schSequenceNbr = this.payformService.nextSchSequenceNbr(+lastSchedule.schSequenceNbr);
      } else {
        // no last schedule, so set origin sic from payform
        schedule.originSicCd = this.currentPayform?.dmclSic;
        schedule.schSequenceNbr = this.payformService.nextSchSequenceNbr();
      }
      schedule.dsrPayformId = this.currentPayform?.dsrPayformId;

      this.state.setPayformSchedule(schedule); // we should use a service

      this.xpoSnackBar.success(SnackbarMessage.ScheduleAdded.replace('{0}', '' + (schedules.length + 1)));
      if (!this.isExpanded) {
        this.expandCollapseClicked();
      }
    }
  }

  loadPayForms() {
    const currentPayformId = this.currentPayform?.dsrPayformId;

    const queryParams = new ListLinehaulPayformsQuery();
    queryParams.beginningShiftStartDate = moment(this.currentPayform?.shiftStartDate).format('YYYY-MM-DD');
    queryParams.dmclSic = this.currentPayform?.dmclSic;
    queryParams.dsrEmployeeId = this.currentPayform?.dsrEmployeeId;
    queryParams.endingShiftStartDate = queryParams.beginningShiftStartDate;
    queryParams.includeZone = this.config.getSetting<boolean>(ConfigManagerProperties.includeZones) ? 'true' : 'false';
    queryParams.payformStatus = [PayformStatusCd.APPROVED, PayformStatusCd.NEW, PayformStatusCd.RETURNED, PayformStatusCd.SUBMITTED, PayformStatusCd.TRANSMITTED];

    this.linehaulService
      .listLinehaulPayforms(queryParams, { toastOnError: false })
      .pipe(take(1))
      .subscribe(
        response => {
          if (response && response.dsrPayform) {
            if (response.dsrPayform.filter(form => form.dsrPayformId !== currentPayformId).length > 0) {
              this.setPayformConflictWarning();
              this.parentForm.updateValueAndValidity();
            }
          }
        },
        error => {}
      );
  }

  setPayformConflictWarning() {
    const payformConflictWarning = {
      type: WarningValidationType.PayformConflictWarning,
    };

    FormUtils.setWarning(this.parentForm, WarningValidationType.PayformConflictWarning, payformConflictWarning);
  }

  checkPayformConflictWarning(): boolean {
    const warnings = LoDash.get(this.parentForm, 'warnings');
    return warnings && warnings[WarningValidationType.PayformConflictWarning];
  }

  private computePaytime() {
    let activitiesArr = [];

    const linehaulSchedules = this.parentForm.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;
    if (linehaulSchedules) {
      activitiesArr = linehaulSchedules.controls.reduce((payformArr, schedule) => {
        const activitiesCtrl = schedule.get(LinehaulScheduleFormFields.Activities) as UntypedFormGroup;
        if (activitiesCtrl) {
          payformArr.push(...LoDash.values(activitiesCtrl.controls));
        }
        return payformArr;
      }, []);
    }

    // Get non schedule activities if present
    const nonSchedActivities = this.parentForm.get(LinehaulScheduleFormFields.Activities) as UntypedFormArray;
    if (nonSchedActivities) {
      activitiesArr.push(...LoDash.values(nonSchedActivities.controls));
    }

    if (activitiesArr && activitiesArr.length > 0) {
      let totalDuration = 0;
      let showSubjectTo = false;
      activitiesArr.forEach((activityControl: UntypedFormControl) => {
        const duration = LoDash.get(activityControl.value, DsrActivityFormFields.DurationInMn, 0);
        const nonDriveTimeType = LoDash.get(activityControl.value, DsrActivityFormFields.NonDriveTimeType, undefined);
        if (nonDriveTimeType && (nonDriveTimeType.paidCd === PayformPaidTypeCd.PAID || nonDriveTimeType.paidCd === PayformPaidTypeCd.SPECIAL)) {
          totalDuration += duration;
        }
        const actvtyName = nonDriveTimeType?.actvtyName ?? '';
        if (!showSubjectTo && (actvtyName === 'Layover' || actvtyName === 'Wait Time - Destination' || actvtyName === 'Wait Time - En Route')) {
          showSubjectTo = true;
        }
      });
      this.paytimeSubject.next(totalDuration);
      this.showSubjectToSubject.next(showSubjectTo);
    } else {
      this.paytimeSubject.next(0);
      this.showSubjectToSubject.next(false);
    }
  }

  private computePaymiles() {
    const linehaulSchedules = this.parentForm.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      this.paymilesSubject.next(0);
      return null;
    }

    const totalMiles = linehaulSchedules.controls.reduce((total, schedule) => {
      const scheduleDetail = schedule.get(LinehaulScheduleFormFields.Details);
      if (!scheduleDetail || scheduleDetail.pending) {
        return total;
      }

      let scheduleMiles = LoDash.get(scheduleDetail.get(DisplayOnlyFormFields.OrigToDestMileage), 'value', 0);
      const detourControl = scheduleDetail.get(LinehaulScheduleFormFields.DetourControl) as UntypedFormGroup;

      if (detourControl) {
        const detourFields = detourControl.get(ScheduleDetourFormFields.DtrFields);
        if (detourFields && detourFields.enabled) {
          const dtrTotalExMilCtrl = detourFields.get(ScheduleDetourFormFields.DtrTotalExMil);
          if (dtrTotalExMilCtrl.valid) {
            const detourMiles = +LoDash.get(dtrTotalExMilCtrl, 'value', 0);
            scheduleMiles += detourMiles;
          }
        }
      }

      return total + scheduleMiles;
    }, 0);

    this.paymilesSubject.next(totalMiles);
  }
}
