import { Directive } from '@angular/core';
import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator, FormArray } from '@angular/forms';
import { LinehaulScheduleFormFields, ScheduleTrailerFormFields } from '../enums';
import { EquipmentErrorTypes } from '../enums/equipment-error-types.enum';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function duplicateEquipmentValidatorFunction(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled) {
      return null;
    }

    const errors = {};
    const dollyNbr1Ctrl = control.get(LinehaulScheduleFormFields.DollyNbr1);
    const dollyNbr2Ctrl = control.get(LinehaulScheduleFormFields.DollyNbr2);
    const dollyControls = [dollyNbr1Ctrl, dollyNbr2Ctrl];

    if (!dollyNbr1Ctrl || !dollyNbr2Ctrl) {
      return null;
    }

    const dollyVals = dollyControls.map(dollyCtrl => dollyCtrl.value);

    if ((dollyVals ?? []).some(val => val && dollyVals.indexOf(val) !== dollyVals.lastIndexOf(val))) {
      const primarySubject = LoDash.unique((dollyVals ?? []).filter(val => val && dollyVals.indexOf(val) !== dollyVals.lastIndexOf(val))).join(', ');
      errors[EquipmentErrorTypes.DuplicateDolly] = {
        type: EquipmentErrorTypes.DuplicateDolly,
        displayError: true,
        primarySubject,
      };
    }

    const trailerControls = control.get(LinehaulScheduleFormFields.ScheduleTrailer);

    if (!trailerControls || !trailerControls.value) {
      return null;
    }

    const trailerVals = trailerControls.value.map(trailerCtrl => trailerCtrl[ScheduleTrailerFormFields.TrlrNbr]);

    if ((trailerVals ?? []).some(val => val && trailerVals.indexOf(val) !== trailerVals.lastIndexOf(val))) {
      const primarySubject = LoDash.unique((trailerVals ?? []).filter(val => val && trailerVals.indexOf(val) !== trailerVals.lastIndexOf(val))).join(', ');
      errors[EquipmentErrorTypes.DuplicateTrailer] = {
        type: EquipmentErrorTypes.DuplicateTrailer,
        displayError: true,
        primarySubject,
      };
    }

    return Object.keys(errors).length ? errors : null;
  };
}

@Directive({
  selector: '[duplicateEquipmentValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DuplicateEquipmentValidator, multi: true }],
})
export class DuplicateEquipmentValidator implements Validator {
  constructor() {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return duplicateEquipmentValidatorFunction()(control);
  }
}
