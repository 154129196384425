import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { OperationType } from '../../classes/operation-type';
import { ViewChild } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OperationTypesData } from '../../classes/operation-types-data';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-operation-types',
  templateUrl: './operation-types.component.html',
  styleUrls: ['./operation-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationTypesComponent implements OnInit, OnDestroy {
  private opTypeListSubject: BehaviorSubject<Array<OperationType>> = new BehaviorSubject<Array<OperationType>>(undefined);

  public opTypeList$ = this.opTypeListSubject.asObservable();
  public selectedOptype: OperationType;

  private readonly trailerOperationListOptions = [
    [new OperationType(0, 'No Trailing / Straight Truck, 2-Axle Tractor, 3-Axle Tractor', 128)],
    [
      new OperationType(1, '1-Axle Trailer or 1-Axle Trailer with Trailing Dolly / 2-Axle Tractor', 117.9),
      new OperationType(2, '2-Axle Semi-Trailer / 3-Axle Tractor', 52.1),
      new OperationType(11, '1-Axle Trailer / 3-Axle Tractor', 52.1),
      new OperationType(12, '2-Axle Semi-Trailer / 2-Axle Tractor', 52.1),
    ],
    [
      new OperationType(5, 'Two 1-Axle Trailers with One or Two Dollies / 2-Axle Tractor', 178.6),
      new OperationType(7, '2-Axle Semi-Trailer with Dolly and 1-Axle Trailer / 3-Axle Tractor', 178.6),
      new OperationType(10, 'Two 1-Axle Trailers with One or Two Dollies / 3-Axle Tractor', 178.6),
    ],
    [new OperationType(13, 'Three 1-Axle Trailers with Two Dollies / 3-Axle Tractor', 111.5), new OperationType(17, 'Three 1-Axle Trailers with Two Dollies / 2-Axle Tractor', 111.4)],
  ];

  @ViewChild('selectList', { static: true })
  selectList: MatSelectionList;

  private unsubscriber = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<OperationTypesComponent>, @Inject(MAT_DIALOG_DATA) public data: OperationTypesData) {}

  ngOnInit() {
    if (!!this.data && this.data.trailerCount >= 0) {
      this.selectedOptype = this.trailerOperationListOptions[this.data.trailerCount].find(item => item.optype === this.data.current);
      this.opTypeListSubject.next(this.trailerOperationListOptions[this.data.trailerCount]);
      this.selectList.selectionChange.pipe(takeUntil(this.unsubscriber)).subscribe((selChange: any) => {
        this.selectList.deselectAll();
        if (selChange.options.length) {
          selChange.options[0].selected = true;
        }
        this.selectedOptype = this.selectList.selectedOptions.selected[0].value;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  doneClicked() {
    const selectedItem = this.selectList.selectedOptions.selected[0];
    this.dialogRef.close(this.selectedOptype);
  }
}
