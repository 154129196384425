import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ActionCd, PayformStatusCd, User, PayformPaidTypeCd } from '@xpo-ltl/sdk-common';
import { DsrActivity, DsrPayform, LinehaulSchedule, Note } from '@xpo-ltl/sdk-linehaulpayform';
import { RoutingInstructions } from '@xpo-ltl/sdk-reference';
import { EdgeAppStateStore } from '@xpo-ltl/ngx-ltl-app-state';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddEditActivityData, ConfirmCancelData, ErrorData, MoveActivityData, MoveActivityScheduleData, OperationTypesData, RouteTypesData } from '../../classes';
import {
  ApprovePayformComponent,
  ConfirmCancelComponent,
  CreatePayformComponent,
  ErrorComponent,
  MoveActivityComponent,
  OperationTypesComponent,
  ReturnReasonFeedbackComponent,
  RouteTypesComponent,
} from '../../dialogs';
import { AddEditActivityComponent } from '../../dialogs/add-edit-activity/add-edit-activity.component';
import { ApproveDialogResults, EmptyPayformSaveTypes, ErrorWarningTypes, RouterUriComponents, DsrActivityFormFields } from '../../enums';
import { AppConstantsService } from '../app-constants.service';
import { DeleteReasonFeedbackComponent } from '../../dialogs/delete-reason-feedback/delete-reason-feedback.component';
import { ApproveReasonFeedbackComponent } from '../../dialogs/approve-reason-feedback/approve-reason-feedback.component';
import { EditReasonFeedbackComponent } from '../../dialogs/edit-reason-feedback/edit-reason-feedback.component';
import { PayformsFilterState, PayformState, PayPortalStateStore } from '../../../app/state';
import { LoDash } from '../../utils/angular-utils/lodash-utils';

@Injectable()
export class DriverPayService {
  payformState: PayformState;
  filtersState: PayformsFilterState;
  user: User;

  constructor(private router: Router, private dialog: MatDialog, private constants: AppConstantsService, private state: PayPortalStateStore, private edgeStateStore: EdgeAppStateStore) {
    this.state.getPayformState().subscribe((payform: PayformState) => {
      this.payformState = payform;
    });
    this.state.setPayformsFilterSicAction(this.edgeStateStore.getSic().sicCode);
  }

  private _currentPayformGroup: UntypedFormGroup;
  public get currentPayformGroup(): UntypedFormGroup {
    return this._currentPayformGroup;
  }
  public set currentPayformGroup(fg: UntypedFormGroup) {
    this._currentPayformGroup = fg;
  }

  public get CurrentPayform(): DsrPayform {
    return this.payformState?.current;
  }

  public get currentPayformSchedules(): LinehaulSchedule[] {
    return this.payformState?.current.linehaulSchedule;
  }
  public get PristinePayform(): DsrPayform {
    return this.payformState?.pristine;
  }

  public createNewPayform() {
    const dialogRef = this.dialog.open(CreatePayformComponent, {
      data: this.edgeStateStore.getSic().sicCode,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((newPayform: DsrPayform) => {
      if (newPayform) {
        newPayform.statusCd = PayformStatusCd.NEW;
        newPayform.dsrPayformId = this.constants.NewPayformId;
        newPayform.versionNbr = '0';

        const newSchedule = new LinehaulSchedule();
        newSchedule.schSequenceNbr = '1'; // new Payform, so only possible to have 1 schedule
        newSchedule.dsrPayformId = newPayform.dsrPayformId;
        newSchedule.originSicCd = newPayform.dmclSic;
        newSchedule.listActionCd = ActionCd.ADD;
        newPayform.linehaulSchedule = [newSchedule];

        this.state.setCurrentPayformAction(newPayform);
        this.state.setPayformReadOnlyAction(false);

        this.router.navigate([`${RouterUriComponents.PAYFORM}`]);
      }
    });
  }

  public isEmptyPayform(payform: DsrPayform) {
    if (!payform) {
      return true;
    }

    if (payform?.linehaulSchedule && payform?.linehaulSchedule.length) {
      const schedules = payform?.linehaulSchedule.filter(sch => sch.listActionCd !== ActionCd.DELETE) as LinehaulSchedule[];
      const nonScheduleActivities = payform.dsrActivity ? (payform.dsrActivity.filter(act => act.listActionCd !== ActionCd.DELETE) as DsrActivity[]) : null;

      return (!schedules || schedules.length === 0) && (!nonScheduleActivities || nonScheduleActivities.length === 0);
    }
  }

  public hasSchedules(payform: DsrPayform): boolean {
    if (!payform) {
      return false;
    }

    const schedules = LoDash.get(payform, 'linehaulSchedule', []).filter(sch => sch.listActionCd !== ActionCd.DELETE) as LinehaulSchedule[];

    return schedules.length > 0;
  }

  public hasPaidActivities(payform: DsrPayform): boolean {
    if (!payform) {
      return false;
    }

    const paidActivities = LoDash.get(payform, 'dsrActivity', []).filter(act => {
      const nonDriveTimeType = LoDash.get(act, DsrActivityFormFields.NonDriveTimeType, {});
      return act.listActionCd !== ActionCd.DELETE && nonDriveTimeType.paidCd !== PayformPaidTypeCd.UNPAID;
    }) as DsrActivity[];

    return paidActivities.length > 0;
  }

  public isPristinePayform() {
    return LoDash.isEqual(JSON.stringify(this.CurrentPayform), JSON.stringify(this.payformState)) && this.CurrentPayform.dsrPayformId !== this.constants.NewPayformId;
  }

  public showAddActivityDialog(schSequenceNbr: string): Observable<DsrActivity> {
    const subject = new Subject<DsrActivity>();

    const data = new AddEditActivityData('Add Activity', undefined, schSequenceNbr);
    const dialogRef = this.dialog.open(AddEditActivityComponent, { data, disableClose: true });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(newActivity => {
        subject.next(newActivity);
        subject.complete();
      });

    return subject.asObservable();
  }

  public showEditActivityDialog(activity: DsrActivity, currentNote: Note): Observable<DsrActivity> {
    const subject = new Subject<DsrActivity>();

    const clonedActivity = { ...activity } as DsrActivity;
    if (!currentNote) {
      currentNote = new Note();
    }
    clonedActivity.note = [{ ...currentNote }];
    const data = new AddEditActivityData(`Edit ${clonedActivity.nonDriveTimeType?.actvtyName ?? ''} Activity`, clonedActivity, clonedActivity.schSequenceNbr);
    const dialogRef = this.dialog.open(AddEditActivityComponent, { data, disableClose: true });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((updatedActivity: DsrActivity) => {
        subject.next(updatedActivity);
        subject.complete();
      });

    return subject.asObservable();
  }

  public showErrorsFoundDialog(): Observable<void> {
    return this.showErrorDialog('Errors have been found.', 'There are some errors that require your attention before proceeding.');
  }

  public showEmptyPayformDialog(scenarioType): Observable<void> {
    let payformMessage = '';

    switch (scenarioType) {
      case EmptyPayformSaveTypes.SaveReturnNoSchedule:
        payformMessage = 'Please add at least one schedule or activity to the Payform.';
        break;
      case EmptyPayformSaveTypes.ApproveNoSchedule:
        payformMessage = 'A payform should contain at least one schedule or activity in it in order to be approved. Please review before approving.';
        break;
      case EmptyPayformSaveTypes.ApproveNoSchdOrPdActvty:
        payformMessage = 'A payform must contain at least one schedule or one paid activity to be approved. Please review before approving.';
        break;
      default:
        payformMessage = 'There are no schedules or activities associated with this payform.';
    }
    return this.showErrorDialog('Empty Payform', payformMessage);
  }

  public showPayformErrorDialog(): Observable<void> {
    return this.showErrorDialog('Unable To Save Payform', 'Errors have been found.');
  }

  private showErrorDialog(title: string, message: string, icon: ErrorWarningTypes = ErrorWarningTypes.Error): Observable<void> {
    const subject = new Subject<void>();
    const dialogRef = this.dialog.open(ErrorComponent, {
      data: new ErrorData(title, message, icon),
    });
    dialogRef.afterClosed().subscribe(() => {
      subject.next();
      subject.complete();
    });
    return subject;
  }

  public showCancelPayformDialog(isNewPayform: boolean): Observable<boolean> {
    const createOrEditLabel = isNewPayform ? 'Creating' : 'Editing';

    return this.showConfirmCancelDialog(
      `Cancel ${createOrEditLabel} Payform?`,
      '<p>If you cancel this payform everything you have entered will be lost.</p><p>How do you wish to continue?</p>',
      'Go Back',
      `Cancel ${createOrEditLabel} Payform`
    );
  }

  public showApprovePayformDialog(): Observable<ApproveDialogResults> {
    const subject = new Subject<ApproveDialogResults>();
    const dialogRef = this.dialog.open(ApprovePayformComponent);
    dialogRef.afterClosed().subscribe((results: ApproveDialogResults) => {
      subject.next(results);
      subject.complete();
    });
    return subject;
  }

  public showDeletePayformDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog('Delete Payform?', '<p>If you delete this payform it will be lost forever.</p><p>How do you wish to continue?</p>', 'Cancel', 'Delete');
  }

  public showDeleteScheduleDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog(
      'Delete Schedule?',
      // eslint-disable-next-line max-len
      '<p>If you delete this schedule all the associated activities will be deleted as well.</p><p>You can move the activities to different schedules by activating the menu icon <i style="position: absolute !important" class="material-icons">more_vert</i><span style="display: inline-block; min-width: 23px;">&nbsp;</span> on each activity you wish to move.</p><p>How do you wish to continue?</p>',
      'Cancel',
      'Delete'
    );
  }

  public showDeleteActivityDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog('Delete Activity?', '<p>If you delete this activity it will be lost forever.</p><p>How do you wish to proceed?</p>', 'Cancel', 'Delete');
  }

  public showNoSchedulesDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog(
      'No Schedule(s) for Activities',
      // eslint-disable-next-line max-len
      '<p>There is no schedule(s) for this payform. Adding an activity will belong to the Other Activities section.</p><p>Add a schedule first if you want this activity to be part of that schedule.</p>',
      'Cancel',
      'Add to Other Activities'
    );
  }

  public showOverridePayformDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog(
      'Override This Payform?',
      '<p>If you override the payform, it will be set from Returned to a Submitted status and DSR will be unable to update it.</p>',
      'Cancel',
      'Override'
    );
  }

  public showSubmitAndOverridePayformDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog(
      'Submit & Override This Payform?',
      '<p>If you override the payform, it will be set to Submitted status and DSR will be unable to update it.</p>',
      'Cancel',
      'Submit & Override'
    );
  }

  public showUndeletePayformDialog(payform: DsrPayform): Observable<boolean> {
    const newStatus = payform && (payform.timeCrdNbr1 || payform.timeCrdNbr2) ? PayformStatusCd.SUBMITTED : 'Not Submitted';

    return this.showConfirmCancelDialog(
      'Undelete This Payform?',
      `<p>Are you sure you want to undelete this payform?</p><p>If you undelete the payform, it will be set to ${newStatus} status.</p>`,
      'Cancel',
      'Undelete'
    );
  }

  public showMaxSchedulesReachedDialog(): Observable<void> {
    return this.showErrorDialog(
      'Maximum Schedules Limit Reached',
      // eslint-disable-next-line max-len
      `This payform already has the maximum of ${this.constants.MaxSchedules} schedules. Delete an existing schedule or create a new payform if more than ${this.constants.MaxSchedules} schedules are needed.`,
      ErrorWarningTypes.None
    );
  }

  public showMaxActivitiesReachedDialog(): Observable<void> {
    return this.showErrorDialog(
      'Maximum Activities Limit Reached',
      // eslint-disable-next-line max-len
      `This payform already has the maximum of ${this.constants.MaxActivities} activities. Delete an existing activity or create a new payform if more than ${this.constants.MaxActivities} activites are needed.`,
      ErrorWarningTypes.None
    );
  }

  private showConfirmCancelDialog(title: string, message: string, cancelButtonText: string, confirmButtonText: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    const data = new ConfirmCancelData(title, message, cancelButtonText, confirmButtonText);
    const dialogRef = this.dialog.open(ConfirmCancelComponent, { data });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      subject.next(result);
      subject.complete();
    });
    return subject;
  }

  public showReturnReasonFeedbackDialog(): Observable<string> {
    const subject = new Subject<string>();
    const dialogRef = this.dialog.open(ReturnReasonFeedbackComponent);
    dialogRef.afterClosed().subscribe((feedback: string) => {
      subject.next(feedback);
      subject.complete();
    });
    return subject;
  }

  public showDeleteReasonFeedbackDialog(): Observable<string> {
    const subject = new Subject<string>();
    const dialogRef = this.dialog.open(DeleteReasonFeedbackComponent);
    dialogRef.afterClosed().subscribe((feedback: string) => {
      subject.next(feedback);
      subject.complete();
    });
    return subject;
  }

  public showApproveReasonFeedbackDialog(): Observable<string> {
    const subject = new Subject<string>();
    const dialogRef = this.dialog.open(ApproveReasonFeedbackComponent);
    dialogRef.afterClosed().subscribe((feedback: string) => {
      subject.next(feedback);
      subject.complete();
    });
    return subject;
  }

  public showEditReasonFeedbackDialog(isNewPayform: boolean): Observable<string> {
    const subject = new Subject<string>();
    const dialogRef = this.dialog.open(EditReasonFeedbackComponent, { data: { isNewPayform } });
    dialogRef.afterClosed().subscribe((feedback: string) => {
      subject.next(feedback);
      subject.complete();
    });
    return subject;
  }

  public showOperationTypesDialog(origin: string, destination: string): Observable<number> {
    const subject = new Subject<number>();
    const data = new OperationTypesData(origin, destination);
    const dialogRef = this.dialog.open(OperationTypesComponent, { data });
    dialogRef.afterClosed().subscribe((opType: number) => {
      subject.next(opType);
      subject.complete();
    });
    return subject;
  }

  public showRouteTypesDialog(origin: string, destination: string): Observable<RoutingInstructions> {
    const subject = new Subject<RoutingInstructions>();
    const data = new RouteTypesData(origin, destination);
    const dialogRef = this.dialog.open(RouteTypesComponent, { data, disableClose: true });
    dialogRef.afterClosed().subscribe((routeType: RoutingInstructions) => {
      subject.next(routeType);
      subject.complete();
    });
    return subject;
  }

  public showMoveActivityDialog(scheduleList: MoveActivityScheduleData[], originalScheduleNumber: string): Observable<MoveActivityScheduleData> {
    const subject = new Subject<MoveActivityScheduleData>();
    const data = new MoveActivityData(scheduleList, originalScheduleNumber);
    const dialogRef = this.dialog.open(MoveActivityComponent, { data });
    dialogRef.afterClosed().subscribe((schedule: MoveActivityScheduleData) => {
      subject.next(schedule);
      subject.complete();
    });
    return subject;
  }
}
