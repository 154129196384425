export enum PayformFormFields {
  DsrPayformId = 'dsrPayformId',
  DsrEmployeeId = 'dsrEmployeeId',
  ShiftStartDate = 'shiftStartDate',
  DmclSic = 'dmclSic',
  StatusCd = 'statusCd',
  TimeCrdNbr1 = 'timeCrdNbr1',
  TimeCrdNbr2 = 'timeCrdNbr2',
  DmclStateCd = 'dmclStateCd',
  DmclCountryCd = 'dmclCountryCd',
  DmclTmzoneName = 'dmclTmzoneName',
  ReviewScore = 'reviewScore',
  DmsRqstNbr = 'dmsRqstNbr',
  SubmtDateTime = 'submtDateTime',
  AprvngSupvsrId = 'aprvngSupvsrId',
  AprvlDateTime = 'aprvlDateTime',
  DmsDocumentId = 'dmsDocumentId',
  AuditInfo = 'auditInfo',
  DsrEmployeeDetail = 'dsrEmployeeDetail',
  AprvngSupvsrEmployeeDetail = 'aprvngSupvsrEmployeeDetail',
  ModifiedInd = 'modifiedInd',
  VersionNbr = 'versionNbr',
  DsrActivity = 'dsrActivity',
  Note = 'note',
  LinehaulSchedule = 'linehaulSchedule'
}
