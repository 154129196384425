import { AbstractControl } from '@angular/forms';

export const toArray = (value: ErrorOptions): string[] => (Array.isArray(value) ? value : [value]);

export type ErrorOptions = string | string[];

export interface ErrorDetails {
  control: AbstractControl;
  errorName: string;
}
