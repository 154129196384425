export enum FooterLabels {
  Delete = 'Delete',
  Cancel = 'Cancel',
  ReturnToDsr = 'Return To DSR',
  Save = 'Save',
  Approve = 'Approve',
  Override = 'Override',
  SubmitAndOverride = 'Submit & Override',
  UndoDelete = 'Undo Delete',
  Debug = 'Debug',
}
