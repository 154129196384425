import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppStateUtils, PayPortalStateStore } from 'app';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { PayformNoteTypeCd, ActionCd } from '@xpo-ltl/sdk-common';
import { DsrActivity, DsrPayform, Note, WorkStandard } from '@xpo-ltl/sdk-linehaulpayform';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { tassign } from 'tassign';
import { ActivityUtils, FormStateDirective, FormUtils, MoveActivityScheduleData } from '../../../../../../classes';
import { ConfigManagerProperties, DsrActivityFormFields, NonDriveTimeTypeFormFields, SnackbarMessage, WarningValidationType } from '../../../../../../enums';
import { AsyncValidatorCompletionService, DriverPayService } from '../../../../../../services';
import { ErrorWarningService, PayFormService } from '../../../../services';
import { XpoAngularUtilsService } from '../../../../../../utils/angular-utils/services';
import { LoDash } from '../../../../../../utils/angular-utils/lodash-utils';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent extends FormStateDirective implements OnInit, OnDestroy {
  readonly$: Observable<boolean>;
  notes: Note[];

  @ViewChild('noteButton')
  noteButton: ElementRef;

  @ViewChild('equipmentButton')
  equipmentButton: ElementRef;

  @Input()
  activity: DsrActivity;

  @Output()
  reSortActivities = new EventEmitter<DsrActivity>();

  private notesArraySubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>(undefined);
  public notesArray$ = this.notesArraySubject.asObservable();

  private equipmentArraySubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(undefined);
  public equipmentArray$ = this.equipmentArraySubject.asObservable();

  private trailerCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public trailerCount$ = this.trailerCountSubject.asObservable();

  private activityWarningsSubject = new BehaviorSubject<any[]>([]);
  public activityWarnings$ = this.activityWarningsSubject.asObservable();

  private timeVarianceOutOfBoundsSubject = new BehaviorSubject<boolean>(false);
  public timeVarianceOutOfBounds$ = this.timeVarianceOutOfBoundsSubject.asObservable();

  private workStandardSubject = new BehaviorSubject<string>(undefined);
  public workStandard$ = this.workStandardSubject.asObservable();

  public badgeSize = 'medium';

  public readonly DsrActivityFormFields = DsrActivityFormFields;
  public readonly NonDriveTimeTypeFormFields = NonDriveTimeTypeFormFields;
  public readonly WarningValidationType = WarningValidationType;

  public hasEquipment = false;
  private timeVarianceSubject = new BehaviorSubject<any>(undefined);
  public timeVariance$ = this.timeVarianceSubject.asObservable();
  public timezoneSic = '';
  isOpen = false;
  private trailerSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(undefined);
  public trailer$ = this.trailerSubject.asObservable();

  private dollySubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(undefined);
  public dolly$ = this.dollySubject.asObservable();

  public currentPayform: DsrPayform;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    public payformService: PayFormService,
    private driverPayService: DriverPayService,
    private changeDetectorRef: ChangeDetectorRef,
    private errorWarningService: ErrorWarningService,
    private config: ConfigManagerService,
    private asyncValidatorCompletion: AsyncValidatorCompletionService,
    public utils: XpoAngularUtilsService,
    private state: PayPortalStateStore,
    private xpoSnackBar: XpoSnackBar
  ) {
    super(formBuilder);
  }

  ngOnInit() {
    this.parentForm.addControl(String(this.activity.actvtySequenceNbr), this.formBuilder.control(this.activity));

    this.readonly$ = this.state.getPayformReadOnlySelector();

    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        this.currentPayform = payform;

        const newNotes = payform.note;
        this.timezoneSic = this.currentPayform?.dmclSic;
        this.notesArraySubject.next((newNotes ?? []).filter((note: Note) => note.noteType === PayformNoteTypeCd.ACTIVITY && +note.noteTypeSequenceNbr === +this.activity.actvtySequenceNbr));
      });

    merge(this.errorWarningService.ActivityWarning$, this.asyncValidatorCompletion.asyncValidationCompleted$)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(() => {
        this.activityWarningsSubject.next(this.getActivityWarnings());
      });

    this.buildEquipment();

    this.updateAfterChange();

    this.updateTimeVariance();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  openEquipment() {
    this.isOpen = !this.isOpen;

    const data = this.equipmentArraySubject.value;

    if (!!data) {
      this.trailerSubject.next(data.filter(x => LoDash.get(x, 'label').startsWith('Trailer')));
      this.dollySubject.next(data.filter(x => LoDash.get(x, 'label').startsWith('Dolly')));
    }
  }

  close() {
    this.isOpen = false;
  }

  activityHasSic(): boolean {
    return !LoDash.isEmpty(this.activity.actvtySicCd);
  }

  handleDifferentScheduleClick(activity: any) {
    const scheduleList = [];
    if (this.currentPayform.linehaulSchedule) {
      let index = 1;
      this.currentPayform.linehaulSchedule.forEach(schedule => {
        if (schedule.listActionCd !== ActionCd.DELETE) {
          const scheduleTitle =
            schedule.originSicCd && AppStateUtils.getDestination(schedule) ? `Schedule ${index} - ${schedule.originSicCd}/${AppStateUtils.getDestination(schedule)}` : `Schedule ${index}`;

          scheduleList.push({
            name: scheduleTitle,
            schSequenceNbr: schedule.schSequenceNbr,
          });
          index++;
        }
      });
    }

    if (activity.schSequenceNbr) {
      scheduleList.push({
        name: 'Non-Schedule Activities',
        schSequenceNbr: null,
      });
    }

    const dsrActivities = this.currentPayform.dsrActivity;
    this.driverPayService
      .showMoveActivityDialog(scheduleList, activity.schSequenceNbr)
      .pipe(take(1))
      .subscribe((targetSchedule: MoveActivityScheduleData) => {
        if (targetSchedule) {
          const newActivities = this.state.moveActivityAction(dsrActivities, activity.actvtySequenceNbr, targetSchedule.schSequenceNbr);
          const updatedState = { ...this.currentPayform, dsrActivity: newActivities };
          this.state.setCurrentPayformAction(updatedState);
          this.parentForm.removeControl(String(activity?.actvtySequenceNbr));
          this.xpoSnackBar.success(SnackbarMessage.ActivityMoved.replace('{0}', activity?.nonDriveTimeType?.actvtyName));
        }
      });
  }

  handleDeleteActivityClick() {
    this.driverPayService
      .showDeleteActivityDialog()
      .pipe(take(1))
      .subscribe(confirmed => {
        if (confirmed) {
          const activities = this.payformService.deleteActivity(this.activity.actvtySequenceNbr);
          const updatedState = { ...this.currentPayform, dsrActivity: activities };
          this.state.setCurrentPayformAction(updatedState);
          this.parentForm.removeControl(String(this.activity.actvtySequenceNbr));
          this.xpoSnackBar.success(SnackbarMessage.ActivityDeleted.replace('{0}', this.activity?.nonDriveTimeType?.actvtyName));
        }
      });
  }

  updateAfterChange() {
    this.hasEquipment = ActivityUtils.requiresEquipment(this.activity.nonDriveTimeType);
  }

  handleEditActivityClick(activity: any) {
    const origNote = LoDash.last(this.notesArraySubject.value);
    this.driverPayService
      .showEditActivityDialog(activity, origNote)
      .pipe(take(1))
      .subscribe((editedActivity: DsrActivity) => {
        // user completed their edit. There are two parts that may have changed:
        // the Activity itself, and the Note associated with it.

        if (editedActivity) {
          const comments = LoDash.get(LoDash.last(editedActivity?.note), 'comments') as string;
          editedActivity.note = undefined;

          if (origNote) {
            // update/delete existing Note
            if (LoDash.isEmpty(comments)) {
              // delete existing note
              this.state.deleteNoteAction(origNote.noteSequenceNbr);
            } else {
              const updatedNote = tassign(origNote, {
                comments,
              });
              this.state.updateNoteAction(updatedNote);
              updatedNote.listActionCd = ActionCd.UPDATE;
              editedActivity.note = [...(editedActivity?.note ?? []), updatedNote];
            }
          } else if (!LoDash.isEmpty(comments)) {
            // no previous note, but we have new comments, so create new note
            const note = this.payformService.createNote(PayformNoteTypeCd.ACTIVITY, editedActivity.actvtySequenceNbr, comments);
            this.state.addNoteAction(note);
            editedActivity.note = [...(editedActivity?.note ?? []), note];
            this.state.updateNoteAction(note);
          }

          try {
            this.currentPayform.dsrActivity.map(act => {
              const note = this.currentPayform.note.find((n: Note) => n.noteType === PayformNoteTypeCd.ACTIVITY && +n.noteTypeSequenceNbr === +act.actvtySequenceNbr);
              if (note) {
                act.note = [...[], note];
              }
            });

            this.state.updateActivityAction(this.currentPayform.dsrActivity, editedActivity);
            this.activity = editedActivity;

            FormUtils.setValue(this.parentForm.get(String(this.activity.actvtySequenceNbr)), editedActivity);
            this.updateAfterChange();
            this.parentForm.updateValueAndValidity();
            this.updateTimeVariance();
            this.buildEquipment();
            this.changeDetectorRef.markForCheck();

            this.reSortActivities.emit(editedActivity);

            this.xpoSnackBar.success(SnackbarMessage.ActivitySaved.replace('{0}', editedActivity?.nonDriveTimeType?.actvtyName));
          } catch (error) {
            this.xpoSnackBar.open({
              message: SnackbarMessage.ActivitySaved.replace('{0}', editedActivity?.nonDriveTimeType?.actvtyName),
              status: 'error',
              matConfig: {
                duration: this.config.getSetting<number>(ConfigManagerProperties.errorToastDuration),
              },
            });
          }
        }
      });
  }

  public getActivityWarnings() {
    const controlsKeys = Object.keys(this.parentForm.controls);
    const activity = this.parentForm.controls[
      controlsKeys.filter(key => (this.parentForm.controls[key].value ? this.parentForm.controls[key].value[DsrActivityFormFields.ActvtySequenceNbr] === this.activity.actvtySequenceNbr : false))[0]
    ];

    if (activity) {
      return activity['warnings'] ? activity['warnings'] : {};
    } else {
      return {};
    }
  }

  public isStartDateTimeInvalid(): boolean {
    return this.hasError('startDateTimeInvalid');
  }

  public isEndDateTimeInvalid(): boolean {
    return this.hasError('endDateTimeInvalid');
  }

  private hasError(errorField: string): boolean {
    let errorExists = false;
    if (this.parentForm.controls) {
      const formGroup = this.parentForm.controls;
      Object.keys(formGroup).forEach(key => {
        const formActivity = formGroup[key].value as DsrActivity;
        if (this.activity.actvtySequenceNbr === formActivity.actvtySequenceNbr) {
          errorExists = formGroup[key].hasError(errorField);
        }
      });
    }

    return errorExists;
  }

  private buildEquipment() {
    const equipments = [];
    const pushItem = (label: string, value: string) => ({
      label,
      value,
    });

    let trailerCount = 0;

    if (this.activity.trlrNbr1) {
      equipments.push(pushItem('Trailer 1', this.activity.trlrNbr1));
      trailerCount++;
    }
    if (this.activity.trlrNbr2) {
      equipments.push(pushItem('Trailer 2', this.activity.trlrNbr2));
      trailerCount++;
    }
    if (this.activity.trlrNbr3) {
      equipments.push(pushItem('Trailer 3', this.activity.trlrNbr3));
      trailerCount++;
    }
    if (this.activity.dollyNbr1) {
      equipments.push(pushItem('Dolly 1', this.activity.dollyNbr1));
    }
    if (this.activity.dollyNbr2) {
      equipments.push(pushItem('Dolly 2', this.activity.dollyNbr2));
    }

    this.equipmentArraySubject.next(equipments);
    this.trailerCountSubject.next(trailerCount);
  }

  private updateTimeVariance() {
    const workStandard = this.getWorkStandard();
    if (workStandard) {
      if (+this.activity.durationInMn < +workStandard.minimumDuration) {
        this.timeVarianceSubject.next(+this.activity.durationInMn - (+workStandard.standardDuration || +workStandard.minimumDuration));
        this.timeVarianceOutOfBoundsSubject.next(true);
      } else if (+this.activity.durationInMn > +workStandard.maxDuration) {
        this.timeVarianceSubject.next(+this.activity.durationInMn - (+workStandard.standardDuration || +workStandard.maxDuration));
        this.timeVarianceOutOfBoundsSubject.next(true);
      } else {
        this.timeVarianceSubject.next(+this.activity.durationInMn - +workStandard.standardDuration);
        this.timeVarianceOutOfBoundsSubject.next(false);
      }
      this.workStandardSubject.next(`Min: ${workStandard.minimumDuration || 'N/A'}, Std: ${workStandard.standardDuration || 'N/A'}, Max: ${workStandard.maxDuration || 'N/A'}`);
    } else {
      this.timeVarianceSubject.next(undefined);
      this.timeVarianceOutOfBoundsSubject.next(false);
      this.workStandardSubject.next('No work standard defined');
    }
  }

  private getWorkStandard(): WorkStandard {
    if (this.hasEquipment) {
      // return work standard for number of trailers we have
      // default to 2 trailers if there are no trailers defined
      const trailers = [this.activity.trlrNbr1, this.activity.trlrNbr2, this.activity.trlrNbr3];
      const reducer = (a, c) => (c ? a + 1 : a);
      const trailerCount = trailers.reduce(reducer, 0);
      // eslint-disable-next-line radix
      const workStd = LoDash.get(this.activity.nonDriveTimeType, 'workStandard', []).find(x => parseInt(x.trlrQuantity) === trailerCount);
      return workStd;
    } else if (
      // no equipment, so return first WorkStandard
      this.activity.nonDriveTimeType &&
      this.activity.nonDriveTimeType.workStandard &&
      this.activity.nonDriveTimeType.workStandard.length > 0
    ) {
      const workStd = LoDash.get(this.activity.nonDriveTimeType, 'workStandard', [])[0];
      return workStd;
    }

    // No work standard
    return undefined;
  }
}
