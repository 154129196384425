import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { takeUntil } from 'rxjs/operators';
import { ScrollbarService } from '../../services/scrollbar/scrollbar.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { AppHeaderService } from '../../services/app-header/app-header.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  public appName: string;
  private isListPayformsVisibleSubject = new BehaviorSubject<boolean>(false);
  public isListPayformsVisible$ = this.isListPayformsVisibleSubject.asObservable();
  private unsubscriber = new Subject<void>();
  private appContainer: Element;

  @ViewChild('navBar')
  navBar: ElementRef;

  constructor(
    private config: ConfigManagerService,
    private scrollbarService: ScrollbarService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private appHeaderService: AppHeaderService
  ) {
    this.appName = config.getSetting(ConfigManagerProperties.appName);
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.unsubscriber)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.isListPayformsVisibleSubject.next(event.url && event.url.startsWith('/view-payform'));
      }
    });
  }

  ngAfterViewInit() {
    this.appContainer = this.document.getElementsByClassName('app-container-content')[0];
    if (this.appContainer) {
      this.appContainer.addEventListener('scroll', this.scrollEvent.bind(this));
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.unsubscriber = undefined;

    if (this.appContainer) {
      this.appContainer.removeEventListener('scroll', this.scrollEvent.bind(this));
    }
  }

  private scrollEvent(event: Event) {
    this.scrollbarService.setScrollbarPosition(this.appContainer.scrollTop);
  }

  listPayformsClicked() {
    this.appHeaderService.listPayformsClicked();
  }
}
