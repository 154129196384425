<div class="activity" *ngIf="(activity?.nonDriveTimeType?.actvtyName | activityName) === 'Arrive' || (activity?.nonDriveTimeType?.actvtyName | activityName) === 'Dispatch'">
  <div class="activity__name">{{ activity?.nonDriveTimeType?.actvtyName | activityName }}</div>

  <div class="activity__sic" [ngClass]="{ 'activity-warning': (activityWarnings$ | async)[WarningValidationType.ActivitySicWarning] }">
    {{ activity?.actvtySicCd }}
  </div>

  <div class="activity__start">
    <div *ngIf="(activity?.nonDriveTimeType?.actvtyName | activityName) === 'Dispatch' && activity?.endDateTimeUtc">{{ activity?.startDateTimeUtc | sicDate: 'HH:mm':timezoneSic | async }}&nbsp;</div>
    <div *ngIf="(activity?.nonDriveTimeType?.actvtyName | activityName) === 'Dispatch' && !activity?.endDateTimeUtc">
      N/A
    </div>
  </div>

  <div class="activity__end">
    <div *ngIf="(activity?.nonDriveTimeType?.actvtyName | activityName) === 'Arrive' && activity?.endDateTimeUtc">{{ activity?.endDateTimeUtc | sicDate: 'HH:mm':timezoneSic | async }}&nbsp;</div>
    <div *ngIf="(activity?.nonDriveTimeType?.actvtyName | activityName) === 'Arrive' && !activity?.endDateTimeUtc">
      N/A
    </div>
  </div>
</div>
<div class="activity" *ngIf="(activity?.nonDriveTimeType?.actvtyName | activityName) !== 'Arrive' && (activity?.nonDriveTimeType?.actvtyName | activityName) !== 'Dispatch'">
  <div class="activity__name">{{ activity?.nonDriveTimeType?.actvtyName | activityName }}</div>

  <div class="activity__sic" [ngClass]="{ 'activity-warning': (activityWarnings$ | async)[WarningValidationType.ActivitySicWarning] }">
    {{ activity?.actvtySicCd }}
  </div>

  <div class="activity__start">
    <div *ngIf="activity?.startDateTimeUtc" [ngClass]="{ 'activity-error': isStartDateTimeInvalid() }">{{ activity?.startDateTimeUtc | sicDate: 'HH:mm':timezoneSic | async }}&nbsp;</div>
    <div *ngIf="!activity?.startDateTimeUtc" [ngClass]="{ 'activity-error-required': true }">
      Required
    </div>
  </div>

  <div class="activity__end">
    <div *ngIf="activity?.endDateTimeUtc" [ngClass]="{ 'activity-error': isEndDateTimeInvalid() }">{{ activity?.endDateTimeUtc | sicDate: 'HH:mm':timezoneSic | async }}&nbsp;</div>
    <div *ngIf="!activity?.endDateTimeUtc" [ngClass]="{ 'activity-error-required': true }">
      Required
    </div>
  </div>

  <div class="activity__altered">
    <mat-icon *ngIf="activity?.manualEntryInd">av_timer</mat-icon>
  </div>

  <div
    class="activity__duration"
    [ngClass]="{ 'activity-warning': (activityWarnings$ | async)[WarningValidationType.ActivityDurationWarning] || (activityWarnings$ | async)[WarningValidationType.MaxDurationWarning] }"
    [matTooltip]="workStandard$ | async"
  >
    {{ activity?.durationInMn }}
    min
  </div>

  <div [ngClass]="{ 'activity__duration-out-of-bounds': timeVarianceOutOfBounds$ | async }" class="activity__variance">
    <span *ngIf="timeVariance$ | async">{{ (timeVariance$ | async) > 0 ? '+' : '' }}{{ timeVariance$ | async }} min</span>
  </div>

  <div class="activity__notes">
    <div *ngFor="let note of notesArray$ | async">{{ note.comments }}</div>
  </div>

  <div class="activity__actions">
    <div #equipmentButton *ngIf="(trailerCount$ | async) > 0">
      <button
        mat-button
        mat-icon-button
        fxLayoutAlign="center center"
        (mouseover)="badgeSize = 'medium'"
        (mouseleave)="badgeSize = 'medium'"
        cdkOverlayOrigin
        (click)="openEquipment()"
        #trigger="cdkOverlayOrigin"
      >
        <mat-icon [matBadge]="trailerCount$ | async" matBadgeColor="primary" [matBadgeSize]="badgeSize">local_shipping</mat-icon>
        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
          <ng-container [ngTemplateOutlet]="statusPopup"></ng-container>
        </ng-template>
      </button>
      <ng-template #statusPopup>
        <div class="activity-equipment-overlay">
          <div fxLayout="column">
            <div class="activity-equipment-overlay__header" fxLayout="row">
              <div fxFlex="" fxLayoutAlign="start center" class="activity-equipment-overlay__header__title">Equipment</div>
              <div fxLayoutAlign="end center" class="activity-equipment-overlay__close">
                <button mat-button mat-icon-button (click)="close()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="activity-equipment-overlay__equipment">
            <div class="row">
              <div class="row__content" *ngFor="let trailer of trailer$ | async">
                <div class="label">{{ trailer.label }}</div>
                <div class="value">{{ trailer.value }}</div>
              </div>
            </div>
            <div class="row">
              <div class="row__content" *ngFor="let dolly of dolly$ | async">
                <div class="label">{{ dolly.label }}</div>
                <div class="value">{{ dolly.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="activity__actions-editable" *ngIf="(readonly$ | async) === false">
      <button mat-button mat-icon-button *ngIf="(readonly$ | async) === false" (click)="handleEditActivityClick(activity)">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-button mat-icon-button fxLayoutAlign="center" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button id="move-activity-button" mat-menu-item (click)="handleDifferentScheduleClick(activity)">Move to Different Schedule</button>
        <button id="delete-activity-button" mat-menu-item (click)="handleDeleteActivityClick()"><span class="activity__content__delete-activity">Delete Activity</span></button>
      </mat-menu>
    </div>
  </div>
</div>
