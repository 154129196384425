import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType } from '../enums';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function eslValidatorFunction(errorWarningService: ErrorWarningService, schSequenceNbr: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !control.parent || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    if (!control.value && FormUtils.hasWarning(control, undefined, WarningValidationType.EslWarning)) {
      FormUtils.unsetWarning(control, WarningValidationType.EslWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
    }

    if (control.value) {
      const origin: string = LoDash.get(control.parent.get(LinehaulScheduleFormFields.OriginSicCd), 'value', '');
      const dest: string = LoDash.get(control.parent.get(LinehaulScheduleFormFields.DestinationSicCd), 'value', '');
      const eslWarning = {
        type: WarningValidationType.EslWarning,
        primarySubject: `${origin.toUpperCase()} -> ${dest.toUpperCase()}`,
      };

      FormUtils.setWarning(control, WarningValidationType.EslWarning, eslWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
    }

    return null;
  };
}

@Directive({
  selector: '[eslValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EslValidator, multi: true }],
})
export class EslValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}
  @Input()
  schSequenceNbr: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return eslValidatorFunction(this.errorWarningService, this.schSequenceNbr)(control);
  }
}
