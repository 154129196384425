import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, Observable, Subscription, timer } from 'rxjs';

@Injectable()
export class ScrollbarService {
  private timer: Observable<number>;
  private subscription: Subscription;
  private appContainerElement: Element;
  private actionButtonsElement: Element;
  private scrollbarStatusSubject = new Subject<boolean>();
  public scrollbarStatus$ = this.scrollbarStatusSubject.asObservable();
  private scrollbarPositionSubject = new Subject<number>();
  public scrollbarPosition$ = this.scrollbarPositionSubject.asObservable();
  private lastScrollbarStatus = undefined;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  startWatcher() {
    if (this.subscription) {
      return;
    } else {
      this.appContainerElement = this.document.getElementsByClassName('app-container-content')[0];
      this.actionButtonsElement = this.document.getElementsByClassName('app-footer-container-row')[0];

      this.timer = timer(0, 500);
      this.subscription = this.timer.subscribe(e => {
        if (this.appContainerElement.clientHeight === this.appContainerElement.scrollHeight) {
          if (this.lastScrollbarStatus !== 'no-scroll') {
            this.scrollbarStatusSubject.next(false);
            this.lastScrollbarStatus = 'no-scroll';
          }
        } else {
          if (this.lastScrollbarStatus !== 'scroll') {
            this.scrollbarStatusSubject.next(true);
            this.lastScrollbarStatus = 'scroll';
          }
        }
      });
    }
  }

  stopWatcher() {
    if (!this.subscription) {
      return;
    } else {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }

    this.scrollbarPositionSubject.unsubscribe();
  }

  setScrollbarPosition(position: number) {
    this.scrollbarPositionSubject.next(position);
  }
}
