<div class="edit-reason-feedback">
  <h2 md-dialog-title>{{title}} Payform Reason</h2>
  <mat-dialog-content class="edit-reason-feedback-content">
    <div class="edit-reason-feedback-content-input"
         fxLayout="row"
         fxLayoutAlign="start start">
      <mat-form-field fxFlex="">
        <textarea matInput
                  matTextareaAutosize
                  matAutosizeMinRows="5"
                  matAutosizeMaxRows="5"
                  maxlength="250"
                  placeholder="Please describe the reason for {{reason}} this payform."
                  [(ngModel)]="feedback"
                  required
                  #feedbackInput="ngModel"></textarea>
        <mat-hint align="end">{{feedbackInput?.value?.length}} / 250</mat-hint>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="edit-reason-feedback-actionbar">
    <button mat-button
            color="primary"
            (click)="cancelClicked()"
            tabindex="-1">Cancel</button>
    <button mat-button
            color="primary"
            (click)="saveClicked()"
            tabindex="-1"
            [disabled]="!feedbackInput.valid">Save</button>
  </mat-dialog-actions>
</div>
