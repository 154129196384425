<div class="payform" fxLayout="column">
  <app-payform-header [parentForm]="form" (editClick)="editClicked()" (expandCollapseClick)="expandCollapseClicked($event)"></app-payform-header>

  <app-errors-warnings *ngIf="errorWarningService.shouldDisplayWarnings()" class="payform-warnings" [isPayform]="true" [warningsCollection]="warningsCollection$ | async"></app-errors-warnings>
  <div *ngIf="!errorWarningService.shouldDisplayWarnings()" class="payform-no-warnings"></div>

  <app-payform-comments class="payform-comments" [parentForm]="form" [expandCollapse]="isExpanded"> </app-payform-comments>
  <div *ngFor="let schNbr of currentSchedules$ | async; let i = index">
    <div [formGroup]="getScheduleFormGroup(schNbr)" class="schedule-container" id="schedule-index-{{ i + 1 }}">
      <app-schedule-details
        id="schedule-details-{{ schNbr }}"
        title="Schedule {{ i + 1 }} Details"
        [schSequenceNbr]="schNbr"
        [parentForm]="getScheduleFormGroup(schNbr)"
        [expandCollapse]="isExpanded"
        [payformGroup]="form"
      ></app-schedule-details>
      <app-schedule-activities
        id="schedule-activities-{{ schNbr }}"
        title="Schedule {{ i + 1 }} Activities"
        [schSequenceNbr]="schNbr"
        [parentForm]="getScheduleFormGroup(schNbr)"
        [expandCollapse]="isExpanded"
      >
      </app-schedule-activities>
    </div>
  </div>

  <app-schedule-activities title="{{ LinehaulScheduleFormFields.NonScheduleActivities }}" *ngIf="showNonScheduleActivities$ | async" [parentForm]="form" [expandCollapse]="isExpanded">
  </app-schedule-activities>

  <app-footer></app-footer>
</div>
