export enum SnackbarMessage {
  PayformApproved = 'Payform Approved.',
  PayformDeleted = 'Payform Deleted.',
  PayformdUndeleted = 'Payform Restored to Submitted.',
  PayformUpdated = 'Payform Updated',
  FeedbackSentToDsr = 'Feedback sent to DSR.',
  NoMoreSubmittedPayforms = 'There are no more payforms to approve at this time.',
  ScheduleAdded = 'Schedule {0} added.',
  ScheduleDeleted = '{0} deleted.',
  ActivityAdded = '{0} activity added.',
  ActivitySaved = '{0} activity saved.',
  ActivityDeleted = '{0} activity deleted.',
  ActivityMoved = '{0} activity moved.',
}
