import { Inject, Injectable } from '@angular/core';
import { PfIntegrationService, SearchScreenDataRequest, SearchScreenDataResponse } from '@xpo-ltl/ngx-presentation-framework';
import { BASE_PATH } from 'app/variables';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchPayformsService extends PfIntegrationService {
  constructor(@Inject(BASE_PATH) basePath: string) {
    super(basePath);
  }

  public getData$(request: SearchScreenDataRequest): Observable<SearchScreenDataResponse> {
    return super.getData$(request);
  }
}
