import { PipeTransform, Pipe } from '@angular/core';
import { AppConstantsService } from '../services/app-constants.service';
import { DsrActivity, NonDriveTimeType } from '@xpo-ltl/sdk-linehaulpayform';
import { PayformPaidTypeCd } from '@xpo-ltl/sdk-common';
import { LoDash } from '../utils/angular-utils/lodash-utils';

@Pipe({
  name: 'paidTimeOrDuration',
})
export class PaidTimeOrDurationPipe implements PipeTransform {
  private paid = 'Pay Time';
  private duration = 'Duration';

  constructor(private constants: AppConstantsService) {}

  transform(input: any): any {
    if (input && LoDash.get(input, 'dsrPayformId', null)) {
      const activity = input as DsrActivity;
      const nonDrvTimeTypeId = LoDash.get(activity, 'nonDriveTimeType.nonDrvTimeTypeId', null);
      if (nonDrvTimeTypeId) {
        const type = this.constants.nonDriveTimeTypes.find(x => x.nonDrvTimeTypeId === nonDrvTimeTypeId);
        return type && type.paidCd === PayformPaidTypeCd.PAID ? this.paid : this.duration;
      }
    } else if (input && LoDash.get(input, 'nonDrvTimeTypeId', null)) {
      return input.paidCd === PayformPaidTypeCd.PAID ? this.paid : this.duration;
    }

    return this.paid;
  }
}
