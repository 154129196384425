import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LinehaulScheduleFormFields } from '../../../../../../enums/linehaul-schedule-form-fields.enum';
import { ErrorWarningService } from '../../../../services/error-warning/error-warning.service';

export class RoutingFormBuilder {
  public static create(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    const formGroup = formBuilder.group({
      [LinehaulScheduleFormFields.DrivingLegId]: [''],
    });
    return formGroup;
  }
}
