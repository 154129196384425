import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApproveDialogResults } from '../../enums';

@Component({
  selector: 'app-approve-reason-feedback',
  templateUrl: './approve-reason-feedback.component.html',
  styleUrls: ['./approve-reason-feedback.component.scss'],
})
export class ApproveReasonFeedbackComponent implements OnInit {
  public feedback = '';

  constructor(private dialogRef: MatDialogRef<ApproveReasonFeedbackComponent>) {}

  ngOnInit() {}

  cancelClicked() {
    this.dialogRef.close();
  }

  approveAndViewPayformsClicked() {
    this.dialogRef.close({ feedback: this.feedback, results: ApproveDialogResults.ApproveAndViewPayforms });
  }

  approveAndNextPayformClicked() {
    this.dialogRef.close({ feedback: this.feedback, results: ApproveDialogResults.ApproveAndNextPayform });
  }
}
