import { Directive, Input } from '@angular/core';
import { UntypedFormArray, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

/**
 * Returns error if more than one instance of a value or a set of values is found in an array.
 *
 * export
 * param {string} key
 * param {(string | string[])} value value or values
 * param {string} alias key used in error message
 * returns {ValidatorFn}
 */
export function xpoOneInstanceValidator(controlKey: string, value: string | string[], alias?: string): ValidatorFn {
  return (formArray: UntypedFormArray): { [key: string]: any } => {
    if (controlKey && value && formArray.value) {
      const errorKey = alias || 'oneInstance';

      if (value instanceof Array) {
        return formArray.value.filter(val =>
          value.some(v => {
            if (val[controlKey] && val[controlKey].toUpperCase) {
              return val[controlKey].toUpperCase() === v.toUpperCase();
            }
          })
        ).length > 1
          ? { [errorKey]: value }
          : null;
      } else {
        // If array has more that one instance of a variable, return error
        return formArray.value.filter(val => {
          if (val[controlKey] && val[controlKey].toUpperCase) {
            return val[controlKey].toUpperCase() === value.toUpperCase();
          }
        }).length > 1
          ? { [errorKey]: value }
          : null;
      }
    }

    // Is valid
    return null;
  };
}

@Directive({
  selector: '[xpoOneInstance]',
  providers: [{ provide: NG_VALIDATORS, useExisting: XpoOneInstanceValidatorDirective, multi: true }],
})
export class XpoOneInstanceValidatorDirective implements Validator {
  @Input() key: string;
  @Input() value: string | string[];

  validate(control: UntypedFormArray): { [key: string]: any } {
    return xpoOneInstanceValidator(this.key, this.value)(control);
  }
}
