<app-notification></app-notification>
<div class="app-container">
  <div class="app-container-stretch-box">
    <app-header></app-header>

    <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="" class="app-container-content">
      <edge-shell [config]="shellMenuConfig">
        <router-outlet appContent></router-outlet>
      </edge-shell>
    </div>
  </div>
</div>
