import { DatePipe, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerService } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SdkEquipmentModule } from '@xpo-ltl-2.0/sdk-equipment';
import { SdkHumanResourceModule } from '@xpo-ltl/sdk-humanresource';
import { SdkLinehaulPayformModule } from '@xpo-ltl/sdk-linehaulpayform';
import { SdkLocationModule } from '@xpo-ltl/sdk-location';
import { LoggingApiService, SdkLoggingModule } from '@xpo-ltl-2.0/sdk-logging';
import { PayrollApiService } from '@xpo-ltl/sdk-payroll';
import { SdkReferenceModule } from '@xpo-ltl/sdk-reference';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ag-grid';
import { AgGridModule } from 'ag-grid-angular';
import { XpoBoardModule } from '@xpo-ltl/ngx-board/core';
import { XpoFiltersModule } from '@xpo-ltl/ngx-board/core';
import { XpoBoardViewsModule } from '@xpo-ltl/ngx-board/core';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-board/ag-grid';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { AppDialogsModule } from './app-dialogs.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { ListPayformsComponent } from './components/list-payforms/list-payforms.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ErrorWarningService, PayFormService } from './components/payform/services';
import { DialogsModule } from './dialogs/dialogs.module';
import { MaterialModule } from './material.module';
import { XpoPipesModule } from './pipes/xpo-pipes.module';
import { PayformResolver } from './resolvers/payform.resolver';
import { EquipmentDetailsCacheService, LocationDetailsCacheService, RoutingInstructionsCacheService, ServiceCenterCacheService } from './services';
import { AppConstantsService } from './services/app-constants.service';
import { AsyncValidatorCompletionService } from './services/async-validator-completion.service';
import { DriverPayService } from './services/driver-pay/driver-pay.service';
import { ScrollbarService } from './services/scrollbar/scrollbar.service';
import { UtilsModule } from './utils/utils.module';
import { ValidatorsModule } from './validators/validators.module';
import { NgIdleModule } from '@ng-idle/core';
import { AppHeaderService } from './services/app-header/app-header.service';
import { XpoDirectivesModule } from './utils/angular-utils/directives';
import { XpoValidatorsModule } from './utils/angular-utils/validators/xpo-validators.module';
import { XpoAngularUtilsModule } from './utils/angular-utils/xpo-angular-utils.module';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { EmailService } from './utils/angular-utils/services/email.service';
import { OpsShellModule } from '@xpo-ltl/ngx-ltl-menu';
import { XpoAuthConfig } from '@xpo/ngx-auth';
import { environment } from 'environments/environment';
import { XpoLtlAuthFirestoreConfig } from '@xpo-ltl/ngx-ltl-auth/firestore';
import { EquipmentApiService } from '@xpo-ltl/sdk-equipment';
import { NgxPresentationFrameworkModule } from '@xpo-ltl/ngx-presentation-framework';
import { BASE_PATH } from './variables';

export function authConfigFactory(configManagerService: ConfigManagerService): XpoAuthConfig {
  return new XpoLtlAuthFirestoreConfig(
    {
      isProd: configManagerService.getSetting('isProd'),
      appCode: environment.auth.appCode,
      scopes: environment.auth.scopes,
    },
    {
      appName: environment.auth.appName,
      apiUri: configManagerService.getSetting('apiUrl'),
      authGrantType: environment.auth.authGrantType,
    },
    {
      firebaseOptions: environment.firebase,
      region: configManagerService.getSetting('region'),
    }
  );
}
@NgModule({
  declarations: [AppComponent, AppHeaderComponent, ListPayformsComponent, NotAuthorizedComponent, NotificationComponent],
  imports: [
    AgGridModule,
    AppDialogsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ConfigManagerModule,
    DialogsModule,
    MatDatepickerModule,
    MaterialModule,
    NgxPresentationFrameworkModule,
    SdkEquipmentModule,
    SdkHumanResourceModule,
    SdkLinehaulPayformModule,
    SdkLocationModule,
    SdkLoggingModule,
    SdkReferenceModule,
    UtilsModule,
    ValidatorsModule,
    XpoAgGridModule,
    XpoFiltersModule,
    XpoAgGridBoardModule,
    XpoBoardModule,
    XpoBoardViewsModule,
    XpoCardModule,
    XpoAngularUtilsModule,
    XpoDirectivesModule,
    XpoPipesModule,
    XpoValidatorsModule,
    XpoSnackBarModule,
    OpsShellModule,
    OpsShellModule.authConfig(authConfigFactory),
    NgIdleModule.forRoot(),
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: BASE_PATH,
      useFactory: (config: ConfigManagerService) => {
        return config.getSetting('apiUrl');
      },
      deps: [ConfigManagerService],
    },
    AppConstantsService,
    AppHeaderService,
    AsyncValidatorCompletionService,
    DataApiService,
    DatePipe,
    DriverPayService,
    EquipmentApiService,
    EquipmentDetailsCacheService,
    LocationDetailsCacheService,
    LoggingApiService,
    NotificationService,
    PayformResolver,
    PayFormService,
    ErrorWarningService,
    PayrollApiService,
    RoutingInstructionsCacheService,
    ScrollbarService,
    ServiceCenterCacheService,
    UpperCasePipe,
    EmailService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
