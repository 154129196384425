import { Directive } from '@angular/core';
import { AbstractControl, Validator, ValidatorFn } from '@angular/forms';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export enum TimeFormatErrors {
  invalidFormat = 'invalidFormat',
}

export function timeFormatValidatorFunction(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!LoDash.isEmpty(control.value)) {
      // validate the control value is in the form "HH:MM",
      // where HH is between [00, 23], and MM is between [00, 59]
      const parts = control.value.split(':');
      // eslint-disable-next-line radix
      const hours = parseInt(parts[0]);
      // eslint-disable-next-line radix
      const minutes = parseInt(parts[1]);

      if (!LoDash.inRange(hours, 24) || !LoDash.inRange(minutes, 60)) {
        // time entered was invalid
        return { [TimeFormatErrors.invalidFormat]: true };
      }
    }
    return null;
  };
}

@Directive({
  selector: '[timeFormatValidator]',
})
export class TimeFormatValidator implements Validator {
  constructor() {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return timeFormatValidatorFunction()(control);
  }
}
