export enum EventKeyCode {
  Delete = 46,
  DeleteSafari = 63272,
  Backspace = 8,
  C = 67,
  V = 86,
  Zero = 48,
  Nine = 57,
  Minus = 189,
  Subtract = 45,
  Enter = 13,
  Tab = 9,
  LeftArrow = 37,
  RightArrow = 39,
}
