import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-errors-warnings',
  templateUrl: './errors-warnings.component.html',
  styleUrls: ['./errors-warnings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsWarningsComponent {
  @Input()
  isPayform = false;
  @Input()
  warningsCollection: any[] = [];
  @Input()
  errorsCollection: any[] = [];

  constructor() {}
}
