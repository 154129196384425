import { NgModule } from '@angular/core';
import { ActivityDurationValidator } from './activity-duration-validator.directive';
import { ActivitySicValidator } from './activity-sic-validator.directive';
import { ActivityWaitTimeValidator } from './activity-wait-time-validator.directive';
import { CushionValidator } from './cushion-validator.directive';
import { DetourValidator } from './detour-validator.directive';
import { DuplicateEquipmentValidator } from './duplicate-equipment-validator.directive';
import { EquipmentCountValidator } from './equipment-count-validator.directive';
import { EquipmentValidator } from './equipment-validator.directive';
import { EslSoloValidator } from './esl-solo-validator.directive';
import { EslValidator } from './esl-validator.directive';
import { HazmatOverrideValidator } from './hazmat-override-validator.directive';
import { HazmatRouteValidator } from './hazmat-route-validator.directive';
import { LoadReleaseValidator } from './load-release-validator.directive';
import { NotGreaterThanDateValidator } from './not-greater-than-date-directive';
import { NotLessThanDateValidator } from './not-less-than-date.directive';
import { OperationTypeValidator } from './operation-type-validator.directive';
import { PayformOriginDestValidator } from './payform-origin-dest-validator.directive';
import { RouteTypeValidator } from './route-type-validator.directive';
import { SicValidator } from './sic-validator.directive';
import { TimeFormatValidator } from './time-format.validator.directive';
import { TotalMileageValidator } from './total-mileage-validator.directive';
import { TripInfoOriginDestValidator } from './trip-info-origin-dest-validator.directive';
import { WarnEmptyValidator } from './warn-empty-validator.directive';
import { ActivityStartEndValidator } from './activity-start-end-validator.directive';
import { MultipleFuelingActivitesValidator } from './multiple-fueling-activities-validator.directive';
import { OneScheduleNoESLNoCushionValidator } from './one-schedule-no-esl-no-cushion=validator.directive';

const validators = [
  ActivityDurationValidator,
  ActivitySicValidator,
  ActivityWaitTimeValidator,
  ActivityStartEndValidator,
  CushionValidator,
  DetourValidator,
  DuplicateEquipmentValidator,
  EquipmentCountValidator,
  EquipmentValidator,
  EslSoloValidator,
  EslValidator,
  HazmatOverrideValidator,
  HazmatRouteValidator,
  LoadReleaseValidator,
  NotGreaterThanDateValidator,
  NotLessThanDateValidator,
  OperationTypeValidator,
  PayformOriginDestValidator,
  RouteTypeValidator,
  SicValidator,
  TimeFormatValidator,
  TotalMileageValidator,
  TripInfoOriginDestValidator,
  WarnEmptyValidator,
  MultipleFuelingActivitesValidator,
  OneScheduleNoESLNoCushionValidator,
];

@NgModule({
  declarations: validators,
  exports: validators,
})
export class ValidatorsModule {}
