import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { FeedbackFormFields } from '../../enums/feedback-form-fields.enum';
import { FeedbackConfig } from './feedback-config';
import { NotificationService, ErrorMessageParser } from '@xpo-ltl/data-api';
import { take, finalize, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { EmailService } from '../../services/email.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Component({
  selector: 'xpo-utils-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) config: FeedbackConfig,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FeedbackComponent>,
    private notificationService: NotificationService,
    private xpoSnackBar: XpoSnackBar,
    private emailService: EmailService
  ) {
    this.feedbackConfig = this.mapConfig(config);
  }

  public feedbackFormFields = FeedbackFormFields;
  public userFeedbackFormGroup: UntypedFormGroup;
  public sendingEmail = false;

  private feedbackConfig: FeedbackConfig;

  ngOnInit() {
    this.initializeForm();
  }

  public hasError(error: string, field: FeedbackFormFields): boolean {
    return this.userFeedbackFormGroup.get(field).hasError(error) && !this.userFeedbackFormGroup.get(field).untouched;
  }

  public cancelClicked() {
    this.dialogRef.close(false);
  }

  public sendClicked() {
    this.sendingEmail = true;

    const formValue = this.userFeedbackFormGroup.getRawValue();
    this.emailService
      .sendEmail(
        formValue[FeedbackFormFields.USER],
        this.feedbackConfig.fromEmail,
        this.feedbackConfig.toEmail,
        formValue[FeedbackFormFields.SUBJECT],
        `${formValue[FeedbackFormFields.MESSAGE]} - ${formValue[FeedbackFormFields.USER]}`
      )
      .pipe(
        take(1),
        catchError(error => {
          this.xpoSnackBar.open({
            message: `${ErrorMessageParser.parseMessage(error) || error}.`,
            status: 'error',
            matConfig: {
              duration: 50000,
            },
          });
          return of(undefined);
        }),
        finalize(() => (this.sendingEmail = false))
      )
      .subscribe((results: any) => {
        if (results) {
          this.dialogRef.close(true);
        }
      });
  }

  private mapConfig(config: FeedbackConfig): FeedbackConfig {
    return {
      disableFromNameInput: config.disableFromNameInput || false,
      disableSubjectInput: config.disableSubjectInput || false,
      fromEmail: config.fromEmail || '',
      fromName: config.fromName || config.fromEmail || '',
      invalidCharacterPattern: config.invalidCharacterPattern || '',
      message: config.message || '',
      subject: config.subject || '',
      title: config.title || 'Feedback',
      toEmail: config.toEmail || '',
    };
  }

  private initializeForm() {
    this.userFeedbackFormGroup = this.formBuilder.group({
      [FeedbackFormFields.USER]: new UntypedFormControl(
        {
          value: this.feedbackConfig.fromName,
          disabled: this.feedbackConfig.disableFromNameInput,
        },
        [Validators.required]
      ),
      [FeedbackFormFields.SUBJECT]: new UntypedFormControl(
        {
          value: this.feedbackConfig.subject,
          disabled: this.feedbackConfig.disableSubjectInput,
        },
        [Validators.required, Validators.pattern(this.feedbackConfig.invalidCharacterPattern)]
      ),
      [FeedbackFormFields.MESSAGE]: new UntypedFormControl(
        {
          value: this.feedbackConfig.message,
          disabled: false,
        },
        [Validators.required, Validators.pattern(this.feedbackConfig.invalidCharacterPattern)]
      ),
    });
  }
}
