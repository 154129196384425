import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, UntypedFormArray } from '@angular/forms';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { WarningValidationType, ScheduleTrailerFormFields } from '../enums';
import { FormUtils } from '../classes';
import { EquipmentErrorTypes } from '../enums/equipment-error-types.enum';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function operationTypeValidatorFunction(errorWarningService: ErrorWarningService, schSequenceNbr: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const equipmentCtrl = control.get(LinehaulScheduleFormFields.EquipmentControl);
    const tripInfoCtrl = control.get(LinehaulScheduleFormFields.TripInfoControl);

    if (!equipmentCtrl || !tripInfoCtrl) {
      return null;
    }

    const originCtrl = tripInfoCtrl.get(LinehaulScheduleFormFields.OriginSicCd);
    const destCtrl = tripInfoCtrl.get(LinehaulScheduleFormFields.DestinationSicCd);

    if (!originCtrl || !destCtrl) {
      return null;
    }

    const cushionInd = LoDash.get(tripInfoCtrl.get(LinehaulScheduleFormFields.CushionInd), 'value', false);
    if (cushionInd) {
      return null;
    }

    const typeOfOperation = LoDash.get(equipmentCtrl.get(LinehaulScheduleFormFields.TypeOfOperation), 'value', undefined);
    const operationCodes = [7, 13, 17];

    if (!typeOfOperation && typeOfOperation !== 0 && originCtrl.valid && destCtrl.valid) {
      return {
        [EquipmentErrorTypes.OperationTypeRequired]: {
          type: EquipmentErrorTypes.OperationTypeRequired,
        },
      };
    }

    if (operationCodes.includes(typeOfOperation)) {
      const origin = LoDash.get(originCtrl, 'value', '');
      const dest = LoDash.get(destCtrl, 'value', '');
      const operationTypeWarning = {
        type: WarningValidationType.OperationTypeWarning,
        primarySubject: `${origin.toUpperCase()} -> ${dest.toUpperCase()}`,
      };
      FormUtils.setWarning(control, WarningValidationType.OperationTypeWarning, operationTypeWarning);
    } else if (!isNaN(typeOfOperation) && typeOfOperation !== '') {
      const trailersControl = equipmentCtrl.get(LinehaulScheduleFormFields.ScheduleTrailer) as UntypedFormArray;
      let trailerCount = 0;
      if (trailersControl) {
        trailersControl.controls.forEach(trailerControl => {
          const trailerValue = trailerControl.get(ScheduleTrailerFormFields.TrlrNbr).value;
          if (trailerValue) {
            trailerCount++;
          }
        });

        const allowedTypes = [[0], [1, 2, 11, 12], [5, 7, 10], [13, 17]];
        if (trailerCount >= 0 && trailerCount <= 3) {
          if (!allowedTypes[trailerCount].includes(typeOfOperation)) {
            return {
              [EquipmentErrorTypes.OperationTypeInvalid]: {
                type: EquipmentErrorTypes.OperationTypeInvalid,
                displayError: true,
              },
            };
          }
        }
      }

      FormUtils.unsetWarning(control, WarningValidationType.OperationTypeWarning);
    }

    errorWarningService.detailWarningsChanged(schSequenceNbr);
    return null;
  };
}

@Directive({
  selector: '[operationTypeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: OperationTypeValidator, multi: true }],
})
export class OperationTypeValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}
  @Input()
  schSequenceNbr: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return operationTypeValidatorFunction(this.errorWarningService, this.schSequenceNbr)(control);
  }
}
