import { Directive, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup, NG_VALIDATORS, Validator, ValidatorFn, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { DsrActivityFormFields } from '../enums/dsr-activity-form-fields.enum';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { NonDriveTimeTypeFormFields } from '../enums/non-drive-time-type-form-fields.enum';
import { ScheduleTrailerFormFields } from '../enums/schedule-trailer-form-fields.enum';
import { WorkStandardFormFields } from '../enums/work-standard-form-fields.enum';
import { AppConstantsService } from '../services/app-constants.service';
import { WarningValidationType } from '../enums/warning-validation-type.enum';
import { PayformFormFields } from '../enums';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function activityDurationValidatorFunction(constants: AppConstantsService, errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      return null;
    }

    const activitiesArr = linehaulSchedules.controls.reduce((payformArr, schedule) => {
      const activitiesCtrl = schedule.get(LinehaulScheduleFormFields.Activities) as UntypedFormGroup;
      if (activitiesCtrl) {
        payformArr.push(...LoDash.values(activitiesCtrl.controls));
      }
      return payformArr;
    }, []);

    // Get non schedule activities if present
    const nonSchedActivities = control.get(LinehaulScheduleFormFields.Activities) as UntypedFormArray;
    if (nonSchedActivities) {
      activitiesArr.push(...LoDash.values(nonSchedActivities.controls));
    }

    (activitiesArr ?? []).forEach((activityControl: UntypedFormControl) => {
      const duration = LoDash.get(activityControl.value, DsrActivityFormFields.DurationInMn, 0);
      const nonDriveTimeType = LoDash.get(activityControl.value, DsrActivityFormFields.NonDriveTimeType, undefined);
      const typeId = nonDriveTimeType ? nonDriveTimeType[NonDriveTimeTypeFormFields.NonDrvTimeTypeId] : undefined;
      const activityName = nonDriveTimeType ? nonDriveTimeType[NonDriveTimeTypeFormFields.ActvtyName] : undefined;
      const trailerCount = returnActivityTrailerCount(activityControl);
      const activitySchSequenceNbr = LoDash.get(activityControl, LinehaulScheduleFormFields.SchSequenceNbr, undefined);

      FormUtils.unsetWarning(activityControl, WarningValidationType.MaxDurationWarning);
      FormUtils.unsetWarning(activityControl, WarningValidationType.ActivityDurationWarning);

      const activityDurationWarning = {
        type: WarningValidationType.ActivityDurationWarning,
        primarySubject: activityName,
        secondarySubject: 'totalCount',
      };
      const maxDurationWarning = {
        type: WarningValidationType.MaxDurationWarning,
        primarySubject: activityName,
      };

      const nonDriveTimeTypeObj = constants?.nonDriveTimeTypes ? constants?.nonDriveTimeTypes.find(activityType => activityType[NonDriveTimeTypeFormFields.NonDrvTimeTypeId] === typeId) : null;

      if (!!nonDriveTimeTypeObj) {
        const workStandardCollection = LoDash.get(nonDriveTimeTypeObj, NonDriveTimeTypeFormFields.WorkStandard, []);
        let workStandard;
        if (workStandardCollection.length === 1) {
          workStandard = LoDash.get(workStandardCollection[0], WorkStandardFormFields.MaxDuration, undefined);
        } else if (workStandardCollection.length > 1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          workStandard = trailerCount > 0 ? returnWorkStandard(workStandardCollection, trailerCount) : returnWorkStandard(workStandardCollection, 2);
        }

        if (!workStandard && duration) {
          if (duration > 60) {
            FormUtils.setWarning(activityControl, WarningValidationType.MaxDurationWarning, maxDurationWarning);
          }
        }
      }
      errorWarningService.activityWarningsChanged(activitySchSequenceNbr);
    });

    return null;
  };
}

function returnActivityTrailerCount(control: AbstractControl) {
  const trlrNbrs = [DsrActivityFormFields.TrlrNbr1, DsrActivityFormFields.TrlrNbr2, DsrActivityFormFields.TrlrNbr3];
  const trailerCount = trlrNbrs.reduce((totalCount, trlrNbr) => (LoDash.get(control.value, trlrNbr, undefined) ? totalCount + 1 : totalCount), 0);
  return trailerCount;
}

function returnWorkStandard(workStandardCollection, trailerCount) {
  const workStandard = workStandardCollection.map(workStd => workStd[WorkStandardFormFields.MaxDuration]).sort((a, b) => a - b);
  return workStandard[trailerCount - 1];
}

@Directive({
  selector: '[activityDurationValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ActivityDurationValidator, multi: true }],
})
export class ActivityDurationValidator implements Validator {
  constructor(private constants: AppConstantsService, private errorWarningService: ErrorWarningService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return activityDurationValidatorFunction(this.constants, this.errorWarningService)(control);
  }
}
