import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-return-reason-feedback',
  templateUrl: './return-reason-feedback.component.html',
  styleUrls: ['./return-reason-feedback.component.scss'],
})
export class ReturnReasonFeedbackComponent implements OnInit {
  public feedback = '';

  constructor(private dialogRef: MatDialogRef<ReturnReasonFeedbackComponent>) {}

  ngOnInit() {}

  cancelClicked() {
    this.dialogRef.close();
  }

  returnClicked() {
    this.dialogRef.close(this.feedback);
  }
}
