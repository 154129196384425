export enum DsrActivityFormFields {
  DsrPayformId = 'dsrPayformId',
  ActvtySequenceNbr = 'actvtySequenceNbr',
  ActvtySicCd = 'actvtySicCd',
  StartDateTime = 'startDateTime',
  EndDateTime = 'endDateTime',
  DurationInMn = 'durationInMn',
  ReviewScr = 'reviewScr',
  ManualEntryInd = 'manualEntryInd',
  StatusCd = 'statusCd',
  AprvngSupvsrId = 'aprvngSupvsrId',
  AprvlDateTime = 'aprvlDateTime',
  LatitudeNbr = 'latitudeNbr',
  LongitudeNbr = 'longitudeNbr',
  SchSequenceNbr = 'schSequenceNbr',
  ListActionCd = 'listActionCd',
  AuditInfo = 'auditInfo',
  TrlrNbr1 = 'trlrNbr1',
  TrlrNbr2 = 'trlrNbr2',
  TrlrNbr3 = 'trlrNbr3',
  DollyNbr1 = 'dollyNbr1',
  DollyNbr2 = 'dollyNbr2',
  NonDriveTimeType = 'nonDriveTimeType',
  Note = 'note'
}
