<div class="equipment"
     [ngClass]="{'equipment-readonly': readonly }"
     fxFlex="">
  <form [formGroup]="form"
        novalidate>
    <div *ngIf="readonly" fxLayout="column">
      <h3 class="equipment-label-readonly">Equipment</h3>
      <div fxLayoutAlign="space-between"
           fxLayout="row">
        <div fxLayout="column"
             fxFlex="33">
          <div class="equipment-readonly-label"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(form, LinehaulScheduleFormFields.TractorNbr),
                           'equipment-error-field': tractorControl()?.errors}"
            >Tractor</div>
          <div class="equipment-readonly-data"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(form, LinehaulScheduleFormFields.TractorNbr),
                           'equipment-error-field': tractorControl()?.errors}"
            >{{(schedule$ | async)?.tractorNbr}}</div>
        </div>
        <div fxLayout="column"
             fxFlex="33">
          <div class="equipment-readonly-label">Empty</div>
          <div class="equipment-readonly-data">{{hasEmpty()?'YES':'NO'}}</div>
        </div>
        <div fxLayout="column"
             fxFlex="34">
          <div class="equipment-readonly-label"
          [ngClass]="{'equipment-warning-color': (errorWarningService.hasWarning(warningsCollection, WarningValidationType.HazmatOverrideWarning) ||
                                                  errorWarningService.hasWarning(warningsCollection, WarningValidationType.HazmatRouteWarning))}"
            >Hazmat</div>
          <div class="equipment-readonly-data"
               [ngClass]="{'equipment-warning-color': (errorWarningService.hasWarning(warningsCollection, WarningValidationType.HazmatOverrideWarning) ||
                                                       errorWarningService.hasWarning(warningsCollection, WarningValidationType.HazmatRouteWarning))}"
          >{{hasHazmat()?'YES':'NO'}}</div>
        </div>
      </div>
      <div fxLayout="row wrap">
        <div fxLayout="column"
             fxFlex="33"
             *ngIf="AppStateUtils.trailerFromSchedule(schedule, 1)">
          <div class="equipment-readonly-label"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(trailerFormGroup(1), ScheduleTrailerFormFields.TrlrNbr),
                           'equipment-error-field': trailerFormControl(1, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
          >Trailer 1</div>
          <div class="equipment-readonly-data"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(trailerFormGroup(1), ScheduleTrailerFormFields.TrlrNbr),
                           'equipment-error-field': trailerFormControl(1, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
          >{{AppStateUtils.trailerFromSchedule(schedule, 1).trlrNbr | equipment}}</div>
        </div>
        <div fxLayout="column"
             fxFlex="33"
             *ngIf="AppStateUtils.trailerFromSchedule(schedule, 2)">
          <div class="equipment-readonly-label"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(trailerFormGroup(2), ScheduleTrailerFormFields.TrlrNbr),
                           'equipment-error-field': trailerFormControl(2, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >Trailer 2</div>
          <div class="equipment-readonly-data"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(trailerFormGroup(2), ScheduleTrailerFormFields.TrlrNbr),
                           'equipment-error-field': trailerFormControl(2, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >{{AppStateUtils.trailerFromSchedule(schedule, 2).trlrNbr | equipment}}</div>
        </div>
        <div fxLayout="column"
             fxFlex="34"
             *ngIf="AppStateUtils.trailerFromSchedule(schedule, 3)">
          <div class="equipment-readonly-label"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(trailerFormGroup(3), ScheduleTrailerFormFields.TrlrNbr),
                           'equipment-error-field': trailerFormControl(3, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >Trailer 3</div>
          <div class="equipment-readonly-data"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(trailerFormGroup(3), ScheduleTrailerFormFields.TrlrNbr),
                           'equipment-error-field': trailerFormControl(3, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >{{AppStateUtils.trailerFromSchedule(schedule, 3).trlrNbr | equipment}}</div>
        </div>
        <div fxLayout="column"
             fxFlex="33"
             *ngIf="(schedule$ | async)?.dollyNbr1">
          <div class="equipment-readonly-label"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(form, LinehaulScheduleFormFields.DollyNbr1),
                           'equipment-error-field': dollyControl(1, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >Dolly 1</div>
          <div class="equipment-readonly-data"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(form, LinehaulScheduleFormFields.DollyNbr1),
                           'equipment-error-field': dollyControl(1, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >{{(schedule$ | async)?.dollyNbr1 | equipment}}</div>
        </div>
        <div fxLayout="column"
             fxFlex="33"
             *ngIf="(schedule$ | async)?.dollyNbr2">
          <div class="equipment-readonly-label"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(form, LinehaulScheduleFormFields.DollyNbr2),
                           'equipment-error-field': dollyControl(2, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >Dolly 2</div>
          <div class="equipment-readonly-data"
               [ngClass]="{'equipment-warning-color': FormUtils.hasWarnings(form, LinehaulScheduleFormFields.DollyNbr2),
                           'equipment-error-field': dollyControl(2, ScheduleTrailerFormFields.TrlrNbr)?.errors}"
            >{{(schedule$ | async)?.dollyNbr2 | equipment}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="!readonly" fxLayout="column">
      <h3 class="equipment-label">Equipment</h3>
      <div class="equipment-content"
           fxLayout="column">
        <!-- First Row : Tractor / Trailer 1 / Load Relase 1 -->
        <div fxLayout="row"
             fxLayoutAlign="space-between center">
          <mat-form-field class="equipment-content-tractor"
                          [ngClass]="{'warning-section': !FormUtils.hasErrors(form, LinehaulScheduleFormFields.TractorNbr) &&
                                                         FormUtils.hasWarnings(form, LinehaulScheduleFormFields.TractorNbr)}">
            <input matInput
                   type="text"
                   autocomplete="off"
                   placeholder="Tractor"
                   [xpoAllowCharacters]="ValidationRegexPatterns.tractorInputValidatorPattern | toRegex"
                   maxlength="8"
                   (blur)="tractorChange()"
                   [value]="tractorControl().value | equipment"
                   [formControl]="tractorControl()">
            <mat-error>
              <div *ngIf="tractorControl().hasError('required')">Required</div>
              <div *ngIf="tractorControl().hasError('invalidFormat')">Invalid Format</div>
              <div *ngIf="tractorControl().hasError('equipmentRequired')">Tractor Required</div>
              <div *ngIf="tractorControl().hasError('duplicateEquipment')">Duplicate Equipment</div>
            </mat-error>
            <div *ngIf="!FormUtils.hasErrors(form, LinehaulScheduleFormFields.TractorNbr) && FormUtils.hasWarnings(form, LinehaulScheduleFormFields.TractorNbr)"
                 class="equipment-warning">
              <div *ngIf="FormUtils.hasWarning(form, LinehaulScheduleFormFields.TractorNbr, WarningValidationType.EquipmentNotFound)">Tractor not found</div>
              <div *ngIf="FormUtils.hasWarning(form, LinehaulScheduleFormFields.TractorNbr, WarningValidationType.EquipmentMismatch)">Not a tractor</div>
            </div>
          </mat-form-field>
          <ng-container [ngTemplateOutlet]="trailerField" [ngTemplateOutletContext]="{displayIndex: 1}"></ng-container>
          <ng-container [ngTemplateOutlet]="loadReleaseField" [ngTemplateOutletContext]="{displayIndex: 1}"></ng-container>
          <mat-checkbox [ngClass]="{'equipment-warning-checkbox': checkTrailerHazmatWarning(WarningValidationType.HazmatRouteWarning, 1)}"
                        class="trailer-1-hazmat"
                        [formControl]="trailerFormControl(1, ScheduleTrailerFormFields.TrlrHazardousInd)">Hazmat</mat-checkbox>
          <mat-checkbox class="trailer-1-empty" [formControl]="trailerFormControl(1, ScheduleTrailerFormFields.TrlrEmtyInd)">Empty</mat-checkbox>
        </div>

        <!-- Second Row : Trailer 2 / Dolly 1 / Load Release 2 -->
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <ng-container [ngTemplateOutlet]="dollyField" [ngTemplateOutletContext]="{displayIndex: 1}"></ng-container>
          <ng-container [ngTemplateOutlet]="trailerField" [ngTemplateOutletContext]="{displayIndex: 2}"></ng-container>
          <ng-container [ngTemplateOutlet]="loadReleaseField" [ngTemplateOutletContext]="{displayIndex: 2}"></ng-container>
          <mat-checkbox [ngClass]="{'equipment-warning-checkbox': checkTrailerHazmatWarning(WarningValidationType.HazmatRouteWarning, 2)}"
                        class="trailer-2-hazmat"
                        [formControl]="trailerFormControl(2, ScheduleTrailerFormFields.TrlrHazardousInd)">Hazmat</mat-checkbox>
          <mat-checkbox class="trailer-2-empty" [formControl]="trailerFormControl(2, ScheduleTrailerFormFields.TrlrEmtyInd)">Empty</mat-checkbox>
        </div>

        <!-- Third Row : Trailer 3 / Dolly 2 / Load Release 3 -->
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container [ngTemplateOutlet]="dollyField" [ngTemplateOutletContext]="{displayIndex: 2}"></ng-container>
            <ng-container [ngTemplateOutlet]="trailerField" [ngTemplateOutletContext]="{displayIndex: 3}"></ng-container>
            <ng-container [ngTemplateOutlet]="loadReleaseField" [ngTemplateOutletContext]="{displayIndex: 3}"></ng-container>
            <mat-checkbox [ngClass]="{'equipment-warning-checkbox': checkTrailerHazmatWarning(WarningValidationType.HazmatRouteWarning, 3)}"
                          class="trailer-3-hazmat"
                          [formControl]="trailerFormControl(3, ScheduleTrailerFormFields.TrlrHazardousInd)">Hazmat</mat-checkbox>
            <mat-checkbox class="trailer-3-empty" [formControl]="trailerFormControl(3, ScheduleTrailerFormFields.TrlrEmtyInd)">Empty</mat-checkbox>
          </div>
      </div>

      <!-- Type of Operation -->
      <div fxLayout="row"
           fxLayoutAlign="space-between center"
           class="equipment-content-type-of-operation">
        <div fxLayout="column"
             fxLayoutAlign="start start">
          <div class="equipment-content-type-of-operation__label">Type of Operation</div>
          <div [ngClass]="{'equipment-warning-color': errorWarningService.hasWarning(warningsCollection, WarningValidationType.OperationTypeWarning),
                           'equipment-error-field': FormUtils.hasError(parentForm.parent, LinehaulScheduleFormFields.Details, EquipmentErrorTypes.OperationTypeInvalid) }"
              class="equipment-content-type-of-operation__value">{{form.get(LinehaulScheduleFormFields.TypeOfOperation).value | typeOfOperation}}</div>
          <div class="equipment-error-field" *ngIf="FormUtils.hasError(parentForm.parent, LinehaulScheduleFormFields.Details, EquipmentErrorTypes.OperationTypeRequired)">Required</div>
        </div>
        <button mat-button
                mat-icon-button
                (click)="handleEditTypeOfOperationClicked()">
          <mat-icon>create</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>

<!-- Templates for Equipment editing -->
<ng-template #trailerField let-displayIndex="displayIndex">
    <mat-form-field class="equipment-content-trailer trailer-{{displayIndex}}"
                    [ngClass]="{'warning-section': !FormUtils.hasErrors(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr) &&
                                                   FormUtils.hasWarnings(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr) &&
                                                   !FormUtils.hasError(parentForm, 'EquipmentControl', EquipmentErrorTypes.OneTractorTwoDollysNoTrailer) &&
                                                   !hasDuplicateTrailerError(trailerFormControl(displayIndex, ScheduleTrailerFormFields.TrlrNbr))}">
      <input matInput
             type="text"
             autocomplete="off"
             placeholder="Trailer {{displayIndex}}"
             [xpoAllowCharacters]="ValidationRegexPatterns.trailerInputValidatorPattern | toRegex"
             maxlength="11"
             (input)="trlrNbrUpdate(displayIndex)"
             (blur)="trlrNbrChange(displayIndex)"
             [errorStateMatcher]="trailerErrorMatcher"
             [value]="trailerFormControl(displayIndex, ScheduleTrailerFormFields.TrlrNbr).value | equipment"
             [formControl]="trailerFormControl(displayIndex, ScheduleTrailerFormFields.TrlrNbr)">
      <mat-error>
        <div *ngIf="FormUtils.hasError(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr, 'invalidFormat')">Invalid Format</div>
        <div *ngIf="hasDuplicateTrailerError(trailerFormControl(displayIndex, ScheduleTrailerFormFields.TrlrNbr)) && !FormUtils.hasError(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr, 'invalidFormat')">Duplicate Equipment</div>
        <div *ngIf="FormUtils.hasError(parentForm, LinehaulScheduleFormFields.EquipmentControl, EquipmentErrorTypes.OneTractorTwoDollysNoTrailer)">A Trailer Is Required</div>
        <div *ngIf="FormUtils.hasError(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr, EquipmentErrorTypes.NoTrailerProvided)">Required</div>
      </mat-error>
      <div *ngIf="!FormUtils.hasErrors(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr) &&
                  FormUtils.hasWarnings(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr) &&
                  !FormUtils.hasError(parentForm, 'EquipmentControl', EquipmentErrorTypes.OneTractorTwoDollysNoTrailer)"
           class="equipment-warning">
        <div *ngIf="FormUtils.hasWarning(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr, WarningValidationType.EquipmentNotFound)">
          Equipment Not Found
        </div>
        <div *ngIf="FormUtils.hasWarning(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.TrlrNbr, WarningValidationType.EquipmentMismatch)">Not a trailer</div>
      </div>
    </mat-form-field>
</ng-template>

<ng-template #loadReleaseField let-displayIndex="displayIndex">
  <mat-form-field class="mat-form-field equipment-content-load-release trailer-{{displayIndex}}-load-release">
    <input matInput
          type="text"
          autocomplete="off"
          placeholder="Load Release #"
          [xpoAllowCharacters]="ValidationRegexPatterns.loadReleaseNumberInputValidatorPattern | toRegex"
          maxlength="7"
          (blur)="loadedRlseNbrChange(displayIndex)"
          [errorStateMatcher]='loadReleaseErrorMatcher'
          [value]="trailerFormControl(displayIndex, ScheduleTrailerFormFields.LoadedRlseNbr).value || ''"
          [formControl]="trailerFormControl(displayIndex, ScheduleTrailerFormFields.LoadedRlseNbr)">
    <mat-error>
      <div *ngIf="FormUtils.hasError(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.LoadedRlseNbr, EquipmentErrorTypes.InvalidLoadReleaseFormat)">Invalid Format</div>
      <div *ngIf="FormUtils.hasError(trailerFormGroup(displayIndex), ScheduleTrailerFormFields.LoadedRlseNbr, EquipmentErrorTypes.LoadReleaseRequired)">Required</div>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #dollyField let-displayIndex="displayIndex">
    <mat-form-field class="equipment-content-dolly dolly-{{displayIndex}}"
                    [ngClass]="{'warning-section': !dollyControl(displayIndex)['errors'] &&
                                                   dollyControl(displayIndex)['warnings'] &&
                                                   Object.keys(dollyControl(displayIndex)['warnings']).length &&
                                                   !(
                                                    FormUtils.hasError(parentForm, 'EquipmentControl', EquipmentErrorTypes.OneDollyRequiredWithTwoTrailers) ||
                                                    FormUtils.hasError(parentForm, 'EquipmentControl', EquipmentErrorTypes.TwoDollysRequiredWithTrailers)
                                                   )}">
      <input matInput
             type="text"
             autocomplete="off"
             placeholder="Dolly {{displayIndex}}"
             [xpoAllowCharacters]="ValidationRegexPatterns.dollyInputValidatorPattern | toRegex"
             maxlength="8"
             (blur)="dollyChange(displayIndex)"
             [value]="dollyControl(displayIndex).value | equipment"
             [errorStateMatcher]="displayIndex === 1 ? dolly1ErrorMatcher : dolly2ErrorMatcher"
             [formControl]="dollyControl(displayIndex)">
      <mat-error>
        <div *ngIf="dollyControl(displayIndex).hasError('invalidFormat')">Invalid Format</div>
        <div *ngIf="FormUtils.hasError(parentForm, 'EquipmentControl', EquipmentErrorTypes.DuplicateDolly)&&
                    !dollyControl(displayIndex).hasError('invalidFormat')">Duplicate Equipment</div>
        <div *ngIf="dollyControl(displayIndex).hasError('required')">Required</div>
        <div *ngIf="(displayIndex === 1 && FormUtils.hasError(parentForm, 'EquipmentControl', EquipmentErrorTypes.OneDollyRequiredWithTwoTrailers) ||
                    FormUtils.hasError(parentForm, 'EquipmentControl', EquipmentErrorTypes.TwoDollysRequiredWithThreeTrailers))">Dolly Is Required</div>
      </mat-error>
      <div *ngIf="!dollyControl(displayIndex).errors &&
                  dollyControl(displayIndex)['warnings'] && !(
                    FormUtils.hasError(parentForm, EquipmentErrorTypes.OneDollyRequiredWithTwoTrailers) &&
                    FormUtils.hasError(parentForm, EquipmentErrorTypes.TwoDollysRequiredWithTrailers)
                  )"
           class="equipment-warning">
        <div *ngIf="dollyControl(displayIndex)['warnings'][WarningValidationType.EquipmentNotFound]">Dolly not found</div>
        <div *ngIf="dollyControl(displayIndex)['warnings'][WarningValidationType.EquipmentMismatch]">Not a dolly</div>
      </div>
    </mat-form-field>
</ng-template>
