import { Injectable } from '@angular/core';
import { User } from '@xpo-ltl/sdk-common';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { BehaviorSubject } from 'rxjs';
import { AccessLevel } from '../enums/access-level.enum';
import { LinehaulPayformApiService, NonDriveTimeType } from '@xpo-ltl/sdk-linehaulpayform';
import { take, retryWhen, delay } from 'rxjs/operators';
import { LoDash } from '../utils/angular-utils/lodash-utils';

@Injectable()
export class AppConstantsService {
  public user: User;
  public nonDriveTimeTypes: NonDriveTimeType[];

  private accessLevelSubject = new BehaviorSubject<AccessLevel>(AccessLevel.None);
  public accessLevel$ = this.accessLevelSubject.asObservable();

  private isProduction = false;

  constructor(private config: ConfigManagerService, private linehaulService: LinehaulPayformApiService) {
    this.isProduction = config.getSetting<boolean>(ConfigManagerProperties.production);

    this.buildActivityList();
  }

  public static isUUID(val: string): boolean {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(val);
  }

  public isAthorizedUser(user: User): boolean {
    if (!user) {
      return false;
    }

    const fullAccessRole = this.isProduction ? 'LTL_LNH_PAYFORM_FULL' : 'TST_LTL_LNH_PAYFORM_FULL';
    const readOnlyRole = this.isProduction ? 'LTL_LNH_PAYFORM_READ_ONLY' : 'TST_LTL_LNH_PAYFORM_READ_ONLY';

    let accessLevel = AccessLevel.None;

    if (user.roles.some(role => role.toUpperCase().indexOf(fullAccessRole) >= 0)) {
      accessLevel = AccessLevel.FullAccess;
    } else if (user.roles.some(role => role.toUpperCase().indexOf(readOnlyRole) >= 0)) {
      accessLevel = AccessLevel.ReadOnly;
    }

    this.accessLevelSubject.next(accessLevel);

    return (accessLevel as number) !== AccessLevel.None;
  }

  public get hasFullAccess(): boolean {
    return this.accessLevelSubject.value === AccessLevel.FullAccess;
  }

  public get MaxSchedules(): number {
    return 8;
  }

  public get MaxActivities(): number {
    return 20;
  }

  public get NewPayformId(): number {
    return -1;
  }

  public get MaxMileage(): number {
    return 640;
  }

  public get standardRoute(): string {
    return '01';
  }

  public get programId(): string {
    return 'LinehaulPayformApp';
  }

  private buildActivityList() {
    this.linehaulService
      .listLinehaulNonDriveTimeTypes({ toastOnError: false })
      .pipe(retryWhen(errors => errors.pipe(delay(1000), take(10))))
      .subscribe(response => {
        this.nonDriveTimeTypes = response && response.nonDriveTimeType ? LoDash.sortBy(response.nonDriveTimeType, item => item?.actvtyName) : [];
      });
  }
}
