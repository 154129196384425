import { Observable } from 'rxjs';
import { delay, finalize, retryWhen, scan, shareReplay } from 'rxjs/operators';

export abstract class BaseCacheService<PType, CType> {
  static readonly MAX_RETRIES = 5;
  static readonly RETRY_DELAY = 500;

  private cache: Map<string, Observable<CType>> = new Map<string, Observable<CType>>();

  constructor(protected maxRetries = BaseCacheService.MAX_RETRIES, protected retryDelay = BaseCacheService.RETRY_DELAY) {}

  protected abstract getKeyFromParams(params: PType): string;
  protected abstract requestData(params: PType): Observable<CType>;

  public request(params: PType): Observable<CType> {
    const key = this.getKeyFromParams(params);
    if (!this.cache.has(key)) {
      const result = Observable.create(observer => {
        this.requestData(params)
          .pipe(
            retryWhen(errors =>
              errors.pipe(
                delay(this.retryDelay),
                scan((tries, err) => {
                  if (tries >= this.maxRetries) {
                    throw err;
                  }
                  return tries + 1;
                }, 0)
              )
            ),
            finalize(() => {
              observer.complete();
            })
          )
          .subscribe(
            data => {
              observer.next(data);
            },
            err => {
              console.error(`Cache failed to get ${JSON.stringify(params)} with error: ${err.error.errorCode}: ${err.error.message}`);
              observer.next(undefined);
            }
          );
      }).pipe(shareReplay(1));
      this.cache.set(key, result);
    }
    return this.cache.get(key);
  }

  public clear(): void {
    this.cache = new Map<string, Observable<CType>>();
  }
}
