import { NonDriveTimeType } from '@xpo-ltl/sdk-linehaulpayform';
enum ActivityNames {
  HOOK = 'HOOK',
  DROP = 'DROP',
}

export class ActivityUtils {
  /**
   * Returns true if the activity requires equipment (trailers/dollys), else false.
   * @param activity Activity to check
   */
  public static requiresEquipment(nonDriveTime: NonDriveTimeType): boolean {
    const actvtyName = nonDriveTime?.actvtyName ?? ''.toUpperCase();
    return actvtyName === ActivityNames.HOOK || actvtyName === ActivityNames.DROP;
  }
}
