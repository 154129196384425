import { PayformStatusCd } from '@xpo-ltl/sdk-common';
import { Moment } from 'moment';

export class PayformsFilter {
  public sicCd: string;
  public employeeName: string;
  public fromDate: Moment;
  public toDate: Moment;
  public status: PayformStatusCd[];
}
