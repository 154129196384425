<div class="detour" [ngClass]="{ 'detour-readonly': readonly }" fxFlex="">
  <div [formGroup]="form" novalidate>
    <div class="detour-content" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3 [ngClass]="{ 'detour-has-warning': errorWarningService.hasWarning(warningsCollection, WarningValidationType.DetourWarning) }">Detour</h3>
        <div fxLayout="row" fxLayoutAlign="end center">
          <mat-slide-toggle class="detour__toggle" *ngIf="!readonly" [formControlName]="LinehaulScheduleFormFields.DetourInd" (change)="toggleChange()"></mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="readonly">
        <div *ngIf="detourEnabled; else detourDisabled">
          <div class="detour-readonly-label" [ngClass]="{ 'detour-has-error': utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrHwUsed, 'required') }">
            Highways or roads used
          </div>
          <div class="detour-readonly-data">{{ (detour$ | async)?.dtrHwUsed }}</div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="detour-content-states-and-miles">
            <div fxLayout="column">
              <div class="detour-readonly-label" [ngClass]="{ 'detour-has-error': utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrState, 'required') }">States</div>
              <div class="detour-readonly-data detour-state">{{ (detour$ | async)?.dtrState }}</div>
            </div>
            <div fxLayout="column">
              <div
                class="detour-readonly-label"
                [ngClass]="{
                  'detour-has-error':
                    utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrTotalExMil, 'required') ||
                    utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrTotalExMil, RouteDetourErrorType.TotalMilesLessThanZero)
                }"
              >
                Extra Miles
              </div>
              <div class="detour-readonly-data">{{ (detour$ | async)?.dtrTotalExMil }}</div>
            </div>
          </div>
        </div>
        <ng-template #detourDisabled>
          <div class="detour-content__not-present">No detour present</div>
        </ng-template>
      </div>
      <div *ngIf="!readonly">
        <div *ngIf="detourEnabled" [formGroup]="fieldsGroup">
          <mat-form-field class="detour-content-highways">
            <input matInput type="text" maxlength="500" autocomplete="off" placeholder="Highways or roads used" (blur)="onBlur()" [formControlName]="ScheduleDetourFormFields.DtrHwUsed" />
            <mat-hint>Seperate with commas</mat-hint>
            <mat-error> <div *ngIf="utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrHwUsed, 'required')">Required</div> </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" maxlength="30" autocomplete="off" placeholder="States" (blur)="onBlur()" [formControlName]="ScheduleDetourFormFields.DtrState" />
            <mat-hint>Ex. CA, OR, WA</mat-hint>
            <mat-error> <div *ngIf="utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrState, 'required')">Required</div> </mat-error>
          </mat-form-field>
          <mat-form-field class="detour-content-states">
            <input
              class="detour-state"
              #detourTotalMiles
              matInput
              type="text"
              maxlength="4"
              autocomplete="off"
              placeholder="Total Extra Miles"
              (blur)="onBlur()"
              (keydown)="totalMilesKeydown($event)"
              [xpoAllowCharacters]="totalMilesExpression"
              [formControlName]="ScheduleDetourFormFields.DtrTotalExMil"
            />
            <mat-error>
              <div *ngIf="utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrTotalExMil, 'required')">Required</div>
              <div *ngIf="utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrTotalExMil, 'invalid')">Invalid</div>
              <div *ngIf="utils.isShowingErrorsForFormControl(fieldsGroup, ScheduleDetourFormFields.DtrTotalExMil, RouteDetourErrorType.TotalMilesLessThanZero)">Total Schedule Pay Miles < 0</div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
