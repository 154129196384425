import { UpperCasePipe } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EslCd } from '@xpo-ltl/sdk-common';
import { DsrPayform, LinehaulSchedule } from '@xpo-ltl/sdk-linehaulpayform';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormStateDirective, FormUtils } from '../../../../../../classes';
import { LinehaulScheduleFormFields } from '../../../../../../enums';
import { ServiceCenterCacheService } from '../../../../../../services';
import { AsyncValidatorCompletionService } from '../../../../../../services/async-validator-completion.service';
import { WarningValidationType } from '../../../../../../enums/warning-validation-type.enum';
import { ErrorWarningService } from '../../../../services';
import { TripInfoFormBuilder } from './trip-info.form-builder';
import { EquipmentErrorTypes } from '../../../../../../enums/equipment-error-types.enum';
import { XpoAngularUtilsService } from '../../../../../../utils/angular-utils/services';
import { ActionPatchData, AppStateUtils, PayPortalStateStore } from '../../../../../../../app/state';

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html',
  styleUrls: ['./trip-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripInfoComponent extends FormStateDirective implements OnInit {
  @Input()
  schSequenceNbr: string;
  @Input()
  readonly = true;
  @Input()
  warningsCollection: any[];

  private scheduleSubject = new BehaviorSubject<LinehaulSchedule>(undefined);
  public schedule$ = this.scheduleSubject.asObservable();

  public readonly WarningValidationType = WarningValidationType;
  public readonly LinehaulScheduleFormFields = LinehaulScheduleFormFields;
  public readonly Object = Object;
  public readonly FormUtils = FormUtils;
  public readonly EquipmentErrorTypes = EquipmentErrorTypes;
  public currentPayform: DsrPayform;

  constructor(
    public utils: XpoAngularUtilsService,
    protected formBuilder: UntypedFormBuilder,
    private serviceCenterCache: ServiceCenterCacheService,
    private upperCasePipe: UpperCasePipe,
    public errorWarningService: ErrorWarningService,
    private asyncValidatorCompleted: AsyncValidatorCompletionService,
    private state: PayPortalStateStore
  ) {
    super(formBuilder);
  }

  ngOnInit(): void {
    this.linkFormToParent(LinehaulScheduleFormFields.TripInfoControl, TripInfoFormBuilder.create, this.serviceCenterCache, this.asyncValidatorCompleted, this.errorWarningService, this.schSequenceNbr);

    // Listen for State changes
    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        this.currentPayform = payform;
        const newSchedule = AppStateUtils.getScheduleFromPayform(payform, this.schSequenceNbr);
        this.scheduleSubject.next(newSchedule);
        this.updateFromState(newSchedule);
      });

    // Listen for Form changes
    this.form
      .get(LinehaulScheduleFormFields.CushionInd)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe(value => {
        if (value) {
          FormUtils.setValues(this.form, {
            [LinehaulScheduleFormFields.EslRegular]: false,
            [LinehaulScheduleFormFields.EslSolo]: false,
          });
        }
        this.updateToState();
      });

    this.form
      .get(LinehaulScheduleFormFields.EslRegular)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe(value => {
        if (value) {
          FormUtils.setValues(this.form, {
            [LinehaulScheduleFormFields.CushionInd]: false,
            [LinehaulScheduleFormFields.EslSolo]: false,
          });
        }
        this.updateToState();
      });

    this.form
      .get(LinehaulScheduleFormFields.EslSolo)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe(value => {
        if (value) {
          FormUtils.setValues(this.form, {
            [LinehaulScheduleFormFields.CushionInd]: false,
            [LinehaulScheduleFormFields.EslRegular]: false,
          });
        }
        this.updateToState();
      });
  }

  onBlur() {
    this.updateToState();
  }

  protected updateFromState(schedule: LinehaulSchedule) {
    FormUtils.setValues(this.form, {
      [LinehaulScheduleFormFields.OriginSicCd]: schedule?.originSicCd,
      [LinehaulScheduleFormFields.DestinationSicCd]: AppStateUtils.getDestination(schedule),
      [LinehaulScheduleFormFields.CushionInd]: schedule?.cushionInd,
      [LinehaulScheduleFormFields.EslRegular]: schedule?.eslCd === EslCd.REGULAR,
      [LinehaulScheduleFormFields.EslSolo]: schedule?.eslCd === EslCd.SOLO,
    });

    this.form.updateValueAndValidity();
  }

  private updateToState() {
    const isVia = this.currentPayform.linehaulSchedule.find(sch => sch.schSequenceNbr === this.schSequenceNbr).viaSicCd;

    const eslCd = this.form.get(LinehaulScheduleFormFields.EslRegular).value ? EslCd.REGULAR : this.form.get(LinehaulScheduleFormFields.EslSolo).value ? EslCd.SOLO : undefined;

    const originSicCd = this.upperCasePipe.transform(this.form.get(LinehaulScheduleFormFields.OriginSicCd).value);
    const destinationSicCd = this.upperCasePipe.transform(this.form.get(LinehaulScheduleFormFields.DestinationSicCd).value);
    const routeControl = this.parentForm.get(this.LinehaulScheduleFormFields.RoutingControl);
    let drivingLegId;
    if (routeControl) {
      drivingLegId = routeControl.get(LinehaulScheduleFormFields.DrivingLegId) ? routeControl.get(LinehaulScheduleFormFields.DrivingLegId).value : undefined;
    }

    const originDestPatchData: ActionPatchData = {
      originSicCd,
    };
    if (isVia) {
      originDestPatchData.viaSicCd = destinationSicCd;
    } else {
      originDestPatchData.destinationSicCd = destinationSicCd;
    }
    if (AppStateUtils.doesPatchChangeState(this.scheduleSubject.value, originDestPatchData)) {
      drivingLegId = undefined;
    }

    const patchData: ActionPatchData = {
      originSicCd,
      eslCd,
      drivingLegId,
      cushionInd: this.form.get(LinehaulScheduleFormFields.CushionInd).value,
    };
    if (isVia) {
      patchData.viaSicCd = destinationSicCd;
    } else {
      patchData.destinationSicCd = destinationSicCd;
    }
    // ensure there was a change before we try to patch
    if (AppStateUtils.doesPatchChangeState(this.scheduleSubject.value, patchData)) {
      this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData });
    }
  }

  checkControlForErrors(controlName: string, errors: string[]) {
    for (const error of errors) {
      if (this.utils.isShowingErrorsForFormControl(this.form, controlName, error)) {
        return true;
      }
    }
    return false;
  }
}
