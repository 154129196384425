import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeOfOperation',
})
export class TypeOfOperationPipe implements PipeTransform {
  transform(value: string | number, args?: any): any {
    let transformedValue = '';
    switch (+value) {
      case 0:
        transformedValue = '00 - No Trailing / Straight Truck, 2-Axle Tractor 3-Axle Tractor';
        break;
      case 1:
        transformedValue = '01 - 1-Axle Trailer or 1-Axle Trailer with Trailing Dolly / 2-Axle Tractor';
        break;
      case 2:
        transformedValue = '02 - 2-Axle Semi-Trailer / 3-Axle Tractor';
        break;
      case 5:
        transformedValue = '05 - Two 1-Axle Trailers with One or Two Dollies / 2 Axle Tractor';
        break;
      case 7:
        transformedValue = '07 - 2-Axle Semi-Trailer with Dolly and 1-Axle trailer / 3-Axle Tractor';
        break;
      case 9:
        transformedValue = '09 - City Trailing / 2-Axle Tractor';
        break;
      case 10:
        transformedValue = '10 - Two 1-Axle Trailers with One or Two Dollies / 3-Axle Tractor';
        break;
      case 11:
        transformedValue = '11 - 1-Axle Trailer / 3-Axle Tractor';
        break;
      case 12:
        transformedValue = '12 - 2-Axle Semi-Trailer / 2-Axle Tractor';
        break;
      case 13:
        transformedValue = '13 - Three 1-Axle Trailers with Two Dollies / 3-Axle Tractor';
        break;
      case 17:
        transformedValue = '17 - Three 1-Axle Trailers with Two Dollies / 2-Axle Tractor';
        break;
    }
    return transformedValue;
  }

  untransform(value: any): number {
    let transformedValue = 0;
    switch (value) {
      case 'No Trailing / Straight Truck, 2-Axle Tractor 3-Axle Tractor':
        transformedValue = 0;
        break;
      case '1-Axle Trailer or 1-Axle Trailer with Trailing Dolly / 2-Axle Tractor':
        transformedValue = 1;
        break;
      case '2-Axle Semi-Trailer / 3-Axle Tractor':
        transformedValue = 2;
        break;
      case 'Two 1-Axle Trailers with One or Two Dollies / 2 Axle Tractor':
        transformedValue = 5;
        break;
      case '2-Axle Semi-Trailer with Dolly and 1-Axle trailer / 3-Axle Tractor':
        transformedValue = 7;
        break;
      case 'City Trailing / 2-Axle Tractor':
        transformedValue = 9;
        break;
      case 'Two 1-Axle Trailers with One or Two Dollies / 3-Axle Tractor':
        transformedValue = 10;
        break;
      case '1-Axle Trailer / 3-Axle Tractor':
        transformedValue = 11;
        break;
      case '2-Axle Semi-Trailer / 2-Axle Tractor':
        transformedValue = 12;
        break;
      case 'Three 1-Axle Trailers with Two Dollies / 3-Axle Tractor':
        transformedValue = 13;
        break;
      case 'Three 1-Axle Trailers with Two Dollies / 2-Axle Tractor':
        transformedValue = 17;
        break;
    }
    return transformedValue;
  }
}
