import { ChangeDetectionStrategy, Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { NotesOverlayRef } from '../../../../../../classes/notes-overlay-ref';
import { Note } from '@xpo-ltl/sdk-linehaulpayform';
import { BehaviorSubject } from 'rxjs';
import { NOTES_OVERLAY_DATA } from './NotesOverlayData';

@Component({
  selector: 'app-notes-overlay',
  templateUrl: './notes-overlay.component.html',
  styleUrls: ['./notes-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesOverlayComponent implements OnInit, OnDestroy {
  private notesSubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>(undefined);
  public notes$ = this.notesSubject.asObservable();

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(evt: KeyboardEvent) {
    this.close();
  }

  constructor(private ref: NotesOverlayRef, private injector: Injector) {}

  public close() {
    if (!!this.ref) {
      this.ref.close();
    }
  }

  ngOnInit() {
    if (this.injector) {
      const data = this.injector.get(NOTES_OVERLAY_DATA);
      if (!!data) {
        this.notesSubject.next(data as Note[]);
      }
    }
  }

  ngOnDestroy(): void {
    this.notesSubject.complete();
    this.notesSubject = null;
  }
}
