import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Directive } from '@angular/core';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { PayformFormFields } from '../enums';
import { DsrActivity } from '@xpo-ltl/sdk-linehaulpayform';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function activityStartEndValidatorFunction(errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      return null;
    }

    const activitiesArr = linehaulSchedules.controls.reduce((payformArr, schedule) => {
      const activitiesCtrl = schedule.get(LinehaulScheduleFormFields.Activities) as UntypedFormGroup;
      if (activitiesCtrl) {
        payformArr.push(...LoDash.values(activitiesCtrl.controls));
      }
      return payformArr;
    }, []);

    // Get non schedule activities if present
    const nonSchedActivities = control.get(LinehaulScheduleFormFields.Activities) as UntypedFormArray;
    if (nonSchedActivities) {
      activitiesArr.push(...LoDash.values(nonSchedActivities.controls));
    }

    if (activitiesArr && activitiesArr.length > 0) {
      activitiesArr.forEach((activityControl: AbstractControl) => {
        const activity = activityControl.value as DsrActivity;
        const isArriveOrDepart = (activity?.nonDriveTimeType?.actvtyName ?? '') === 'Arrive' || (activity?.nonDriveTimeType?.actvtyName ?? '') === 'Dispatch';

        if (!activity.startDateTimeUtc && !isArriveOrDepart) {
          activityControl.setErrors({ startDateTimeInvalid: true });
        } else if (activityControl.hasError('startDateTimeInvalid')) {
          activityControl.errors['startDateTimeInvalid'] = null;
        }
        if (!activity.endDateTimeUtc && !isArriveOrDepart) {
          activityControl.setErrors({ endDateTimeInvalid: true });
        } else if (activityControl.hasError('endDateTimeInvalid')) {
          activityControl.errors['endDateTimeInvalid'] = null;
        }
      });
    }

    return null;
  };
}

@Directive({
  selector: '[activityStartEndValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ActivityStartEndValidator, multi: true }],
})
export class ActivityStartEndValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return activityStartEndValidatorFunction(this.errorWarningService)(control);
  }
}
