import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PayformNoteTypeCd, PayformStatusCd, Person, User } from '@xpo-ltl/sdk-common';
import {
  ApproveLinehaulPayformResp,
  ApproveLinehaulPayformRqst,
  DeleteLinehaulPayformRqst,
  DsrActivity,
  DsrPayform,
  LinehaulPayformApiService,
  LinehaulSchedule,
  Note,
  UpsertLinehaulPayformResp,
  UpsertLinehaulPayformRqst,
} from '@xpo-ltl/sdk-linehaulpayform';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppConstantsService } from '../../../services/app-constants.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ButtonType, ConfigManagerProperties, FooterLabels } from '../../../enums';
import { AppStateUtils, PayPortalStateStore } from 'app';
import { AppFooterService } from '../../../services';
import { ButtonConfig } from '../../../classes';
import { LoginService } from '../../../login/services/login.service';
import { LoDash } from '../../../utils/angular-utils/lodash-utils';

@Injectable()
export class PayFormService implements OnDestroy {
  private payformFormGroupSubject = new BehaviorSubject<UntypedFormGroup>(undefined);
  public payformFormGroup$ = this.payformFormGroupSubject.asObservable();
  public currentPayform: DsrPayform;
  private unsubscriber = new Subject<void>();
  private debugEnabled = false;
  private user: User;

  private nextSequenceNumbers: {
    schSequenceNbr: number;
    noteSequenceNbr: number;
    actvtySequenceNbr: number;
  } = {
    schSequenceNbr: 0,
    noteSequenceNbr: 0,
    actvtySequenceNbr: 0,
  };

  get invalid$(): Observable<any> {
    return this.invalidSubject$.asObservable();
  }
  setInvalid(value: boolean) {
    this.invalidSubject$.next(value);
  }

  private invalidSubject$ = new BehaviorSubject<boolean>(true);

  get dataChanged$(): Observable<any> {
    return this.dataChangedSubject$.asObservable();
  }

  setDataChanged(value: boolean) {
    this.dataChangedSubject$.next(value);
  }
  private dataChangedSubject$ = new BehaviorSubject<boolean>(true);

  constructor(
    private constants: AppConstantsService,
    private linehaulService: LinehaulPayformApiService,
    private config: ConfigManagerService,
    private state: PayPortalStateStore,
    private footerService: AppFooterService,
    private xpoSnackBar: XpoSnackBar,
    private loginService: LoginService
  ) {
    this.loginService
      .getLoggedInUser(this.config.getSetting<string>(ConfigManagerProperties.loggedInUserRoot))
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(payform => {
        this.currentPayform = payform;
        this.calculateNextSequenceNumbers(payform);
      });

    this.debugEnabled = this.config.getSetting<string>(ConfigManagerProperties.buildVersion) === 'local-version';
  }

  private calculateNextSequenceNumbers(payform: DsrPayform): void {
    this.nextSequenceNumbers = {
      schSequenceNbr: Math.max(...[0, ...(payform?.linehaulSchedule ?? []).map(sch => +sch.schSequenceNbr)]),
      noteSequenceNbr: Math.max(...[0, ...(payform?.note ?? []).map(sch => +sch.noteSequenceNbr)]),
      actvtySequenceNbr: Math.max(...[0, ...(payform?.dsrActivity ?? []).map(sch => +sch.actvtySequenceNbr)]),
    };
  }

  public nextSchSequenceNbr(schNbr?: any): string {
    const scheduleNo = schNbr ? schNbr : 0;
    this.nextSequenceNumbers.schSequenceNbr = scheduleNo + 1;
    return String(this.nextSequenceNumbers.schSequenceNbr);
  }

  public nextNoteSequenceNbr(): string {
    this.nextSequenceNumbers.noteSequenceNbr += 1;
    return String(this.nextSequenceNumbers.noteSequenceNbr);
  }

  public nextActvtySequenceNbr(): string {
    this.nextSequenceNumbers.actvtySequenceNbr += 1;
    return String(this.nextSequenceNumbers.actvtySequenceNbr);
  }

  /**
   * Create a new Note with the current User as commenter
   */
  public createNote(noteType: PayformNoteTypeCd, noteTypeSequenceNbr: string | number, comments: string): Note {
    const note = new Note();
    note.noteSequenceNbr = this.nextNoteSequenceNbr();
    note.dsrPayformId = this.currentPayform.dsrPayformId;
    note.noteDateTimeUtc = new Date();
    note.noteType = noteType;
    note.noteTypeSequenceNbr = noteTypeSequenceNbr ? String(noteTypeSequenceNbr) : undefined;
    note.comments = comments;

    note.commenterEmployeeDetail = new Person();
    note.commenterEmployeeDetail.firstName = this.user.givenName;
    note.commenterEmployeeDetail.lastName = this.user.lastName;
    note.commenterEmployeeDetail.fullName = this.user.displayName;
    return note;
  }

  /**
   * Delete the DsrActivity and any associated Data
   * @param actvtySequenceNbr Id of activity to delete
   */
  public deleteActivity(actvtySequenceNbr: string) {
    // delete any Notes associated with this activity
    (this.currentPayform.note ?? []).forEach(note => {
      if (note.noteType === PayformNoteTypeCd.ACTIVITY && note.noteTypeSequenceNbr === actvtySequenceNbr) {
        this.state.deleteNoteAction(note.noteSequenceNbr);
      }
    });

    // finally, delete the Activity
    return this.state.deleteActivityAction(actvtySequenceNbr);
  }

  /**
   * Delete the Schedule and any associated Data (ie, DsrActivities, etc)
   * @param schSequenceNbr Schedule id to delete
   */
  public deleteSchedule(schSequenceNbr: string) {
    if (this.currentPayform && this.currentPayform.dsrActivity) {
      this.currentPayform.dsrActivity.forEach(act => {
        if (act.schSequenceNbr === schSequenceNbr) {
          this.deleteActivity(act.actvtySequenceNbr);
        }
      });
    }

    // finally, delete the schedule
    return this.state.deleteScheduleAction(schSequenceNbr);
    // this.state.setCurrentPayformAction(this.currentPayform);
  }

  /**
   * Takes the passed payform and returns a new one that is in the correct format for upserting
   *
   * @param payform payform to prepare for upsert
   */
  private prepareForUpsert(sourcePayform: DsrPayform): DsrPayform {
    const sourceAsString = JSON.stringify(sourcePayform);

    // make a deep copy.  We don't want ANY changes to get reflected in the sourcePayform!
    const payform = JSON.parse(sourceAsString) as DsrPayform;

    // set submit time to current local time
    payform.submtDateTimeUtc = new Date();

    // Make sure that we have an AuditInfo for everything that requires it
    (payform.linehaulSchedule ?? []).forEach((schedule: LinehaulSchedule) => {
      if (schedule.scheduleDetour) {
        // make sure ScheduleDetour has an AuditInfo.  Upsert fails if it doesn't have one.
        schedule.scheduleDetour.auditInfo = {
          ...payform?.auditInfo,
          ...schedule.scheduleDetour?.auditInfo,
        };
      }
    });

    // Replace default dsrPayformId with undefined
    this.removeDefaultDsrPayformId(payform);

    // strip name from auditInfo
    this.stripNamesFromIds(payform);

    // Move new Notes to correct sections
    this.moveNotes(payform);

    return payform;
  }

  // Remove all names from ids ( ie, '1234|John Smith' => '1234')
  private stripNamesFromIds(payform: DsrPayform): DsrPayform {
    const getId = id => (id ? id.split('|')[0] : undefined);

    const fixAuditInfo = (item: any) => {
      const orig = item?.auditInfo;
      const isPayform = item.versionNbr !== undefined;
      if (orig) {
        const ai = {
          ...orig,
          createdById: getId(orig.createdById),
          updateById: getId(orig.updateById),
        };

        if (isPayform) {
          ai.updateById = undefined;
          ai.updateByPgmId = this.constants.programId;
        }
        LoDash.set(item, 'auditInfo', ai);
      }
    };

    fixAuditInfo(payform);

    if (payform && payform.dsrActivity) {
      payform?.dsrActivity.forEach((activity: DsrActivity) => {
        fixAuditInfo(activity);
        fixAuditInfo(activity.nonDriveTimeType);
      });
    }

    if (payform && payform.note) {
      payform?.note.forEach((note: Note) => {
        fixAuditInfo(note);
      });
    }

    if (payform && payform.linehaulSchedule) {
      payform?.linehaulSchedule.forEach((schedule: LinehaulSchedule) => {
        fixAuditInfo(schedule);
        if (schedule.scheduleDetour) {
          fixAuditInfo(schedule.scheduleDetour);
        }
        if (schedule && schedule.scheduleTrailer) {
          schedule.scheduleTrailer.forEach(trailer => {
            fixAuditInfo(trailer);
          });
        }
      });
    }

    return payform;
  }

  private removeDefaultDsrPayformId(payform: DsrPayform): DsrPayform {
    payform.dsrPayformId = payform.dsrPayformId === this.constants.NewPayformId ? undefined : payform.dsrPayformId;

    const removeDefault = item => {
      if (item.dsrPayformId === this.constants.NewPayformId) {
        item.dsrPayformId = undefined;
      }
    };

    (payform.note ?? []).forEach(removeDefault);
    (payform.linehaulSchedule ?? []).forEach(removeDefault);
    (payform.dsrActivity ?? []).forEach(removeDefault);

    return payform;
  }

  private moveNotes(payform: DsrPayform): DsrPayform {
    // get list of all new, non-PAYFORM notes

    payform.note = (payform.note ?? []).filter(note => note.noteType === PayformNoteTypeCd.PAYFORM);
    (payform.note ?? []).forEach(note => {
      switch (note.noteType) {
        case PayformNoteTypeCd.ACTIVITY:
          const activity = AppStateUtils.getActivityFromState(payform.dsrActivity, note.noteTypeSequenceNbr);
          if (activity) {
            activity.note = activity.note ?? [];
            activity.note.push(note);
          }
          break;

        case PayformNoteTypeCd.DETOUR:
          // TODO - move notes
          break;

        case PayformNoteTypeCd.SCHEDULE:
          // TODO - move notes
          break;
      }
    });

    return payform;
  }

  /**
   * Upsert the current Payform as SUBMITTED
   */
  public submitPayform(feedback: string): Observable<UpsertLinehaulPayformResp> {
    if (feedback) {
      // add feedback as a Note to the payform
      const feedbackNote = this.createNote(PayformNoteTypeCd.PAYFORM, null, feedback);
      this.state.setPayformNote(feedbackNote);
    }

    this.state.getCurrentPayformState().subscribe(pf => {
      this.currentPayform = pf;
    });

    const payform = this.prepareForUpsert(this.currentPayform);
    payform.statusCd = PayformStatusCd.SUBMITTED;
    if (feedback) {
      payform.supvsrEditedInd = true;
    }

    const request = new UpsertLinehaulPayformRqst();
    request.dsrPayform = payform;

    return this.linehaulService.upsertLinehaulPayform(request).pipe(take(1));
  }

  /**
   * DELETED the current Payform
   */
  public deletePayform(feedback: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    const complete = result => {
      subject.next(result);
      subject.complete();
    };

    // add feedback as a Note to the payform
    const feedbackNote = this.createNote(PayformNoteTypeCd.PAYFORM, null, feedback);
    this.state.setPayformNote(feedbackNote);

    const payform = this.prepareForUpsert(this.currentPayform);
    payform.supvsrEditedInd = true;

    const upsertRequest = new UpsertLinehaulPayformRqst();
    upsertRequest.dsrPayform = payform;
    this.linehaulService
      .upsertLinehaulPayform(upsertRequest, { toastOnError: false })
      .pipe(take(1))
      .subscribe(
        () => {
          const deleteRequest = new DeleteLinehaulPayformRqst();
          deleteRequest.dsrPayformId = this.currentPayform.dsrPayformId;
          this.linehaulService
            .deleteLinehaulPayform(deleteRequest)
            .pipe(take(1))
            .subscribe(
              () => {
                complete(true);
              },
              () => complete(false)
            );
        },
        error => {
          this.showError(error);
          complete(false);
        }
      );

    return subject;
  }

  /**
   * Restore the payform from DELETED status to NEW status
   */
  public restorePayform(): Observable<UpsertLinehaulPayformResp> {
    const payform = this.prepareForUpsert(this.currentPayform);
    payform.statusCd = payform.timeCrdNbr1 || payform.timeCrdNbr2 ? PayformStatusCd.SUBMITTED : PayformStatusCd.NEW;
    payform.supvsrEditedInd = true;

    const request = new UpsertLinehaulPayformRqst();
    request.dsrPayform = payform;
    return this.linehaulService.upsertLinehaulPayform(request).pipe(take(1));
  }

  /**
   * Add comment to current Payform and upsert it as RETURNED
   * @param comments Note to add to the payform when returning it
   */
  public returnPayform(feedback: string): Observable<UpsertLinehaulPayformResp> {
    // add feedback as a Note to the payform
    const feedbackNote = this.createNote(PayformNoteTypeCd.PAYFORM, null, feedback);
    this.state.setPayformNote(feedbackNote);

    const payform = this.prepareForUpsert(this.currentPayform);
    payform.statusCd = PayformStatusCd.RETURNED;

    const request = new UpsertLinehaulPayformRqst();
    request.dsrPayform = payform;
    return this.linehaulService.upsertLinehaulPayform(request).pipe(take(1));
  }

  public approvePayform(feedback?: string): Observable<ApproveLinehaulPayformResp> {
    const subject = new Subject<ApproveLinehaulPayformResp>();

    const completed = results => {
      subject.next(results);
      subject.complete();
    };

    if (feedback) {
      // add feedback as a Note to the payform
      const feedbackNote = this.createNote(PayformNoteTypeCd.PAYFORM, null, feedback);
      this.state.setPayformNote(feedbackNote);

      const payform = this.prepareForUpsert(this.currentPayform);
      payform.statusCd = PayformStatusCd.SUBMITTED;
      payform.supvsrEditedInd = true;

      const upsertRequest = new UpsertLinehaulPayformRqst();
      upsertRequest.dsrPayform = payform;
      this.linehaulService
        .upsertLinehaulPayform(upsertRequest, { toastOnError: false })
        .pipe(take(1))
        .subscribe(
          upsertResults => {
            if (upsertResults) {
              const approveRequest = new ApproveLinehaulPayformRqst();
              approveRequest.dsrPayform = upsertResults.dsrPayform;
              this.linehaulService
                .approveLinehaulPayform(approveRequest)
                .pipe(take(1))
                .subscribe(results => {
                  completed(results);
                });
            } else {
              completed(undefined);
            }
          },
          error => {
            this.showError(error);
            completed(undefined);
          }
        );
    } else {
      const payform = this.prepareForUpsert(this.currentPayform);
      payform.statusCd = PayformStatusCd.SUBMITTED;

      const request = new ApproveLinehaulPayformRqst();
      request.dsrPayform = payform;
      this.linehaulService
        .approveLinehaulPayform(request, { toastOnError: false })
        .pipe(take(1))
        .subscribe(
          results => {
            completed(results);
          },
          error => {
            this.showError(error);
            completed(undefined);
          }
        );
    }

    return subject;
  }

  private showError(error): void {
    const errorMessage = error.error.moreInfo[0].message || error.message;

    this.xpoSnackBar.open({
      message: errorMessage,
      status: 'error',
      matConfig: {
        duration: this.config.getSetting<number>(ConfigManagerProperties.errorToastDuration),
      },
    });
  }

  setFooter(readonly: boolean) {
    this.state
      .getCurrentPayformState()
      .pipe(take(1))
      .subscribe(state => (this.currentPayform = state));
    if (this.currentPayform) {
      const payform = this.currentPayform;
      switch (payform.statusCd) {
        case PayformStatusCd.NEW:
          if (payform.dsrPayformId < 0) {
            this.setButtonConfigForSubmittedPayform(readonly);
          } else {
            this.setButtonConfigForNewPayform(readonly);
          }
          break;

        case PayformStatusCd.SUBMITTED:
          this.setButtonConfigForSubmittedPayform(readonly);
          break;

        case PayformStatusCd.RETURNED:
          this.setButtonConfigForReturnedPayform(readonly);
          break;

        case PayformStatusCd.APPROVED:
          this.setButtonConfigForApprovedPayform(readonly);
          break;

        case PayformStatusCd.TRANSMITTED:
          this.setButtonConfigForTransmittedPayform(readonly);
          break;

        case PayformStatusCd.DELETED:
          this.setButtonConfigForDeletedPayform(readonly);
          break;
      }
    }
  }

  private setButtonConfigForNewPayform(readonly: boolean) {
    this.footerService.setButtonConfig(
      [new ButtonConfig(FooterLabels.Debug, false, this.debugEnabled, ButtonType.Warning, false), new ButtonConfig(FooterLabels.Cancel, false, true, ButtonType.Primary)],
      [new ButtonConfig(FooterLabels.SubmitAndOverride, false, true, ButtonType.Primary, true)]
    );
  }

  private setButtonConfigForSubmittedPayform(readonly: boolean) {
    if (readonly) {
      this.footerService.setButtonConfig(
        [new ButtonConfig(FooterLabels.Debug, false, this.debugEnabled, ButtonType.Warning, false), new ButtonConfig(FooterLabels.Cancel, false, true, ButtonType.Primary)],
        [new ButtonConfig(FooterLabels.ReturnToDsr, false, true, ButtonType.Primary), new ButtonConfig(FooterLabels.Approve, false, true, ButtonType.Primary, true, this.invalid$)]
      );
    } else {
      this.footerService.setButtonConfig(
        [new ButtonConfig(FooterLabels.Debug, false, this.debugEnabled, ButtonType.Warning, false), new ButtonConfig(FooterLabels.Cancel, false, true, ButtonType.Primary)],
        [
          new ButtonConfig(FooterLabels.Delete, false, true && this.currentPayform.dsrPayformId >= 0, ButtonType.Warning),
          new ButtonConfig(FooterLabels.ReturnToDsr, false, true, ButtonType.Primary),
          new ButtonConfig(FooterLabels.Save, false, true, ButtonType.Primary, false, this.dataChanged$),
          new ButtonConfig(FooterLabels.Approve, undefined, true, ButtonType.Primary, true, this.invalid$),
        ]
      );
    }
  }

  private setButtonConfigForReturnedPayform(readonly: boolean) {
    this.footerService.setButtonConfig(
      [new ButtonConfig(FooterLabels.Debug, false, this.debugEnabled, ButtonType.Warning, false), new ButtonConfig(FooterLabels.Cancel, false, true, ButtonType.Primary)],
      [new ButtonConfig(FooterLabels.Override, false, true, ButtonType.Primary, true)]
    );
  }

  private setButtonConfigForApprovedPayform(readonly: boolean) {
    this.footerService.setButtonConfig(
      [new ButtonConfig(FooterLabels.Debug, false, this.debugEnabled, ButtonType.Warning, false), new ButtonConfig(FooterLabels.Cancel, false, true, ButtonType.Primary)],
      []
    );
  }

  private setButtonConfigForTransmittedPayform(readonly: boolean) {
    this.footerService.setButtonConfig(
      [new ButtonConfig(FooterLabels.Debug, false, this.debugEnabled, ButtonType.Warning, false), new ButtonConfig(FooterLabels.Cancel, false, true, ButtonType.Primary)],
      []
    );
  }

  private setButtonConfigForDeletedPayform(readonly: boolean) {
    this.footerService.setButtonConfig(
      [new ButtonConfig(FooterLabels.Debug, false, this.debugEnabled, ButtonType.Warning, false), new ButtonConfig(FooterLabels.Cancel, false, true, ButtonType.Primary)],
      [new ButtonConfig(FooterLabels.UndoDelete, false, true, ButtonType.Primary, true)]
    );
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
