import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export class PayformCommentsFormBuilder {
  /**
   * Return a FormGroup that contains all of the controls that need to be updated and/or validated.
   *
   * Add any Validators here so that they run even when the HTML controls are not rendered
   */
  public static create(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    const formGroup = formBuilder.group({});
    return formGroup;
  }
}
