import { AfterViewInit, Directive, Input, OnDestroy, Injector, OnChanges, ElementRef, Renderer2, Host, Self, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractControl, FormArrayName, FormGroupDirective, UntypedFormArray } from '@angular/forms';
import { WarningsUtils, WarningDetails } from './xpo-warning-helper';
import { watchAsyncFormStatus } from '../utils';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '../classes';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';

@Directive({
  selector: '[xpoWarnings]',
  exportAs: 'xpoWarnings',
  providers: [MatFormField],
})
export class XpoWarningsDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public subject = new BehaviorSubject<WarningDetails>(null);

  private unsubscriber: Unsubscriber;
  private _control: AbstractControl;
  private formArrayName: FormArrayName;

  @Input('xpoWarnings')
  controlName: string | number;
  @Input()
  formArrayIndex = -1;
  @Input()
  xpoWarningsParentControl: AbstractControl;

  constructor(
    private form: FormGroupDirective,
    private injector: Injector,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Host()
    @Self()
    private formField: MatFormField
  ) {}

  public get control() {
    return this._control;
  }

  ngOnInit() {
    this.unsubscriber = new Unsubscriber();
    this.setControl();
  }

  ngAfterViewInit() {
    this.checkStatus();

    this.control.statusChanges.pipe(watchAsyncFormStatus(this.control, 50), takeUntil(this.unsubscriber.done)).subscribe(() => {
      this.checkStatus();
    });

    this.control.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe(() => {
      this.checkStatus();
    });
  }

  ngOnChanges() {
    this.setControl();

    this._control[`_${WarningsUtils.warningsPropertyName}`] = this._control[WarningsUtils.warningsPropertyName];

    const getter = () => this[`_${WarningsUtils.warningsPropertyName}`];
    const setter = (value: any) => {
      this[`_${WarningsUtils.warningsPropertyName}`] = value;
      this.checkStatus();
    };

    Object.defineProperty(this._control, WarningsUtils.warningsPropertyName, {
      get: getter,
      set: setter,
    });
  }

  public checkStatus() {
    const parentFormField = this.elementRef.nativeElement.closest('.mat-form-field');

    if (!this.control) {
      this.setControl();

      if (!this.control) {
        return;
      }
    }

    if (!this.subject) {
      return;
    }

    if (WarningsUtils.hasWarnings(this.control) && !this.control.errors && this.isParentControlValid()) {
      const warnings = WarningsUtils.getWarnings(this.control);

      this.addWarningClass(parentFormField);

      (Object.keys(warnings) ?? []).forEach(warningName => {
        this.subject.next({ control: this.control, warningName });
      });
    } else {
      if (!this.xpoWarningsParentControl || (!!this.xpoWarningsParentControl && !WarningsUtils.hasWarnings(this.xpoWarningsParentControl))) {
        this.removeWarningClass(parentFormField);
      }
    }
    this.formField['_changeDetectorRef'].markForCheck();
  }

  private setControl(): void {
    if (this.formArrayIndex > -1 && !this.formArrayName) {
      this.formArrayName = this.injector.get(FormArrayName);
    }

    if (this.formArrayName && this.formArrayIndex >= 0) {
      this._control = (this.form.control.get(this.formArrayName.name as string) as UntypedFormArray).at(this.formArrayIndex);
      if (typeof this.controlName === 'string') {
        this._control = this._control.get(this.controlName);
      }
    } else {
      this._control = this.form.control.get(this.controlName as string);
    }
  }

  private isParentControlValid(): boolean {
    if (this.xpoWarningsParentControl) {
      return this.xpoWarningsParentControl.valid;
    }
    return true;
  }

  private addWarningClass(parentFormField) {
    this.renderer.addClass(parentFormField, 'mat-warnings');
  }

  private removeWarningClass(parentFormField) {
    this.renderer.removeClass(parentFormField, 'mat-warnings');
  }

  ngOnDestroy() {
    if (this.subject) {
      this.subject.complete();
      this.subject = null;
    }
    if (this.unsubscriber) {
      this.unsubscriber.complete();
      this.unsubscriber = undefined;
    }
  }
}
