import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { PayformStatusCd, Person } from '@xpo-ltl/sdk-common';
import { DsrEmployee, HumanResourceApiService, SearchEmployeeDetailsBySicResp } from '@xpo-ltl/sdk-humanresource';
import { DsrPayform, LinehaulPayformApiService, ListLinehaulPayformsQuery } from '@xpo-ltl/sdk-linehaulpayform';
import * as moment from 'moment-timezone';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, skip, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfirmCancelData } from '../../classes/confirm-cancel-data';
import { ConfigManagerProperties } from '../../enums';
import { ErrorWarningTypes } from '../../enums/error-warning-types.enum';
import { SubstringPipe } from '../../pipes/substring-pipe';
import { AppConstantsService } from '../../services/app-constants.service';
import { ConfirmCancelComponent } from '../confirm-cancel/confirm-cancel.component';
import { LoDash } from '../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-create-payform',
  templateUrl: './create-payform.component.html',
  styleUrls: ['./create-payform.component.scss'],
})
export class CreatePayformComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly defaultTimezone = 'America/Los_Angeles';
  public sic: string;
  public employee: string;
  public shiftStart: Date;
  public employeeFocus = false;
  private employeeListSubject = new Subject<DsrEmployee[]>();
  public employeeList$ = this.employeeListSubject.asObservable();
  public searching = false;
  public minDate: Date;
  public maxDate: Date;
  private unsubscriber = new Subject<void>();
  public selectedEmployee: DsrEmployee;
  private includeZones = true;

  public readonly ValidationRegexPatterns = ValidationRegexPatterns;

  @ViewChild('sicInput', { static: true })
  sicInput: NgModel;
  @ViewChild('empInput', { static: true })
  empInput: NgModel;
  @ViewChild('empInputField')
  empInputField: ElementRef;
  @ViewChild('shiftStartInput')
  shiftStartInput: NgModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<CreatePayformComponent>,
    private hrService: HumanResourceApiService,
    private substringPipe: SubstringPipe,
    private linehaulPayformApiService: LinehaulPayformApiService,
    private dialog: MatDialog,
    private constants: AppConstantsService,
    private config: ConfigManagerService
  ) {
    this.sic = `${data}`;
    this.includeZones = this.config.getSetting<boolean>(ConfigManagerProperties.includeZones);
  }

  ngOnInit() {
    this.minDate = moment()
      .add(-1, 'years')
      .toDate();
    this.maxDate = new Date();

    this.sicInput.valueChanges.pipe(takeUntil(this.unsubscriber), distinctUntilChanged()).subscribe(this.handleSicChanged.bind(this));
  }

  inputChange() {
    this.empInput.valueChanges
      .pipe(
        takeUntil(this.unsubscriber),
        distinctUntilChanged(),
        tap(value => {
          this.employeeListSubject.next(null);

          this.searching = value && value.length > 0;

          return value;
        }),
        debounceTime(100),
        switchMap(value => this.lookupEmployee(value))
      )
      .subscribe(this.handleEmployeeChanged.bind(this));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.empInputField.nativeElement.focus();
    });
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
      this.unsubscriber = undefined;
    }
  }

  private handleSicChanged(newValue: string) {
    if (newValue !== this.sic) {
      this.employee = null;
    }
  }

  private handleEmployeeChanged(response: SearchEmployeeDetailsBySicResp) {
    const empInputErrors = () => ({ 'not-found': 'Employee not found.' });

    this.searching = false;

    if (response && response.dsrEmployees) {
      this.employeeListSubject.next(LoDash.sortBy(response.dsrEmployees, (emp: DsrEmployee) => `${emp.lastName}, ${emp.firstName}`));

      this.selectedEmployee = response.dsrEmployees.find(emp => emp.employeeId === this.substringPipe.transform(this.employee, ['(', ')']).toUpperCase());

      if (this.selectedEmployee && this.selectedEmployee.locationSic) {
        this.sic = this.selectedEmployee.locationSic.toUpperCase();
        this.empInput.control.setValue(`${this.selectedEmployee.fullName} (${this.selectedEmployee.employeeId})`);
      }

      this.empInput.control.setErrors(this.selectedEmployee ? undefined : empInputErrors());
    }
  }

  private lookupEmployee(value: string): Observable<SearchEmployeeDetailsBySicResp> {
    if (value) {
      this.employee = this.substringPipe.transform(value, ['(', ')']);

      const request = { includeZones: null, locationSic: null, searchString: null };
      request.includeZones = [this.includeZones];
      request.locationSic = [this.sic];
      request.searchString = [this.employee.toUpperCase()];

      return this.hrService
        .searchEmployeeDetailsBySic(request, {
          loadingOverlayEnabled: false,
        })
        .pipe(
          catchError(() => {
            this.searching = false;
            return of(new SearchEmployeeDetailsBySicResp());
          })
        );
    }
  }

  clearEmployeeName() {
    this.employee = null;
  }

  handleCreateClick() {
    if (this.shiftStart && this.sic && this.employee) {
      const payform = new DsrPayform();
      payform.dsrPayformId = this.constants.NewPayformId;
      payform.dmclSic = this.sic.toUpperCase();
      payform.dsrEmployeeDetail = new Person();
      payform.dsrEmployeeId = this.selectedEmployee.employeeId;
      payform.dsrEmployeeDetail.firstName = this.selectedEmployee.firstName;
      payform.dsrEmployeeDetail.lastName = this.selectedEmployee.lastName;
      payform.dsrEmployeeDetail.fullName = this.selectedEmployee.fullName;

      const calendarDate = moment(this.shiftStart);
      const shiftStartDate = new Date(calendarDate.year(), calendarDate.month(), calendarDate.date(), 0, 0, 0, 0);
      payform.shiftStartDate = moment(shiftStartDate).format('YYYY-MM-DD');

      this.countExistingPayforms(payform.dmclSic, payform.dsrEmployeeId, shiftStartDate)
        .pipe(take(1))
        .subscribe(count => {
          if (count) {
            this.confirmPayformCreate(shiftStartDate, count)
              .pipe(take(1))
              .subscribe(response => {
                if (response) {
                  this.matDialogRef.close(payform);
                }
              });
          } else if (count === 0) {
            this.matDialogRef.close(payform);
          }
        });
    }
  }

  private countExistingPayforms(sic: string, employeeId: string, shiftDate: Date): Observable<number> {
    const subject = new Subject<number>();

    const queryParams = new ListLinehaulPayformsQuery();
    queryParams.beginningShiftStartDate = moment(shiftDate).format('YYYY-MM-DD');
    queryParams.dmclSic = sic;
    queryParams.dsrEmployeeId = employeeId;
    queryParams.endingShiftStartDate = queryParams.beginningShiftStartDate;
    queryParams.includeZone = this.config.getSetting<boolean>(ConfigManagerProperties.includeZones) ? 'true' : 'false';
    queryParams.payformStatus = [PayformStatusCd.APPROVED, PayformStatusCd.NEW, PayformStatusCd.RETURNED, PayformStatusCd.SUBMITTED, PayformStatusCd.TRANSMITTED];

    this.linehaulPayformApiService
      .listLinehaulPayforms(queryParams, { toastOnError: false })
      .pipe(take(1))
      .subscribe(
        response => {
          if (response && response.dsrPayform) {
            subject.next(response.dsrPayform.length);
          } else {
            subject.next(0);
          }
          subject.complete();
        },
        error => {
          subject.next(0);
          subject.complete();
        }
      );

    return subject.asObservable();
  }

  private confirmPayformCreate(shiftDate: Date, count: number): Observable<boolean> {
    const data = new ConfirmCancelData(
      'Payform for this date already exists.',
      `<p>${count} payform(s) for ${moment(shiftDate).format(
        'MM/DD/YYYY'
      )} already exists. Do you still wish to create a duplicate payform for this date?<p><p>This is not a common action to take.</p>`,
      'Cancel',
      'Create Duplicate Payform',
      ErrorWarningTypes.Warning
    );
    const dialogRef = this.dialog.open(ConfirmCancelComponent, { data });
    return dialogRef.afterClosed();
  }
}
