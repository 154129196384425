import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ButtonConfig } from '../../classes/button-config';

@Injectable()
export class AppFooterService {
  private leftButtonConfigSubject = new BehaviorSubject<ButtonConfig[]>(undefined);
  public leftButtonConfig$ = this.leftButtonConfigSubject.asObservable();

  private rightButtonConfigSubject = new BehaviorSubject<ButtonConfig[]>(undefined);
  public rightButtonConfig$ = this.rightButtonConfigSubject.asObservable();

  private buttonClickedSubject = new Subject<string>();
  public buttonClicked$ = this.buttonClickedSubject.asObservable();

  constructor() {}

  public setButtonConfig(leftButtonConfig: ButtonConfig[], rightButtonConfig: ButtonConfig[]) {
    this.setLeftButtonConfig(leftButtonConfig);
    this.setRightButtonConfig(rightButtonConfig);
  }

  public setLeftButtonConfig(config: ButtonConfig[]) {
    this.leftButtonConfigSubject.next(config);
  }

  public clearLeftButtonConfig() {
    this.leftButtonConfigSubject.next([]);
  }

  public setRightButtonConfig(config: ButtonConfig[]) {
    this.rightButtonConfigSubject.next(config);
  }

  public clearRightButtonConfig() {
    this.rightButtonConfigSubject.next([]);
  }

  public handleButtonClicked(label: string) {
    this.buttonClickedSubject.next(label);
  }

  public get hasLeftButtonConfig(): boolean {
    return (this.leftButtonConfigSubject.value ?? []).some(v => v);
  }

  public get hasRightButtonConfig(): boolean {
    return (this.rightButtonConfigSubject.value ?? []).some(v => v);
  }
}
