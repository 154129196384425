import { AbstractControl } from '@angular/forms';
import { LoDash } from '../lodash-utils';

export const toArray = (value: WarningOptions): string[] => (Array.isArray(value) ? value : [value]);

export type WarningOptions = string | string[];

export interface WarningDetails {
  control: AbstractControl;
  warningName: string;
}

export class WarningsUtils {
  public static warningsPropertyName = 'warnings';

  public static hasWarning(control: AbstractControl, warning: string): boolean {
    if (control && control.enabled) {
      return control && control.enabled && this.hasWarnings(control) && !!this.getWarning(control, warning);
    }
    return false;
  }

  public static hasWarnings(control: AbstractControl): boolean {
    return !!control[WarningsUtils.warningsPropertyName].length;
  }

  public static getWarning(control: AbstractControl, warning: string): any {
    return LoDash.get(control, `${WarningsUtils.warningsPropertyName}[${warning}]`, undefined);
  }

  public static getWarnings(control: AbstractControl): any {
    return LoDash.get(control, WarningsUtils.warningsPropertyName, {});
  }
}
