export enum LinehaulScheduleFormFields {
  TripInfoControl = 'TripInfoControl',
  EquipmentControl = 'EquipmentControl',
  RoutingControl = 'RoutingControl',
  DetourControl = 'DetourControl',
  CurrentRouteValue = 'CurrentRouteValue',
  Activities = 'Activities',
  Details = 'Details',
  NonScheduleActivities = 'Non-Schedule Activities',

  DsrPayformId = 'dsrPayformId',
  SchSequenceNbr = 'schSequenceNbr',
  SchId = 'schId',
  OriginSicCd = 'originSicCd',
  DestinationSicCd = 'destinationSicCd',
  ViaSicCd = 'viaSicCd',
  TractorNbr = 'tractorNbr',
  SchDepartDateTime = 'schDepartDateTime',
  SchArriveDateTime = 'schArriveDateTime',
  DsrEmployeeId2 = 'dsrEmployeeId2',
  ManualEntryInd = 'manualEntryInd',
  HazmatOverrideInd = 'hazmatOverrideInd',
  DollyNbr1 = 'dollyNbr1',
  DollyNbr2 = 'dollyNbr2',
  TypeOfOperation = 'typeOfOperation',
  EslCd = 'eslCd',
  DetourInd = 'detourInd',
  CushionInd = 'cushionInd',
  DepartLatNbr = 'departLatNbr',
  DepartLongNbr = 'departLongNbr',
  ArivLatNbr = 'arivLatNbr',
  ArivLongNbr = 'arivLongNbr',
  DrivingLegId = 'drivingLegId',
  ListActionCd = 'listActionCd',
  AuditInfo = 'auditInfo',
  Note = 'note',
  ScheduleDetour = 'scheduleDetour',
  ScheduleTrailer = 'scheduleTrailer',
  EslRegular = 'eslRegular',
  EslSolo = 'eslSolo',
}
