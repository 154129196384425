import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { LinehaulSchedule } from '@xpo-ltl/sdk-linehaulpayform';
import { RoutingInstructions } from '@xpo-ltl/sdk-reference';
import { ActionPatchData, AppStateUtils, PayPortalStateStore } from '../../../../../../../app/state';
import { BehaviorSubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormStateDirective } from '../../../../../../classes/form-state.component';
import { WarningValidationType } from '../../../../../../enums';
import { DisplayOnlyFormFields } from '../../../../../../enums/display-only-form-fields.enum';
import { LinehaulScheduleFormFields } from '../../../../../../enums/linehaul-schedule-form-fields.enum';
import { RoutingInstructionsCacheService } from '../../../../../../services';
import { DriverPayService } from '../../../../../../services/driver-pay/driver-pay.service';
import { ErrorWarningService } from '../../../../services';
import { RoutingFormBuilder } from './routing.form-builder';
import { ErrorWarningMessage } from '../../../../../../classes/error-warning-message';
import { LoDash } from '../../../../../../utils/angular-utils/lodash-utils';
@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutingComponent extends FormStateDirective implements OnInit {
  @Input()
  schSequenceNbr: string;
  @Input()
  readonly = true;
  @Input()
  warningsCollection: ErrorWarningMessage[];

  private schedule: LinehaulSchedule;

  private editDisabledSubject = new BehaviorSubject<boolean>(true);
  public editDisabled$ = this.editDisabledSubject.asObservable();

  private currentRouteSubject = new BehaviorSubject<RoutingInstructions>(undefined);
  public currentRoute$ = this.currentRouteSubject.asObservable();

  public readonly LinehaulScheduleFormFields = LinehaulScheduleFormFields;
  public readonly DisplayOnlyFormFields = DisplayOnlyFormFields;
  public readonly WarningValidationType = WarningValidationType;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private driverPayService: DriverPayService,
    public errorWarningService: ErrorWarningService,
    private routingInstructionsCacheService: RoutingInstructionsCacheService,
    private state: PayPortalStateStore
  ) {
    super(formBuilder);
  }

  ngOnInit() {
    this.linkFormToParent(LinehaulScheduleFormFields.RoutingControl, RoutingFormBuilder.create);

    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        const schedule = AppStateUtils.getScheduleFromPayform(payform, this.schSequenceNbr);
        this.updateFromState(schedule);
      });

    this.currentRoute$.pipe(takeUntil(this.unsubscriber.done)).subscribe((route: RoutingInstructions) => {
      if (!route) {
        this.form.updateValueAndValidity();
      }
    });
  }

  handleEditClicked() {
    this.driverPayService
      .showRouteTypesDialog(this.schedule?.originSicCd, AppStateUtils.getDestination(this.schedule))
      .pipe(take(1))
      .subscribe((route: RoutingInstructions) => {
        if (route && !LoDash.isEqual(route, this.currentRouteSubject.value)) {
          const patchData: ActionPatchData = {
            drivingLegId: route.drivingLegId,
          };
          this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData });
          this.currentRouteSubject.next(route);
        }
      });
  }

  updateFromState(schedule: LinehaulSchedule) {
    this.schedule = schedule;

    this.routingInstructionsCacheService
      .request({
        origSicCd: this.schedule?.originSicCd,
        destSicCd: AppStateUtils.getDestination(this.schedule),
        drivingLegId: this.schedule?.drivingLegId,
      })
      .pipe(take(1))
      .subscribe((routes: RoutingInstructions[]) => {
        this.editDisabledSubject.next(LoDash.isEmpty(routes));

        let currentRoute;
        if (LoDash.isEmpty(this.schedule?.drivingLegId)) {
          if (!this.readonly) {
            // no previous route was selected, so see if there is a default one to select
            currentRoute = (routes ?? []).find((route: RoutingInstructions) => route.tripCd === '01');
            if (currentRoute) {
              const patchData: ActionPatchData = {
                drivingLegId: currentRoute.drivingLegId,
              };

              this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData });
            }
          }
        } else {
          // find the current route in the routes.
          // TODO - what if we can't find it?
          currentRoute = (routes ?? []).find(route => route.drivingLegId === this.schedule?.drivingLegId) as RoutingInstructions;
        }

        this.currentRouteSubject.next(currentRoute);
      });

    // save the drivingLegId so other components can get to it easily
    this.form.get(LinehaulScheduleFormFields.DrivingLegId).setValue(this.schedule?.drivingLegId);
  }
}
