import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GetPayformDetailsByIdResp, GetPayformDetailsByIdRqst, LinehaulPayformApiService, DsrPayform, GetPayformDetailsByIdQuery } from '@xpo-ltl/sdk-linehaulpayform';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class PayformResolver implements Resolve<DsrPayform> {
  constructor(private linehaulPayformApiService: LinehaulPayformApiService) {}

  // Resolves with the DsrPayform matching the route ID
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DsrPayform> | Promise<DsrPayform> | DsrPayform {
    return new Observable(observer => {
      const routeId = +route.params['id'];

      if (routeId) {
        const request = new GetPayformDetailsByIdRqst();
        request.payformId = routeId;
        const queryParams = new GetPayformDetailsByIdQuery();

        this.linehaulPayformApiService
          .getPayformDetailsById(request, queryParams)
          .pipe(take(1))
          .subscribe((payformResponse: GetPayformDetailsByIdResp) => {
            observer.next(payformResponse.dsrPayform);
            observer.complete();
          });
      } else {
        observer.error('Payform ID Required!');
        observer.complete();
      }
    });
  }
}
