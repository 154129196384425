import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType } from '../enums';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function detourValidatorFunction(errorWarningService: ErrorWarningService, schSequenceNbr: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !control.parent || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }
    const detourCtrl = control.parent;
    const schDetailCtrl = detourCtrl.parent;
    const tripInfo = schDetailCtrl.get(LinehaulScheduleFormFields.TripInfoControl);

    if (!control.value || !tripInfo) {
      FormUtils.unsetWarning(control, WarningValidationType.DetourWarning);
    } else {
      const origin = LoDash.get(tripInfo.get(LinehaulScheduleFormFields.OriginSicCd), 'value', '');
      const dest = LoDash.get(tripInfo.get(LinehaulScheduleFormFields.DestinationSicCd), 'value', '');
      const detourWarning = {
        type: WarningValidationType.DetourWarning,
        primarySubject: `${origin.toUpperCase()} -> ${dest.toUpperCase()}`,
      };
      FormUtils.setWarning(control, WarningValidationType.DetourWarning, detourWarning);
    }

    errorWarningService.detailWarningsChanged(schSequenceNbr);
    return null;
  };
}

@Directive({
  selector: '[detourValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DetourValidator, multi: true }],
})
export class DetourValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}
  @Input()
  schSequenceNbr: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return detourValidatorFunction(this.errorWarningService, this.schSequenceNbr)(control);
  }
}
