import { Directive } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { FormUtils } from '../classes';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { LinehaulScheduleFormFields, PayformFormFields, ScheduleDetourFormFields, WarningValidationType } from '../enums';
import { DisplayOnlyFormFields } from '../enums/display-only-form-fields.enum';
import { RouteDetourErrorType } from '../enums/route-detour-error-types.enum';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function totalMileageValidatorFunction(mileageLimit: number, errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    FormUtils.unsetWarning(control, WarningValidationType.TotalMilesWarning);

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      return null;
    }

    const totalMiles = linehaulSchedules.controls.reduce((total, schedule) => {
      const scheduleDetail = schedule.get(LinehaulScheduleFormFields.Details);
      if (!scheduleDetail || scheduleDetail.pending) {
        return total;
      }

      let scheduleMiles = LoDash.get(scheduleDetail.get(DisplayOnlyFormFields.OrigToDestMileage), 'value', 0);
      const detourControl = scheduleDetail.get(LinehaulScheduleFormFields.DetourControl) as UntypedFormGroup;

      if (detourControl) {
        const detourFields = detourControl.get(ScheduleDetourFormFields.DtrFields);
        if (detourFields && detourFields.enabled) {
          const dtrTotalExMilCtrl = detourFields.get(ScheduleDetourFormFields.DtrTotalExMil);
          if (dtrTotalExMilCtrl.valid) {
            const detourMiles = +LoDash.get(dtrTotalExMilCtrl, 'value', 0);
            scheduleMiles += detourMiles;

            if (dtrTotalExMilCtrl.hasError(RouteDetourErrorType.TotalMilesLessThanZero)) {
              delete dtrTotalExMilCtrl.errors[RouteDetourErrorType.TotalMilesLessThanZero];
            }
          }

          if (scheduleMiles < 0) {
            dtrTotalExMilCtrl.setErrors({
              [RouteDetourErrorType.TotalMilesLessThanZero]: {
                displayError: true,
                type: RouteDetourErrorType.TotalMilesLessThanZero,
              },
            });
          } else {
            if (dtrTotalExMilCtrl.hasError(RouteDetourErrorType.TotalMilesLessThanZero)) {
              delete dtrTotalExMilCtrl.errors[RouteDetourErrorType.TotalMilesLessThanZero];
            }
          }

          const scheduleSeqNbr = schedule.get(LinehaulScheduleFormFields.SchSequenceNbr).value;
          errorWarningService.detailWarningsChanged(scheduleSeqNbr);
        }
      }

      return total + scheduleMiles;
    }, 0);

    if (totalMiles > mileageLimit) {
      const totalMilesWarning = {
        type: WarningValidationType.TotalMilesWarning,
        primarySubject: mileageLimit,
      };
      FormUtils.setWarning(control, WarningValidationType.TotalMilesWarning, totalMilesWarning);
    }

    errorWarningService.payformWarningsChanged();
    return null;
  };
}

@Directive({
  selector: '[totalMileageValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TotalMileageValidator, multi: true }],
})
export class TotalMileageValidator implements Validator {
  private mileageLimit: number;

  constructor(private errorWarningService: ErrorWarningService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return totalMileageValidatorFunction(this.mileageLimit, this.errorWarningService)(control);
  }
}
