import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function notSameValueValidator(names: string[], value?: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const controls: AbstractControl[] = names.map(v => control.get(v));
    const v = value === undefined || value === null ? controls[0].value : value;

    for (let i = 0; i < controls.length - 1; i++) {
      for (let j = 1; j < controls.length; j++) {
        if (controls[i].value === v && controls[j].value === v) {
          return { notSameValue: { value: v } };
        }
      }
    }

    return null;
  };
}

@Directive({
  selector: '[xpoNotSameValueValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: XpoNotSameValueValidatorDirective, multi: true }],
})
export class XpoNotSameValueValidatorDirective implements Validator {
  @Input() names: string[] = [];
  @Input() value: any = null;

  validate(control: AbstractControl): { [key: string]: any } {
    return this.names ? notSameValueValidator(this.names, this.value)(control) : null;
  }
}
