<div class="operation-types">
  <h2 md-dialog-title>Operation Type</h2>
  <mat-dialog-content fxLayout="column" class="operation-types-content">
    <div fxLayout="row" class="operation-types-content-header">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50" class="operation-types-content-header-orig-dest">
        <span>{{data.origin | uppercase}}</span>
        <mat-icon>arrow_forward</mat-icon>
        <span>{{data.destination | uppercase}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
        Displaying {{(opTypeList$ | async)?.length}} choices
      </div>
    </div>
    <mat-selection-list #selectList tabindex="-1">
      <mat-list-option *ngFor="let optype of opTypeList$ | async" [value]="optype" class="operation-types-content-row" [ngClass]="{'selected': optype === selectedOptype}">
        <div fxLayout="row" fxLayoutAlign="stretch center" fxFill="">
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="68" class="operation-types-content-description">
            <span><b *ngIf="optype.optype > 0">{{optype.optype}} - </b>{{optype.description}}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" fxFlex="30">
              <mat-icon [svgIcon]="optype.image" [ngStyle]="{'width': optype.imageWidth, 'height': '35px'}"></mat-icon>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions class="operation-types-actionbar">
    <button mat-button
            color="primary"
            (click)="cancelClicked()"
            tabindex="-1">Cancel</button>
    <button mat-button
            color="primary"
            (click)="doneClicked()"
            [disabled]="!selectedOptype"
            tabindex="-1">Done</button>
  </mat-dialog-actions>
</div>
