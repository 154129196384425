<div class="return-reason-feedback">
  <h2 md-dialog-title>Return Reason Feedback</h2>
  <mat-dialog-content class="return-reason-feedback-content">
    <div class="return-reason-feedback-content-input"
         fxLayout="row"
         fxLayoutAlign="start start">
      <mat-form-field fxFlex="">
        <textarea matInput
                  matTextareaAutosize
                  matAutosizeMinRows="5"
                  matAutosizeMaxRows="5"
                  maxlength="250"
                  placeholder="Please describe your reason for returning this payform to the DSR."
                  [(ngModel)]="feedback"
                  required
                  #feedbackInput="ngModel"></textarea>
        <mat-hint align="end">{{feedbackInput?.value?.length}} / 250</mat-hint>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="return-reason-feedback-actionbar">
    <button mat-button
            color="primary"
            (click)="cancelClicked()"
            tabindex="-1">Cancel</button>
    <button mat-button
            color="primary"
            (click)="returnClicked()"
            tabindex="-1"
            [disabled]="!feedbackInput.valid">Return to DSR</button>
  </mat-dialog-actions>
</div>
