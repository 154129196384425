import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of as observableOf, Subject } from 'rxjs';
import { concatMap, defaultIfEmpty, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { PayformState } from './app.state';
import { DsrActivity, DsrPayform, LinehaulSchedule, Note, ScheduleTrailer } from '@xpo-ltl/sdk-linehaulpayform';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { AppStateUtils } from './app-state.utils';
import { tassign } from 'tassign';
import { EdgeAppStateStore, EdgeModuleStateStore } from '@xpo-ltl/ngx-ltl-app-state';
export abstract class PayPortalStateStoreBase<T> {
  private stateSubject$: BehaviorSubject<T>;

  private updateStateQueueSource$ = new Subject<T>();
  private updateStateQueue$ = this.updateStateQueueSource$.asObservable();

  protected get state$(): Observable<T> {
    return this.stateSubject$.asObservable();
  }

  protected get state(): T {
    return this.stateSubject$.value;
  }

  protected registeredStateProperties = [];
  private queryParams: { [key: string]: string } = {};

  /** Notifies when we want to update the query parameters in the URL */
  protected queryParamsChanged: Subject<any> = new Subject();
  queryParamsChanged$ = this.queryParamsChanged.asObservable();

  constructor() {
    this.stateSubject$ = new BehaviorSubject<T>({
      ...this.getInitialState(),
    } as T);

    // Subscribing to requests to update the state change. This is to ensure that 2 state changes are not
    // happening at the same time. With concatMap, it ensures  that updates to the state are made sequentially,
    // ensuring that the recent state is used when doing this.stateSubject.getValue()
    this.updateStateQueue$.pipe(concatMap(v => observableOf(Object.assign({}, this.stateSubject$.getValue(), v)))).subscribe(newState => {
      this.stateSubject$.next(newState);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected createStateObservable$<K>(func: (s: T) => any): Observable<K> {
    return this.state$.pipe(map(func), distinctUntilChanged());
  }

  /**
   * The initial state of the store that will be appended to the base initial state of the store
   */
  protected abstract getInitialState(): Partial<T>;

  protected publishStateChange(state: T): void {
    this.updateQueryParams(state);
    this.updateStateQueueSource$.next(state);
  }

  /**
   * Returns the current registered query parameters
   */
  public getQueryParameters(): { [key: string]: string } {
    return this.queryParams;
  }

  /**
   * Extracts the registered property from the specified state
   * to make it available in the URL
   * @param state Current application state
   */
  protected updateQueryParams(state: T): void {
    this.registeredStateProperties.forEach(property => {
      const foundProp = this.findPropertyInState(state, property);
      if (foundProp) {
        this.queryParams[foundProp.key] = foundProp.value;
      }
    });
  }

  /**
   * Triggers a notification for the queryParamsChanged$ Observable
   */
  public emitQueryParamsChangeEvent(): void {
    this.queryParamsChanged.next(true);
  }

  /**
   * Looks into the specified object for the specified property name
   * recursively
   * @param state State to look into
   * @param property Property name to search for
   * @returns The property with its value
   */
  protected findPropertyInState(state: any, property: string): any {
    for (const key in state) {
      if (typeof state[key] == 'object' && state[key] !== null) {
        return this.findPropertyInState(state[key], property);
      } else if (state.hasOwnProperty(property)) {
        return { key: property, value: state[property] };
      } else {
        return null;
      }
    }
  }

  /**
   * Allows us to keep control of the registered state properties.
   * Any property added here will be matched against every state
   * update
   * @param properties Property names to be registered into query parameter control
   */
  public registerStatePropertiesIntoQueryParams(properties: string[]): void {
    properties.forEach(property => {
      if (!this.registeredStateProperties.includes(property)) {
        this.registeredStateProperties.push(property);
      }
    });
  }

  /**
   * Allows us to keep control of the registered state properties.
   * Removing a property will cause the state changes to stop matching it
   * against the state properties.
   * @param properties Property names to be removed from query parameter control
   */
  public unregisterStatePropertiesFromQueryParams(properties: string[]): void {
    properties.forEach(property => {
      if (this.registeredStateProperties.includes(property)) {
        this.registeredStateProperties.splice(this.registeredStateProperties.indexOf(property), 1);
      }
    });
  }
}

export interface PayPortalAppState {
  payform?: PayformState;
  isReadOnly?: boolean;
  sicCd?: string;
}

@Injectable({ providedIn: 'root' })
export class PayPortalStateStore extends EdgeModuleStateStore<PayPortalAppState> {
  protected getInitialState(): Partial<PayPortalAppState> {
    return {};
  }
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  readonly generalParameters$: Observable<Map<string, string>>;
  generalParameters: Map<string, string>;

  constructor(appStateStore: EdgeAppStateStore) {
    super(appStateStore);

    this.generalParameters$ = combineLatest(
      [appStateStore.region$().pipe(defaultIfEmpty(null)), appStateStore.sic$().pipe(defaultIfEmpty(null)), appStateStore.shift$().pipe(defaultIfEmpty(null))],
      (region, sic, shift) => {
        this.generalParameters = new Map<string, string>([
          ['sicCode', sic?.sicCode || ''],
          ['shiftCode', shift?.shiftCode || ''],
        ]);
        return this.generalParameters;
      }
    );
  }
  public setPayform(state: PayformState, action: any): PayformState {
    return {
      readOnly: state.readOnly,
      current: { ...action.newPayform },
      pristine: { ...action.newPayform },
    };
  }

  setPayformStateAction(payform: PayformState): void {
    this.publishStateChange({
      payform: {
        readOnly: this.state.isReadOnly,
        current: { ...payform.current },
        pristine: { ...payform.pristine },
      },
    });
  }

  getPayformState() {
    return this.state$.pipe(map(state => state.payform));
  }

  getPayformFilterState() {
    return this.state$.pipe(map(state => state));
  }

  getCurrentPayformState() {
    return this.state$.pipe(map(state => state.payform?.current));
  }

  getCurrentDsrPayformStatusCd() {
    return this.state$.pipe(map(state => state.payform.current.statusCd));
  }

  setCurrentPayformAction(current: DsrPayform): void {
    this.publishStateChange({
      payform: {
        readOnly: this.state.isReadOnly,
        current: { ...current },
        pristine: { ...current },
      },
    });
  }

  setPayformsFilterSicAction(sicCd: string) {
    const updatedState = { ...this.state, ...{ sicCd } };
    this.publishStateChange(updatedState);
  }

  setPayformReadOnlyAction(isReadOnly: boolean): void {
    const updatedState = {
      payform: {
        current: this.state.payform.current,
        pristine: this.state.payform.pristine,
        readOnly: isReadOnly,
      },
      ...{ isReadOnly },
    };
    this.publishStateChange(updatedState);
  }

  setPayformNote(note: Note) {
    const newNote = note;
    newNote.listActionCd = ActionCd.ADD;
    const updatedState = { ...this.state.payform.current, note: [...(this.state.payform.current?.note ?? []), newNote] };
    this.publishStateChange({
      payform: {
        readOnly: this.state.isReadOnly,
        current: { ...updatedState },
        pristine: { ...updatedState },
      },
    });
  }

  addNoteAction(note: Note) {
    let notes = this.state.payform.current.note;

    const newNote = note;
    this.setPayformNote(newNote);
    notes = [...notes, newNote];
    return notes;
  }

  updateNoteAction(note: Note) {
    const notes = this.state.payform.current.note;

    const noteIndex = AppStateUtils.findNoteIndexInState(notes, note.noteSequenceNbr);

    if (notes[noteIndex].listActionCd !== ActionCd.ADD) {
      notes[noteIndex].listActionCd = ActionCd.UPDATE;
    }
    notes[noteIndex].comments = note.comments;

    return [...notes];
  }

  deleteNoteAction(noteSequenceNbr: string) {
    let notes = this.state.payform.current.note;

    const existingNote = AppStateUtils.findNoteInState(notes, noteSequenceNbr) as Note;
    if (existingNote) {
      if (existingNote.listActionCd === ActionCd.ADD) {
        // remove newly added note from list
        notes = notes.filter(note => note !== existingNote);
      } else {
        // mark exisiting note as deleted
        existingNote.listActionCd = ActionCd.DELETE;
      }
      return [...notes];
    } else {
      // note didn't exist in this Payform, so nothing to do
      return notes;
    }
  }

  setPayformActivity(activity: DsrActivity) {
    if (activity) {
      const newActivity = activity;
      newActivity.listActionCd = ActionCd.ADD;
      newActivity.statusCd = 'New';
      const updatedState = { ...this.state.payform.current, dsrActivity: [...(this.state.payform.current?.dsrActivity ?? []), newActivity] };
      this.publishStateChange({
        payform: {
          readOnly: this.state.isReadOnly,
          current: { ...updatedState },
          pristine: { ...updatedState },
        },
      });
    }
  }

  moveActivityAction(activities, actvtySequenceNbr: any, targetSchSequenceNbr: any) {
    // let clonedActivities = activities;
    if (AppStateUtils.findActivityInState(activities, actvtySequenceNbr)) {
      // eslint-disable-next-line radix
      const existing = activities.filter(item => parseInt(item.actvtySequenceNbr) === parseInt(actvtySequenceNbr))[0] as DsrActivity;
      activities = activities.filter(act => act !== existing);
      existing.schSequenceNbr = targetSchSequenceNbr ? targetSchSequenceNbr : undefined;
      if (existing.listActionCd !== ActionCd.DELETE && existing.listActionCd !== ActionCd.ADD) {
        // Activity is not new, and not marked for delete, so mark it for Update
        existing.listActionCd = ActionCd.UPDATE;
      }
      activities = [...activities, existing];
      return activities;
    } else {
      // activity didn't exist in this Payform, so nothing to do
      return activities;
    }
  }

  deleteActivityAction(actvtySequenceNbr: string) {
    let activities = this.state.payform.current.dsrActivity;
    const existing = AppStateUtils.findActivityInState(activities, actvtySequenceNbr);
    if (existing && existing.listActionCd !== ActionCd.DELETE) {
      if (existing.listActionCd === ActionCd.ADD) {
        // remove newly added activity from list
        activities = activities.filter(act => act !== existing);
      } else {
        // mark exisiting activity as deleted
        existing.listActionCd = ActionCd.DELETE;
      }
      activities = [...activities];
      return activities;
    } else {
      // activity didn't exist in this Payform, so nothing to do
      return activities;
    }
  }

  setPayformSchedule(schedule: any) {
    const newSchedule = schedule;
    newSchedule.listActionCd = ActionCd.ADD;
    const updatedState = { ...this.state.payform.current, linehaulSchedule: [...(this.state.payform.current.linehaulSchedule ?? []), newSchedule] };
    this.publishStateChange({
      payform: {
        readOnly: this.state.isReadOnly,
        current: { ...updatedState },
        pristine: { ...updatedState },
      },
    });
  }

  deleteScheduleAction(schSequenceNbr: string) {
    let schedules = this.state.payform.current.linehaulSchedule;

    const existing = AppStateUtils.findScheduleInState(schedules, schSequenceNbr) as LinehaulSchedule;
    if (existing) {
      if (existing.listActionCd === ActionCd.ADD) {
        // remove newly added activity from list
        schedules = schedules.filter(schedule => schedule !== existing);
      } else {
        // mark exisiting activity as deleted
        existing.listActionCd = ActionCd.DELETE;
      }
      schedules = [...schedules];
      return schedules;
    } else {
      // Schedule didn't exist in this Payform, so nothing to do
      return schedules;
    }
  }

  getPayformReadOnlySelector() {
    return this.state$.pipe(map(state => state.isReadOnly));
  }

  updateActivityAction(state: any, dsrActivity: DsrActivity) {
    const actvtySequenceNbr = dsrActivity?.actvtySequenceNbr;
    const existing: DsrActivity = AppStateUtils.getActivityFromState(state, actvtySequenceNbr);
    if (!existing) {
      throw new Error(`ACTIVITY '${actvtySequenceNbr}' NOT FOUND`);
    }

    if (existing.listActionCd === ActionCd.DELETE) {
      throw new Error(`ACTIVITY '${actvtySequenceNbr}' IS DELETED`);
    }

    for (const [key, value] of Object.entries(dsrActivity)) {
      existing[key] = value;
    }

    if (existing.listActionCd !== ActionCd.ADD) {
      existing.listActionCd = ActionCd.UPDATE;
    }

    return existing;
  }

  patchScheduleAction(action: any): LinehaulSchedule[] {
    const schedules = this.state.payform.current.linehaulSchedule;
    const scheduleIndex = AppStateUtils.findScheduleIndexInState(schedules, action.schSequenceNbr);
    if (scheduleIndex < 0) {
      throw new Error(`SCHEDULE '${action.schSequenceNbr}' NOT FOUND`);
    }

    schedules[scheduleIndex] = tassign(schedules[scheduleIndex], action.patchData);

    if (schedules[scheduleIndex].listActionCd !== ActionCd.ADD) {
      schedules[scheduleIndex].listActionCd = ActionCd.UPDATE;
    }

    return schedules;
  }

  updateTrailerAction(state: ScheduleTrailer[], action: any) {
    if (!state) {
      return;
    } else {
      let newState = [...state];

      let index = state.findIndex(
        trailer => trailer.listActionCd !== ActionCd.DELETE && trailer.displaySequenceNbr === action.trailer.displaySequenceNbr && trailer.trlrNbr !== action.trailer.trlrNbr
      );
      if (index >= 0) {
        // there is an existing, different trailer in the requested display slot. mark it for delete
        newState = AppStateUtils.deleteTrailerAt(newState, index);
      }

      index = newState.findIndex(trailer => trailer.trlrNbr === action.trailer.trlrNbr && trailer.displaySequenceNbr === action.trailer.displaySequenceNbr);
      if (index >= 0) {
        // trailer already exists, so update it
        newState[index] = tassign(newState[index], action.trailer, {
          listActionCd: newState[index].listActionCd === ActionCd.ADD ? ActionCd.ADD : ActionCd.UPDATE,
        });
      } else {
        // trailer didn't exist, so we need to add it
        const newTrailer = tassign(new ScheduleTrailer(), action.trailer, { listActionCd: ActionCd.ADD });
        newState.push(newTrailer);
      }

      return newState;
    }
  }

  deleteTrailerAction(state: ScheduleTrailer[], action: any) {
    if (state) {
      const index = state.findIndex(trailer => trailer.listActionCd !== ActionCd.DELETE && trailer.displaySequenceNbr === action.displaySequenceNbr);
      if (index >= 0) {
        const newState = AppStateUtils.deleteTrailerAt([...state], index);
        return newState;
      } else {
        return state;
      }
    }
  }
}
