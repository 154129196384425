<h1 mat-dialog-title class="alert-wrapper__title">{{data.titleText || 'NO TITLE PROVIDED'}}
</h1>
<div mat-dialog-content class="alert-wrapper__content">
  <ng-template appDialogTemplateInjector>
  </ng-template>
  <div *ngIf="data.contentText">
    {{data.contentText}}
  </div>
</div>
<div class="alert-wrapper__footer" mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button *ngIf="!data?.hideDismissButton" mat-button tabindex="-1" (click)="handleCloseClick()" color="primary">{{data.dismissalText || 'Close'}}</button>
</div>
