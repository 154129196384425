import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LinehaulScheduleFormFields } from '../../../../enums/linehaul-schedule-form-fields.enum';
import { DisplayOnlyFormFields } from '../../../../enums/display-only-form-fields.enum';
import { AppConstantsService } from '../../../../services';
import { ErrorWarningService } from '../../services';
import { routeTypeValidatorFunction, operationTypeValidatorFunction, hazmatOverrideValidatorFunction, hazmatRouteValidatorFunction } from '../../../../validators';

/**
 * Return a FormGroup that contains all of the controls that need to be updated and/or validated.
 *
 * Add any Validators here so that they run even when the HTML controls are not rendered
 */
export class ScheduleDetailsFormBuilder {
  public static create(formBuilder: UntypedFormBuilder, errorWarningService: ErrorWarningService, constantsService: AppConstantsService, schSequenceNbr: string): UntypedFormGroup {
    const formGroup = formBuilder.group(
      {
        [LinehaulScheduleFormFields.CurrentRouteValue]: [null],
        [DisplayOnlyFormFields.OrigToDestMileage]: [0],
        [DisplayOnlyFormFields.TotalMileage]: [0],
        [DisplayOnlyFormFields.ScheduleTripCode]: [null],
        [DisplayOnlyFormFields.HazmatOverrideInd]: [false],
      },
      {
        validator: [
          routeTypeValidatorFunction(errorWarningService, constantsService, schSequenceNbr),
          operationTypeValidatorFunction(errorWarningService, schSequenceNbr),
          hazmatOverrideValidatorFunction(errorWarningService, schSequenceNbr),
          hazmatRouteValidatorFunction(errorWarningService, schSequenceNbr),
        ],
      }
    );
    return formGroup;
  }
}
