import { Pipe, PipeTransform } from '@angular/core';
import { LinehaulSchedule } from '@xpo-ltl/sdk-linehaulpayform';
import { EslCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'eslCushion',
})
export class EslCushionPipe implements PipeTransform {
  transform(schedule: LinehaulSchedule, args?: any): any {
    if (schedule) {
      if (schedule.eslCd === EslCd.REGULAR) {
        return 'ESL';
      }
      if (schedule.eslCd === EslCd.SOLO) {
        return 'ESL Solo';
      }
      if (schedule.cushionInd) {
        return 'Cushion';
      }
    }

    return '';
  }
}
