import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { RoutingInstructions } from '@xpo-ltl/sdk-reference';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, retryWhen, take, takeUntil } from 'rxjs/operators';
import { RouteTypesData } from '../../classes/route-types-data';
import { RoutingInstructionsCacheService } from '../../services/routing-instructions-cache.service';
import { LoDash } from '../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-route-types',
  templateUrl: './route-types.component.html',
  styleUrls: ['./route-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteTypesComponent implements OnInit, OnDestroy {
  private routeListSubject = new BehaviorSubject<RoutingInstructions[]>([]);
  public routeList$ = this.routeListSubject.asObservable();
  public selectedRoute: RoutingInstructions;
  private searchingSubject = new BehaviorSubject<boolean>(true);
  public searching$ = this.searchingSubject.asObservable();

  @ViewChild('selectList', { static: true })
  selectList: MatSelectionList;

  private unsubscriber = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<RouteTypesComponent>, @Inject(MAT_DIALOG_DATA) public data: RouteTypesData, private routingInstructionsCache: RoutingInstructionsCacheService) {}

  ngOnInit() {
    this.routingInstructionsCache
      .request({
        origSicCd: this.data.origin,
        destSicCd: this.data.destination,
      })
      .pipe(retryWhen(errors => errors.pipe(delay(1000), take(10))))
      .subscribe(
        routingInstructions => {
          if (routingInstructions && routingInstructions.length > 0) {
            this.routeListSubject.next(LoDash.sortBy(routingInstructions, item => item.tripCd));
          }

          this.searchingSubject.next(false);
        },
        error => {
          this.searchingSubject.next(false);
        }
      );

    this.selectList.selectionChange.pipe(takeUntil(this.unsubscriber)).subscribe((selChange: any) => {
      this.selectList.deselectAll();
      selChange.options[0].selected = true;
      this.selectedRoute = this.selectList.selectedOptions.selected[0].value;
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  doneClicked() {
    const selectedItem = this.selectList.selectedOptions.selected[0];
    this.dialogRef.close(this.selectedRoute);
  }
}
