import { Injectable } from '@angular/core';

Injectable();
export abstract class LoDash {
  public static get(obj, propsArg, defaultValue?) {
    const [current, ...rest] = this.sanitizePath(propsArg);

    if (!obj) {
      return defaultValue;
    }
    let props;
    let prop;
    if (Array.isArray(propsArg)) {
      props = propsArg.slice(0);
    }
    if (typeof propsArg == 'string') {
      props = propsArg.split('.');
    }
    if (typeof propsArg == 'symbol') {
      props = [propsArg];
    }
    if (!Array.isArray(props)) {
      throw new Error('props arg must be an array, a string or a symbol');
    }
    while (props.length) {
      prop = props.shift();
      if (!obj) {
        return defaultValue;
      }
      obj = obj[prop];
      if (obj === undefined) {
        return defaultValue;
      }
    }
    return obj;
  }

  public static getWithRegexp(obj, path, defaultValue = undefined) {
    const travel = regexp =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
  }

  public static set(obj, path, value) {
    const [current, ...rest] = this.sanitizePath(path);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    rest.length >= 1 ? this.set((obj[current] = obj[current] || {}), rest, value) : (obj[current] = value);
    return obj;
  }

  public static unset(obj, path) {
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    pathArray.reduce((acc, key, i) => {
      if (i === pathArray.length - 1) {
        delete acc[key];
      }
      return acc[key];
    }, obj);
  }

  public static isEmpty(obj) {
    if (obj == null) {
      return true;
    }

    if (Array.isArray(obj)) {
      return !obj.length;
    }

    if (typeof obj == 'string') {
      return !obj.length;
    }

    const type = {}.toString.call(obj);

    if (type === '[object Object]') {
      return !Object.keys(obj).length && !Object.getOwnPropertySymbols(obj).length;
    }

    if (type === '[object Map]' || type === '[object Set]') {
      return !obj.size;
    }

    // other primitive || unidentifed object type
    return Object(obj) !== obj || !Object.keys(obj).length;
  }

  public static filter(obj, predicate) {
    const result = {};
    const keys = Object.keys(obj);
    const len = keys.length;
    for (let i = 0; i < len; i++) {
      const key = keys[i];
      if (predicate(key, obj[key])) {
        result[key] = obj[key];
      }
    }
    return result;
  }

  public static values(obj) {
    const result = [];
    if (Array.isArray(obj)) {
      return obj.slice(0);
    }
    if (typeof obj == 'object' || typeof obj == 'function') {
      const keys = Object.keys(obj);
      const len = keys.length;
      for (let i = 0; i < len; i++) {
        result.push(obj[keys[i]]);
      }
      return result;
    }
    throw new Error('argument to `values` must be an object');
  }

  public static replace(str, subStr, newSubStr) {
    if (arguments.length !== 3 || typeof str != 'string' || typeof subStr != 'string' || typeof newSubStr != 'string') {
      throw new Error('just-replace-all expects three string arguments');
    }
    if (!subStr) {
      return str;
    }
    return str.split(subStr).join(newSubStr);
  }

  public static has(obj, propsArg) {
    if (!obj) {
      return false;
    }
    let props;
    let prop;
    if (Array.isArray(propsArg)) {
      props = propsArg.slice(0);
    }
    if (typeof propsArg == 'string') {
      props = propsArg.split('.');
    }
    if (typeof propsArg == 'symbol') {
      props = [propsArg];
    }
    if (!Array.isArray(props)) {
      throw new Error('props arg must be an array, a string or a symbol');
    }

    while (props.length) {
      prop = props.shift();

      // if we are recursing, but met a nullish value, we cannot
      // access it via .hasOwnProperty and should return negatively
      if (obj == null) {
        return false;
      }
      if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
      if (props.length === 0) {
        return true;
      }

      obj = obj[prop];
    }

    return false;
  }

  public static last(arr) {
    if (!Array.isArray(arr)) {
      throw new Error('expected an array');
    }
    return arr[arr.length - 1];
  }

  public static sortBy(arr, iteratee) {
    if (!Array.isArray(arr)) {
      throw new Error('arr should be an array');
    }

    if (iteratee !== undefined && typeof iteratee !== 'string' && typeof iteratee !== 'function') {
      throw new Error('iteratee should be a string or a function');
    }

    if (arr.length <= 1) {
      return arr;
    }

    const copied = arr.slice();

    if (!iteratee) {
      return copied.sort(function(a, b) {
        return a - b;
      });
    }

    return copied.sort(this.handleSort(iteratee));
  }

  public static capitalize(str) {
    if (typeof str != 'string') {
      throw Error('just-capitalize expects a string argument');
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  public static inRange(number, start, end?) {
    if (typeof number !== 'number') {
      throw new Error('expected a number for first argument');
    }

    if (typeof start !== 'number') {
      throw new Error('expected a number for second argument');
    }

    if (end !== undefined && typeof end !== 'number') {
      throw new Error('expected a number or undefined for third argument');
    }

    let _start = start;
    let _end = end;

    if (arguments.length === 2) {
      _start = 0;
      _end = start;
    }

    return number >= _start && number < _end;
  }

  public static range(start, end) {
    return Array.apply(0, Array(end - start)).map((element, index) => index + start);
  }

  public static isEqual(x, y) {
    const ok = Object.keys;
    const tx = typeof x;
    const ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? ok(x).length === ok(y).length && ok(x).every(key => LoDash.isEqual(x[key], y[key])) : x === y;
  }

  public static padStart(str, length, padStr) {
    if (typeof str != 'string' || (arguments.length > 2 && typeof padStr != 'string') || !(isFinite(length) && length >= 0 && Math.floor(length) === length)) {
      throw Error('1st and 3rd args must be strings, 2nd arg must be a positive integer');
    }
    if (padStr == null || padStr === '') {
      padStr = ' ';
    }
    const strLen = str.length - (str.match(this.surrogatePairRegEx) || []).length;
    const padStrLen = padStr.length;
    const surrPairsInPad = (padStr.match(this.surrogatePairRegEx) || []).length;
    if (surrPairsInPad && padStrLen / surrPairsInPad !== 2) {
      throw Error('padding mixes regular characters and surrogate pairs');
    }

    if (!length || length <= strLen) {
      return str;
    }
    let padCount = Math.floor((length - strLen) / padStrLen);
    let padRemainder = (length - strLen) % padStrLen;
    if (surrPairsInPad) {
      padCount = 2 * padCount;
      padRemainder = 2 * padRemainder;
    }
    return (padRemainder ? [padStr.slice(-padRemainder)] : [])
      .concat(new Array(padCount + 1).join(padStr))
      .concat(str)
      .join('');
  }

  public static unique(arr, sorted?, strings?) {
    if (!Array.isArray(arr)) {
      throw new Error('expected an array for the first argument');
    }
    if (sorted != null && typeof sorted != 'boolean') {
      throw new Error('expected a boolean, null or undefined for the second argument');
    }
    if (strings != null && typeof strings != 'boolean') {
      throw new Error('expected a boolean, null or undefined for the third argument');
    }
    if (!sorted && strings && arr[0] !== Object(arr[0])) {
      return this.stringUnique(arr);
    }
    const result = [];
    let duplicate;
    let seenNaN;
    let lastAdded;
    const len = arr.length;
    for (let i = 0; i < len; i++) {
      const elem = arr[i];
      if (typeof elem == 'number' && isNaN(elem)) {
        duplicate = seenNaN;
        seenNaN = true;
      }
      duplicate = duplicate || (lastAdded && lastAdded === elem);
      if (!duplicate && !sorted) {
        duplicate = result.indexOf(elem) > -1;
      }
      if (!duplicate) {
        result.push(elem);
        lastAdded = elem;
      } else {
        duplicate = false;
      }
    }
    return result;
  }

  public static reduce(obj, predicate /*, initialValue*/) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const args = [callback];
    // `initialValue` is optional
    let hasInitialValue = 2 in arguments;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    hasInitialValue && args.push(arguments[2]);

    function callback(previousValue, currentKey, currentIndex, array) {
      // when `initialValue` is not provided then
      // `previousValue` is the value associated to the first key
      if (!hasInitialValue) {
        previousValue = obj[array[0]];
        hasInitialValue = true;
      }
      return predicate(previousValue, currentKey, obj[currentKey], currentIndex, array);
    }

    return Array.prototype.reduce.apply(Object.keys(obj), args);
  }

  public static pickBy(object) {
    const obj = {};
    for (const key in object) {
      if (object[key]) {
        obj[key] = object[key];
      }
    }
    return obj;
  }

  public static pullAt(arr, idxs) {
    return idxs
      .reverse()
      .map(idx => arr.splice(idx, 1)[0])
      .reverse();
  }

  public static castArray(arr) {
    return Array.isArray(arr) ? arr : [arr];
  }

  public static startCase(str) {
    return str.replace(/(^|\s)\S/g, function(t) {
      return t.toUpperCase();
    });
  }

  private static sanitizePath(path) {
    let rgxBracketToDot;
    path = path || [];
    return Array.isArray(path) ? path : path.replace(rgxBracketToDot || (rgxBracketToDot = /\[(\w+)\]/g), '.$1').split('.');
  }

  private static stringUnique(arr) {
    const lookup = {};
    const len = arr.length;
    for (let i = 0; i < len; i++) {
      lookup[arr[i]] = true;
    }
    return Object.keys(lookup);
  }

  private static surrogatePairRegEx = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

  private static handleSort(iteratee) {
    return function(a, b) {
      const keyA = typeof iteratee === 'string' ? a[iteratee] : iteratee(a);
      const keyB = typeof iteratee === 'string' ? b[iteratee] : iteratee(b);

      if (typeof keyA === 'string' && typeof keyB === 'string') {
        const valueA = keyA.toUpperCase();
        const valueB = keyB.toUpperCase();

        if (valueA < valueB) {
          return -1;
        }

        if (valueA > valueB) {
          return 1;
        }

        return 0;
      }

      return keyA - keyB;
    };
  }
}
