import { ListPayformsComponent } from './components/list-payforms/list-payforms.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { RouterUriComponents } from './enums/router-uri-components.enum';
import { PayformResolver } from './resolvers/payform.resolver';
import { PayformComponent } from './components/payform/payform.component';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

export const RouterDefinitions: Routes = [
  {
    path: RouterUriComponents.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
  },
  {
    path: RouterUriComponents.LIST_PAYFORMS,
    component: ListPayformsComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: RouterUriComponents.PAYFORM,
    loadChildren: () => import('app/components/payform/payform.module').then(m => m.PayformModule),
  },
  {
    path: `${RouterUriComponents.VIEW_PAYFORM}/:id`,
    loadChildren: () => import('app/components/payform/payform.module').then(m => m.PayformModule),
    resolve: { dsrPayform: PayformResolver },
    component: PayformComponent,
  },
  {
    path: '',
    redirectTo: `/${RouterUriComponents.LIST_PAYFORMS}`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: true })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
