<div class="notes-overlay">
  <div fxLayout="column">
    <div class="notes-overlay__header"
         fxLayout="row">
      <div fxFlex=""
           fxLayoutAlign="start center"
           class="notes-overlay__header__title">DSR Comment</div>
      <div fxLayoutAlign="end center" class="notes-overlay__close">
        <button mat-button
                mat-icon-button
                (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="notes-overlay__notes">
    <div class="notes-overlay__notes__note"
         *ngFor="let note of notes$ | async">{{note.comments}}</div>
  </div>
</div>
