import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { PayformNoteTypeCd } from '@xpo-ltl/sdk-common';
import { LinehaulSchedule } from '@xpo-ltl/sdk-linehaulpayform';
import { RoutingInstructions } from '@xpo-ltl/sdk-reference';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { FormStateDirective, FormUtils } from '../../../../classes';
import { DisplayOnlyFormFields, ErrorWarningTypes, LinehaulScheduleFormFields, SnackbarMessage, WarningValidationType } from '../../../../enums';
import { AppConstantsService, DriverPayService } from '../../../../services';
import { AsyncValidatorCompletionService } from '../../../../services/async-validator-completion.service';
import { RoutingInstructionsCacheService } from '../../../../services/routing-instructions-cache.service';
import { ErrorWarningService, PayFormService } from '../../services';
import { ScheduleDetailsFormBuilder } from './schedule-details.form-builder';
import { AppStateUtils, PayPortalStateStore, PayformState } from 'app';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { LoDash } from '../../../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleDetailsComponent extends FormStateDirective implements OnInit {
  readonly: boolean;

  @Input()
  title: string;
  @Input()
  schSequenceNbr: string;
  @Input()
  expandCollapse: boolean;
  @Input()
  payformGroup: UntypedFormGroup;

  @ViewChild(MatExpansionPanel)
  panel: MatExpansionPanel;

  private scheduleSubject = new BehaviorSubject<LinehaulSchedule>(undefined);
  public schedule$ = this.scheduleSubject.asObservable();

  private currentRoute: RoutingInstructions;

  private totalMilesSubject = new BehaviorSubject<number>(0);
  public totalMiles$ = this.totalMilesSubject.asObservable();

  private validationTypeSubject = new BehaviorSubject<string>(ErrorWarningTypes.None);
  public validationType$ = this.validationTypeSubject.asObservable();

  private warningsCollectionSubject = new BehaviorSubject<Array<any>>([]);
  public warningsCollection$ = this.warningsCollectionSubject.asObservable();

  private errorsCollectionSubject = new BehaviorSubject<Array<any>>([]);
  public errorsCollection$ = this.errorsCollectionSubject.asObservable();

  public readonly LinehaulScheduleFormFields = LinehaulScheduleFormFields;
  public readonly DisplayOnlyFormFields = DisplayOnlyFormFields;
  public readonly PayformNoteTypeCd = PayformNoteTypeCd;
  public readonly ErrorWarningTypes = ErrorWarningTypes;
  public readonly WarningValidationType = WarningValidationType;

  public timezoneSic = '';
  public payformState: PayformState;
  readonly$: Observable<boolean>;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private payformService: PayFormService,
    private driverPayService: DriverPayService,
    private routingInstructionsCacheService: RoutingInstructionsCacheService,
    private asyncValidatorCompletion: AsyncValidatorCompletionService,
    private constantsService: AppConstantsService,
    private config: ConfigManagerService,
    public errorWarningService: ErrorWarningService,
    private state: PayPortalStateStore,
    private xpoSnackBar: XpoSnackBar
  ) {
    super(formBuilder);
  }

  public get equipmentFormArray(): UntypedFormArray {
    // TODO - This should come from LinehaulSchedule
    if (!!this.form) {
      return this.form.get(LinehaulScheduleFormFields.ScheduleTrailer) as UntypedFormArray;
    }
  }

  public get scheduleDetourFormGroup(): UntypedFormGroup {
    // TODO - This should come from LinehaulSchedule
    if (!!this.form) {
      return this.form.get(LinehaulScheduleFormFields.ScheduleDetour) as UntypedFormGroup;
    }
  }

  ngOnInit() {
    this.linkFormToParent(LinehaulScheduleFormFields.Details, ScheduleDetailsFormBuilder.create, this.errorWarningService, this.constantsService, this.schSequenceNbr);

    this.readonly$ = this.state.getPayformReadOnlySelector();
    // Listen for State changes
    this.state
      .getPayformState()
      .pipe(
        takeUntil(this.unsubscriber.done),
        map((state: PayformState) => {
          this.payformState = state;
          return AppStateUtils.getScheduleFromPayform(state.current, this.schSequenceNbr);
        })
      )
      .subscribe(schedule => {
        this.scheduleSubject.next(schedule);
        this.updateFromState(schedule);
      });

    this.errorWarningService.DetailWarning$.pipe(takeUntil(this.unsubscriber.done)).subscribe(schSequenceNbr => {
      if (schSequenceNbr === this.schSequenceNbr) {
        this.checkFormErrorsWarnings();
      }
    });

    merge(this.form.statusChanges, this.asyncValidatorCompletion.asyncValidationCompleted$)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(value => {
        this.checkFormErrorsWarnings();
      });

    this.timezoneSic = this.payformState?.current?.dmclSic;
  }

  checkFormErrorsWarnings() {
    const warnings = this.errorWarningService.collectWarnings(this.form);
    this.warningsCollectionSubject.next(warnings);

    const displayErrors = this.errorWarningService.collectErrors(this.form);
    this.errorsCollectionSubject.next(displayErrors);

    if (this.form.invalid) {
      this.validationTypeSubject.next(ErrorWarningTypes.Error);
      this.openPanel();
      return;
    }

    if (warnings.length > 0) {
      this.validationTypeSubject.next(ErrorWarningTypes.Warning);
      this.openPanel();
      return;
    }

    this.validationTypeSubject.next(ErrorWarningTypes.None);
    this.closePanel();
  }

  protected updateFromState(schedule: LinehaulSchedule) {
    if (
      !this.currentRoute ||
      this.currentRoute.origSicCd !== schedule?.originSicCd ||
      this.currentRoute.destSicCd !== AppStateUtils.getDestination(schedule) ||
      this.currentRoute.drivingLegId !== schedule?.drivingLegId
    ) {
      // Origin/Destination changed so get new route
      this.routingInstructionsCacheService
        .request({
          origSicCd: schedule?.originSicCd,
          destSicCd: AppStateUtils.getDestination(schedule),
          drivingLegId: schedule?.drivingLegId,
        })
        .pipe(take(1))
        .subscribe((routes: RoutingInstructions[]) => {
          this.currentRoute = (routes ?? []).find(route => route.drivingLegId === schedule?.drivingLegId) as RoutingInstructions;
          this.updateTotalMiles(schedule);

          const tripCode = this.currentRoute?.tripCd ?? null;

          FormUtils.setValues(this.form, { [DisplayOnlyFormFields.ScheduleTripCode]: tripCode });
        });
    } else {
      // just update total mileage
      this.updateTotalMiles(schedule);
    }
    const hazmatOverrideInd = schedule?.hazmatOverrideInd ?? false;

    FormUtils.setValues(this.form, { [DisplayOnlyFormFields.HazmatOverrideInd]: hazmatOverrideInd });

    this.checkFormErrorsWarnings();
  }

  private updateTotalMiles(schedule: LinehaulSchedule) {
    const mileage = +LoDash.get(this.currentRoute, 'milesBetweenOrigAndDestSic', 0);
    const detourMileage = +LoDash.get(schedule, 'scheduleDetour.dtrTotalExMil', 0);
    const totalMileage = mileage + (schedule?.detourInd ? detourMileage : 0);
    this.totalMilesSubject.next(totalMileage);

    FormUtils.setValues(this.form, {
      [DisplayOnlyFormFields.TotalMileage]: totalMileage,
      [DisplayOnlyFormFields.OrigToDestMileage]: mileage,
    });

    setTimeout(() => {
      this.driverPayService.currentPayformGroup.updateValueAndValidity();
      this.errorWarningService.detailWarningsChanged(this.schSequenceNbr);
    }, 250);
  }

  protected updateToState() {
    // Apply Form changes to the State
  }

  deleteSchedule($event) {
    $event.stopPropagation();

    this.driverPayService
      .showDeleteScheduleDialog()
      .pipe(take(1))
      .subscribe(results => {
        if (results) {
          const schedules = this.payformService.deleteSchedule(this.schSequenceNbr);
          const updatedState = { ...this.payformState.current, linehaulSchedule: schedules };
          this.state.setCurrentPayformAction(updatedState);

          this.xpoSnackBar.success(SnackbarMessage.ScheduleDeleted.replace('{0}', this.title));
        }
      });
  }

  checkTotalMileageWarning(): boolean {
    return LoDash.has(this.payformGroup['warnings'], WarningValidationType.TotalMilesWarning);
  }

  checkTotalMilesLessThanZero(): boolean {
    const totalMiles = this.form.get(DisplayOnlyFormFields.TotalMileage).value;
    return totalMiles < 0;
  }
  checkOriginDestWarning(sicType: string): boolean {
    if (LoDash.has(this.payformGroup['warnings'], WarningValidationType.OriginDestWarning) && this.form.enabled) {
      const originDestWarning = LoDash.get(this.payformGroup['warnings'], WarningValidationType.OriginDestWarning);
      if (originDestWarning['firstSchSequenceNbr'] && originDestWarning['firstSchSequenceNbr'] === this.schSequenceNbr && sicType === 'origin') {
        return true;
      } else if (originDestWarning['lastSchSequenceNbr'] && originDestWarning['lastSchSequenceNbr'] === this.schSequenceNbr && sicType === 'dest') {
        return true;
      }
    }
    return false;
  }

  private openPanel() {
    if (this.payformState?.readOnly && this.panel && this.panel.closed) {
      this.panel.open();
    }
  }

  private closePanel() {
    if (this.payformState?.readOnly && this.panel && this.panel.opened) {
      this.panel.close();
    }
  }
}
