import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { XpoPipesModule } from '../pipes/xpo-pipes.module';
import { UtilsModule } from '../utils/utils.module';
import { ValidatorsModule } from '../validators/validators.module';
import { AddEditActivityComponent } from './add-edit-activity/add-edit-activity.component';
import { ApprovePayformComponent } from './approve-payform/approve-payform.component';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { CreatePayformComponent } from './create-payform/create-payform.component';
import { ErrorComponent } from './error/error.component';
import { MoveActivityComponent } from './move-activity/move-activity.component';
import { OperationTypesComponent } from './operation-types/operation-types.component';
import { ReturnReasonFeedbackComponent } from './return-reason-feedback/return-reason-feedback.component';
import { RouteTypesComponent } from './route-types/route-types.component';
import { DeleteReasonFeedbackComponent } from './delete-reason-feedback/delete-reason-feedback.component';
import { ApproveReasonFeedbackComponent } from './approve-reason-feedback/approve-reason-feedback.component';
import { EditReasonFeedbackComponent } from './edit-reason-feedback/edit-reason-feedback.component';
import { XpoDirectivesModule } from '../utils/angular-utils/directives';
@NgModule({
  declarations: [
    ErrorComponent,
    ConfirmCancelComponent,
    ReturnReasonFeedbackComponent,
    DeleteReasonFeedbackComponent,
    ApproveReasonFeedbackComponent,
    EditReasonFeedbackComponent,
    OperationTypesComponent,
    CreatePayformComponent,
    RouteTypesComponent,
    MoveActivityComponent,
    AddEditActivityComponent,
    ApprovePayformComponent,
  ],
  imports: [MaterialModule, XpoDirectivesModule, ValidatorsModule, UtilsModule, XpoPipesModule],
  exports: [
    ErrorComponent,
    ConfirmCancelComponent,
    ReturnReasonFeedbackComponent,
    DeleteReasonFeedbackComponent,
    ApproveReasonFeedbackComponent,
    EditReasonFeedbackComponent,
    OperationTypesComponent,
    CreatePayformComponent,
    RouteTypesComponent,
    MoveActivityComponent,
    AddEditActivityComponent,
    ApprovePayformComponent,
  ],
})
export class DialogsModule {}
