import { Directive, Input } from '@angular/core';
import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator, UntypedFormArray } from '@angular/forms';
import { LinehaulScheduleFormFields, ScheduleTrailerFormFields } from '../enums';
import { EquipmentErrorTypes } from '../enums/equipment-error-types.enum';

export function equipmentCountValidatorFunction(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled) {
      return null;
    }

    const dollyCtrl1 = control.get(LinehaulScheduleFormFields.DollyNbr1);
    const dollyCtrl2 = control.get(LinehaulScheduleFormFields.DollyNbr2);

    if (!dollyCtrl1 || !dollyCtrl2) {
      return null;
    }

    const dollyFields = [dollyCtrl1, dollyCtrl2];
    const dollyCount = dollyFields.reduce((sum, dollyCtrl) => (dollyCtrl.value ? sum + 1 : sum), 0);
    const trailersCtrl = control.get(LinehaulScheduleFormFields.ScheduleTrailer) as UntypedFormArray;

    if (!trailersCtrl) {
      return null;
    }

    const trailerCount = trailersCtrl.controls.reduce((sum, trailerCtrl) => (trailerCtrl && trailerCtrl.value[ScheduleTrailerFormFields.TrlrNbr] ? sum + 1 : sum), 0);
    const tractorCtrl = control.get(LinehaulScheduleFormFields.TractorNbr) as UntypedFormArray;

    if (!tractorCtrl) {
      return null;
    }
    const tractorCount = tractorCtrl.value ? 1 : 0;

    const equipmentCount = {
      dollyCount,
      trailerCount,
      tractorCount,
    };

    if (equipmentCount.tractorCount === 1 && equipmentCount.trailerCount === 2 && (equipmentCount.dollyCount === 0 || !dollyCtrl1.value)) {
      return {
        [EquipmentErrorTypes.OneDollyRequiredWithTwoTrailers]: {
          type: EquipmentErrorTypes.OneDollyRequiredWithTwoTrailers,
          displayError: true,
        },
      };
    } else if (equipmentCount.tractorCount === 1 && equipmentCount.trailerCount === 0 && equipmentCount.dollyCount === 2) {
      return {
        [EquipmentErrorTypes.OneTractorTwoDollysNoTrailer]: {
          type: EquipmentErrorTypes.OneTractorTwoDollysNoTrailer,
          displayError: true,
        },
      };
    } else if (equipmentCount.tractorCount === 1 && equipmentCount.trailerCount === 3 && equipmentCount.dollyCount < 2) {
      return {
        [EquipmentErrorTypes.TwoDollysRequiredWithThreeTrailers]: {
          type: EquipmentErrorTypes.TwoDollysRequiredWithThreeTrailers,
          displayError: true,
        },
      };
    }

    return null;
  };
}

@Directive({
  selector: '[equipmentCountValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EquipmentCountValidator, multi: true }],
})
export class EquipmentCountValidator implements Validator {
  constructor() {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return equipmentCountValidatorFunction()(control);
  }
}
