import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType } from '../enums';
import { FormUtils } from '../classes';

export function tripInfoOriginDestValidatorFunction(errorWarningService: ErrorWarningService, schSequenceNbr: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const origin = control.get(LinehaulScheduleFormFields.OriginSicCd) ? control.get(LinehaulScheduleFormFields.OriginSicCd) : undefined;
    const dest = control.get(LinehaulScheduleFormFields.DestinationSicCd) ? control.get(LinehaulScheduleFormFields.DestinationSicCd) : undefined;

    if (!origin || !dest || !origin.value || !dest.value || origin.invalid || dest.invalid) {
      FormUtils.unsetWarning(control, WarningValidationType.SameOriginDestWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
      return null;
    }

    if (origin.value.toUpperCase() === dest.value.toUpperCase()) {
      const sameOriginDestWarning = {
        type: WarningValidationType.SameOriginDestWarning,
        primarySubject: origin.value,
      };

      FormUtils.setWarning(control, WarningValidationType.SameOriginDestWarning, sameOriginDestWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
    } else {
      FormUtils.unsetWarning(control, WarningValidationType.SameOriginDestWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
    }

    return null;
  };
}

@Directive({
  selector: '[tripInfoOriginDestValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TripInfoOriginDestValidator, multi: true }],
})
export class TripInfoOriginDestValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}
  @Input()
  schSequenceNbr: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return tripInfoOriginDestValidatorFunction(this.errorWarningService, this.schSequenceNbr)(control);
  }
}
