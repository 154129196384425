import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'equipment',
})
export class EquipmentPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value && value.indexOf('-') < 0) {
      const isNumeric = /^\d+$/.test(value);
      if (isNumeric && value.length === 7) {
        value = `${value.substring(0, 3)}-${value.substring(3)}`;
      } else if (value.length >= 8 && value.length <= 10 && /^\d+$/.test(value.substring(4))) {
        value = `${value.substring(0, 4).toUpperCase()}-${value.substring(4)}`;
      }
    }

    return value || '';
  }
}
