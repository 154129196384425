import { Pipe, PipeTransform } from '@angular/core';
import { LinehaulSchedule } from '@xpo-ltl/sdk-linehaulpayform';

/**
 * Convert the passed string into a RegEx expression
 */
@Pipe({
  name: 'viaOrDestination',
})
export class ViaOrDestinationPipe implements PipeTransform {
  transform(schedule: LinehaulSchedule): string {
    if (schedule) {
      return schedule.viaSicCd ? schedule.viaSicCd : schedule.destinationSicCd;
    }
    return undefined;
  }
}
