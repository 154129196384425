<div class="container">
  <div class="container__title">Create New Payform</div>
  <mat-dialog-content class="container__content">
    <div fxFlex="" fxLayout="column" fxLayoutGap="8px">
      <div fxFlex="" fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex="20">
          <input
            matInput
            class="container__sic-input"
            id="create-payform-sic-input"
            autocomplete="off"
            placeholder="SIC"
            type="text"
            required
            maxlength="3"
            size="3"
            pattern="^[a-zA-Z]{3}$"
            sicValidator=""
            [(ngModel)]="sic"
            #sicInput="ngModel"
          />
          <mat-error *ngIf="sicInput.invalid && (sicInput.dirty || sicInput.touched)">
            <div *ngIf="sicInput.errors?.required">Required</div>
            <div *ngIf="sicInput.errors?.pattern">Wrong format</div>
            <div *ngIf="sicInput.errors?.invalidLocation">Invalid SIC</div>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="80">
          <input
            matInput
            id="create-payform-emp-input"
            autocomplete="off"
            placeholder="Employee Name or ID"
            type="text"
            required
            maxlength="30"
            [ngModel]="employee"
            (keyup)="inputChange()"
            #empInput="ngModel"
            #empInputField
            [matAutocomplete]="auto"
          />
          <button mat-button *ngIf="empInput?.value && !searching" matSuffix mat-icon-button aria-label="Clear Employee Name" (click)="clearEmployeeName()">
            <mat-icon>close</mat-icon>
          </button>
          <span *ngIf="searching" class="spinner"></span>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let emp of employeeList$ | async" value="{{ emp.fullName }} ({{ emp.employeeId }})"> {{ emp.fullName }} ({{ emp.employeeId }}) </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="empInput.invalid && (empInput.dirty || empInput.touched)">
            <div *ngIf="empInput.errors.required">Required</div>
            <div *ngIf="empInput.errors['not-found']">{{ empInput.errors['not-found'] }}</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="" fxLayout="row">
        <mat-form-field class="container__shift-input">
          <input
            matInput
            [matDatepicker]="picker"
            id="create-payform-shift-start-input"
            [xpoAllowCharacters]="ValidationRegexPatterns.dateInputValidatorPattern | toRegex"
            type="text"
            xpoDate=""
            required
            autocomplete="off"
            placeholder="Shift Start"
            [(ngModel)]="shiftStart"
            #shiftStartInput="ngModel"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="shiftStartInput.invalid">
            <div *ngIf="shiftStartInput.errors.required">Required</div>
            <div *ngIf="!shiftStartInput.errors.required && shiftStartInput.invalid">Invalid Date</div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="container-actionbar">
    <button id="cancel-button" mat-button color="primary" [mat-dialog-close]="false">CANCEL</button>
    <button id="done-button" mat-button color="primary" [disabled]="sicInput.invalid || empInput.invalid || shiftStartInput.invalid" (click)="handleCreateClick()">CREATE</button>
  </mat-dialog-actions>
</div>
