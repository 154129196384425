import { ErrorWarningService } from '../components/payform/services';
import { ValidatorFn, AbstractControl, UntypedFormArray, UntypedFormGroup, NG_VALIDATORS, Validator } from '@angular/forms';
import { PayformFormFields, LinehaulScheduleFormFields, WarningValidationType } from '../enums';
import { DsrActivity } from '@xpo-ltl/sdk-linehaulpayform';
import { Directive } from '@angular/core';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function multipleFuelingActivitiesValidatorFunction(errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      return null;
    }

    const activitiesArr = linehaulSchedules.controls.reduce((payformArr, schedule) => {
      const activitiesCtrl = schedule.get(LinehaulScheduleFormFields.Activities) as UntypedFormGroup;
      if (activitiesCtrl) {
        payformArr.push(...LoDash.values(activitiesCtrl.controls));
      }
      return payformArr;
    }, []);

    // Get non schedule activities if present
    const nonSchedActivities = control.get(LinehaulScheduleFormFields.Activities) as UntypedFormArray;
    if (nonSchedActivities) {
      activitiesArr.push(...LoDash.values(nonSchedActivities.controls));
    }

    if (activitiesArr && activitiesArr.length > 0) {
      let fuelingActivities = 0;
      activitiesArr.forEach((activityControl: AbstractControl) => {
        const activity = activityControl.value as DsrActivity;
        if ((activity?.nonDriveTimeType?.actvtyName ?? '') === 'Fueling') {
          fuelingActivities++;
        }
      });

      if (fuelingActivities > 1) {
        const fuelingWarning = {
          type: WarningValidationType.MultipleFuelingWarning,
          primarySubject: fuelingActivities,
        };
        FormUtils.setWarning(control, WarningValidationType.MultipleFuelingWarning, fuelingWarning);
        errorWarningService.payformWarningsChanged();
      } else {
        FormUtils.unsetWarning(control, WarningValidationType.MultipleFuelingWarning);
        errorWarningService.payformWarningsChanged();
      }
    } else {
      FormUtils.unsetWarning(control, WarningValidationType.MultipleFuelingWarning);
      errorWarningService.payformWarningsChanged();
    }

    return null;
  };
}

@Directive({
  selector: '[multipleFuelingActivitiesValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MultipleFuelingActivitesValidator, multi: true }],
})
export class MultipleFuelingActivitesValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return multipleFuelingActivitiesValidatorFunction(this.errorWarningService)(control);
  }
}
