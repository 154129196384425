import { DatePipe } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { AbstractControl, NgModel, NG_VALIDATORS, Validator } from '@angular/forms';
import * as moment from 'moment-timezone';

@Directive({
  selector: '[notGreaterThanDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NotGreaterThanDateValidator,
      multi: true,
    },
  ],
})
export class NotGreaterThanDateValidator implements Validator {
  @Input('notGreaterThanDate')
  notGreaterThanDate: NgModel;

  constructor(private datePipe: DatePipe) {}
  validate(control: AbstractControl): { [key: string]: any } {
    if (this.notGreaterThanDate && this.notGreaterThanDate.value && control.value) {
      const otherDate = moment(this.notGreaterThanDate.value)
        .endOf('day')
        .toDate();
      const validator = control.validator;
      control.clearValidators();
      this.notGreaterThanDate.control.updateValueAndValidity({
        onlySelf: true,
      });
      control.validator = validator;
      if (
        moment(control.value)
          .endOf('day')
          .toDate() > otherDate
      ) {
        return {
          greaterThanOther: `Date cannot be greater than ${this.datePipe.transform(otherDate, 'MM/dd/yyyy')}`,
        };
      }
    }
    return undefined;
  }
}
