import { Component, Input } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';

@Component({
  selector: 'xpo-utils-region-banner',
  templateUrl: './region-banner.component.html',
  styleUrls: ['./region-banner.component.scss'],
})
export class RegionBannerComponent {
  constructor(private configManagerService: ConfigManagerService) {}
  @Input() showRegion: boolean;

  public get isProd(): boolean {
    return this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }
  public get region(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
  }
}
