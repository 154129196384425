import { Injectable } from '@angular/core';
import { PayrollApiService, ListExtendedLaneRateRqst, ExtendedLaneRate, ListExtendedLaneRateResp } from '@xpo-ltl/sdk-payroll';
import { take } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { BaseCacheService } from '../../utils/angular-utils/services';

@Injectable()
export class ExtendedLaneRateCacheService extends BaseCacheService<string, ListExtendedLaneRateResp> {
  private readonly key = 'RETRIEVED';

  constructor(private payrollService: PayrollApiService) {
    super();
  }

  protected getKeyFromParams(params: string): string {
    return this.key;
  }

  protected requestData(params: string): Observable<ListExtendedLaneRateResp> {
    const request = new ListExtendedLaneRateRqst();
    return this.payrollService.listExtendedLaneRate(request);
  }

  public isESL(origin: string, destination: string): Observable<ExtendedLaneRate> {
    const subject = new ReplaySubject<ExtendedLaneRate>(1);

    this.request(this.key)
      .pipe(take(1))
      .subscribe(results => {
        const esl = results.extendedLaneRate.find(x => x.routeOrigSic === origin && x.routeDestSic === destination);
        subject.next(esl);
        subject.complete();
      });

    return subject.asObservable();
  }
}
