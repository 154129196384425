<div class="error-dialog">
  <div fxLayout="row">
      <mat-icon *ngIf="data.icon !== ErrorWarningTypes.None">error</mat-icon>
      <h2 md-dialog-title>{{data.title}}</h2>
  </div>
  <mat-dialog-content class="error-dialog-content">
   <div>{{data.message}}</div>
  </mat-dialog-content>
  <mat-dialog-actions class="error-dialog-actionbar">
    <button mat-button color="primary" [mat-dialog-close]="true" tabindex="-1">OK</button>
  </mat-dialog-actions>
</div>
