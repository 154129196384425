import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { EquipmentType } from '../enums/equipment-type.enum';
import { AsyncValidatorCompletionService } from '../services/async-validator-completion.service';
import { WarningValidationType } from '../enums';
import { EquipmentDetailsCacheService } from '../services/equipment-details-cache.service';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function equipmentValidatorFunction(
  equipmentDetailsCache: EquipmentDetailsCacheService,
  equipmentType: EquipmentType,
  errorWarningService: ErrorWarningService,
  asyncValidatorCompleted: AsyncValidatorCompletionService,
  schSequenceNbr: string
): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (!control || !equipmentType || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return of(null);
    }

    FormUtils.unsetWarning(control, WarningValidationType.EquipmentNotFound);
    FormUtils.unsetWarning(control, WarningValidationType.EquipmentMismatch);
    errorWarningService.detailWarningsChanged(schSequenceNbr);

    if (!control.value) {
      return of(null);
    }

    const equipmentTypes = {
      [EquipmentType.Power]: 'tractor',
      [EquipmentType.Dolly]: EquipmentType.Dolly,
      [EquipmentType.Trailer]: EquipmentType.Trailer,
    };

    // ERROR
    const invalidFormat = { invalidFormat: true };

    // WARNINGS
    const equipmentNotFound = {
      type: WarningValidationType.EquipmentNotFound,
      primarySubject: control.value,
      secondarySubject: LoDash.capitalize(equipmentTypes[equipmentType]),
    };
    const equipmentMismatch = {
      type: WarningValidationType.EquipmentMismatch,
      primarySubject: control.value,
      secondarySubject: LoDash.capitalize(equipmentTypes[equipmentType]),
    };

    const hyphenCount = (control.value.match(/-/g) || []).length;
    const hyphenPos = control.value.indexOf('-');
    if (hyphenPos < 0 || hyphenCount > 1) {
      control.markAsTouched();
      asyncValidatorCompleted.done(true);
      return of(invalidFormat);
    }

    let prefix = control.value.substring(0, hyphenPos);
    const isPrefixNumeric = /^\d+$/.test(prefix);
    if (isPrefixNumeric && prefix.length >= 4) {
      control.markAsTouched();
      asyncValidatorCompleted.done(true);
      return of(invalidFormat);
    }
    while (isPrefixNumeric && prefix.length < 4) {
      prefix = `0${prefix}`;
    }
    const suffix = control.value.substring(hyphenPos + 1);
    const isSuffixNumeric = /^\d+$/.test(suffix);
    if (suffix.length < 4 || (!isPrefixNumeric && suffix.length < 4) || (isPrefixNumeric && (suffix.length > 4 || !isSuffixNumeric))) {
      control.markAsTouched();
      asyncValidatorCompleted.done(true);
      return of(invalidFormat);
    }
    return equipmentDetailsCache.request({ prefix, suffix }).pipe(
      map(
        response => {
          const expectedType = equipmentType === EquipmentType.Power ? 'P' : equipmentType === EquipmentType.Trailer ? 'T' : 'D';
          if (!(response && response.equipmentInfo && response.equipmentInfo.equipmentTypeCd === expectedType)) {
            FormUtils.setWarning(control, WarningValidationType.EquipmentMismatch, equipmentMismatch);
            errorWarningService.detailWarningsChanged(schSequenceNbr);
          }
          asyncValidatorCompleted.done(true);
          return undefined;
        },
        errors => {
          FormUtils.setWarning(control, WarningValidationType.EquipmentNotFound, equipmentNotFound);

          errorWarningService.detailWarningsChanged(schSequenceNbr);
          asyncValidatorCompleted.done(true);
          return of(undefined);
        }
      ),
      catchError(error => {
        FormUtils.setWarning(control, WarningValidationType.EquipmentNotFound, equipmentNotFound);
        errorWarningService.detailWarningsChanged(schSequenceNbr);
        asyncValidatorCompleted.done(true);
        return of(undefined);
      })
    );
  };
}

@Directive({
  selector: '[equipmentValidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: EquipmentValidator, multi: true }],
})
export class EquipmentValidator implements Validator {
  @Input()
  equipmentType: EquipmentType;
  @Input()
  schSequenceNbr: string;

  constructor(private equipmentDetailsCache: EquipmentDetailsCacheService, private errorWarningService: ErrorWarningService, private asyncValidatorCompleted: AsyncValidatorCompletionService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return equipmentValidatorFunction(this.equipmentDetailsCache, this.equipmentType, this.errorWarningService, this.asyncValidatorCompleted, this.schSequenceNbr)(control);
  }
}
