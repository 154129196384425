import { Subscription, Observable } from 'rxjs';

export class ButtonConfig {
  constructor(
    public label: string,
    public disabled: boolean,
    public visible: boolean,
    public color: string,
    public raised: boolean = false,
    public disabled$?: Observable<boolean>
  ) {}
}
