import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PayPortalStateStore } from '../../../../../../../app/state';
import { EquipmentType, LinehaulScheduleFormFields, ScheduleTrailerFormFields } from '../../../../../../enums';
import { AsyncValidatorCompletionService, EquipmentDetailsCacheService } from '../../../../../../services';
import {
  equipmentValidatorFunction,
  equipmentCountValidatorFunction,
  duplicateEquipmentValidatorFunction,
  loadReleaseValidatorFunction,
  operationTypeValidatorFunction,
} from '../../../../../../validators';
import { ErrorWarningService } from '../../../../services';

export class EquipmentFormBuilder {
  public static create(
    formBuilder: UntypedFormBuilder,
    equipmentDetailsCache: EquipmentDetailsCacheService,
    errorWarningService: ErrorWarningService,
    asyncValidatorCompleted: AsyncValidatorCompletionService,
    schSequenceNbr: string,
    state: PayPortalStateStore
  ): UntypedFormGroup {
    const formGroup = formBuilder.group(
      {
        [LinehaulScheduleFormFields.TractorNbr]: [
          '',
          Validators.required,
          [equipmentValidatorFunction(equipmentDetailsCache, EquipmentType.Power, errorWarningService, asyncValidatorCompleted, schSequenceNbr)],
        ],
        [LinehaulScheduleFormFields.DollyNbr1]: ['', null, [equipmentValidatorFunction(equipmentDetailsCache, EquipmentType.Dolly, errorWarningService, asyncValidatorCompleted, schSequenceNbr)]],
        [LinehaulScheduleFormFields.DollyNbr2]: ['', null, [equipmentValidatorFunction(equipmentDetailsCache, EquipmentType.Dolly, errorWarningService, asyncValidatorCompleted, schSequenceNbr)]],
        [LinehaulScheduleFormFields.ScheduleTrailer]: formBuilder.array([
          // Trailer 1
          formBuilder.group({
            [ScheduleTrailerFormFields.TrlrNbr]: ['', null, [equipmentValidatorFunction(equipmentDetailsCache, EquipmentType.Trailer, errorWarningService, asyncValidatorCompleted, schSequenceNbr)]],
            [ScheduleTrailerFormFields.LoadedRlseNbr]: ['', loadReleaseValidatorFunction()],
            [ScheduleTrailerFormFields.TrlrHazardousInd]: '',
            [ScheduleTrailerFormFields.TrlrEmtyInd]: '',
          }),
          // Trailer 2
          formBuilder.group({
            [ScheduleTrailerFormFields.TrlrNbr]: ['', null, [equipmentValidatorFunction(equipmentDetailsCache, EquipmentType.Trailer, errorWarningService, asyncValidatorCompleted, schSequenceNbr)]],
            [ScheduleTrailerFormFields.LoadedRlseNbr]: ['', loadReleaseValidatorFunction()],
            [ScheduleTrailerFormFields.TrlrHazardousInd]: '',
            [ScheduleTrailerFormFields.TrlrEmtyInd]: '',
          }),
          // Trailer 3
          formBuilder.group({
            [ScheduleTrailerFormFields.TrlrNbr]: ['', null, [equipmentValidatorFunction(equipmentDetailsCache, EquipmentType.Trailer, errorWarningService, asyncValidatorCompleted, schSequenceNbr)]],
            [ScheduleTrailerFormFields.LoadedRlseNbr]: ['', loadReleaseValidatorFunction()],
            [ScheduleTrailerFormFields.TrlrHazardousInd]: '',
            [ScheduleTrailerFormFields.TrlrEmtyInd]: '',
          }),
        ]),
        [LinehaulScheduleFormFields.TypeOfOperation]: ['', operationTypeValidatorFunction(errorWarningService, schSequenceNbr)],
      },
      {
        validator: [equipmentCountValidatorFunction(), duplicateEquipmentValidatorFunction()],
      }
    );
    return formGroup;
  }
}
