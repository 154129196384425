import { Pipe, PipeTransform } from '@angular/core';
import { PayformStatusCd } from '@xpo-ltl/sdk-common';
import { DsrPayform } from '@xpo-ltl/sdk-linehaulpayform';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SicDatePipe } from './sic-date.pipe';

@Pipe({
  name: 'payformStatus',
})
export class PayformStatusPipe implements PipeTransform {
  constructor(private sicDatePipe: SicDatePipe) {}

  transform(payform: DsrPayform, args?: any): any {
    let transformedValue: Observable<string>;
    if (!!payform) {
      switch (payform.statusCd) {
        case PayformStatusCd.TRANSMITTED:
        case PayformStatusCd.APPROVED:
          transformedValue = this.sicDatePipe
            .transform(payform.aprvlDateTimeUtc, 'MM/DD/YYYY HH:mm', payform.dmclSic)
            .pipe(map(time => `Approved by: ${payform.aprvngSupvsrEmployeeDetail.fullName}, ${time}`));
          break;
        case PayformStatusCd.SUBMITTED:
          transformedValue = of(`Payform Status: Submitted`);
          break;
        case PayformStatusCd.DELETED:
          transformedValue = this.sicDatePipe
            .transform(payform.aprvlDateTimeUtc, 'MM/DD/YYYY HH:mm', payform.dmclSic)
            .pipe(map(time => `Deleted by: ${payform.aprvngSupvsrEmployeeDetail.fullName}, ${time}`));
          break;
        case PayformStatusCd.RETURNED:
          transformedValue = of(`Payform Status: Returned`);
          break;
        case PayformStatusCd.NEW:
          transformedValue = of(`Payform Status: Not Submitted`);
          break;
        default:
          transformedValue = of(`Payform Status: Not Submitted`);
          break;
      }
    }
    return transformedValue;
  }
}
