import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LinehaulScheduleFormFields } from '../../../../../../enums';
import { ServiceCenterCacheService } from '../../../../../../services';
import { AsyncValidatorCompletionService } from '../../../../../../services/async-validator-completion.service';
import { sicValidatorFunction, tripInfoOriginDestValidatorFunction, cushionValidatorFunction, eslValidatorFunction, eslSoloValidatorFunction } from '../../../../../../validators';
import { ErrorWarningService } from '../../../../services/error-warning/error-warning.service';

export class TripInfoFormBuilder {
  public static create(
    formBuilder: UntypedFormBuilder,
    serviceCenterCache: ServiceCenterCacheService,
    asyncValidatorCompleted: AsyncValidatorCompletionService,
    errorWarningService: ErrorWarningService,
    schSequenceNbr: string
  ): UntypedFormGroup {
    const formGroup = formBuilder.group(
      {
        [LinehaulScheduleFormFields.OriginSicCd]: ['', [Validators.required], [sicValidatorFunction(serviceCenterCache, asyncValidatorCompleted)]],
        [LinehaulScheduleFormFields.DestinationSicCd]: ['', [Validators.required], [sicValidatorFunction(serviceCenterCache, asyncValidatorCompleted)]],
        [LinehaulScheduleFormFields.CushionInd]: [false, cushionValidatorFunction(errorWarningService, schSequenceNbr)],
        [LinehaulScheduleFormFields.EslRegular]: [false, eslValidatorFunction(errorWarningService, schSequenceNbr)],
        [LinehaulScheduleFormFields.EslSolo]: [false, eslSoloValidatorFunction(errorWarningService, schSequenceNbr)],
      },
      { validator: [tripInfoOriginDestValidatorFunction(errorWarningService, schSequenceNbr)] }
    );

    return formGroup;
  }
}
