import { NG_VALIDATORS, AbstractControl, ValidatorFn, Validator, UntypedFormArray } from '@angular/forms';
import { Directive } from '@angular/core';
import { ErrorWarningService } from '../components/payform/services';
import { PayformFormFields, LinehaulScheduleFormFields, WarningValidationType } from '../enums';
import { FormUtils } from '../classes';

export function oneScheduleNoESLNoCushionValidatorFunction(errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    FormUtils.unsetWarning(control, WarningValidationType.OnlyOneScheduleWarning);

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      return null;
    }

    if (linehaulSchedules.length === 1) {
      const scheduleDetailsControls = linehaulSchedules.controls[0].get(LinehaulScheduleFormFields.Details);
      if (scheduleDetailsControls) {
        const tripControls = scheduleDetailsControls.get(LinehaulScheduleFormFields.TripInfoControl);
        if (tripControls) {
          const eslRegControl = tripControls.get(LinehaulScheduleFormFields.EslRegular);
          const eslSoloControl = tripControls.get(LinehaulScheduleFormFields.EslSolo);
          const cushionControl = tripControls.get(LinehaulScheduleFormFields.CushionInd);

          if (!(eslRegControl.value || eslSoloControl.value || cushionControl.value)) {
            const warning = {
              type: WarningValidationType.OnlyOneScheduleWarning,
              primarySubject: '',
            };

            FormUtils.setWarning(control, WarningValidationType.OnlyOneScheduleWarning, warning);
          }
        }
      }
    }

    errorWarningService.payformWarningsChanged();
    return null;
  };
}

@Directive({
  selector: '[oneScheduleNoESLNoCushionValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: OneScheduleNoESLNoCushionValidator, multi: true }],
})
export class OneScheduleNoESLNoCushionValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return oneScheduleNoESLNoCushionValidatorFunction(this.errorWarningService)(control);
  }
}
