import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activityName'
})
export class ActivityNamePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value) {
      value = value.replace('- Destination', '- Dest');
      value = value.replace('- En Route', '- En Rte');
      value = value.replace('- Paperwork', '- Papers');
    }

    return value;
  }
}
