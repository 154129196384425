import { Injectable } from '@angular/core';
import { EquipmentApiService, GetEquipmentDtlsByPfxSfxResp, GetEquipmentDtlsByPfxSfxRqst } from '@xpo-ltl/sdk-equipment';
import { BaseCacheService } from '../utils/angular-utils/services';
import { Observable } from 'rxjs';

export interface EquipmentDetailsCacheParams {
  prefix: string;
  suffix: number;
}

@Injectable()
export class EquipmentDetailsCacheService extends BaseCacheService<EquipmentDetailsCacheParams, GetEquipmentDtlsByPfxSfxResp> {
  constructor(private equipmentService: EquipmentApiService) {
    super();
  }

  protected getKeyFromParams(params: EquipmentDetailsCacheParams): string {
    return `${(params.prefix ?? '').toUpperCase()},${params.suffix ?? ''}`;
  }

  protected requestData(params: EquipmentDetailsCacheParams): Observable<GetEquipmentDtlsByPfxSfxResp> {
    const request = new GetEquipmentDtlsByPfxSfxRqst();
    request.equipmentPrefix = params.prefix.toUpperCase();
    request.equipmentSuffix = params.suffix;
    return this.equipmentService.getEquipmentDtlsByPfxSfx(request, { toastOnError: false });
  }
}
