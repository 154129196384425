import { Component } from '@angular/core';
import { ICellRendererPresentationFrameworkAngularComp } from '@xpo-ltl/ngx-presentation-framework';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-display-employee-cell-renderer',
  templateUrl: './display-employee-cell-renderer.component.html',
})
export class DisplayEmployeeCellRendererComponent implements ICellRendererPresentationFrameworkAngularComp {
  params: any;

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params?.data;
  }
}
