export interface FeedbackConfigInterface {
  disableFromNameInput?: boolean;
  disableSubjectInput?: boolean;
  fromEmail?: string;
  fromName?: string;
  invalidCharacterPattern?: string;
  message?: string;
  subject?: string;
  title?: string;
  toEmail?: string;
}

export class FeedbackConfig implements FeedbackConfigInterface {
  public disableFromNameInput?: boolean;
  public disableSubjectInput?: boolean;
  public fromEmail?: string;
  public fromName?: string;
  public invalidCharacterPattern?: string;
  public message?: string;
  public subject?: string;
  public title?: string;
  public toEmail?: string;
}
