import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LoDash } from '../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-edit-reason-feedback',
  templateUrl: './edit-reason-feedback.component.html',
  styleUrls: ['./edit-reason-feedback.component.scss'],
})
export class EditReasonFeedbackComponent implements OnInit {
  public feedback = '';
  public title = '';
  public reason = '';

  constructor(private dialogRef: MatDialogRef<EditReasonFeedbackComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    const isNewPayform = LoDash.get(data, 'isNewPayform', false);
    if (isNewPayform) {
      this.title = 'Create New';
      this.reason = 'creating';
    } else {
      this.title = 'Edit';
      this.reason = 'editing';
    }
  }

  ngOnInit() {}

  cancelClicked() {
    this.dialogRef.close();
  }

  saveClicked() {
    this.dialogRef.close(this.feedback);
  }
}
