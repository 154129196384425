import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'xpo-spinner',
  templateUrl: './xpo-spinner.component.html',
  styleUrls: ['./xpo-spinner.component.scss'],
})
export class XpoSpinnerComponent implements OnInit {
  private showSpinnerSubject = new BehaviorSubject<boolean>(false);
  public showSpinner$ = this.showSpinnerSubject.asObservable();

  constructor() {}

  @Input('showSpinner')
  public set showSpinner(show: boolean) {
    this.showSpinnerSubject.next(show);
  }
  ngOnInit() {}
}
