import { Injectable } from '@angular/core';
import { GetServiceCenterDetailsBySicResp, LocationApiService } from '@xpo-ltl/sdk-location';
import { BaseCacheService } from '../utils/angular-utils/services';

import { Observable } from 'rxjs';

export interface ServiceCenterCacheParams {
  sicCd: string;
}

@Injectable()
export class ServiceCenterCacheService extends BaseCacheService<ServiceCenterCacheParams, GetServiceCenterDetailsBySicResp> {
  constructor(private locationService: LocationApiService) {
    super();
  }

  protected getKeyFromParams(params: ServiceCenterCacheParams): string {
    return params.sicCd.toUpperCase();
  }

  protected requestData(params: ServiceCenterCacheParams): Observable<GetServiceCenterDetailsBySicResp> {
    const request = { sicCd: null };
    request.sicCd = params.sicCd.toUpperCase();

    return this.locationService.getServiceCenterDetailsBySic(request, { toastOnError: false });
  }
}
