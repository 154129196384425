import { Validator, AbstractControl, NG_VALIDATORS, UntypedFormArray, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { Directive } from '@angular/core';
import { DsrActivityFormFields } from '../enums/dsr-activity-form-fields.enum';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { NonDriveTimeTypeFormFields } from '../enums/non-drive-time-type-form-fields.enum';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { PayformFormFields } from '../enums';
import { ServiceCenterCacheService } from '../services/service-center-cache.service';
import { AsyncValidatorCompletionService } from '../services/async-validator-completion.service';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function activityWaitTimeValidatorFunction(errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      return null;
    }

    const payformData = linehaulSchedules.controls.reduce(
      (payformObj, schedule) => {
        const activitiesCtrl = schedule.get(LinehaulScheduleFormFields.Activities) as UntypedFormGroup;
        if (activitiesCtrl) {
          payformObj.activities.push(...LoDash.values(activitiesCtrl.controls));
        }

        const scheduleDetailCtrl = schedule.get(LinehaulScheduleFormFields.Details);
        if (scheduleDetailCtrl) {
          const tripInfo = scheduleDetailCtrl.get(LinehaulScheduleFormFields.TripInfoControl);
          if (tripInfo) {
            const originSic = tripInfo.get(LinehaulScheduleFormFields.OriginSicCd);
            const destSic = tripInfo.get(LinehaulScheduleFormFields.DestinationSicCd);
            if (originSic && destSic) {
              payformObj.schDetailSics.push(originSic.value, destSic.value);
            }

            const viaSic = tripInfo.get(LinehaulScheduleFormFields.ViaSicCd);
            if (viaSic) {
              payformObj.schDetailSics.push(viaSic.value);
            }
          }
        }
        return payformObj;
      },
      { activities: [], schDetailSics: [] }
    );

    // Get non schedule activities if present
    const nonSchedActivities = control.get(LinehaulScheduleFormFields.Activities) as UntypedFormArray;
    if (nonSchedActivities) {
      payformData.activities.push(...LoDash.values(nonSchedActivities.controls));
    }

    // Loop through activities, and add up all wait times that match waitTimeIds
    const waitTimeIds = [3, 4];
    const waitTime = payformData.activities.reduce((total, activity) => {
      const activityVal = LoDash.get(activity, 'value', undefined);

      if (activityVal) {
        const nonDriveTimeTypeObj = LoDash.get(activityVal, DsrActivityFormFields.NonDriveTimeType, undefined);
        if (nonDriveTimeTypeObj) {
          const nonDriveTimeTypeId = nonDriveTimeTypeObj[NonDriveTimeTypeFormFields.NonDrvTimeTypeId];
          if (waitTimeIds.includes(nonDriveTimeTypeId)) {
            return (total += +activityVal[DsrActivityFormFields.DurationInMn] || 0);
          }
          return total;
        }
      }
    }, 0);

    errorWarningService.payformWarningsChanged();
    return null;
  };
}

@Directive({
  selector: '[activityWaitTimeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ActivityWaitTimeValidator, multi: true }],
})
export class ActivityWaitTimeValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService, private serviceCenterCache: ServiceCenterCacheService, private asyncValidatorCompleted: AsyncValidatorCompletionService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return activityWaitTimeValidatorFunction(this.errorWarningService)(control);
  }
}
