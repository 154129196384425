export class OperationType {
  constructor(
    public optype: number,
    public description: string,
    public width: number
  ) {}

  public get image(): string {
    return `optype_${this.optype}`;
  }

  public get imageWidth(): string {
    return `${this.width}px`;
  }
}
