import { DatePipe } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { AbstractControl, NgModel, NG_VALIDATORS, Validator } from '@angular/forms';
import * as moment from 'moment-timezone';

@Directive({
  selector: '[notLessThanDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NotLessThanDateValidator,
      multi: true,
    },
  ],
})
export class NotLessThanDateValidator implements Validator {
  @Input('notLessThanDate')
  notLessThanDate: NgModel;

  constructor(private datePipe: DatePipe) {}

  validate(control: AbstractControl): { [key: string]: any } {
    if (this.notLessThanDate && this.notLessThanDate.value && control.value) {
      const otherDate = moment(this.notLessThanDate.value)
        .startOf('day')
        .toDate();
      const validator = control.validator;
      control.clearValidators();
      this.notLessThanDate.control.updateValueAndValidity({ onlySelf: true });
      control.validator = validator;
      if (
        moment(control.value)
          .startOf('day')
          .toDate() < otherDate
      ) {
        return {
          lessThanOther: `Date cannot be less than ${this.datePipe.transform(otherDate, 'MM/dd/yyyy')}`,
        };
      }
    }
    return undefined;
  }
}
