import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType } from '../enums';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function hazmatOverrideValidatorFunction(errorWarningService: ErrorWarningService, schSequenceNbr: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const tripInfo = control.get(LinehaulScheduleFormFields.TripInfoControl);
    const hazmatOverrideInd = LoDash.get(control.get(LinehaulScheduleFormFields.HazmatOverrideInd), 'value', false);

    if (!tripInfo || !hazmatOverrideInd) {
      FormUtils.unsetWarning(control, WarningValidationType.HazmatOverrideWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
      return null;
    }

    const origin = LoDash.get(tripInfo.get(LinehaulScheduleFormFields.OriginSicCd), 'value', '');
    const dest = LoDash.get(tripInfo.get(LinehaulScheduleFormFields.DestinationSicCd), 'value', '');

    const hazmatOverrideWarning = {
      type: WarningValidationType.HazmatOverrideWarning,
      primarySubject: `${origin.toUpperCase()} -> ${dest.toUpperCase()}`,
    };

    FormUtils.setWarning(control, WarningValidationType.HazmatOverrideWarning, hazmatOverrideWarning);
    errorWarningService.detailWarningsChanged(schSequenceNbr);
    return null;
  };
}

@Directive({
  selector: '[hazmatOverrideValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: HazmatOverrideValidator, multi: true }],
})
export class HazmatOverrideValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}
  @Input()
  schSequenceNbr: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return hazmatOverrideValidatorFunction(this.errorWarningService, this.schSequenceNbr)(control);
  }
}
