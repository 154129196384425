<div class="confirm-cancel">
  <div fxLayout="row">
      <mat-icon *ngIf="icon" [ngClass]="[icon]">{{icon}}</mat-icon>
      <h2 md-dialog-title>{{title}}</h2>
  </div>
  <mat-dialog-content class="confirm-cancel-content">
    <div [innerHTML]="content"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="confirm-cancel-actionbar">
    <button mat-button color="primary" [mat-dialog-close]="false" tabindex="-1">{{leftButtonTitle}}</button>
    <button mat-button color="primary" [mat-dialog-close]="true" tabindex="-1">{{rightButtonTitle}}</button>
  </mat-dialog-actions>
</div>
