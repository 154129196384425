<div class="approve-payform-dialog">
  <div fxLayout="row">
    <h2 md-dialog-title>Approve This Payform?</h2>
  </div>
  <mat-dialog-content class="approve-payform-dialog-content">
    <div>
      <p>If you approve this payform everything within it will be final.</p>
      <p>How do you wish to continue?</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="approve-payform-dialog-actionbar"
                      fxLayout="row"
                      fxLayoutAlign="space-between center">
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <button mat-button
              color="primary"
              (click)="closeDialog(ApproveDialogResults.Cancel)"
              tabindex="-1">Cancel</button>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end center">
      <button mat-button
              color="primary"
              (click)="closeDialog(ApproveDialogResults.ApproveAndViewPayforms)"
              tabindex="-1">Approve & View Payforms</button>
      <button mat-button
              color="primary"
              (click)="closeDialog(ApproveDialogResults.ApproveAndNextPayform)"
              tabindex="-1">Approve & Next Payform</button>
    </div>
  </mat-dialog-actions>
</div>
