export enum PayformCommentsFormFields {
  NoteSequenceNbr = 'noteSequenceNbr',
  DsrPayformId = 'dsrPayformId',
  NoteType = 'noteType',
  NoteTypeSequenceNbr = 'noteTypeSequenceNbr',
  NoteDateTime = 'noteDateTime',
  CommenterEmployeeDetail = 'commenterEmployeeDetail',
  Role = 'role',
  Comments = 'comments',
  ListActionCd = 'listActionCd',
  AuditInfo = 'auditInfo'
}
