<div class="trip-info" [ngClass]="{ 'trip-info-readonly': readonly }">
  <div class="trip-info-content" [formGroup]="form">
    <h3>Trip Info</h3>
    <div fxLayout="column" *ngIf="readonly">
      <div class="trip-info-content-readonly" fxLayoutAlign="space-between center" fxLayout="row">
        <div fxLayout="column">
          <div
            class="trip-info-content-readonly-label"
            [ngClass]="{
              'trip-info-warning-header': errorWarningService.hasWarning(warningsCollection, WarningValidationType.SameOriginDestWarning),
              'trip-info-error-field': !(schedule$ | async)?.originSicCd
            }"
          >
            Origin
          </div>
          <div
            class="trip-info-content-readonly-data sic-value"
            [ngClass]="{ 'trip-info-warning-color': errorWarningService.hasWarning(warningsCollection, WarningValidationType.SameOriginDestWarning) }"
          >
            {{ (schedule$ | async)?.originSicCd }}
          </div>
        </div>
        <div fxLayout="column">
          <div
            class="trip-info-content-readonly-label"
            [ngClass]="{
              'trip-info-warning-header': errorWarningService.hasWarning(warningsCollection, WarningValidationType.SameOriginDestWarning),
              'trip-info-error-field': (schedule$ | async) && !(schedule$ | async)?.destinationSicCd && !(schedule$ | async)?.viaSicCd
            }"
          >
            Destination
          </div>
          <div
            class="trip-info-content-readonly-data sic-value"
            [ngClass]="{ 'trip-info-warning-color': errorWarningService.hasWarning(warningsCollection, WarningValidationType.SameOriginDestWarning) }"
          >
            {{ schedule$ | async | viaOrDestination }}
          </div>
        </div>
        <div fxLayout="column">
          <div
            class="trip-info-content-readonly-label"
            [ngClass]="{
              'trip-info-warning-header':
                errorWarningService.hasWarning(warningsCollection, WarningValidationType.EslWarning) ||
                errorWarningService.hasWarning(warningsCollection, WarningValidationType.EslSoloWarning) ||
                errorWarningService.hasWarning(warningsCollection, WarningValidationType.CushionWarning)
            }"
          >
            Status
          </div>
          <div
            class="trip-info-content-readonly-data"
            [ngClass]="{
              'trip-info-warning-color':
                errorWarningService.hasWarning(warningsCollection, WarningValidationType.EslWarning) ||
                errorWarningService.hasWarning(warningsCollection, WarningValidationType.EslSoloWarning) ||
                errorWarningService.hasWarning(warningsCollection, WarningValidationType.CushionWarning)
            }"
          >
            {{ schedule$ | async | eslCushion }}
          </div>
        </div>
      </div>
      <div class="trip-info-content-readonly-label" [ngClass]="{ 'trip-info-warning-header': errorWarningService.hasWarning(warningsCollection, WarningValidationType.OperationTypeWarning) }">
        Type of Operation
      </div>
      <div
        class="trip-info-content-readonly-data"
        [ngClass]="{
          'trip-info-warning-color': errorWarningService.hasWarning(warningsCollection, WarningValidationType.OperationTypeWarning),
          'trip-info-error-field':
            FormUtils.hasError(parentForm?.parent, LinehaulScheduleFormFields.Details, EquipmentErrorTypes.OperationTypeRequired) ||
            FormUtils.hasError(parentForm?.parent, LinehaulScheduleFormFields.Details, EquipmentErrorTypes.OperationTypeInvalid)
        }"
      >
        <span>{{ (schedule$ | async)?.typeOfOperation | typeOfOperation }}</span>
        <span *ngIf="FormUtils.hasError(parentForm.parent, LinehaulScheduleFormFields.Details, EquipmentErrorTypes.OperationTypeRequired)">Required</span>
      </div>
    </div>
    <div fxLayout="column" *ngIf="!readonly">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="trip-info-content-orig-dest">
        <mat-form-field class="trip-info-content-origin" [ngClass]="{ 'warning-section': form.valid && form['warnings'] && Object.keys(form['warnings']).length }" [formGroup]="form">
          <input
            class="sic-value"
            matInput
            type="text"
            autocomplete="off"
            placeholder="Origin"
            maxlength="3"
            minlength="3"
            [formControlName]="LinehaulScheduleFormFields.OriginSicCd"
            (blur)="onBlur()"
          />
          <mat-error>
            <div *ngIf="checkControlForErrors(LinehaulScheduleFormFields.OriginSicCd, ['required'])">Required</div>
            <div *ngIf="checkControlForErrors(LinehaulScheduleFormFields.OriginSicCd, ['invalidLocation', 'minlength'])">Invalid SIC</div>
          </mat-error>
          <div class="trip-info-warning" *ngIf="form.valid && errorWarningService.hasWarning(warningsCollection, WarningValidationType.SameOriginDestWarning)">
            <div>Origin/Dest duplicate</div>
          </div>
        </mat-form-field>
        <mat-form-field
          class="trip-info-content-destination"
          [ngClass]="{ 'warning-section': form.valid && errorWarningService.hasWarning(warningsCollection, WarningValidationType.SameOriginDestWarning) }"
        >
          <input
            class="sic-value"
            matInput
            type="text"
            autocomplete="off"
            placeholder="Destination"
            maxlength="3"
            minlength="3"
            [formControlName]="LinehaulScheduleFormFields.DestinationSicCd"
            (blur)="onBlur()"
          />
          <mat-error>
            <div *ngIf="checkControlForErrors(LinehaulScheduleFormFields.DestinationSicCd, ['required'])">Required</div>
            <div *ngIf="checkControlForErrors(LinehaulScheduleFormFields.DestinationSicCd, ['invalidLocation', 'minlength'])">Invalid SIC</div>
          </mat-error>
          <div class="trip-info-warning" *ngIf="form.valid && errorWarningService.hasWarning(warningsCollection, WarningValidationType.SameOriginDestWarning)">
            <div>Origin/Dest duplicate</div>
          </div>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox [formControlName]="LinehaulScheduleFormFields.EslRegular">
          <span [ngClass]="{ 'trip-info-warning-color': errorWarningService.hasWarning(warningsCollection, WarningValidationType.EslWarning) }">
            ESL
          </span>
        </mat-checkbox>
        <mat-checkbox [formControlName]="LinehaulScheduleFormFields.EslSolo">
          <span [ngClass]="{ 'trip-info-warning-color': errorWarningService.hasWarning(warningsCollection, WarningValidationType.EslSoloWarning) }">
            ESL Solo
          </span>
        </mat-checkbox>
        <mat-checkbox [formControlName]="LinehaulScheduleFormFields.CushionInd">
          <span [ngClass]="{ 'trip-info-warning-color': errorWarningService.hasWarning(warningsCollection, WarningValidationType.CushionWarning) }">
            Cushion
          </span>
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>
