import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { XpoDialogManagerService } from '../../services/xpo-dialog-manager.service';
import { AppFooterService } from '../../services/app-footer/app-footer.service';
import { OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { ScrollbarService } from '../../services/scrollbar/scrollbar.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterComponent implements OnInit, OnDestroy {
  private hasButtonsSubject = new BehaviorSubject<boolean>(true);
  public hasButtons$ = this.hasButtonsSubject.asObservable();

  private unsubscriber = new Subject<void>();
  private actionButtonsElement: Element;

  constructor(
    private dialog: MatDialog,
    private dialogManager: XpoDialogManagerService,
    public footerService: AppFooterService,
    private scrollbarService: ScrollbarService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.actionButtonsElement = this.document.getElementsByClassName('app-footer-container-row')[0];

    this.footerService.leftButtonConfig$.pipe(takeUntil(this.unsubscriber)).subscribe(config => {
      this.hasButtonsSubject.next(this.footerService.hasLeftButtonConfig || this.footerService.hasRightButtonConfig);
    });

    this.footerService.rightButtonConfig$.pipe(takeUntil(this.unsubscriber)).subscribe(config => {
      this.hasButtonsSubject.next(this.footerService.hasLeftButtonConfig || this.footerService.hasRightButtonConfig);
    });

    this.scrollbarService.scrollbarStatus$.pipe(takeUntil(this.unsubscriber)).subscribe(hasScrollbars => {
      if (hasScrollbars) {
        this.actionButtonsElement.setAttribute('class', 'app-footer-container-row app-footer-container-row-with-scroll');
      } else {
        this.actionButtonsElement.setAttribute('class', 'app-footer-container-row');
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  buttonClicked(label: string) {
    this.footerService.handleButtonClicked(label);
  }
}
