<div  [ngClass]="{'mat-elevation-z1': isPayform, 'errors-warnings-container-bottom': !isPayform}"
      *ngIf="(errorsCollection).length > 0" class="errors-warnings-container"
      fxLayout="row">
  <div [ngClass]="{'errors-warnings-indicator-payform': !isPayform}" 
        class="errors-warnings-indicator errors-warnings-indicator-error" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon>error</mat-icon>
  </div>
  <div class="errors-warnings-details" fxLayout="column">
    <div class="errors-warnings-details-header">Please review the {{errorsCollection.length}} error(s) below:</div>
    <div class="errors-warnings-details-item-error" *ngFor="let error of errorsCollection">{{error['message']}}</div>
  </div>
</div>
<div  [ngClass]="{'mat-elevation-z1': isPayform, 'errors-warnings-container-bottom': !isPayform}"
      *ngIf="(warningsCollection).length > 0" class="errors-warnings-container"
      fxLayout="row">
  <div [ngClass]="{'errors-warnings-indicator-payform': !isPayform}" class="errors-warnings-indicator errors-warnings-indicator-warning" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon>warning</mat-icon>
  </div>
  <div class="errors-warnings-details" fxLayout="column">
    <div class="errors-warnings-details-header">Please review the {{warningsCollection.length}} warning(s) below:</div>
    <div class="errors-warnings-details-item-warning" *ngFor="let warning of warningsCollection">{{warning['message']}}</div>
  </div>
</div>
