export enum ScheduleTrailerFormFields {
  DsrPayformId = 'dsrPayformId',
  SchSequenceNbr = 'schSequenceNbr',
  TrlrNbr = 'trlrNbr',
  DisplaySequenceNbr = 'displaySequenceNbr',
  TrlrInstId = 'trlrInstId',
  LoadedRlseNbr = 'loadedRlseNbr',
  TrlrEmtyInd = 'trlrEmtyInd',
  TrlrWeight = 'trlrWeight',
  TrlrHazardousInd = 'trlrHazardousInd',
  LoadedDestinationSic = 'loadedDestinationSic',
  ListActionCd = 'listActionCd',
  AuditInfo = 'auditInfo'
}
