<div class="not-authorized-container">
    <div class="not-authorized-container-content">
        <div>
            <p>We're sorry, you are not authorized to use this application.</p>
            <p>Please contact the IT Service Desk</p>
            <ul>
                <li>
                    <mat-icon>phone</mat-icon>
                    <div>(855) XPO-MYIT (855-976-6948)</div>
                </li>
                <li>
                    <mat-icon>email</mat-icon>
                    <a href="mailto:XPOITServiceDesk@xpo.com">XPOITServiceDesk@xpo.com</a>
                </li>
                <li>
                    <mat-icon>web</mat-icon>
                    <a href="http://xpologistics.service-now.com/">xpologistics.service-now.com</a>
                </li>
                <li>
                    <mat-icon>chat</mat-icon>
                    <a href="https://xpologistics.service-now.com/$chat_support.do?queueID=8b9c69f5db27620016dcf9baae961919">Chat with IT Service Desk</a>
                </li>
            </ul>
        </div>
    </div>
</div>