import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { NotificationService } from '@xpo-ltl/data-api';
import { ActionCd, PayformNoteTypeCd } from '@xpo-ltl/sdk-common';
import { DsrActivity, DsrPayform, NonDriveTimeType, Note } from '@xpo-ltl/sdk-linehaulpayform';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { FormStateDirective } from '../../../../classes/form-state.component';
import { ConfigManagerProperties, ErrorWarningTypes, LinehaulScheduleFormFields, SnackbarMessage } from '../../../../enums';
import { AsyncValidatorCompletionService } from '../../../../services/async-validator-completion.service';
import { DriverPayService } from '../../../../services/driver-pay/driver-pay.service';
import { PayFormService } from '../../services/pay-form.service';
import { ErrorWarningService } from '../../services/error-warning/error-warning.service';
import { ScheduleActivitiesFormBuilder } from './schedule-activities.form-builder';
import * as moment from 'moment-timezone';
import { PayPortalStateStore } from '../../../../../app/state';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { LoDash } from '../../../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-schedule-activities',
  templateUrl: './schedule-activities.component.html',
  styleUrls: ['./schedule-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleActivitiesComponent extends FormStateDirective implements OnInit, AfterContentInit, OnDestroy {
  readonly$ = new BehaviorSubject<boolean>(false);
  activities: DsrActivity[];

  @Input()
  title: string;
  @Input()
  schSequenceNbr: string = undefined;
  @Input()
  expandCollapse: boolean;
  @Input()
  isNonScheduleActivities = false;

  @ViewChild(MatExpansionPanel)
  panel: MatExpansionPanel;

  private activitiesArraySubject = new BehaviorSubject<DsrActivity[]>([]);
  public activitiesArray$ = this.activitiesArraySubject.asObservable();

  private validationTypeSubject = new BehaviorSubject<string>(ErrorWarningTypes.None);
  public validationType$ = this.validationTypeSubject.asObservable();

  private warningsCollectionSubject = new BehaviorSubject<any[]>([]);
  public warningsCollection$ = this.warningsCollectionSubject.asObservable();

  public readonly LinehaulScheduleFormFields = LinehaulScheduleFormFields;
  public readonly ActionCd = ActionCd;
  public readonly validationType = ErrorWarningTypes.None;
  public readonly ErrorWarningTypes = ErrorWarningTypes;

  public currentPayform: DsrPayform;
  activities$ = new BehaviorSubject<DsrActivity[]>([]);

  componentDestroyed$ = new Subject();

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private driverPayService: DriverPayService,
    private payformService: PayFormService,
    private asyncValidatorCompletion: AsyncValidatorCompletionService,
    private changeDetector: ChangeDetectorRef,
    public errorWarningService: ErrorWarningService,
    private state: PayPortalStateStore,
    private xpoSnackBar: XpoSnackBar
  ) {
    super(formBuilder);
  }

  ngOnInit() {
    // Create the FormGroup
    this.linkFormToParent(LinehaulScheduleFormFields.Activities, ScheduleActivitiesFormBuilder.create);

    this.state
      .getPayformReadOnlySelector()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(readonly => this.readonly$.next(readonly));
    // begin listening for changes to data
    this.state
      .getPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        this.currentPayform = payform.current;
        const activities = payform.current.dsrActivity;
        const newActivities = activities ? [...activities] : [];

        const makeDate = (d, def) => {
          if (d) {
            return moment(d)
              .seconds(0)
              .milliseconds(0)
              .toDate();
          }
          return moment(def).toDate();
        };

        // build an "fake" arrive activity for display / sort in activities section
        if (this.driverPayService.currentPayformSchedules && this.driverPayService.currentPayformSchedules.length) {
          const schedule = this.driverPayService.currentPayformSchedules.length ? this.driverPayService.currentPayformSchedules.find(s => s.schSequenceNbr === this.schSequenceNbr) : null;
          if (schedule) {
            const arrive = new DsrActivity();
            arrive.schSequenceNbr = this.schSequenceNbr;
            arrive.actvtySequenceNbr = '0';
            arrive.actvtySicCd = `${schedule.originSicCd || ''} -> ${schedule.viaSicCd ? schedule.viaSicCd : schedule.destinationSicCd || ''}`;
            arrive.startDateTimeUtc = makeDate(schedule.schArriveDateTimeUtc, '2999-01-01');
            arrive.endDateTimeUtc = schedule.schArriveDateTimeUtc;
            arrive.nonDriveTimeType = new NonDriveTimeType();
            arrive.nonDriveTimeType.actvtyName = 'Arrive';
            newActivities.push(arrive);

            // build a "fake" depart activity for display / sort in activities section
            const depart = new DsrActivity();
            depart.schSequenceNbr = this.schSequenceNbr;
            depart.actvtySequenceNbr = '0';
            depart.actvtySicCd = `${schedule.originSicCd || ''} -> ${schedule.viaSicCd ? schedule.viaSicCd : schedule.destinationSicCd || ''}`;
            depart.startDateTimeUtc = makeDate(schedule.schDepartDateTimeUtc, '2001-01-01');
            depart.endDateTimeUtc = schedule.schDepartDateTimeUtc;
            depart.nonDriveTimeType = new NonDriveTimeType();
            depart.nonDriveTimeType.actvtyName = 'Dispatch';
            newActivities.push(depart);
          }
        }

        // eslint-disable-next-line max-len
        const filteredActivities = newActivities.filter(
          (activity: DsrActivity) => LoDash.isEqual(activity.schSequenceNbr, this.schSequenceNbr) || (!this.schSequenceNbr && +activity.schSequenceNbr === 0)
        );

        const sortByActivities = LoDash.sortBy(filteredActivities, (act: DsrActivity) => `${act.startDateTimeUtc}, ${act.actvtySequenceNbr}`);
        this.activitiesArraySubject.next(sortByActivities);
      });

    merge(this.form.statusChanges, this.asyncValidatorCompletion.asyncValidationCompleted$)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(value => {
        this.checkFormErrorsWarnings();
      });

    this.errorWarningService.ActivityWarning$.pipe(takeUntil(this.unsubscriber.done)).subscribe(schSequenceNbr => {
      if (schSequenceNbr === this.schSequenceNbr || (schSequenceNbr === LinehaulScheduleFormFields.NonScheduleActivities && this.title === LinehaulScheduleFormFields.NonScheduleActivities)) {
        this.checkFormErrorsWarnings();
      }
    });
  }

  ngAfterContentInit() {
    this.validationType$.pipe(takeUntil(this.unsubscriber.done)).subscribe(value => {
      // TODO: UGLY HACK!
      // ASYNC SUBSCRIBER IN HTML NOT PICKING UP CHANGES WITHOUT A TIMEOUT
      setTimeout(() => {
        this.changeDetector.markForCheck();
      });
    });
  }

  addActivityClicked($event) {
    $event.stopPropagation();

    this.driverPayService
      .showAddActivityDialog(this.schSequenceNbr)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((activity: DsrActivity) => {
        if (activity) {
          const comments = LoDash.get(LoDash.last(activity.note), 'comments') as string;
          activity.note = undefined;

          // add new Activity
          activity.schSequenceNbr = this.schSequenceNbr;
          activity.dsrPayformId = this.currentPayform.dsrPayformId;
          activity.actvtySequenceNbr = this.payformService.nextActvtySequenceNbr();
          this.state.setPayformActivity(activity);

          // add new Note for Activity
          if (!LoDash.isEmpty(comments)) {
            const note = this.payformService.createNote(PayformNoteTypeCd.ACTIVITY, activity.actvtySequenceNbr, comments);
            this.state.setPayformNote(note);

            this.currentPayform.dsrActivity.map(act => {
              const nn = this.currentPayform.note.find((n: Note) => n.noteType === PayformNoteTypeCd.ACTIVITY && +n.noteTypeSequenceNbr === +act.actvtySequenceNbr);
              if (note) {
                act.note = [...[], nn];
              }
            });
          }

          this.xpoSnackBar.success(SnackbarMessage.ActivityAdded.replace('{0}', activity?.nonDriveTimeType?.actvtyName ?? ''));
        } else {
          this.state.setPayformActivity(null);
        }
      });
  }

  reSortActivities(activity: DsrActivity) {
    let activities = this.activitiesArraySubject.value;
    // eslint-disable-next-line radix
    const existing = activities.find(a => parseInt(a.actvtySequenceNbr) === parseInt(activity.actvtySequenceNbr));
    activities = activities.filter(act => act !== existing);
    activities.push(activity);

    this.activitiesArraySubject.next(LoDash.sortBy(activities, a => a.startDateTimeUtc));
  }

  private checkFormErrorsWarnings() {
    const warnings = this.errorWarningService.collectWarnings(this.form);

    this.warningsCollectionSubject.next(warnings);

    if (this.form.invalid) {
      this.validationTypeSubject.next(ErrorWarningTypes.Error);
      return;
    }

    if (Object.keys(warnings).length) {
      this.validationTypeSubject.next(ErrorWarningTypes.Warning);
      return;
    }

    this.validationTypeSubject.next(ErrorWarningTypes.None);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
