import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ScheduleDetourFormFields } from '../../../../../../enums/schedule-detour-form-fields.enum';
import { LinehaulScheduleFormFields } from '../../../../../../enums/linehaul-schedule-form-fields.enum';
import { detourValidatorFunction } from '../../../../../../validators';
import { ErrorWarningService } from '../../../../services';

export class DetourFormBuilder {
  /**
   * Return a FormGroup that contains all of the controls that need to be updated and/or validated.
   *
   * Add any Validators here so that they run even when the HTML controls are not rendered
   */
  public static create(formBuilder: UntypedFormBuilder, errorWarningService: ErrorWarningService, schSequenceNbr: string): UntypedFormGroup {
    const formGroup = formBuilder.group({
      [LinehaulScheduleFormFields.DetourInd]: [false, [detourValidatorFunction(errorWarningService, schSequenceNbr)]],
      [ScheduleDetourFormFields.DtrFields]: formBuilder.group({
        [ScheduleDetourFormFields.DtrHwUsed]: ['', [Validators.required]],
        [ScheduleDetourFormFields.DtrState]: ['', [Validators.required]],
        [ScheduleDetourFormFields.DtrTotalExMil]: ['', [Validators.required]],
      }),
    });
    return formGroup;
  }
}
