import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActivityNamePipe } from './activity-name.pipe';
import { EquipmentPipe } from './equipment.pipe';
import { PaidTimeOrDurationPipe } from './paid-time-or-duration.pipe';
import { PayformStatusPipe } from './payform-status.pipe';
import { ReplacePipe } from './replace';
import { ObjectToIterablePipe } from './obj-to-iterable.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SicDatePipe } from './sic-date.pipe';
import { SubstringPipe } from './substring-pipe';
import { TcNumberPipe } from './tc-number.pipe';
import { ToRegexPipe } from './to-regex.pipe';
import { TypeOfOperationPipe } from './type-of-operation.pipe';
import { ViaOrDestinationPipe } from './via-or-destination.pipe';
import { EslCushionPipe } from './esl-cushion.pipe';
import { MinutesToHoursPipe } from './minutes-to-hours.pipe';

const pipes = [
  SafeHtmlPipe,
  TcNumberPipe,
  PayformStatusPipe,
  SubstringPipe,
  ActivityNamePipe,
  TypeOfOperationPipe,
  EquipmentPipe,
  ReplacePipe,
  ObjectToIterablePipe,
  SicDatePipe,
  PaidTimeOrDurationPipe,
  ToRegexPipe,
  ViaOrDestinationPipe,
  EslCushionPipe,
  MinutesToHoursPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  providers: pipes,
  exports: pipes,
})
export class XpoPipesModule {}
