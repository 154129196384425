import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Directive } from '@angular/core';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType, PayformFormFields } from '../enums';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function payformOriginDestValidatorFunction(errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules.controls || linehaulSchedules.controls.length === 1) {
      FormUtils.unsetWarning(control, WarningValidationType.OriginDestWarning);
      errorWarningService.payformWarningsChanged();
      return null;
    }

    let origin: string;
    let dest: string;
    let hasEsl = false;
    const originDestObj = {};

    (linehaulSchedules.controls ?? []).forEach(schedule => {
      const schSequenceNbr: string = LoDash.get(schedule.get(LinehaulScheduleFormFields.SchSequenceNbr), 'value', undefined);
      const scheduleDetail = schedule.get(LinehaulScheduleFormFields.Details);

      if (schSequenceNbr && scheduleDetail) {
        const tripInfo = scheduleDetail.get(LinehaulScheduleFormFields.TripInfoControl);

        if (tripInfo) {
          if (!originDestObj['firstSchSequenceNbr'] || schSequenceNbr < originDestObj['firstSchSequenceNbr']) {
            origin = LoDash.get(tripInfo.get(LinehaulScheduleFormFields.OriginSicCd), 'value', undefined);
            originDestObj['firstSchSequenceNbr'] = schSequenceNbr;
          }
          if (!originDestObj['lastSchSequenceNbr'] || originDestObj['lastSchSequenceNbr'] < schSequenceNbr) {
            dest = LoDash.get(tripInfo.get(LinehaulScheduleFormFields.DestinationSicCd), 'value', undefined);
            originDestObj['lastSchSequenceNbr'] = schSequenceNbr;
          }
          if (LoDash.get(tripInfo.get(LinehaulScheduleFormFields.EslRegular), 'value', undefined)) {
            hasEsl = true;
          }
        }
      }
    });

    if (origin && dest && origin.toUpperCase() !== dest.toUpperCase() && !hasEsl) {
      const originDestWarning = { type: WarningValidationType.OriginDestWarning };
      FormUtils.setWarning(control, WarningValidationType.OriginDestWarning, originDestWarning);
    } else {
      FormUtils.unsetWarning(control, WarningValidationType.OriginDestWarning);
    }

    errorWarningService.payformWarningsChanged();
    return null;
  };
}

export function returnLastSchedule(id: number, control: UntypedFormGroup) {
  if (id === 1) {
    return null;
  }
  const scheduleDetail = control.get(`Schedule ${id} Details`);

  if (scheduleDetail.disabled) {
    return returnLastSchedule(id - 1, control);
  }

  return scheduleDetail;
}

@Directive({
  selector: '[payformOriginDestValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PayformOriginDestValidator, multi: true }],
})
export class PayformOriginDestValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return payformOriginDestValidatorFunction(this.errorWarningService)(control);
  }
}
