export class ListPayformsCell {
  constructor(
    public dsrPayformId: number,
    public empName: string,
    public empId: string,
    public sic: string,
    public shiftStartDate: string,
    public status: string
  ) {}
}
