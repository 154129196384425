import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Directive } from '@angular/core';
import { DsrActivityFormFields } from '../enums/dsr-activity-form-fields.enum';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { NonDriveTimeTypeFormFields } from '../enums/non-drive-time-type-form-fields.enum';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType } from '../enums/warning-validation-type.enum';
import { PayformFormFields } from '../enums';
import { FormUtils } from '../classes';
import { DsrActivity } from '@xpo-ltl/sdk-linehaulpayform';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function activitySicValidatorFunction(errorWarningService: ErrorWarningService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }

    const linehaulSchedules = control.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (!linehaulSchedules) {
      return null;
    }

    const payformData = linehaulSchedules.controls.reduce(
      (payformObj, schedule) => {
        const activitiesCtrl = schedule.get(LinehaulScheduleFormFields.Activities) as UntypedFormGroup;
        if (activitiesCtrl) {
          payformObj.activities.push(...LoDash.values(activitiesCtrl.controls));
        }

        const scheduleDetailCtrl = schedule.get(LinehaulScheduleFormFields.Details);
        if (scheduleDetailCtrl) {
          const tripInfo = scheduleDetailCtrl.get(LinehaulScheduleFormFields.TripInfoControl);
          if (tripInfo) {
            const originSic = tripInfo.get(LinehaulScheduleFormFields.OriginSicCd);
            const destSic = tripInfo.get(LinehaulScheduleFormFields.DestinationSicCd);
            if (originSic && destSic) {
              payformObj.schDetailSics.push(originSic.value, destSic.value);
            }

            const viaSic = tripInfo.get(LinehaulScheduleFormFields.ViaSicCd);
            if (viaSic) {
              payformObj.schDetailSics.push(viaSic.value);
            }
          }
        }
        return payformObj;
      },
      { activities: [], schDetailSics: [] }
    );

    (payformData.activities ?? []).forEach(activityControl => {
      const activitySic = LoDash.get(activityControl.value, DsrActivityFormFields.ActvtySicCd, undefined);
      const activity = activityControl.value as DsrActivity;
      const isArriveOrDepart = (activity?.nonDriveTimeType?.actvtyName ?? '') === 'Arrive' || (activity?.nonDriveTimeType?.actvtyName ?? '') === 'Dispatch';

      if (activitySic && !isArriveOrDepart && payformData.schDetailSics.every(sic => sic !== activitySic)) {
        const nonDriveTimeType = LoDash.get(activityControl.value, DsrActivityFormFields.NonDriveTimeType, undefined);
        const primarySubject = LoDash.get(nonDriveTimeType, NonDriveTimeTypeFormFields.ActvtyName, undefined);
        const activitySicWarning = {
          type: WarningValidationType.ActivitySicWarning,
          primarySubject,
        };

        if (activityControl.enabled) {
          FormUtils.setWarning(activityControl, WarningValidationType.ActivitySicWarning, activitySicWarning);
        }
      } else {
        FormUtils.unsetWarning(activityControl, WarningValidationType.ActivitySicWarning);
      }
      errorWarningService.activityWarningsChanged(activityControl.parent[LinehaulScheduleFormFields.SchSequenceNbr]);
    });

    return null;
  };
}

@Directive({
  selector: '[activitySicValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ActivitySicValidator, multi: true }],
})
export class ActivitySicValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return activitySicValidatorFunction(this.errorWarningService)(control);
  }
}
