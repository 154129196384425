export enum WarningMessage {
  ActivityDurationWarning = 'The {0} activity is outside the defined time standard. Please review for accuracy.',
  ActivityDurationWarnings = '{1} activities ({0}) are outside the defined time standards. Please review for accuracy.',
  ActivitySicWarning = 'SIC for {0} does not match the Origin or Dest or Via SIC for any of the schedules. Please review for accuracy.',
  ActivitySicWarnings = 'SICs for {0} do not match the Origin or Dest or Via SIC for any of the schedules. Please review for accuracy.',
  CushionWarning = 'Cushion is selected for schedule {0}. Please review for accuracy.',
  DetourWarning = '{0} contains a detour. Please review for accuracy.',
  EquipmentMismatch = '{0} is not a {1}. Please review for accuracy.',
  EquipmentMismatchs = '{0} are not valid equipment numbers. Please review for accuracy.',
  EquipmentNotFound = '{1} not found for {0}. Please review for accuracy.',
  EquipmentNotFounds = 'Equipment not found for {0}. Please review for accuracy.',
  EslSoloWarning = 'ESL solo is selected for schedule {0}. Please review for accuracy.',
  EslWarning = 'ESL is selected for schedule {0}. Please review for accuracy.',
  HazmatOverrideWarning = 'DSR has overridden the system derived hazmat indicator for the trailers on schedule {0}. Please review for accuracy.',
  HazmatRouteWarning = "A Hazmat route has been selected for schedule {0}, but Hazmat indicator is set to 'No' for the schedule. Please review for accuracy.",
  MaxDurationWarning = 'The duration for {0} is more than 1 hour. Please review for accuracy.',
  MaxDurationWarnings = 'The durations for {0} are more than 1 hour. Please review for accuracy.',
  OperationTypeWarning = 'The selected Type of Operation for {0} is a Long Combination Vehicle (LCV). Please review for accuracy.',
  OriginDestWarning = 'DSR did not return to Origin SIC at end of shift. Please review for accuracy.',
  PayformConflictWarning = 'More than 1 payform exists for this DSR and this shift date. Please review for accuracy.',
  RouteTypeWarning = 'The route selected for {0} is not a standard route. Please review for accuracy.',
  SameOriginDestWarning = 'The schedule origin and destination ({0}) are identical. Please review for accuracy.',
  TotalMilesWarning = 'Total miles for the schedules in the Payform is more than {0}. Please review for accuracy.',
  MultipleFuelingWarning = 'Payform contains {0} Fueling activities. Please review for accuracy.',
  OnlyOneScheduleWarning = 'Payform only contains 1 schedule. Please review for accuracy.',
}
