<div *ngIf="!isNonScheduleActivities || (activitiesArray$ | async)?.length > 0 || (readonly$ | async) === false" class="driverpay-schedule">
  <mat-expansion-panel class="driverpay-schedule-activities" [expanded]="expandCollapse">
    <mat-expansion-panel-header
      [ngClass]="{
        'driverpay-schedule-activities-has-warnings': (validationType$ | async) === ErrorWarningTypes.Warning,
        'driverpay-schedule-activities-has-errors': (validationType$ | async) === ErrorWarningTypes.Error
      }"
    >
      <mat-panel-title>
        <div fxLayout="row" fxFlex="" fxLayoutAlign="start center">
          <div class="driverpay-schedule-activities-title">{{ title }}</div>
        </div>
        <div *ngIf="(readonly$ | async) === false" fxLayout="row" fxLayoutAlign="end center" class="driverpay-schedule-activities-row">
          <button id="add-activity-button" mat-button mat-icon-button (click)="addActivityClicked($event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-errors-warnings *ngIf="errorWarningService.shouldDisplayWarnings()" [warningsCollection]="warningsCollection$ | async"></app-errors-warnings>
      <div class="driverpay-schedule__activity-header" *ngIf="(activitiesArray$ | async)?.length > 0">
        <div class="activity-header">Activity</div>
        <div class="sic-header">SIC</div>
        <div class="start-time-header">Start Time</div>
        <div class="end-time-header">End Time</div>
        <div class="time-altered-header">Time Altered</div>
        <div class="duration-header">Duration</div>
        <div class="variance-header">Time Variance</div>
        <div class="comments-header">Comments</div>
        <div class="equipment-header">
          <div *ngIf="readonly$ | async">Equipment</div>
          <div *ngIf="(readonly$ | async) === false">Equipment & Actions</div>
        </div>
      </div>
      <div>
        <div *ngFor="let activity of activitiesArray$ | async" class="driverpay-schedule-activities-item">
          <app-activity *ngIf="activity?.listActionCd !== ActionCd.DELETE" [activity]="activity" [parentForm]="form" (reSortActivities)="reSortActivities($event)"> </app-activity>
        </div>
        <div *ngIf="(activitiesArray$ | async)?.length === 0" class="driverpay-schedule__no-activites">No activities present</div>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>
