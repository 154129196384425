<div class="app-footer-container" [ngClass]="{ 'app-footer-container-default': (hasButtons$ | async) === false }" fxLayout="row" fxLayout="stretch stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="app-footer-container-row">
    <div fxLayout="row" fxLayoutAlign="start center" class="app-footer-container-row-left">
      <button
        *ngFor="let button of footerService.leftButtonConfig$ | async; let i = index"
        id="left-footer-{{ i + 1 }}-button"
        mat-button
        [ngClass]="{ 'mat-raised-button': button.raised }"
        [color]="button.color"
        [disabled]="button.disabled"
        [ngStyle]="{ display: button.visible ? 'block' : 'none' }"
        (click)="buttonClicked(button.label)"
      >
        {{ button.label }}
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="app-footer-container-row-right">
      <ng-container *ngIf="footerService.rightButtonConfig$ | async as config">
        <ng-container *ngFor="let button of config; let i = index">
          <button
            id="right-footer-{{ i + 1 }}-button"
            mat-button
            [ngClass]="{ 'mat-raised-button': button.raised }"
            [color]="button.color"
            [disabled]="button.disabled$ ? (button.disabled$ | async) : button.disabled"
            [ngStyle]="{ display: button.visible ? 'block' : 'none' }"
            (click)="buttonClicked(button.label)"
          >
            {{ button.label }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
