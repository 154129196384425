import { Pipe, PipeTransform } from '@angular/core';
import { LoDash } from '../utils/angular-utils/lodash-utils';
@Pipe({
  name: 'toIterable',
})
export class ObjectToIterablePipe implements PipeTransform {
  transform(value: Object): Object[] {
    return LoDash.values(value);
  }
}
