export enum EquipmentErrorTypes {
  OneDollyRequiredWithTwoTrailers = 'oneDollyRequiredWithTwoTrailers',
  OneTractorTwoDollysNoTrailer = 'oneTractorTwoDollysNoTrailer',
  TwoDollysRequiredWithThreeTrailers = 'twoDollysRequiredWithThreeTrailers',
  DuplicateDolly = 'duplicateDolly',
  DuplicateTrailer = 'duplicateTrailer',
  InvalidLoadReleaseFormat = 'invalidLoadReleaseFormat',
  LoadReleaseRequired = 'loadReleaseRequired',
  NoTrailerProvided = 'noTrailerProvided',
  OperationTypeRequired = 'operationTypeRequired',
  OperationTypeInvalid = 'operationTypeInvalid',
}
