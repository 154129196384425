import { ElementRef, Injectable, Injector } from '@angular/core';
import { NotesOverlayComponent } from '../components/schedule-activities/components/notes-overlay/notes-overlay.component';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ConnectedPosition, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { NotesOverlayRef } from '../../../classes/notes-overlay-ref';
import { Note } from '@xpo-ltl/sdk-linehaulpayform';
import { NOTES_OVERLAY_DATA } from '../components/schedule-activities/components/notes-overlay/NotesOverlayData';
import { Observable } from 'rxjs';

@Injectable()
export class NotesOverlayService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  // created for a specific implementation if this ends up being used in other components then we should make these configs externally passed in.
  public openOverlay(connectedToElement: ElementRef, notes: Note[]): NotesOverlayRef {
    const overlayPosition: ConnectedPosition = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: 41,
      offsetY: -49,
    };

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(connectedToElement)
      .withPositions([overlayPosition]);
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const overlayConfig: OverlayConfig = { hasBackdrop: true, backdropClass: 'cdk-overlay-transparent-backdrop', scrollStrategy, positionStrategy };
    const overlayRef = this.overlay.create(overlayConfig);
    const notesOverlyaRef = new NotesOverlayRef(overlayRef);
    const customInjector = this.createInjector(notes, notesOverlyaRef);
    overlayRef.attach(new ComponentPortal(NotesOverlayComponent, null, customInjector));
    return notesOverlyaRef;
  }

  private createInjector(notes: Note[], ref: NotesOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(NotesOverlayRef, ref);
    injectionTokens.set(NOTES_OVERLAY_DATA, notes);
    return new PortalInjector(this.injector, injectionTokens);
  }
}
