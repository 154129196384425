import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { ErrorWarningService } from '../components/payform/services/error-warning/error-warning.service';
import { WarningValidationType } from '../enums';
import { DisplayOnlyFormFields } from '../enums/display-only-form-fields.enum';
import { LinehaulScheduleFormFields } from '../enums/linehaul-schedule-form-fields.enum';
import { AppConstantsService } from '../services/app-constants.service';
import { FormUtils } from '../classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export function routeTypeValidatorFunction(errorWarningService: ErrorWarningService, constantsService: AppConstantsService, schSequenceNbr: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control || control.disabled || !errorWarningService.shouldDisplayWarnings()) {
      return null;
    }
    const tripInfoCtrl = control.get(LinehaulScheduleFormFields.TripInfoControl);
    const detourCtrl = control.get(LinehaulScheduleFormFields.DetourControl);
    const routingCtrl = control.get(LinehaulScheduleFormFields.RoutingControl);
    const tripCode = LoDash.get(control.get(DisplayOnlyFormFields.ScheduleTripCode), 'value', null);

    if (!tripInfoCtrl) {
      return null;
    }

    const originCtrl = tripInfoCtrl.get(LinehaulScheduleFormFields.OriginSicCd);
    const destCtrl = tripInfoCtrl.get(LinehaulScheduleFormFields.DestinationSicCd);

    if (!originCtrl || !destCtrl) {
      return null;
    }

    const origin = LoDash.get(originCtrl, 'value', '');
    const dest = LoDash.get(destCtrl, 'value', '');

    if (!origin || !dest || origin.length < 3 || dest.length < 3) {
      FormUtils.unsetWarning(control, WarningValidationType.RouteTypeWarning);
      errorWarningService.detailWarningsChanged(schSequenceNbr);
    }

    if (tripCode && tripCode !== constantsService.standardRoute) {
      const routeTypeWarning = {
        type: WarningValidationType.RouteTypeWarning,
        primarySubject: `${origin.toUpperCase()} -> ${dest.toUpperCase()}`,
      };

      FormUtils.setWarning(control, WarningValidationType.RouteTypeWarning, routeTypeWarning);
    } else {
      FormUtils.unsetWarning(control, WarningValidationType.RouteTypeWarning);
    }

    errorWarningService.detailWarningsChanged(schSequenceNbr);
    return null;
  };
}

@Directive({
  selector: '[routeTypeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RouteTypeValidator, multi: true }],
})
export class RouteTypeValidator implements Validator {
  constructor(private errorWarningService: ErrorWarningService, private constantsService: AppConstantsService) {}
  @Input()
  schSequenceNbr: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return routeTypeValidatorFunction(this.errorWarningService, this.constantsService, this.schSequenceNbr)(control);
  }
}
