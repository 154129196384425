export enum NonDriveTimeTypeFormFields {
  NonDrvTimeTypeId = 'nonDrvTimeTypeId',
  KindOfTimeCd = 'kindOfTimeCd',
  PaidCd = 'paidCd',
  RequiredCommentInd = 'requiredCommentInd',
  ActvtyName = 'actvtyName',
  ActvtyDescription = 'actvtyDescription',
  AuditInfo = 'auditInfo',
  WorkStandard = 'workStandard',
}
