import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'minutesToHours',
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(val: number, args?: any): any {
    if (val) {
      const hours = val / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      return `${rhours} hr ${rminutes} min`;
    } else if (val === 0) {
      return '0';
    }

    return '';
  }
}
