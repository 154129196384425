import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AsyncValidatorCompletionService } from '../services/async-validator-completion.service';
import { ServiceCenterCacheService } from '../services/service-center-cache.service';

export function sicValidatorFunction(
  serviceCenterCacheService: ServiceCenterCacheService,
  asyncValidatorCompleted: AsyncValidatorCompletionService
): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (!control || !control.value || control.value.length < 3) {
      return of(null);
    }

    return serviceCenterCacheService.request({ sicCd: control.value }).pipe(
      map(
        response => {
          asyncValidatorCompleted.done(true);
          return response && response.componentCd ? null : { invalidLocation: true };
        },
        errors => {
          asyncValidatorCompleted.done(true);
          return { invalidLocation: true };
        }
      ),
      catchError(error => {
        asyncValidatorCompleted.done(true);
        return of({ invalidLocation: true });
      })
    );
  };
}

@Directive({
  selector: '[sicValidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: SicValidator, multi: true }],
})
export class SicValidator implements Validator {
  constructor(
    private serviceCenterCache: ServiceCenterCacheService,
    private asyncValidatorCompleted: AsyncValidatorCompletionService
  ) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return sicValidatorFunction(this.serviceCenterCache, this.asyncValidatorCompleted)(control);
  }
}
