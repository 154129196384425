import { Component, OnInit } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
@Component({
  selector: 'xpo-utils-version-banner',
  templateUrl: './version-banner.component.html',
  styleUrls: ['./version-banner.component.scss'],
})
export class VersionBannerComponent {
  constructor(private configManagerService: ConfigManagerService) {}

  public get buildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }
}
