<div [formGroup]="userFeedbackFormGroup" class="feedback-container">
  <h2 class="mat-dialog-title feedback-container-title">User Feedback</h2>
  <mat-dialog-content>
    <div class="feedback-container-content" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field>
        <input matInput type="text" placeholder="From" maxlength="50" autocomplete="off" [formControlName]="feedbackFormFields.USER" />
        <mat-error>
          <div class="mat-error" *ngIf="hasError('required', feedbackFormFields.USER)">Required</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" placeholder="Subject" maxlength="100" autocomplete="off" [formControlName]="feedbackFormFields.SUBJECT" />
        <mat-error>
          <div class="mat-error" *ngIf="hasError('required', feedbackFormFields.SUBJECT)">Required</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <textarea matInput maxTextareaAutosize placeholder="Message" autocomplete="off" [formControlName]="feedbackFormFields.MESSAGE"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="feedback-container-actions">
    <button mat-button color="primary" (click)="cancelClicked()" tabindex="-1">Cancel</button>
    <button mat-button color="primary" (click)="sendClicked()" tabindex="-1" [disabled]="!userFeedbackFormGroup.valid || sendingEmail">Send</button>
  </mat-dialog-actions>
</div>
