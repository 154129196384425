import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApproveDialogResults } from '../../enums/approve-dialog-results.enum';

@Component({
  selector: 'app-approve-payform',
  templateUrl: './approve-payform.component.html',
  styleUrls: ['./approve-payform.component.scss'],
})
export class ApprovePayformComponent implements OnInit {
  public ApproveDialogResults = ApproveDialogResults;

  constructor(private dialogRef: MatDialogRef<ApprovePayformComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  closeDialog(buttonLabel: ApproveDialogResults) {
    this.dialogRef.close(buttonLabel);
  }
}
