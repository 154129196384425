import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class AsyncValidatorCompletionService {
  private asyncValidationCompledSubject = new BehaviorSubject<boolean>(false);
  public asyncValidationCompleted$ = this.asyncValidationCompledSubject.asObservable();

  constructor() {}

  public done(done: boolean) {
    // TODO: THIS IS ONE UGLY HACK!  THERE IS A BUG IN ANGULAR THAT IS NOT CHANGING THE STATUS FROM PENDING WHEN
    // USING THE ASYNC VALIDATORS SO THIS IS HACKED IN PLACE UNTIL SUCH TIME THIS IS FIXED.
    // TODO: ANGULAR BUG REPORT-https://github.com/angular/angular/issues/20424
    setTimeout(() => {
      this.asyncValidationCompledSubject.next(done);
    });
  }
}
