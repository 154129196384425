<div class="routing" [ngClass]="{ 'routing-readonly': readonly }" fxFlex="">
  <div class="routing-content" fxLayout="column" fxFlex="">
    <div fxLayout="row" fxLayouAlign="space-between center">
      <h3>Routing</h3>
      <div *ngIf="!readonly">
        <button id="edit-route-schedule-{{ schSequenceNbr }}-button" class="edit-route" mat-button mat-icon-button [disabled]="editDisabled$ | async" (click)="handleEditClicked()">
          <mat-icon [ngClass]="{ disabled: editDisabled$ | async }">create</mat-icon>
        </button>
      </div>
    </div>
    <div [ngClass]="{ 'routing-has-warning': errorWarningService.hasWarning(warningsCollection, WarningValidationType.RouteTypeWarning) }" *ngIf="(editDisabled$ | async) === false">
      <div *ngIf="currentRoute$ | async">{{ currentRouteSubject.value.tripCd }} - {{ currentRouteSubject.value.instructionTxt }}</div>
      <!-- <div *ngIf="!(currentRoute$ | async)" class="error">Select Route</div> -->
    </div>
    <!-- <div *ngIf="(editDisabled$ | async)" class="error">Invalid Route</div> -->
  </div>
</div>
