<button mat-button color="primary" (click)="onUpdateFormState()">Update Form State</button>
<mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="form-state-tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <button mat-icon-button disabled></button>
      <ng-container [ngTemplateOutlet]="formStateNode" [ngTemplateOutletContext]="{node: node}"></ng-container>
    </li>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <li>
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <ng-container [ngTemplateOutlet]="formStateNode" [ngTemplateOutletContext]="{node: node}"></ng-container>
      </div>
      <ul [class.invisible]="!nestedTreeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>

<ng-template #formStateNode let-node="node">
  <div class="tree-node">
    <table>
      <thead>
        <tr>
          <th>name</th>
          <th>status</th>
          <th>valid</th>
          <th>pending</th>
          <th>enabled</th>
          <th>dirty</th>
          <th>touched</th>
          <th>value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{node.name}}</td>
          <td [class]="node.status">{{node.status}}</td>
          <td>{{node.valid}}</td>
          <td>{{node.pending}}</td>
          <td>{{node.enabled}}</td>
          <td>{{node.dirty}}</td>
          <td>{{node.touched}}</td>
          <td>{{getValue(node.value)}}</td>
        </tr>
      </tbody>
    </table>
    <code *ngIf="node.errors">errors: {{node.errors | json}}</code>
    <code *ngIf="node.warnings">warnings: {{node.warnings | json}}</code>
  </div>
</ng-template>
