import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { ScrollbarService } from './services/scrollbar/scrollbar.service';
import { EdgeAppStateStore } from '@xpo-ltl/ngx-ltl-app-state';
import { EdgeShellConfig, EdgeShellSic } from '@xpo-ltl/ngx-ltl-menu';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  @HostBinding('class') hostClass = 'app-container';

  title: string;
  build: string;
  isProduction: boolean;
  isListView = true;

  private unsubscriber = new Subject<void>();

  constructor(
    private configManagerService: ConfigManagerService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private scrollbarService: ScrollbarService,
    private router: Router,
    private appStateStore: EdgeAppStateStore,
    private configManager: ConfigManagerService
  ) {
    iconRegistry.addSvgIcon('xpo-logo', sanitizer.bypassSecurityTrustResourceUrl('./assets/images/xpo_logo.svg'));

    const optypes = [0, 1, 2, 5, 7, 9, 10, 11, 12, 13, 17];
    optypes.forEach(optype => {
      iconRegistry.addSvgIcon(`optype_${optype}`, sanitizer.bypassSecurityTrustResourceUrl(`./assets/images/optype_${optype}.svg`));
    });

    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    this.isProduction = this.configManagerService.getSetting<boolean>('production');
  }

  private readonly sics: EdgeShellSic[] = [
    {
      sicCode: 'UPO',
      name: 'UPO',
      timeZoneName: 'UPO',
      stateCode: 'UPO',
      displayName: 'UPO',
      rrsInd: true,
      shifts: [
        {
          shiftCode: 'O',
          name: 'OTB',
          shortName: 'OTB',
        },
      ],
    },
  ];

  shellMenuConfig: EdgeShellConfig = {
    appTitle: 'Edge Linehaul Pay Portal',
    appEnv: this.configManager.getSetting('region'),
    shiftSelection: false,
    sicSelection: true,
    menuEntries: [
      {
        label: 'Payforms',
        path: 'list-payforms',
      },
    ],
  };

  ngOnInit(): void {
    this.scrollbarService.startWatcher();

    this.router.events.pipe(takeUntil(this.unsubscriber)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isListView = !event['url'].startsWith('/view-payform') && !event['url'].startsWith('/payform');
      }
    });

    // TODO: Remove default hard-coding once SIC/SHIFT are persisted
    // on the query string
    const sicCode = this.sics.find(x => x.sicCode === 'UPO');
    this.appStateStore.setSic(sicCode);
  }
}
