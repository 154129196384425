<div fxLayout="column" fxLayoutGap="16px" class="driverpay-schedule-details-container">
  <mat-expansion-panel class="driverpay-schedule-details" [expanded]="expandCollapse">
    <mat-expansion-panel-header
      [ngClass]="{
        'driverpay-schedule-details-has-warnings': (validationType$ | async) === ErrorWarningTypes.Warning,
        'driverpay-schedule-details-has-errors': (validationType$ | async) === ErrorWarningTypes.Error
      }"
    >
      <mat-panel-title>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="" class="driverpay-schedule-details-row">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="driverpay-schedule-details-row-title">{{ title }}</div>
            <div *ngIf="(schedule$ | async)?.originSicCd && (schedule$ | async | viaOrDestination)" fxLayout="row" fxLayoutAlign="start center">
              <div class="sic-value" [ngClass]="{ 'driverpay-schedule-details-header-warning': (errorWarningService.PayformWarning$ | async) && checkOriginDestWarning('origin') }">
                {{ (schedule$ | async)?.originSicCd }}
              </div>
              <mat-icon>arrow_forward</mat-icon>
              <div class="sic-value" [ngClass]="{ 'driverpay-schedule-details-header-warning': (errorWarningService.PayformWarning$ | async) && checkOriginDestWarning('dest') }">
                {{ schedule$ | async | viaOrDestination }}
              </div>
            </div>
            <div *ngIf="(schedule$ | async)?.originSicCd && (schedule$ | async | viaOrDestination)" fxLayout="row" fxLayoutAlign="start center">
              <span>|</span>
              <div
                [ngClass]="{
                  'driverpay-schedule-details-header-error': checkTotalMilesLessThanZero(),
                  'driverpay-schedule-details-header-warning': (errorWarningService.PayformWarning$ | async) && checkTotalMileageWarning()
                }"
              >
                {{ totalMiles$ | async | number }} Miles
              </div>
            </div>
            <div *ngIf="(schedule$ | async)?.schDepartDateTimeUtc" fxLayout="row" fxLayoutAlign="start center">
              <span>|</span>
              <div>Dispatched {{ (schedule$ | async)?.schDepartDateTimeUtc | sicDate: 'HH:mm':timezoneSic | async }}</div>
            </div>
            <div *ngIf="(schedule$ | async)?.schArriveDateTimeUtc" fxLayout="row" fxLayoutAlign="start center">
              <span>|</span>
              Arrived {{ (schedule$ | async)?.schArriveDateTimeUtc | sicDate: 'HH:mm':timezoneSic | async }}
            </div>
          </div>
          <div *ngIf="(readonly$ | async) === false" fxLayout="row" fxLayoutAlign="end center">
            <button id="delete-schedule-button" mat-button mat-icon-button (click)="deleteSchedule($event)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-errors-warnings *ngIf="errorWarningService.shouldDisplayWarnings()" [warningsCollection]="warningsCollection$ | async" [errorsCollection]="errorsCollection$ | async"></app-errors-warnings>
      <div fxLayout="row" *ngIf="readonly$ | async; else editablePayform">
        <app-trip-info [warningsCollection]="warningsCollection$ | async" [parentForm]="form" [schSequenceNbr]="schSequenceNbr"></app-trip-info>
        <app-equipment [warningsCollection]="warningsCollection$ | async" [parentForm]="form" [schSequenceNbr]="schSequenceNbr"></app-equipment>
        <app-routing [warningsCollection]="warningsCollection$ | async" [parentForm]="form" [schSequenceNbr]="schSequenceNbr"></app-routing>
        <app-detour [warningsCollection]="warningsCollection$ | async" [parentForm]="form" [schSequenceNbr]="schSequenceNbr"></app-detour>
        <app-notes [parentForm]="form" [typeSequenceNbr]="schSequenceNbr" [noteType]="PayformNoteTypeCd.SCHEDULE"></app-notes>
      </div>
      <ng-template #editablePayform>
        <div fxLayout="row">
          <div fxLayout="column" *ngIf="warningsCollection$ | async as warningsCollection">
            <app-trip-info [warningsCollection]="warningsCollection" [parentForm]="form" [schSequenceNbr]="schSequenceNbr" [readonly]="false"></app-trip-info>
            <app-routing [warningsCollection]="warningsCollection" [parentForm]="form" [schSequenceNbr]="schSequenceNbr" [readonly]="false"></app-routing>
          </div>
          <app-equipment [warningsCollection]="warningsCollection" [parentForm]="form" [schSequenceNbr]="schSequenceNbr" [readonly]="false"></app-equipment>
          <app-detour [warningsCollection]="warningsCollection" [parentForm]="form" [schSequenceNbr]="schSequenceNbr" [readonly]="false"></app-detour>
        </div>
      </ng-template>
    </ng-template>
  </mat-expansion-panel>
</div>
