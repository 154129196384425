<mat-expansion-panel class="driverpay-comments" #commentsExpansionPanel [expanded]="expandCollapse">
  <mat-expansion-panel-header>
    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="" fxLayout="start center" class="driverpay-comments__header">{{ title$ | async }}</div>
      <div *ngIf="(readonly$ | async) === false">
        <div *ngIf="(editMode$ | async) === false">
          <button mat-button mat-icon-button class="driverpay-comments__show-add" (click)="handleHeaderAddAction($event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div *ngIf="editMode$ | async">
          <button mat-button mat-icon-button class="driverpay-comments__show-cancel" (click)="handleHeaderCancelAction($event)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div>
      <!-- ADD COMMENT SECTION -->
      <div class="driverpay-comments__add-container" *ngIf="editMode$ | async" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-form-field fxFlex="">
          <textarea #commentText matInput [(ngModel)]="commentInput" maxlength="250" placeholder="New Comment" class="driverpay-comments__add-container__comment-text"></textarea>
          <mat-hint align="end">{{ commentText?.value?.length }} / 250</mat-hint>
        </mat-form-field>
        <button mat-raised-button="" [disabled]="!commentInput || commentInput.length <= 0" color="primary" (click)="handleAddComment()" class="driverpay-comments__add-container__add-action">
          Add
        </button>
      </div>
      <!-- COMMENTS SECTION -->
      <div [formGroup]="form" *ngIf="(notesArray$ | async)?.length > 0">
        <div class="driverpay-comments__comment__header" fxLayoutAlign="row" fxLayoutGap="16px">
          <div class="driverpay-comments__comment__header__date">Date</div>
          <div class="driverpay-comments__comment__header__author">Author</div>
          <div class="driverpay-comments__comment__header__role">Role</div>
          <div class="driverpay-comments__comment__header__comment-text">Comments</div>
        </div>
        <div class="driverpay-comments__items">
          <div class="driverpay-comments__comment" *ngFor="let comment of notesArray$ | async; let i = index" fxLayout="row" fxLayoutGap="16px">
            <div class="driverpay-comments__comment__date">{{ comment.noteDateTimeUtc | sicDate: 'MM/DD/YYYY, HH:mm':payformSicCd | async }}</div>
            <div class="driverpay-comments__comment__author">{{ comment.commenterEmployeeDetail?.fullName }}</div>
            <div class="driverpay-comments__comment__role">{{ isDsrComment(comment) ? 'DSR' : 'Supervisor' }}</div>
            <div class="driverpay-comments__comment__comment-text">{{ comment.comments }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="(notesArray$ | async)?.length === 0 && (editMode$ | async) === false" class="driverpay-comments__not-present">
        No comments present
      </div>
    </div>
  </ng-template>
</mat-expansion-panel>
