<div class="route-types">
  <h2 md-dialog-title>Route Type</h2>
  <mat-dialog-content fxLayout="column" class="route-types-content">
    <div fxLayout="row" class="route-types-content-header">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50" class="route-types-content-header-orig-dest">
        <span>{{ data.origin | uppercase }}</span>
        <mat-icon>arrow_forward</mat-icon>
        <span>{{ data.destination | uppercase }}</span>
        <svg *ngIf="searching$ | async" class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
      <div *ngIf="(searching$ | async) === false" fxLayout="row" fxLayoutAlign="end center" fxFlex="50">Displaying {{ (routeList$ | async).length }} choices</div>
    </div>
    <mat-selection-list #selectList tabindex="-1" class="route-types-content-list">
      <mat-list-option *ngFor="let route of routeList$ | async" [value]="route" class="route-types-content-row" [ngClass]="{ selected: route === selectedRoute }">
        <div>{{ route.tripCd }} - {{ route.instructionTxt }} ({{ route.milesBetweenOrigAndDestSic | number }} Miles)</div>
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions class="route-types-actionbar">
    <button mat-button color="primary" (click)="cancelClicked()" tabindex="-1">Cancel</button>
    <button mat-button color="primary" (click)="doneClicked()" [disabled]="!selectedRoute" tabindex="-1">Done</button>
  </mat-dialog-actions>
</div>
