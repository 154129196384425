import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '../model/user';
import { map, tap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { Subject, Observable } from 'rxjs';

const ACCESS_KEY = '.AccessToken';
const REFRESH_KEY = '.RefreshToken';
const USER_INFO_KEY = '.UserInfo';
const ACCESS_TOKEN_EXPIRATION_KEY = '.AccessTokenExpiration';
const REFRESH_TOKEN_EXPIRATION_KEY = '.RefreshTokenExpiration';
const SCOPE_DELIMITER = '^';
const SCOPE_KEY = '.Scope';

export interface IAccessData {
  expires_in: number;
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

@Injectable({ providedIn: 'root' })
export class LoginService {
  private _refreshTokenExpirationMultiplier = 2;
  private config;
  private isLoaded: boolean;
  private _cachedUser: User;
  private userLoggedInSubject = new Subject<void>();

  public userLoggedIn$ = this.userLoggedInSubject.asObservable();

  constructor(private http: HttpClient, private configManager: ConfigManagerService, private injector: Injector) {}
  private appendUrlParts(part1: string, part2: string): string {
    const conditionedPart1 = `${part1}${part1.endsWith('/') ? '' : '/'}`;
    const conditionedPart2 = `${part2.startsWith('/') ? part2.substring(1) : part2}`;
    return `${conditionedPart1}${conditionedPart2}`;
  }
  private getConfigValue<T>(setting: string): T {
    if (!this.config) {
      this.config = {
        appName: this.configManager.getSetting(ConfigManagerProperties.AppName),
        apiUrl: this.configManager.getSetting(ConfigManagerProperties.ApiUrl),
        scopeOptions: this.configManager.getSetting(ConfigManagerProperties.ScopeOptions),
        appNames: this.configManager.getSetting(ConfigManagerProperties.AppNames),
        secretTokens: this.configManager.getSetting(ConfigManagerProperties.SecretTokens),
      };
      if (this.config.scopeOptions && !Array.isArray(this.config.scopeOptions)) {
        console.warn('LoginService: scopeOptions appears to be a string.  Converting to Array.  Consider re-defining this value');
        this.config.scopeOptions = [this.config.scopeOptions];
      }

      if (!this.config.appNames && this.configManager.getSetting(ConfigManagerProperties.AppName)) {
        console.warn('LoginService: appNames is missing.  Legacy appName used to construct array.  Consider defining appNames array.');
        this.config.appNames = [this.configManager.getSetting(ConfigManagerProperties.AppName)];
      }

      if (!this.config.secretTokens && this.configManager.getSetting(ConfigManagerProperties.SecretToken)) {
        console.warn('LoginService: secretTokens is missing.  Legacy secretToken used to construct array.  Consider defining secretTokens array.');
        this.config.secretTokens = [this.configManager.getSetting(ConfigManagerProperties.SecretToken)];
      }
    }

    return this.config[setting];
  }

  private getSecretToken(appName: string): string {
    const token = this.getConfigValue<Array<string>>(ConfigManagerProperties.SecretTokens)[this.getConfigValue<Array<string>>(ConfigManagerProperties.AppNames).indexOf(appName)];
    return token;
  }

  private getScope(appName: string): string[] {
    let scopes: string[];
    const scopeOptions = this.getConfigValue<Array<string>>(ConfigManagerProperties.ScopeOptions);
    const appNames = this.getConfigValue<Array<string>>(ConfigManagerProperties.AppNames);
    if (scopeOptions && scopeOptions.length === appNames.length) {
      const scopesForIndex: string = scopeOptions[appNames.indexOf(appName)];
      if (scopesForIndex && scopesForIndex.length > 0) {
        scopes = scopesForIndex.split(SCOPE_DELIMITER);
      }
    }
    return scopes;
  }

  /**
   * Get the logged in user detail
   * @param loggedInUserEndpoint End point i.e. 'shipment/1.0/appusers/logged-in-user'
   */
  public getLoggedInUser(loggedInUserEndpoint: string): Observable<User> {
    if (this._cachedUser) {
      return of(this._cachedUser);
    }
    return this.http.get(`${this.getUrl(loggedInUserEndpoint)}`).pipe(
      map(response => response['data'] as User),
      tap(user => (this._cachedUser = user))
    );
  }

  public getLoggedInUserScopes(): Observable<string[]> {
    if (this.isAuthorized()) {
      const scopes: string = <string>localStorage.getItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + SCOPE_KEY);
      return of(scopes ? scopes.replace(/"/g, '').split(' ') : undefined);
    } else {
      return of([]);
    }
  }

  /**
   * Get access token
   */
  public getAccessToken(): Observable<string> {
    if (this.isAuthorized()) {
      const token: string = <string>localStorage.getItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + ACCESS_KEY);
      return of(token);
    } else {
      return of('');
    }
  }

  /**
   * Handles refreshToken requests. Stores returned access token and refresh token in
   * local storage along with expiration time.
   *
   */
  public loadTokensFromRefresh(): Observable<any> {
    const body = 'grant_type=refresh_token&refresh_token=' + this.getRefreshToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: `Basic ${this.getSecretToken(this.getConfigValue<string>(ConfigManagerProperties.AppName))}`,
    });

    const options = { headers: headers };

    return this.http.post<IAccessData>(this.getUrl('token'), body, options).pipe(
      map(accessData => {
        this.saveAccessData(accessData);
      })
    );
  }

  /**
   * Handles secret token requests. Stores returned access token and refresh token in
   * local storage along with expiration time.
   *
   */
  public loadTokensFromUser(username: string, password: string, app?: string): Observable<any> {
    let encodedPassword = encodeURIComponent(password);
    let body = `grant_type=password&password=${encodedPassword}&username=${username}`;

    // loading username
    this.setAssociatedUserName(username);

    const applicationName = app ? app : this.getConfigValue<string>(ConfigManagerProperties.AppName);

    // check for scope entry
    const scopes: string[] = this.getScope(applicationName);
    if (scopes && scopes.length > 0) {
      body += '&scope=';
      scopes.forEach(aScope => (body += aScope + ' '));
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Authorization: `Basic ${this.getSecretToken(applicationName)}`,
    });

    const options = { headers: headers };

    return this.http.post<IAccessData>(this.getUrl('token'), body, options).pipe(
      tap(accessData => {
        this.saveAccessData(accessData, applicationName);
      })
    );
  }

  protected getUrl(uri) {
    let url = this.getConfigValue<string>(ConfigManagerProperties.ApiUrl);
    return this.appendUrlParts(url, uri);
  }

  public isAuthorized(): boolean {
    let authorize = false;
    if (Date.now() < this.getAccessTokenExpiration().getTime()) {
      authorize = true;
    } else {
      localStorage.removeItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + ACCESS_KEY);
    }

    return authorize;
  }

  public getAssociatedUserName(): { user: string; isAuthenticated: boolean } {
    const userInfo = { user: undefined, isAuthenticated: false };
    if (this.isAuthorized()) {
      userInfo.user = localStorage.getItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + USER_INFO_KEY);
      userInfo.isAuthenticated = true;
    }
    return userInfo;
  }

  public setAssociatedUserName(userName: string) {
    localStorage.setItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + USER_INFO_KEY, userName);
  }
  /**
   * Get refresh token
   */
  public getRefreshToken(): string {
    let token: string;
    if (Date.now() < this.getRefreshTokenExpiration().getTime()) {
      token = <string>localStorage.getItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + REFRESH_KEY);
    }
    return token;
  }

  /**
   * Set access token
   */
  public setAccessToken(token: string, app?: string) {
    const applicationName = app ? app : this.getConfigValue<string>(ConfigManagerProperties.AppName);
    localStorage.setItem(applicationName + ACCESS_KEY, token);
  }

  /**
   * Set refresh token
   */
  public setRefreshToken(token: string, app?: string) {
    const applicationName = app ? app : this.getConfigValue<string>(ConfigManagerProperties.AppName);
    localStorage.setItem(applicationName + REFRESH_KEY, token);
  }

  public setAccessTokenExpiration(dt: Date, app?: string) {
    const applicationName = app ? app : this.getConfigValue<string>(ConfigManagerProperties.AppName);
    localStorage.setItem(applicationName + ACCESS_TOKEN_EXPIRATION_KEY, JSON.stringify(dt));
  }

  /**
   * If access token expiration date exist in local storage then return it, else return current date.
   */
  public getAccessTokenExpiration() {
    try {
      const s = <string>localStorage.getItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + ACCESS_TOKEN_EXPIRATION_KEY);
      return new Date(Date.parse(JSON.parse(s)));
    } catch (err) {
      return new Date();
    }
  }

  public setRefreshTokenExpiration(dt: Date, app?: string) {
    const applicationName = app ? app : this.getConfigValue<string>(ConfigManagerProperties.AppName);
    localStorage.setItem(applicationName + REFRESH_TOKEN_EXPIRATION_KEY, JSON.stringify(dt));
  }

  private setScope(scope: string, app?: string) {
    const applicationName = app ? app : this.getConfigValue<string>(ConfigManagerProperties.AppName);
    localStorage.setItem(applicationName + SCOPE_KEY, JSON.stringify(scope));
  }

  public getRefreshTokenExpiration() {
    try {
      const s = <string>localStorage.getItem(this.getConfigValue<string>(ConfigManagerProperties.AppName) + REFRESH_TOKEN_EXPIRATION_KEY);
      return new Date(Date.parse(JSON.parse(s)));
    } catch (err) {
      return new Date();
    }
  }

  public saveAccessData(accessData: IAccessData, app?: string) {
    const accessTokenExpiration = new Date();
    const refreshTokenExpiration = new Date();
    const applicationName = app ? app : this.getConfigValue<string>(ConfigManagerProperties.AppName);
    accessTokenExpiration.setTime(accessTokenExpiration.getTime() + (accessData.expires_in - 240) * 1000);
    refreshTokenExpiration.setTime(refreshTokenExpiration.getTime() + accessData.expires_in * 1000 * this._refreshTokenExpirationMultiplier);

    this.setAccessToken(accessData.access_token, applicationName);
    this.setRefreshToken(accessData.refresh_token, applicationName);
    this.setAccessTokenExpiration(accessTokenExpiration, applicationName);
    this.setRefreshTokenExpiration(refreshTokenExpiration, applicationName);
    this.setScope(accessData.scope, applicationName);
  }

  public loginByApplication(userName: string, password: string): Observable<any> {
    const observablesTokenBatch = [];
    this.getConfigValue<Array<string>>(ConfigManagerProperties.AppNames).forEach(application => {
      observablesTokenBatch.push(this.loadTokensFromUser(userName, password, application));
    });
    return forkJoin(observablesTokenBatch);
  }

  /**
   * Remove tokens
   */
  public clear() {
    const appName = this.getConfigValue<string>(ConfigManagerProperties.AppName);
    localStorage.removeItem(`${appName}${ACCESS_KEY}`);
    localStorage.removeItem(`${appName}${REFRESH_KEY}`);
    localStorage.removeItem(`${appName}${ACCESS_TOKEN_EXPIRATION_KEY}`);
    localStorage.removeItem(`${appName}${REFRESH_TOKEN_EXPIRATION_KEY}`);
    localStorage.removeItem(`${appName}${USER_INFO_KEY}`);
    localStorage.removeItem(`${appName}${SCOPE_KEY}`);
  }
}
