import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[xpoPreventCharacters]',
})
export class XpoPreventCharactersDirective {
  constructor() {}
  @Input('xpoPreventCharacters') xpoPreventCharacters: string[];

  @HostListener('keydown', ['$event'])
  handleClickEvent(event) {
    return !this.xpoPreventCharacters.some(char => char === event.key);
  }
}
