import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoAllowCharactersDirective } from './xpo-allow-characters.directive';
import { XpoCurrencyInputMaskDirective } from './xpo-currency-input-mask.directive';
import { XpoErrorDirective } from './xpo-error.directive';
import { XpoErrorsDirective } from './xpo-errors.directive';
import { XpoMaxNumberLengthDirective } from './xpo-max-number-length.directive';
import { XpoSmartSelectDirective } from './xpo-smart-select.directive';
import { XpoUppercaseDirective } from './xpo-uppercase.directive';
import { XpoWarningDirective } from './xpo-warning.directive';
import { XpoWarningsDirective } from './xpo-warnings.directive';
import { XpoPreventCharactersDirective } from './xpo-prevent-characters.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    XpoAllowCharactersDirective,
    XpoUppercaseDirective,
    XpoErrorDirective,
    XpoErrorsDirective,
    XpoWarningDirective,
    XpoWarningsDirective,
    XpoMaxNumberLengthDirective,
    XpoSmartSelectDirective,
    XpoCurrencyInputMaskDirective,
    XpoPreventCharactersDirective,
  ],
  exports: [
    XpoAllowCharactersDirective,
    XpoUppercaseDirective,
    XpoErrorDirective,
    XpoErrorsDirective,
    XpoWarningDirective,
    XpoWarningsDirective,
    XpoMaxNumberLengthDirective,
    XpoSmartSelectDirective,
    XpoCurrencyInputMaskDirective,
    XpoPreventCharactersDirective,
  ],
})
export class XpoDirectivesModule {}
