import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class AppHeaderService {
  private listPayformsClickedSubject = new Subject<boolean>();
  public listPayformsClicked$ = this.listPayformsClickedSubject.asObservable();

  constructor() {}

  public listPayformsClicked() {
    this.listPayformsClickedSubject.next(true);
  }
}
