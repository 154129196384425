export enum AddEditActivityFormFields {
  ActivityType = 'activityType',
  SIC = 'sic',
  StartDate = 'startDate',
  StartTime = 'startTime',
  EndTime = 'endTime',
  Duration = 'duration',
  Tractor = 'tractor',
  SpansTwoDays = 'spansTwoDays',
  Trailer1 = 'trailer1',
  Trailer2 = 'trailer2',
  Trailer3 = 'trailer3',
  Dolly1 = 'dolly1',
  Dolly2 = 'dolly2',
  Comments = 'comments',
  Equipment = 'equipment',
}
