<div class="approve-reason-feedback">
  <h2 md-dialog-title>Approve This Payform?</h2>
  <mat-dialog-content class="approve-reason-feedback-content">
    <div class="approve-reason-feedback-content-input"
         fxLayout="row"
         fxLayoutAlign="start start">
      <mat-form-field fxFlex="">
        <textarea matInput
                  matTextareaAutosize
                  matAutosizeMinRows="5"
                  matAutosizeMaxRows="5"
                  maxlength="250"
                  placeholder="Please describe your reason for editing this payform."
                  [(ngModel)]="feedback"
                  required
                  #feedbackInput="ngModel"></textarea>
        <mat-hint align="end">{{feedbackInput?.value?.length}} / 250</mat-hint>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="approve-reason-feedback-actionbar">
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <button mat-button
              color="primary"
              (click)="cancelClicked();"
              tabindex="-1">Cancel</button>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="end center">
      <button mat-button
              color="primary"
              (click)="approveAndViewPayformsClicked()"
              tabindex="-1"
              [disabled]="!feedbackInput.valid">Approve & View Payforms</button>
      <button mat-button
              color="primary"
              (click)="approveAndNextPayformClicked()"
              tabindex="-1"
              [disabled]="!feedbackInput.valid">Approve & Next Payform</button>
    </div>
  </mat-dialog-actions>
</div>
