import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PayformFormFields } from '../../enums';
import { ErrorWarningService } from './services';
import { AppConstantsService } from '../../services';
import {
  totalMileageValidatorFunction,
  payformOriginDestValidatorFunction,
  activitySicValidatorFunction,
  activityWaitTimeValidatorFunction,
  activityDurationValidatorFunction,
} from '../../validators';
import { activityStartEndValidatorFunction } from '../../validators/activity-start-end-validator.directive';
import { multipleFuelingActivitiesValidatorFunction } from '../../validators/multiple-fueling-activities-validator.directive';
import { oneScheduleNoESLNoCushionValidatorFunction } from '../../validators/one-schedule-no-esl-no-cushion=validator.directive';

export class PayformFormBuilder {
  /**
   * Return a FormGroup that contains all of the controls that need to be updated from
   * current Payform state and/or validated.
   *
   * Add any Validators here so that they run even when the HTML controls are not rendered
   */
  public static create(formBuilder: UntypedFormBuilder, errorWarningService: ErrorWarningService, constantsService: AppConstantsService): UntypedFormGroup {
    const formGroup = formBuilder.group(
      {
        [PayformFormFields.LinehaulSchedule]: formBuilder.array([]),
      },
      {
        validator: [
          totalMileageValidatorFunction(constantsService.MaxMileage, errorWarningService),
          payformOriginDestValidatorFunction(errorWarningService),
          activitySicValidatorFunction(errorWarningService),
          activityWaitTimeValidatorFunction(errorWarningService),
          activityDurationValidatorFunction(constantsService, errorWarningService),
          activityStartEndValidatorFunction(errorWarningService),
          multipleFuelingActivitiesValidatorFunction(errorWarningService),
          oneScheduleNoESLNoCushionValidatorFunction(errorWarningService),
        ],
      }
    );
    return formGroup;
  }
}
