import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-delete-reason-feedback',
  templateUrl: './delete-reason-feedback.component.html',
  styleUrls: ['./delete-reason-feedback.component.scss'],
})
export class DeleteReasonFeedbackComponent implements OnInit {
  public feedback = '';

  constructor(private dialogRef: MatDialogRef<DeleteReasonFeedbackComponent>) {}

  ngOnInit() {}

  cancelClicked() {
    this.dialogRef.close();
  }

  deleteClicked() {
    this.dialogRef.close(this.feedback);
  }
}
