import { Component, HostBinding, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PayPortalStateStore } from '../../../app/state';
import { DisplayEmployeeCellRendererComponent } from './display-employee-cell-renderer';
import { DriverPayService } from '../../services/driver-pay/driver-pay.service';
import { PFConfig } from '@xpo-ltl/ngx-presentation-framework';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-payforms',
  templateUrl: './list-payforms.component.html',
  styleUrls: ['./list-payforms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListPayformsComponent implements OnDestroy {
  @HostBinding('class') hostClass = 'list-payforms';

  screenId = '';
  private unsubscriber = new Subject<void>();
  readonly config!: PFConfig;

  constructor(private configManagerService: ConfigManagerService, private route: ActivatedRoute, private stateStore: PayPortalStateStore, public driverPayService: DriverPayService) {
    this.config = {
      customActions: {},
      customComponents: {},
      cellRenderers: {
        displayEmployeeCellRendererComponent: DisplayEmployeeCellRendererComponent,
      },
      generalParameters$: this.stateStore.generalParameters$,
      screenApiBasepath: (this.configManagerService.getSetting('apiUrl') ?? this.configManagerService.getSetting('apiUri')) + '/opslnhpayform/1.0',
    };

    this.route.data.pipe(takeUntil(this.unsubscriber)).subscribe(v => {
      this.screenId = 'PAYFORM';
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
