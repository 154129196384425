import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { MoveActivityData, MoveActivityScheduleData } from '../../classes/move-activity-data';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-move-activity',
  templateUrl: './move-activity.component.html',
  styleUrls: ['./move-activity.component.scss'],
})
export class MoveActivityComponent implements OnInit, OnDestroy {
  public scheduleList: MoveActivityScheduleData[] = [];
  public selectedSchedule: MoveActivityScheduleData;
  @ViewChild('selectList', { static: true })
  selectList: MatSelectionList;

  private unsubscriber = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<MoveActivityComponent>, @Inject(MAT_DIALOG_DATA) public data: MoveActivityData) {
    this.scheduleList = data ? data.schedules : [];
  }

  ngOnInit() {
    this.selectList.selectionChange.pipe(takeUntil(this.unsubscriber)).subscribe((selChange: any) => {
      this.selectList.deselectAll();
      if (selChange.options && selChange.options.length) {
        selChange.options[0].selected = true;
      }
      this.selectedSchedule = this.selectList.selectedOptions.selected[0].value as MoveActivityScheduleData;
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  doneClicked() {
    this.dialogRef.close(this.selectedSchedule);
  }
}
