import { Input, OnDestroy, Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Unsubscriber } from 'app/utils/angular-utils/classes';
import { LoDash } from '../utils/angular-utils/lodash-utils';

export type FormConstructor = (formBuilder: UntypedFormBuilder, ...args: any[]) => UntypedFormGroup;

@Directive()
export abstract class FormStateDirective implements OnDestroy {
  @Input()
  parentForm: UntypedFormGroup;

  public form: UntypedFormGroup;
  protected unsubscriber = new Unsubscriber();

  constructor(protected formBuilder: UntypedFormBuilder) {}

  protected linkFormToParent(groupName: string, formConstructor: FormConstructor, ...args: any[]) {
    this.form = this.parentForm.get(groupName) as UntypedFormGroup;
    if (!this.form) {
      this.form = formConstructor(this.formBuilder, ...args);
      this.parentForm.setControl(groupName, this.form);

      // for debugging, set a name field on each control
      LoDash.set(this.form, 'name', groupName);
    }
    this.form.enable();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.form.disable();
  }
}
