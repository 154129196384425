import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActionPatchData, AppStateUtils, PayPortalStateStore } from '../../../../../../../app/state';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { LinehaulSchedule, ScheduleTrailer } from '@xpo-ltl/sdk-linehaulpayform';
import { BehaviorSubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormStateDirective, FormUtils, OperationType } from '../../../../../../classes';
import { OperationTypesComponent } from '../../../../../../dialogs';
import { EquipmentType, LinehaulScheduleFormFields, ScheduleTrailerFormFields, WarningValidationType } from '../../../../../../enums';
import { EquipmentErrorTypes } from '../../../../../../enums/equipment-error-types.enum';
import { EquipmentPipe } from '../../../../../../pipes';
import { EquipmentDetailsCacheService } from '../../../../../../services';
import { AsyncValidatorCompletionService } from '../../../../../../services/async-validator-completion.service';
import { ErrorWarningService } from '../../../../services/error-warning/error-warning.service';
import { EquipmentFormBuilder } from './equipment.form-builder';
import { XpoDialogManagerService } from '../../../../../../utils/angular-utils/dialogs/xpo-dialog-manager.service';
import { LoDash } from '../../../../../../utils/angular-utils/lodash-utils';

export class EquipmentErrorMatcher implements ErrorStateMatcher {
  constructor(private validatorFn: Function) {
    this.validatorFn = validatorFn;
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!((control && control.invalid && (control.dirty || control.touched)) || this.validatorFn(form, control));
  }
}
@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [XpoDialogManagerService],
})
export class EquipmentComponent extends FormStateDirective implements OnInit, AfterViewInit {
  @Input()
  schSequenceNbr: string;
  @Input()
  readonly = true;
  @Input()
  warningsCollection: any[];

  private trailersFormArray: UntypedFormArray;

  private scheduleSubject = new BehaviorSubject<LinehaulSchedule>(undefined);
  public schedule$ = this.scheduleSubject.asObservable();

  public get schedule() {
    return this.scheduleSubject.value;
  }

  public readonly ScheduleTrailerFormFields = ScheduleTrailerFormFields;
  public readonly LinehaulScheduleFormFields = LinehaulScheduleFormFields;
  public readonly EquipmentType = EquipmentType;
  public readonly ValidationRegexPatterns = ValidationRegexPatterns;
  public readonly WarningValidationType = WarningValidationType;
  public readonly Object = Object;
  public readonly FormUtils = FormUtils;
  public readonly AppStateUtils = AppStateUtils;
  public readonly EquipmentErrorTypes = EquipmentErrorTypes;

  dolly1ErrorMatcher = new EquipmentErrorMatcher(this.hasEquipmentDolly1Errors);
  dolly2ErrorMatcher = new EquipmentErrorMatcher(this.hasEquipmentDolly2Errors);
  trailerErrorMatcher = new EquipmentErrorMatcher(this.hasEquipmentTrailerErrors.bind(this));
  loadReleaseErrorMatcher = new EquipmentErrorMatcher(this.hasEquipmentLoadReleaseErrors);

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private dialog: XpoDialogManagerService,
    private changeDetector: ChangeDetectorRef,
    private equipmentDetailsCache: EquipmentDetailsCacheService,
    private equipmentPipe: EquipmentPipe,
    private errorWarningService: ErrorWarningService,
    private asyncValidatorCompleted: AsyncValidatorCompletionService,
    private state: PayPortalStateStore
  ) {
    super(formBuilder);
  }

  ngOnInit() {
    this.linkFormToParent(
      LinehaulScheduleFormFields.EquipmentControl,
      EquipmentFormBuilder.create,
      this.equipmentDetailsCache,
      this.errorWarningService,
      this.asyncValidatorCompleted,
      this.schSequenceNbr
    );

    this.trailersFormArray = this.form.get(LinehaulScheduleFormFields.ScheduleTrailer) as UntypedFormArray;

    // Listen for Schedule changes
    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        const newSchedule = AppStateUtils.getScheduleFromPayform(payform, this.schSequenceNbr);
        this.scheduleSubject.next(newSchedule);
        this.updateFromState();

        this.tractorControl().updateValueAndValidity({ emitEvent: false });
        this.form.updateValueAndValidity();
        const newTrailers = AppStateUtils.getScheduleFromPayform(payform, this.schSequenceNbr)?.scheduleTrailer;
        // update my local schedule copy with the new trailers
        this.schedule.scheduleTrailer = newTrailers;
        this.updateFormTrailersFromState();
      });
  }

  ngAfterViewInit(): void {
    const watchTrailerValueChanges = (displaySequenceNbr: number) => {
      const trailerGroup = this.trailerFormGroup(displaySequenceNbr);
      if (trailerGroup) {
        trailerGroup
          .get(ScheduleTrailerFormFields.TrlrHazardousInd)
          .valueChanges.pipe(takeUntil(this.unsubscriber.done))
          .subscribe(value => {
            if (value) {
              FormUtils.setValues(trailerGroup, {
                [ScheduleTrailerFormFields.TrlrEmtyInd]: false,
              });
            }
            this.updateTrailerToState(displaySequenceNbr);
          });

        trailerGroup
          .get(ScheduleTrailerFormFields.TrlrEmtyInd)
          .valueChanges.pipe(takeUntil(this.unsubscriber.done))
          .subscribe(value => {
            if (value) {
              FormUtils.setValues(trailerGroup, {
                [ScheduleTrailerFormFields.TrlrHazardousInd]: false,
                [ScheduleTrailerFormFields.LoadedRlseNbr]: '',
              });
            }
            this.updateTrailerToState(displaySequenceNbr);
          });
      }
    };

    watchTrailerValueChanges(1);
    watchTrailerValueChanges(2);
    watchTrailerValueChanges(3);
  }

  //// State Access functions

  private updateFormWithTrailer(formIndex: number, trailer: ScheduleTrailer) {
    const trailerGroup = this.trailersFormArray.at(formIndex) as UntypedFormGroup;

    const trlrNbr = this.equipmentPipe.transform(LoDash.get(trailer, 'trlrNbr', ''));
    const loadedRlseNbr = LoDash.get(trailer, 'loadedRlseNbr', '');
    const trlrHazardousInd = LoDash.get(trailer, 'trlrHazardousInd', false);
    const trlrEmtyInd = LoDash.get(trailer, 'trlrEmtyInd', false);

    FormUtils.setValues(trailerGroup, {
      [ScheduleTrailerFormFields.TrlrNbr]: trlrNbr,
      [ScheduleTrailerFormFields.LoadedRlseNbr]: loadedRlseNbr,
      [ScheduleTrailerFormFields.TrlrHazardousInd]: trlrHazardousInd,
      [ScheduleTrailerFormFields.TrlrEmtyInd]: trlrEmtyInd,
    });

    const enableControls = !LoDash.isEmpty(trlrNbr);
    FormUtils.setEnabled(trailerGroup, ScheduleTrailerFormFields.LoadedRlseNbr, enableControls && !trlrEmtyInd);
    FormUtils.setEnabled(trailerGroup, ScheduleTrailerFormFields.TrlrHazardousInd, enableControls);
    FormUtils.setEnabled(trailerGroup, ScheduleTrailerFormFields.TrlrEmtyInd, enableControls);

    trailerGroup.markAsTouched();
  }

  protected updateFormTrailersFromState() {
    [1, 2, 3].forEach(displayIndex => {
      const trailer = AppStateUtils.trailerFromSchedule(this.schedule, displayIndex);
      this.updateFormWithTrailer(displayIndex - 1, trailer);
    });
  }

  protected updateFromState() {
    FormUtils.setValues(this.form, {
      [LinehaulScheduleFormFields.TractorNbr]: this.equipmentPipe.transform(this.schedule?.tractorNbr),
      [LinehaulScheduleFormFields.DollyNbr1]: this.equipmentPipe.transform(AppStateUtils.dollyFromSchedule(this.schedule, 1)),
      [LinehaulScheduleFormFields.DollyNbr2]: this.equipmentPipe.transform(AppStateUtils.dollyFromSchedule(this.schedule, 2)),
      [LinehaulScheduleFormFields.TypeOfOperation]: +this.schedule?.typeOfOperation,
    });
    this.changeDetector.markForCheck();
    this.form.updateValueAndValidity();
  }

  //// Form access functions
  public tractorControl(): AbstractControl {
    return this.form.get(LinehaulScheduleFormFields.TractorNbr);
  }

  public trailerFormControl(displaySequenceNbr, controlName: string): AbstractControl {
    return this.trailersFormArray.at(displaySequenceNbr - 1).get(controlName);
  }

  public trailerFormGroup(displaySequenceNbr): UntypedFormGroup {
    return this.trailersFormArray.at(displaySequenceNbr - 1) as UntypedFormGroup;
  }

  public dollyControl(dollyId: number): AbstractControl {
    switch (dollyId) {
      case 1:
        return this.form.get(LinehaulScheduleFormFields.DollyNbr1);
      case 2:
        return this.form.get(LinehaulScheduleFormFields.DollyNbr2);
      default:
        return undefined;
    }
  }

  private updateToState() {
    let opType = this.form.get(LinehaulScheduleFormFields.TypeOfOperation).value;
    if (isNaN(opType)) {
      opType = undefined;
    } else {
      opType = LoDash.padStart(opType + '', 2, '0');
    }

    const patchData: ActionPatchData = {
      tractorNbr: this.equipmentPipe.transform(this.tractorControl().value),
      typeOfOperation: opType,
      dollyNbr1: this.equipmentPipe.transform(this.dollyControl(1).value),
      dollyNbr2: this.equipmentPipe.transform(this.dollyControl(2).value),
    };
    if (AppStateUtils.doesPatchChangeState(this.schedule, patchData)) {
      this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData });
    }
  }

  private updateTrailerToState(displaySequenceNbr: number) {
    const trailerPatch = this.trailerPatchFromForm(displaySequenceNbr);
    if (LoDash.isEmpty(trailerPatch.trlrNbr)) {
      // removing trailer, so delete it
      this.state.deleteTrailerAction(this.schedule.scheduleTrailer, { schSequenceNbr: this.schSequenceNbr, displaySequenceNbr });
    } else {
      // update the existing trailer with new number
      this.state.updateTrailerAction(this.schedule.scheduleTrailer, { schSequenceNbr: this.schSequenceNbr, trailer: trailerPatch });
    }
    this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData: {} });
  }

  /**
   * Return data from the Form for specified Trailer. Subset of ScheduleTrailer
   * @param displaySequenceNbr (1,2, or 3)
   */
  private trailerPatchFromForm(displaySequenceNbr: number): ActionPatchData {
    const patch = {
      trlrNbr: this.equipmentPipe.transform(this.trailerFormControl(displaySequenceNbr, ScheduleTrailerFormFields.TrlrNbr).value),
      loadedRlseNbr: this.trailerFormControl(displaySequenceNbr, ScheduleTrailerFormFields.LoadedRlseNbr).value,
      trlrEmtyInd: this.trailerFormControl(displaySequenceNbr, ScheduleTrailerFormFields.TrlrEmtyInd).value,
      trlrHazardousInd: this.trailerFormControl(displaySequenceNbr, ScheduleTrailerFormFields.TrlrHazardousInd).value,
      dsrPayformId: this.schedule.dsrPayformId,
      schSequenceNbr: this.schedule.schSequenceNbr,
      displaySequenceNbr,
    };
    return patch;
  }

  // Misc Methods

  public get numberOfTrailers() {
    const count = (this.schedule.scheduleTrailer ?? []).reduce((acc, trailer) => acc + +(trailer.listActionCd !== ActionCd.DELETE), 0);
    return count;
  }

  public hasEmpty(): boolean {
    const foundEmpty = (LoDash.get(this.schedule, 'scheduleTrailer') ?? []).find(trailer => trailer.listActionCd !== ActionCd.DELETE && trailer.trlrEmtyInd);
    return !!foundEmpty;
  }

  public hasHazmat(): boolean {
    const foundHazmat = (LoDash.get(this.schedule, 'scheduleTrailer') ?? []).find(trailer => trailer.listActionCd !== ActionCd.DELETE && trailer.trlrHazardousInd);
    return !!foundHazmat;
  }

  public trlrNbrChange(displayIndex: number) {
    const newValue = LoDash.get(this.trailerFormControl(displayIndex, ScheduleTrailerFormFields.TrlrNbr), 'value');
    const curValue = this.equipmentPipe.transform(LoDash.get(AppStateUtils.trailerFromSchedule(this.schedule, displayIndex), 'trlrNbr', ''));
    if (curValue !== newValue) {
      // the trailer number has changed, so we need to update our state
      if (LoDash.isEmpty(newValue) || LoDash.isEmpty(curValue)) {
        // number of trailers has changed, so we have to force update of type of operation
        FormUtils.setValues(this.form, {
          [LinehaulScheduleFormFields.TypeOfOperation]: undefined,
        });
        this.updateToState();
      }

      this.updateTrailerToState(displayIndex);
    }
  }

  public trlrNbrUpdate(displayIndex: number) {
    const trailerGroup = this.trailerFormGroup(displayIndex);
    const newValue = LoDash.get(trailerGroup.get(ScheduleTrailerFormFields.TrlrNbr), 'value');
    FormUtils.setEnabled(trailerGroup, ScheduleTrailerFormFields.LoadedRlseNbr, !LoDash.isEmpty(newValue));
  }

  public loadedRlseNbrChange(displayIndex: number) {
    const newValue = this.trailerFormControl(displayIndex, ScheduleTrailerFormFields.LoadedRlseNbr).value;
    const curValue = LoDash.get(AppStateUtils.trailerFromSchedule(this.schedule, displayIndex), 'loadedRlseNbr', '');
    if (curValue !== newValue) {
      if (!LoDash.isEmpty(newValue)) {
        // if there is a load release number, then the trailer can't be empty
        const trlrEmptyControl = this.trailerFormControl(displayIndex, ScheduleTrailerFormFields.TrlrEmtyInd);
        FormUtils.setValue(trlrEmptyControl, false);
      }

      const trailerPatch = this.trailerPatchFromForm(displayIndex);
      this.state.updateTrailerAction(this.schedule.scheduleTrailer, { schSequenceNbr: this.schSequenceNbr, trailer: trailerPatch });
      this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData: {} });
    }
  }

  public dollyChange(displayIndex: number) {
    const newValue = this.dollyControl(displayIndex).value;
    const curValue = this.equipmentPipe.transform(AppStateUtils.dollyFromSchedule(this.schedule, displayIndex));
    if (curValue !== newValue) {
      this.updateToState();
    }
  }

  public tractorChange() {
    const newValue = this.tractorControl().value;
    const curValue = this.equipmentPipe.transform(this.schedule.tractorNbr);
    if (curValue !== newValue) {
      this.updateToState();
    }
  }

  public handleEditTypeOfOperationClicked() {
    this.dialog
      .open(OperationTypesComponent, {
        minWidth: 750,
        data: {
          origin: this.schedule.originSicCd || '',
          destination: AppStateUtils.getDestination(this.schedule) || '',
          trailerCount: this.numberOfTrailers,
          current: this.form.get(LinehaulScheduleFormFields.TypeOfOperation).value,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((value: OperationType) => {
        if (!!value) {
          this.form.get(LinehaulScheduleFormFields.TypeOfOperation).setValue(value.optype);
          this.updateToState();
          this.changeDetector.markForCheck();
        }
      });
  }

  public checkTrailerHazmatWarning(warningType, trailerNum): boolean {
    const schFormGroup = this.parentForm.parent as UntypedFormGroup;
    return (
      this.trailerFormControl(trailerNum, ScheduleTrailerFormFields.TrlrNbr).value &&
      !this.trailerFormControl(trailerNum, ScheduleTrailerFormFields.TrlrHazardousInd).value &&
      FormUtils.hasWarning(schFormGroup, LinehaulScheduleFormFields.Details, warningType)
    );
  }

  public hasDuplicateTrailerError(control: AbstractControl) {
    return (
      FormUtils.hasError(this.parentForm, LinehaulScheduleFormFields.EquipmentControl, EquipmentErrorTypes.DuplicateTrailer) &&
      (this.form.errors[EquipmentErrorTypes.DuplicateTrailer].subject ?? []).some(val => val === control.value)
    );
  }

  // ERROR MATCHER FUNCTIONS

  private hasEquipmentLoadReleaseErrors(form: FormGroupDirective | NgForm, control: AbstractControl) {
    const loadReleaseErrors = [EquipmentErrorTypes.InvalidLoadReleaseFormat, EquipmentErrorTypes.LoadReleaseRequired];

    const trailerCtrl = control.parent;
    return !!(trailerCtrl.errors ?? []).some(error => loadReleaseErrors.indexOf(error.type) > -1);
  }

  private hasEquipmentTrailerErrors(form: FormGroupDirective | NgForm, control: AbstractControl) {
    const equipmentErrors = [EquipmentErrorTypes.DuplicateTrailer, EquipmentErrorTypes.OneTractorTwoDollysNoTrailer];

    const equipmentControl = form.form.get(LinehaulScheduleFormFields.Details).get(LinehaulScheduleFormFields.EquipmentControl);

    let errorsCollection = LoDash.pickBy(equipmentControl.errors);

    errorsCollection = this.filterDuplicateTrailerError(errorsCollection, control);

    if (Object.keys(errorsCollection).includes(EquipmentErrorTypes.DuplicateTrailer)) {
      return control.value && !LoDash.isEmpty(errorsCollection);
    } else {
      return !LoDash.isEmpty(errorsCollection);
    }
  }

  private filterDuplicateTrailerError(errorsCollection, control: AbstractControl) {
    if (Object.keys(errorsCollection).includes(EquipmentErrorTypes.DuplicateTrailer) && errorsCollection[EquipmentErrorTypes.DuplicateTrailer].value !== control.value) {
      return LoDash.pickBy(errorsCollection.errors);
    } else {
      return errorsCollection;
    }
  }

  private hasEquipmentDolly1Errors(form: FormGroupDirective | NgForm, control: AbstractControl) {
    const equipmentErrors = [EquipmentErrorTypes.OneDollyRequiredWithTwoTrailers, EquipmentErrorTypes.TwoDollysRequiredWithThreeTrailers, EquipmentErrorTypes.DuplicateDolly];
    const equipmentControl = form.form.get(LinehaulScheduleFormFields.Details).get(LinehaulScheduleFormFields.EquipmentControl);
    const errorsCollection = LoDash.pickBy(equipmentControl.errors);

    return !control.value && !LoDash.isEmpty(errorsCollection);
  }

  private hasEquipmentDolly2Errors(form: FormGroupDirective | NgForm, control: AbstractControl) {
    const equipmentErrors = [EquipmentErrorTypes.DuplicateDolly, EquipmentErrorTypes.TwoDollysRequiredWithThreeTrailers];

    const equipmentControl = form.form.get(LinehaulScheduleFormFields.Details).get(LinehaulScheduleFormFields.EquipmentControl);

    const errorsCollection = LoDash.pickBy(equipmentControl.errors);

    return !control.value && !LoDash.isEmpty(errorsCollection);
  }
}
