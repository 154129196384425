import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PayformStatusCd } from '@xpo-ltl/sdk-common';
import { DsrPayform } from '@xpo-ltl/sdk-linehaulpayform';
import { BehaviorSubject } from 'rxjs';
import { ErrorWarning } from '../../../../classes/error-warning';
import { ErrorWarningMessage } from '../../../../classes/error-warning-message';
import { ErrorWarningTypes } from '../../../../enums';
import { ErrorMessage } from '../../../../enums/error-message.enum';
import { WarningMessage } from '../../../../enums/warning-message.enum';
import { PayPortalStateStore } from 'app';
import { LoDash } from '../../../../utils/angular-utils/lodash-utils';

@Injectable()
export class ErrorWarningService {
  private PayformWarningSubject = new BehaviorSubject(undefined);
  public PayformWarning$ = this.PayformWarningSubject.asObservable();

  private DetailWarningSubject = new BehaviorSubject(undefined);
  public DetailWarning$ = this.DetailWarningSubject.asObservable();

  private ActivityWarningSubject = new BehaviorSubject(undefined);
  public ActivityWarning$ = this.ActivityWarningSubject.asObservable();

  constructor(private state: PayPortalStateStore) {}

  public payformWarningsChanged() {
    this.PayformWarningSubject.next(true);
  }
  public detailWarningsChanged(schSequenceNbr: string) {
    this.DetailWarningSubject.next(schSequenceNbr);
  }
  public activityWarningsChanged(schSequenceNbr: string) {
    this.ActivityWarningSubject.next(schSequenceNbr);
  }

  public collectWarnings(control: AbstractControl, searchRecursively: boolean = true): ErrorWarningMessage[] {
    let warningsObj = [];

    const findControlWarnings = formControl => {
      if (formControl && formControl.enabled && formControl['warnings'] && Object.keys(formControl['warnings']).length) {
        warningsObj = [...warningsObj, ...LoDash.values(formControl['warnings'])];
      }
      if (searchRecursively) {
        if (formControl.controls) {
          Object.keys(formControl?.controls).forEach(key => {
            findControlWarnings(formControl?.controls[key]);
          });
        }
      }
    };

    findControlWarnings(control);

    return this.combineMessages(warningsObj, ErrorWarningTypes.Warning);
  }

  public collectErrors(control: AbstractControl, searchRecursively: boolean = true): any[] {
    const errorsObj = [];

    const findControlErrors = formControl => {
      if (formControl.errors && formControl.errors.length) {
        const displayErrors = formControl.errors.filter(error => !!error.displayError);
        errorsObj.push(...displayErrors);
      }
      if (searchRecursively) {
        Object.keys(LoDash.get(formControl, 'controls', [])).forEach(c => findControlErrors(c));
      }
    };

    findControlErrors(control);

    return this.combineMessages(errorsObj, ErrorWarningTypes.Error);
  }

  private combineMessages(errorWarnings: ErrorWarning[], errorWarningType: string) {
    if (!errorWarnings.length) {
      return errorWarnings;
    }

    const returnErrorWarnings = [];
    const dupErrorWarnings = [];
    const errorWarningKeyMap = this.mapErrorWarningKeys(errorWarnings);

    (errorWarnings ?? []).forEach(errorWarning => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      errorWarningKeyMap[errorWarning.type] === 1 ? returnErrorWarnings.push(this.constructErrorWarningMessage(errorWarning, true, errorWarningType)) : dupErrorWarnings.push(errorWarning);
    });

    const errorWarningsKeys = Object.keys(errorWarningKeyMap);
    if (errorWarningsKeys.every(key => errorWarningsKeys[key] === 1)) {
      return returnErrorWarnings;
    }

    const consolodatedDupErrorWarnings = {};

    (dupErrorWarnings ?? []).forEach(dupErrorWarning => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      consolodatedDupErrorWarnings[dupErrorWarning.type]
        ? consolodatedDupErrorWarnings[dupErrorWarning.type].dupErrorWarnings.push(dupErrorWarning)
        : (consolodatedDupErrorWarnings[dupErrorWarning.type] = {
            type: dupErrorWarning.type,
            primarySubjects: [],
            secondarySubjects: [],
            dupErrorWarnings: [dupErrorWarning],
          });
    });

    const warnings = Object.keys(consolodatedDupErrorWarnings);
    warnings.forEach(key => {
      (consolodatedDupErrorWarnings[key].dupErrorWarnings ?? []).forEach(dupErrorWarning => {
        consolodatedDupErrorWarnings[key].primarySubjects.push(dupErrorWarning.primarySubject);
        consolodatedDupErrorWarnings[key].secondarySubjects.push(dupErrorWarning.secondarySubject);
      });

      const type = consolodatedDupErrorWarnings[key].type;
      const primarySubject = this.buildListSentenceString(consolodatedDupErrorWarnings[key].primarySubjects);
      let secondarySubject;
      if ((consolodatedDupErrorWarnings[key].secondarySubjects ?? []).some(subject => subject === 'totalCount')) {
        secondarySubject = consolodatedDupErrorWarnings[key].primarySubjects.length;
      } else {
        secondarySubject = this.buildListSentenceString(consolodatedDupErrorWarnings[key].secondarySubjects);
      }
      const combinedErrorWarningObj = { type, primarySubject, secondarySubject };
      const combinedErrorWarning = this.constructErrorWarningMessage(combinedErrorWarningObj, false, errorWarningType);

      returnErrorWarnings.push(combinedErrorWarning);
    });

    return returnErrorWarnings;
  }

  private buildListSentenceString(list): string {
    return `${list.slice(0, list.length - 1).join(', ')} and ${list.slice(-1)}`;
  }

  private mapErrorWarningKeys(errorWarnings): object {
    const keyMap = {};

    (errorWarnings ?? []).forEach(errorWarning => {
      keyMap[errorWarning.type] = keyMap[errorWarning.type] ? keyMap[errorWarning.type] + 1 : 1;
    });

    return keyMap;
  }

  private constructErrorWarningMessage({ type, primarySubject, secondarySubject }, isUnique: boolean = true, errorWarningType: string): ErrorWarningMessage {
    const typeCaps = type[0]?.toUpperCase() + type?.slice(1);
    let baseMessage;

    if (errorWarningType === ErrorWarningTypes.Error) {
      baseMessage = isUnique ? ErrorMessage[typeCaps] : ErrorMessage[`${typeCaps}s`];
    } else if (errorWarningType === ErrorWarningTypes.Warning) {
      baseMessage = isUnique ? WarningMessage[typeCaps] : WarningMessage[`${typeCaps}s`];
    }

    const message = baseMessage.replace('{0}', primarySubject).replace('{1}', secondarySubject);
    const errorWarningMessage = new ErrorWarningMessage(type, message);

    return errorWarningMessage;
  }

  public shouldDisplayWarnings(): boolean {
    let currentPayform: DsrPayform;
    this.state.getCurrentPayformState().subscribe(payform => {
      currentPayform = payform;
    });
    return (
      currentPayform?.statusCd === PayformStatusCd.APPROVED ||
      currentPayform?.statusCd === PayformStatusCd.RETURNED ||
      currentPayform?.statusCd === PayformStatusCd.NEW ||
      currentPayform?.statusCd === PayformStatusCd.SUBMITTED ||
      currentPayform?.statusCd === PayformStatusCd.TRANSMITTED
    );
  }

  public hasWarning(warningsCollection: any[], warning: string): boolean {
    return !!(warningsCollection ?? []).find(warningObj => warningObj.type === warning);
  }
}
