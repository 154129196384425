import { Injectable } from '@angular/core';
import { ListRoutingInstructionsByScheduleLegRqst, ReferenceApiService, RoutingInstructions } from '@xpo-ltl/sdk-reference';
import { BaseCacheService } from '../utils/angular-utils/services';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

interface RoutingInstructionsCacheParams {
  origSicCd: string;
  destSicCd: string;
  drivingLegId?: string;
}

@Injectable()
export class RoutingInstructionsCacheService extends BaseCacheService<RoutingInstructionsCacheParams, RoutingInstructions[]> {
  constructor(private referenceService: ReferenceApiService) {
    super();
  }

  public request(params: RoutingInstructionsCacheParams): Observable<RoutingInstructions[]> {
    return super.request(params).pipe(map(routes => (params.drivingLegId ? (routes ?? []).filter((route: RoutingInstructions) => route.drivingLegId === params.drivingLegId) : routes)));
  }

  protected getKeyFromParams(params: RoutingInstructionsCacheParams): string {
    return params.destSicCd ? `${(params.origSicCd ?? '').toUpperCase()},${(params.destSicCd ?? '').toUpperCase()}` : `${(params.origSicCd ?? '').toUpperCase()}`;
  }

  protected requestData(params: RoutingInstructionsCacheParams): Observable<RoutingInstructions[]> {
    const request = new ListRoutingInstructionsByScheduleLegRqst();
    request.origSicCd = (params.origSicCd ?? '').toUpperCase();
    request.destSicCd = (params.destSicCd ?? '').toUpperCase();
    return this.referenceService.listRoutingInstructionsByScheduleLeg(request, { toastOnError: false }).pipe(pluck('routingInstructions'));
  }
}
