import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PayformNoteTypeCd } from '@xpo-ltl/sdk-common';
import { DsrPayform, Note } from '@xpo-ltl/sdk-linehaulpayform';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormStateDirective } from '../../../../classes/form-state.component';
import { PayformCommentsFormFields } from '../../../../enums/payform-comments-form-fields.enum';
import { PayformFormFields } from '../../../../enums/payform-form-fields.enum';
import { PayFormService } from '../../services/pay-form.service';
import { PayformCommentsFormBuilder } from './payform-comments.form-builder';
import { PayPortalStateStore } from '../../../../../app/state';
import { LoDash } from '../../../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-payform-comments',
  templateUrl: './payform-comments.component.html',
  styleUrls: ['./payform-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayformCommentsComponent extends FormStateDirective implements OnInit, OnDestroy {
  readonly$: Observable<boolean>;
  notes$: Observable<Note[]>;

  @Input()
  expandCollapse: boolean;

  @ViewChild('commentsExpansionPanel')
  commentsExpansionPanel;
  @ViewChild('commentText')
  commentText: ElementRef;

  private editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private notesArraySubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>(undefined);

  public currentPayform: DsrPayform;
  public payformSicCd: string;

  public notesArray$ = this.notesArraySubject.asObservable();
  public editMode$ = this.editModeSubject.asObservable();
  public commentInput: string;
  public titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('Payform Comments');
  public title$ = this.titleSubject.asObservable();

  public readonly PayformFormFields = PayformFormFields;
  public readonly PayformCommentsFormFields = PayformCommentsFormFields;

  constructor(protected formBuilder: UntypedFormBuilder, private payformService: PayFormService, private renderer: Renderer2, private state: PayPortalStateStore) {
    super(formBuilder);
  }

  ngOnInit() {
    // Create the FormGroup
    this.linkFormToParent(PayformCommentsFormFields.Comments, PayformCommentsFormBuilder.create);
    this.readonly$ = this.state.getPayformReadOnlySelector();
    this.state.getCurrentPayformState().subscribe(payform => {
      this.currentPayform = payform;
      this.payformSicCd = payform.dmclSic;

      const newNotes = payform.note;
      this.notesArraySubject.next((newNotes ?? []).filter((note: Note) => note.noteType === PayformNoteTypeCd.PAYFORM));
      this.updateTitle();
      this.expandCollapse = LoDash.get(this.notesArraySubject, 'value.length', []) > 0;
    });
  }

  handleAddComment() {
    this.editModeSubject.next(false);

    const note = this.payformService.createNote(PayformNoteTypeCd.PAYFORM, null, this.commentInput);
    this.notesArraySubject.next([note]);
    this.state.setPayformNote(note);
    this.commentInput = '';
  }

  handleHeaderAddAction($event) {
    $event.stopPropagation();
    this.editModeSubject.next(true);
    this.expandPanel();
    setTimeout(() => {
      if (this.commentText) {
        this.renderer.selectRootElement(this.commentText.nativeElement).focus();
      }
    });
  }

  handleHeaderCancelAction($event) {
    $event.stopPropagation();
    this.editModeSubject.next(false);
  }

  private updateTitle() {
    let title = 'Payform Comments';
    const numPayformComments = LoDash.get(this.notesArraySubject, 'value.length', []);
    if (numPayformComments > 0) {
      title = `${title} (${numPayformComments})`;
    }
    this.titleSubject.next(title);
  }

  isDsrComment(note: Note): boolean {
    const getId = id => (id ? id.split('|')[0] : id);

    let isDsr = false;
    if (note && note?.auditInfo) {
      const employeeId = this.currentPayform.dsrEmployeeId;
      if (employeeId === getId(note?.auditInfo.createdById)) {
        isDsr = true;
      }
    }
    return isDsr;
  }

  expandPanel() {
    this.commentsExpansionPanel.open();
  }

  collapsePanel() {
    this.commentsExpansionPanel.close();
  }
}
