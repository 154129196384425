import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ActionCd, PayformStatusCd } from '@xpo-ltl/sdk-common';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { DsrPayform, GetNextSubmittedPayformBySicQuery, GetNextSubmittedPayformBySicRqst, LinehaulPayformApiService, LinehaulSchedule } from '@xpo-ltl/sdk-linehaulpayform';
import { PayPortalStateStore, PayformState } from '../../../app/state';
import { ErrorWarningMessage } from '../../classes/error-warning-message';
import { BehaviorSubject, merge } from 'rxjs';
import { Unsubscriber } from '../../utils/angular-utils/classes';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { FormUtils } from '../../classes';
import { ApproveDialogResults, ConfigManagerProperties, EmptyPayformSaveTypes, FooterLabels, LinehaulScheduleFormFields, PayformFormFields, RouterUriComponents, SnackbarMessage } from '../../enums';
import { AppConstantsService, AppFooterService, DriverPayService, ServiceCenterCacheService } from '../../services';
import { AppHeaderService } from '../../services/app-header/app-header.service';
import { PayformFormBuilder } from './payform.form-builder';
import { ErrorWarningService, PayFormService, SearchPayformsService } from './services';
import { LoDash } from '../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-payform',
  templateUrl: './payform.component.html',
  styleUrls: ['./payform.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PayFormService, AppFooterService, SearchPayformsService],
})
export class PayformComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'payform';

  public payformState: PayformState;

  private currentSchedulesSubject = new BehaviorSubject<string[]>(undefined);
  public currentSchedules$ = this.currentSchedulesSubject.asObservable();

  private showNonScheduleActivitiesSubject = new BehaviorSubject<boolean>(false);
  public showNonScheduleActivities$ = this.showNonScheduleActivitiesSubject.asObservable();

  private warningsCollectionSubject = new BehaviorSubject<Array<ErrorWarningMessage>>([]);
  public warningsCollection$ = this.warningsCollectionSubject.asObservable();

  private unsubscriber = new Unsubscriber();
  public form: UntypedFormGroup;
  private schedulesFormArray: UntypedFormArray;

  public isExpanded = true;
  private dataChangedSubject = new BehaviorSubject<boolean>(false);

  public readonly PayformFormFields = PayformFormFields;
  public readonly LinehaulScheduleFormFields = LinehaulScheduleFormFields;

  private debugEnabled = false;
  private isReadOnly = true;
  private routePayform: DsrPayform;
  private currentPayform: DsrPayform;

  constructor(
    private activatedRoute: ActivatedRoute,
    private driverPayService: DriverPayService,
    private footerService: AppFooterService,
    private formBuilder: UntypedFormBuilder,
    private linehaulService: LinehaulPayformApiService,
    private config: ConfigManagerService,
    private constants: AppConstantsService,
    private payformService: PayFormService,
    public errorWarningService: ErrorWarningService,
    private serviceCenterCacheService: ServiceCenterCacheService,
    private router: Router,
    private headerService: AppHeaderService,
    private stateStore: PayPortalStateStore,
    private xpoSnackBar: XpoSnackBar,
    private searchPayforms: SearchPayformsService
  ) {
    this.debugEnabled = this.config.getSetting<string>(ConfigManagerProperties.buildVersion) === 'local-version';
  }

  ngOnInit() {
    this.form = PayformFormBuilder.create(this.formBuilder, this.errorWarningService, this.constants);
    this.driverPayService.currentPayformGroup = this.form;

    this.schedulesFormArray = this.form.get(PayformFormFields.LinehaulSchedule) as UntypedFormArray;

    if (this.activatedRoute) {
      this.routePayform = this.activatedRoute.snapshot?.data?.dsrPayform as DsrPayform;
      if (this.routePayform) {
        if (this.routePayform && this.routePayform.dsrEmployeeId) {
          // we got a payform through routing
          this.stateStore.setCurrentPayformAction(this.routePayform);
          this.stateStore.setPayformStateAction({ readOnly: true, current: this.routePayform, pristine: this.routePayform });
          this.isReadOnly = this.routePayform.dsrPayformId >= 0;
          this.stateStore.setPayformReadOnlyAction(this.isReadOnly);
          this.payformService.setFooter(this.isReadOnly);
        } else {
          this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
        }
      } else {
        this.stateStore
          .getPayformState()
          .pipe(take(1))
          .subscribe((state: PayformState) => {
            if (state) {
              this.payformState = state;
              const routePayform = state.current;
              this.routePayform = routePayform;
              if (routePayform && routePayform.dsrEmployeeId) {
                this.stateStore.setCurrentPayformAction(routePayform);

                this.isReadOnly = state.current.dsrPayformId >= 0;
                this.stateStore.setPayformReadOnlyAction(this.isReadOnly);

                this.payformService.setFooter(this.isReadOnly);
              } else {
                this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
              }
            } else {
              this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
            }
          });
      }
    }

    this.watchStoreChanges();

    this.watchHeaderService();
    this.watchFooterService();

    this.watchErrorsWarnings();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private watchStoreChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe(() => {
      this.payformService.setDataChanged(this.driverPayService.isPristinePayform());
    });

    this.form.statusChanges.pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged()).subscribe(() => {
      setTimeout(() => {
        this.payformService.setInvalid(this.form.invalid || this.driverPayService.isEmptyPayform(this.routePayform));
        this.payformService.setDataChanged(this.driverPayService.isPristinePayform());
      }, 100);
    });

    this.stateStore
      .getPayformReadOnlySelector()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(readonly => {
        this.payformService.setFooter(readonly);
        this.isReadOnly = readonly;
      });

    this.stateStore
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        this.currentPayform = payform;

        const hasSchedules = (this.currentPayform.linehaulSchedule ?? []).some(sch => sch.listActionCd !== ActionCd.DELETE);
        const hasNonScheduleActivities = (this.currentPayform.dsrActivity ?? []).some(act => !act.schSequenceNbr);
        this.showNonScheduleActivitiesSubject.next(!hasSchedules || !!hasNonScheduleActivities);

        if (this.currentPayform) {
          if (this.currentPayform[PayformFormFields.DmclSic]) {
            this.form[PayformFormFields.DmclSic] = this.currentPayform[PayformFormFields.DmclSic];
            this.requestDmsclStateCd();
          }
        } else {
          if (payform[PayformFormFields.DmclSic]) {
            this.form[PayformFormFields.DmclSic] = payform[PayformFormFields.DmclSic];
            this.requestDmsclStateCd();
          }
        }

        // need to rebuild footer to reflect new Payform
        this.payformService.setFooter(this.isReadOnly);

        this.payformService.setInvalid(this.form.invalid || this.driverPayService.isEmptyPayform(this.currentPayform));

        //GET Schedules

        const activeSchedules = (this.currentPayform.linehaulSchedule ?? []).filter(sch => sch.listActionCd !== ActionCd.DELETE) as LinehaulSchedule[];
        const activeScheduleNumbers = activeSchedules.map(s => s.schSequenceNbr) as string[];

        const arrays = new Array(this.currentSchedulesSubject.value ?? [], activeScheduleNumbers ?? []);
        const removedSchedules = arrays.reduce((a, b) => a.filter(c => !b.includes(c)));

        // remove old schedules from array
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let ii = 0; ii < removedSchedules.length; ii++) {
          const schNbr = removedSchedules[ii];
          const index = this.getScheduleFormGroupIndex(schNbr);
          if (index >= 0) {
            this.schedulesFormArray.removeAt(index);
          }
        }

        // add new schedules to array
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let ii = 0; ii < activeScheduleNumbers.length; ii++) {
          const schNbr = activeScheduleNumbers[ii];
          const index = this.getScheduleFormGroupIndex(schNbr);
          if (index < 0) {
            const group = this.formBuilder.group({
              [LinehaulScheduleFormFields.SchSequenceNbr]: schNbr,
            });
            this.schedulesFormArray.push(group);
          }
        }

        // this needs to happen AFTER we've rebuilt the FormArray!
        this.currentSchedulesSubject.next(LoDash.sortBy(activeScheduleNumbers, s => s));
      });
  }

  private watchHeaderService(): void {
    this.headerService.listPayformsClicked$.pipe(takeUntil(this.unsubscriber.done)).subscribe(cancel => {
      if (cancel) {
        this.handleCancelClicked();
      }
    });
  }

  private watchFooterService(): void {
    this.footerService.buttonClicked$.pipe(takeUntil(this.unsubscriber.done)).subscribe(buttonClick => {
      switch (buttonClick) {
        case FooterLabels.Cancel:
          this.handleCancelClicked();
          break;
        case FooterLabels.Delete:
          this.handleDeletePayformClicked();
          break;
        case FooterLabels.UndoDelete:
          this.handleUndoDeletePayformClicked();
          break;
        case FooterLabels.ReturnToDsr:
          this.handleReturnPayformToDsrClicked();
          break;
        case FooterLabels.SubmitAndOverride:
          this.handleSubmitAndOverridePayformClicked();
          break;
        case FooterLabels.Override:
          this.handleOverridePayformClicked();
          break;
        case FooterLabels.Save:
          this.handleSavePayformClicked();
          break;
        case FooterLabels.Approve:
          this.handleApprovePayformClicked();
          break;
        case FooterLabels.Debug:
          this.handleDebugClicked();
          break;
      }
    });
  }

  private watchErrorsWarnings(): void {
    merge(this.errorWarningService.PayformWarning$, this.errorWarningService.DetailWarning$, this.errorWarningService.ActivityWarning$)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(() => {
        this.checkFormWarnings();
      });
  }

  private getScheduleFormGroupIndex(schNbr: string): number {
    // find the index in the array
    for (let ii = 0; ii < this.schedulesFormArray.controls.length; ii++) {
      const ctrl = this.schedulesFormArray.at(ii).get(LinehaulScheduleFormFields.SchSequenceNbr) as UntypedFormControl;
      if (ctrl.value === schNbr) {
        return ii;
      }
    }
    return -1;
  }

  public getScheduleFormGroup(schNbr: string): UntypedFormGroup {
    const index = this.getScheduleFormGroupIndex(schNbr);
    if (index >= 0) {
      return this.schedulesFormArray.at(index) as UntypedFormGroup;
    } else {
      return undefined;
    }
  }

  getSchedule(schSequenceNbr: string): LinehaulSchedule {
    return (this.routePayform?.linehaulSchedule ?? []).find(s => s.schSequenceNbr === schSequenceNbr);
  }

  getScheduleIndex(schSequenceNbr: string) {
    return (this.routePayform?.linehaulSchedule ?? []).findIndex(sch => sch.schSequenceNbr === schSequenceNbr) + 1;
  }

  expandCollapseClicked(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  editClicked() {}

  private handleDebugClicked() {
    const controls = FormUtils.getControlState(this.form, 'Payform', true);
    console.log(JSON.stringify(controls));
  }

  private handleCancelClicked() {
    if (!this.isReadOnly && !this.driverPayService.isPristinePayform()) {
      const payform = this.routePayform;
      const isNewPayform = payform.dsrPayformId === this.constants.NewPayformId;

      if (!isNewPayform && payform.statusCd === PayformStatusCd.NEW) {
        this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
      } else {
        this.driverPayService
          .showCancelPayformDialog(isNewPayform)
          .pipe(take(1))
          .subscribe(results => {
            if (results) {
              this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
            }
          });
      }
    } else {
      this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
    }
  }

  private handleApprovePayformClicked() {
    const payform = this.currentPayform;

    if (this.driverPayService.isEmptyPayform(payform)) {
      this.driverPayService.showEmptyPayformDialog(EmptyPayformSaveTypes.ApproveNoSchedule);
    } else if (!(this.driverPayService.hasSchedules(payform) || this.driverPayService.hasPaidActivities(payform))) {
      this.driverPayService.showEmptyPayformDialog(EmptyPayformSaveTypes.ApproveNoSchdOrPdActvty);
    } else if (this.form.invalid) {
      this.driverPayService.showPayformErrorDialog();
    } else {
      if (!this.isReadOnly && !this.driverPayService.isPristinePayform()) {
        this.driverPayService
          .showApproveReasonFeedbackDialog()
          .pipe(take(1))
          .subscribe((dialogResults: any) => {
            if (dialogResults) {
              const feedback = dialogResults.feedback;
              const results = dialogResults.results;

              this.handleApproveNextAction(results, feedback);
            }
          });
      } else {
        this.driverPayService
          .showApprovePayformDialog()
          .pipe(take(1))
          .subscribe((dialogResults: ApproveDialogResults) => {
            this.handleApproveNextAction(dialogResults);
          });
      }
    }
  }

  private handleApproveNextAction(dialogResults: ApproveDialogResults, feedback?: string) {
    if (dialogResults === ApproveDialogResults.ApproveAndViewPayforms || dialogResults === ApproveDialogResults.ApproveAndNextPayform) {
      this.payformService.approvePayform(feedback).subscribe(results => {
        if (results) {
          this.xpoSnackBar.success(SnackbarMessage.PayformApproved);

          if (dialogResults === ApproveDialogResults.ApproveAndViewPayforms) {
            this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
          } else {
            this.fetchNextSubmittedPayform(results.dsrPayform.dmclSic);
          }
        }
      });
    }
  }

  private fetchNextSubmittedPayform(sic: string) {
    const request = new GetNextSubmittedPayformBySicRqst();
    request.sic = sic;
    request.includeZone = this.config.getSetting<boolean>(ConfigManagerProperties.includeZones);

    const queryParams = new GetNextSubmittedPayformBySicQuery();
    queryParams.includeZone = request.includeZone ? 'true' : 'false';

    this.linehaulService
      .getNextSubmittedPayformBySic(request, queryParams)
      .pipe(take(1))
      .subscribe(
        response => {
          if (response && response.dsrPayform) {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.stateStore.setPayformReadOnlyAction(true);
            this.router.navigate([`/${RouterUriComponents.VIEW_PAYFORM}/${response.dsrPayform?.dsrPayformId}`]);
          } else {
            this.xpoSnackBar.success(SnackbarMessage.NoMoreSubmittedPayforms);
            this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
          }
        },
        error => {
          this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
        }
      );
  }

  private submitPayform(feedback?: string) {
    this.payformService.submitPayform(feedback).subscribe(
      upsertResults => {
        if (upsertResults) {
          this.stateStore.setPayformReadOnlyAction(true);
          this.xpoSnackBar.success(SnackbarMessage.PayformUpdated);

          if (this.routePayform?.dsrPayformId !== upsertResults.dsrPayform.dsrPayformId) {
            // load new Payform page
            this.router.navigate([`/${RouterUriComponents.VIEW_PAYFORM}/${upsertResults.dsrPayform.dsrPayformId}`]);
          } else {
            // update the existing Payform state
            this.stateStore.setCurrentPayformAction(upsertResults.dsrPayform);
          }
        }
      },
      error => {
        const errorMessage = error.error.moreInfo[0].message;
        this.xpoSnackBar.open({
          message: errorMessage,
          status: 'error',
          matConfig: {
            duration: this.config.getSetting<number>(ConfigManagerProperties.errorToastDuration),
          },
        });
      }
    );
  }

  private handleSavePayformClicked() {
    const payform = this.routePayform;
    if (this.driverPayService.isPristinePayform()) {
      // Perhaps display a dialog?
    } else if (this.driverPayService.isEmptyPayform(payform)) {
      this.driverPayService.showEmptyPayformDialog(EmptyPayformSaveTypes.SaveReturnNoSchedule);
    } else if (this.form.invalid) {
      this.driverPayService.showPayformErrorDialog();
    } else {
      const isNewPayform = payform.dsrPayformId === this.constants.NewPayformId;
      this.driverPayService
        .showEditReasonFeedbackDialog(isNewPayform)
        .pipe(take(1))
        .subscribe(feedback => {
          if (feedback) {
            this.submitPayform(feedback);
          }
        });
    }
  }

  private handleOverridePayformClicked() {
    this.driverPayService
      .showOverridePayformDialog()
      .pipe(take(1))
      .subscribe(results => {
        if (results) {
          this.submitPayform();
        }
      });
  }

  private handleSubmitAndOverridePayformClicked() {
    this.driverPayService
      .showSubmitAndOverridePayformDialog()
      .pipe(take(1))
      .subscribe(results => {
        if (results) {
          const payform = this.routePayform;
          payform.submtDateTimeUtc = new Date();

          this.submitPayform();
        }
      });
  }

  private handleDeletePayformClicked() {
    this.driverPayService
      .showDeleteReasonFeedbackDialog()
      .pipe(take(1))
      .subscribe(feedbackResults => {
        if (feedbackResults) {
          this.payformService.deletePayform(feedbackResults).subscribe(results => {
            if (results) {
              this.xpoSnackBar.success(SnackbarMessage.PayformDeleted);
              this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
            }
          });
        }
      });
  }

  private handleUndoDeletePayformClicked() {
    const payform = this.routePayform;

    this.driverPayService
      .showUndeletePayformDialog(payform)
      .pipe(take(1))
      .subscribe(results => {
        if (results) {
          this.payformService.restorePayform().subscribe(upsertResults => {
            if (upsertResults) {
              this.xpoSnackBar.success(SnackbarMessage.PayformdUndeleted);

              this.stateStore.setCurrentPayformAction(upsertResults.dsrPayform);
            }
          });
        }
      });
  }

  private handleReturnPayformToDsrClicked() {
    const payform = this.routePayform;
    if (this.driverPayService.isEmptyPayform(payform)) {
      this.driverPayService.showEmptyPayformDialog(EmptyPayformSaveTypes.SaveReturnNoSchedule);
    } else {
      this.driverPayService
        .showReturnReasonFeedbackDialog()
        .pipe(take(1))
        .subscribe(feedbackResults => {
          if (feedbackResults) {
            // submit the Payform as Returned
            this.payformService.returnPayform(feedbackResults).subscribe(upsertResults => {
              if (upsertResults) {
                this.xpoSnackBar.success(SnackbarMessage.FeedbackSentToDsr);
                this.router.navigate([`/${RouterUriComponents.LIST_PAYFORMS}`]);
              }
            });
          }
        });
    }
  }

  checkFormWarnings() {
    const warnings = this.errorWarningService.collectWarnings(this.form, false);
    this.warningsCollectionSubject.next(warnings);
  }

  private requestDmsclStateCd() {
    const sicCd = this.form[PayformFormFields.DmclSic];
    this.serviceCenterCacheService.request({ sicCd }).subscribe(response => {
      const responseDmclStateCd = response.address ? response.address.stateCd : undefined;
      this.form[PayformFormFields.DmclStateCd] = responseDmclStateCd;
      this.form.updateValueAndValidity();
    });
  }
}
