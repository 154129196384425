<div class="move-activity">
    <h2 md-dialog-title>Move Activity</h2>
    <mat-dialog-content fxLayout="column" class="move-activity-content">
      <div fxLayout="row" class="move-activity-content-header">
        Which schedule would you like to move this activity to?
      </div>
      <mat-selection-list #selectList tabindex="-1">
        <div *ngFor="let schedule of scheduleList">
          <mat-list-option *ngIf="schedule.schSequenceNbr !== data.originalScheduleNumber" [value]="schedule" [ngClass]="{'selected': schedule === selectedSchedule}">
            <div fxLayout="row" fxLayoutAlign="stretch center" fxFill="" class="move-activity-content-row">
              {{schedule.name}}
            </div>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </mat-dialog-content>
    <mat-dialog-actions class="move-activity-actionbar">
      <button mat-button
              color="primary"
              (click)="cancelClicked()"
              tabindex="-1">Cancel</button>
      <button mat-button
              color="primary"
              (click)="doneClicked()"
              [disabled]="!selectedSchedule"
              tabindex="-1">Done</button>
    </mat-dialog-actions>
  </div>
