import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UpperCasePipe } from '@angular/common';
import { DsrPayform, LinehaulSchedule, Note, ScheduleDetour } from '@xpo-ltl/sdk-linehaulpayform';
import { RoutingInstructions } from '@xpo-ltl/sdk-reference';
import { ActionPatchData, AppStateUtils, PayPortalStateStore, PayformState } from '../../../../../../../app/state';
import { BehaviorSubject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { FormStateDirective } from '../../../../../../classes/form-state.component';
import { FormUtils } from '../../../../../../classes/form-utils.class';
import { WarningValidationType } from '../../../../../../enums';
import { LinehaulScheduleFormFields } from '../../../../../../enums/linehaul-schedule-form-fields.enum';
import { RouteDetourErrorType } from '../../../../../../enums/route-detour-error-types.enum';
import { ScheduleDetourFormFields } from '../../../../../../enums/schedule-detour-form-fields.enum';
import { RoutingInstructionsCacheService } from '../../../../../../services';
import { ErrorWarningService } from '../../../../services';
import { DetourFormBuilder } from './detour.form-builder';

import { XpoAngularUtilsService } from '../../../../../../utils/angular-utils/services';
import { LoDash } from '../../../../../../utils/angular-utils/lodash-utils';

@Component({
  selector: 'app-detour',
  templateUrl: './detour.component.html',
  styleUrls: ['./detour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetourComponent extends FormStateDirective implements OnInit, AfterViewInit {
  public totalMilesExpression = new RegExp('^[-,0-9]$');

  @Input()
  schSequenceNbr: string;

  @Input()
  readonly = true;
  @Input()
  warningsCollection: Array<Object>;

  notes: Note[];

  @ViewChild('noteButton')
  noteButton: ElementRef;

  @ViewChild('detourTotalMiles')
  detourTotalMiles: ElementRef;

  public badgeSize = 'small';
  public fieldsGroup: UntypedFormGroup;

  public readonly ScheduleDetourFormFields = ScheduleDetourFormFields;
  public readonly WarningValidationType = WarningValidationType;
  public readonly LinehaulScheduleFormFields = LinehaulScheduleFormFields;
  public readonly RouteDetourErrorType = RouteDetourErrorType;

  public detourEnabled = false;
  private detourSubject = new BehaviorSubject<ScheduleDetour>(undefined);
  public detour$ = this.detourSubject.asObservable();
  public currentPayform: DsrPayform;

  constructor(
    public utils: XpoAngularUtilsService,
    protected formBuilder: UntypedFormBuilder,
    public errorWarningService: ErrorWarningService,
    private routingInstructionsCacheService: RoutingInstructionsCacheService,
    private upperCasePipe: UpperCasePipe,
    private state: PayPortalStateStore
  ) {
    super(formBuilder);
  }

  ngOnInit() {
    // Create the FormGroup
    this.linkFormToParent(LinehaulScheduleFormFields.DetourControl, DetourFormBuilder.create, this.errorWarningService, this.schSequenceNbr);
    this.fieldsGroup = this.form.get(ScheduleDetourFormFields.DtrFields) as UntypedFormGroup;

    this.state
      .getCurrentPayformState()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(payform => {
        this.currentPayform = payform;
      });
  }

  ngAfterViewInit() {
    this.form
      .get(LinehaulScheduleFormFields.DetourInd)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe(value => {
        if (this.detourEnabled !== value) {
          this.detourEnabled = value;
          this.updateToState();
        }
      });

    this.state
      .getPayformState()
      .pipe(
        takeUntil(this.unsubscriber.done),
        map((state: PayformState) => AppStateUtils.getScheduleFromPayform(state.current, this.schSequenceNbr))
      )
      .subscribe((schedule: LinehaulSchedule) => {
        this.updateFromState(schedule);

        if (schedule?.detourInd) {
          this.fieldsGroup.enable();
        } else {
          this.fieldsGroup.disable();
        }

        if (schedule?.originSicCd && AppStateUtils.getDestination(schedule)) {
          this.routingInstructionsCacheService
            .request({
              origSicCd: schedule?.originSicCd,
              destSicCd: AppStateUtils.getDestination(schedule),
              drivingLegId: schedule?.drivingLegId,
            })
            .pipe(take(1))
            .subscribe((routes: RoutingInstructions[]) => {
              const detourIndCtrl = this.form.get(LinehaulScheduleFormFields.DetourInd);

              if (LoDash.isEmpty(routes)) {
                if (!schedule?.detourInd) {
                  this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData: { detourInd: true } });
                }
                detourIndCtrl.disable();
              } else {
                detourIndCtrl.enable();
              }
            });
          this.form.updateValueAndValidity();
        }
      });
  }

  toggleChange() {
    this.fieldsGroup.enable();
  }

  onBlur() {
    if (this.fieldsGroup && this.fieldsGroup.get(ScheduleDetourFormFields.DtrTotalExMil) && this.fieldsGroup.get(ScheduleDetourFormFields.DtrTotalExMil).value === '-') {
      this.fieldsGroup.get(ScheduleDetourFormFields.DtrTotalExMil).setErrors({ invalid: true });
      this.form.updateValueAndValidity();
    } else {
      this.updateToState();
    }
  }

  updateFromState(schedule: LinehaulSchedule) {
    this.detourEnabled = schedule?.detourInd;
    this.detourSubject.next(schedule?.scheduleDetour);

    FormUtils.setValues(this.form, {
      [LinehaulScheduleFormFields.DetourInd]: schedule?.detourInd,
    });

    const detour = schedule?.scheduleDetour;

    const fieldsGroup = this.form.get(ScheduleDetourFormFields.DtrFields) as UntypedFormGroup;
    FormUtils.setValues(fieldsGroup, {
      [ScheduleDetourFormFields.DtrHwUsed]: LoDash.get(detour, 'dtrHwUsed', undefined),
      [ScheduleDetourFormFields.DtrState]: LoDash.get(detour, 'dtrState', undefined),
      [ScheduleDetourFormFields.DtrTotalExMil]: LoDash.get(detour, 'dtrTotalExMil', undefined),
    });

    this.form.updateValueAndValidity();
  }

  updateToState() {
    const schedule = this.currentPayform.linehaulSchedule.find(s => s.schSequenceNbr === this.schSequenceNbr);

    const patchData: ActionPatchData = {
      detourInd: this.detourEnabled,
      scheduleDetour: {
        dsrPayformId: this.currentPayform.dsrPayformId,
        schSequenceNbr: this.schSequenceNbr,
        dtrHwUsed: this.fieldsGroup.get(ScheduleDetourFormFields.DtrHwUsed).value,
        dtrState: this.upperCasePipe.transform(this.fieldsGroup.get(ScheduleDetourFormFields.DtrState).value),
        dtrTotalExMil: this.fieldsGroup.get(ScheduleDetourFormFields.DtrTotalExMil).value,
      },
    };

    // ensure there was a change before we try to patch
    if (AppStateUtils.doesPatchChangeState(schedule, patchData)) {
      this.state.patchScheduleAction({ schSequenceNbr: this.schSequenceNbr, patchData });
    }
  }

  totalMilesKeydown($event) {
    if ($event.key === '-') {
      const detourElement = this.detourTotalMiles.nativeElement;
      const detourMiles = this.fieldsGroup.get(ScheduleDetourFormFields.DtrTotalExMil).value ? `${this.fieldsGroup.get(ScheduleDetourFormFields.DtrTotalExMil).value}` : '';
      const allTextSelected = detourElement.selectionStart === 0 && detourElement.selectionEnd === detourMiles.length && detourMiles.length > 0;
      if (detourMiles && detourMiles.length > 0 && !allTextSelected) {
        $event.preventDefault();
        $event.stopPropagation();
      }
    }
  }
}
